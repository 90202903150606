import { APP_NAME } from "@constants/app.constants";

import UrbanWardCreateComponent from "@screens/master-urban/urban-ward/urban-ward-create/urban-ward-create";
import UrbanWardEditComponent from "@screens/master-urban/urban-ward/urban-ward-edit/urban-ward-edit";
import UrbanWardFileUploadComponent from "@screens/master-urban/urban-ward/urban-ward-file/urban-ward-file";
import UrbanWardListComponent from "@screens/master-urban/urban-ward/urban-ward-list/urban-ward-list";

const masterUrbanListRoutes = [
  {
    name: "Urban Ward List",
    icon: "life-buoy",
    path: "/master-urban/urban-ward",
    component: UrbanWardListComponent,
  },

  {
    name: `Create Urban Ward - ${APP_NAME}`,
    path: "/master-urban/urban-ward/create",
    component: UrbanWardCreateComponent,
  },

  {
    name: `Edit Urban Ward - ${APP_NAME}`,
    path: "/master-urban/urban-ward/edit",
    component: UrbanWardEditComponent,
  },

  {
    name: `Upload File Urban Ward - ${APP_NAME}`,
    path: "/master-urban/urban-ward/upload",
    component: UrbanWardFileUploadComponent,
  },
];

export default masterUrbanListRoutes;
