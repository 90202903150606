import { APP_NAME } from "@constants/app.constants";

import ACVoterCreateComponent from "@screens/catalog/ac-voter/ac-voter-create/ac-voter-create.component";
import ACVoterEditComponent from "@screens/catalog/ac-voter/ac-voter-edit/ac-voter-edit.component";
import ACVoterListComponent from "@screens/catalog/ac-voter/ac-voter-list/ac-voter-list.component";
import ACCreateComponent from "@screens/catalog/ac/ac-create/ac-create.component";
import ACEditComponent from "@screens/catalog/ac/ac-edit/ac-edit.component";
import ACListComponent from "@screens/catalog/ac/ac-list/ac-list.component";
import CandidateCreateComponent from "@screens/catalog/ac/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog/ac/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog/ac/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog/ac/candidate/candidate-list/candidate-list.component";
import UsersListComponent from "@screens/catalog/ac/user/users-list/users-list.component";
import UsersEditComponent from "@screens/catalog/ac/user/users-edit/users-edit.component";
import UsersCreateComponent from "@screens/catalog/ac/user/users-create/users-create.component";
import AcVoterFileUploadComponent from "@screens/catalog/ac-voter/ac-voter-file/ac-voter-file.component";

const catalogAcRoutes = [
  {
    name: `AC - ${APP_NAME}`,
    path: "/catalog/ac",
    icon: "package",
    component: ACListComponent,
  },
  {
    name: `Create AC - ${APP_NAME}`,
    path: "/catalog/ac/create",
    component: ACCreateComponent,
  },
  {
    name: `Edit AC - ${APP_NAME}`,
    path: "/catalog/ac/edit",
    component: ACEditComponent,
  },

  {
    name: `AC Candidate - ${APP_NAME}`,
    path: "/catalog/ac/candidate",
    component: CandidateListComponent,
  },
  {
    name: `Create AC Candidate - ${APP_NAME}`,
    path: "/catalog/ac/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit AC Candidate - ${APP_NAME}`,
    path: "/catalog/ac/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog/ac/candidate/upload",
    component: CandidateFileUploadComponent,
  },
  {
    name: `AC User - ${APP_NAME}`,
    path: "/catalog/ac/user",
    component: UsersListComponent,
  },
  {
    name: `Create AC User - ${APP_NAME}`,
    path: "/catalog/ac/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit AC User - ${APP_NAME}`,
    path: "/catalog/ac/user/edit",
    component: UsersEditComponent,
  },
  {
    name: `AC Voter - ${APP_NAME}`,
    path: "/catalog/ac/voter",
    component: ACVoterListComponent,
  },
  {
    name: `Create AC Voter - ${APP_NAME}`,
    path: "/catalog/ac/voter/create",
    component: ACVoterCreateComponent,
  },
  {
    name: `Edit AC Voter - ${APP_NAME}`,
    path: "/catalog/ac/voter/edit",
    component: ACVoterEditComponent,
  },
  {
    name: `Upload Voter File - ${APP_NAME}`,
    path: "/catalog/ac/voter/upload",
    component: AcVoterFileUploadComponent,
  },
];

export default catalogAcRoutes;
