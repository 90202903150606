import { END_POINT } from "@constants/api.constants";

const { SENT_EMAIL_STATUS_TOGGLE } = END_POINT;

const tableTitle = "Sent Email List";
const keyColumn = "id";
const menuUrl = "reports/sent-email";
const tableColumn = [
  {
    id: "email_id",
    numeric: false,
    disablePadding: true,
    label: "Email Id",
  },
  { id: "subject", numeric: false, disablePadding: true, label: "Subject" },
  {
    id: "email_content",
    numeric: false,
    disablePadding: true,
    label: "Content",
  },
  { id: "to_address", numeric: false, disablePadding: true, label: "Sent To" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isToggleChip: true,
    toggleAction: SENT_EMAIL_STATUS_TOGGLE,
    primaryKey: keyColumn,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];
const breadCrumbs = [
  { name: "Reports", menu_url: null },
  { name: "Sent Email", menu_url: "/reports/sent-email" },
];

const filters = [
  {
    name: "email_id",
    id: "emailId",
    label: "Email Id",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
