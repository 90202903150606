import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const MapVoterFieldsTemplateComponent = ({
  templateModal: { formInputs, formTriggers, handleBackClick },
  templateEvents: { handleFormSubmit, handleTriggerClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Upload File"}
        breadCrumbs={[{ name: "File Upload", menu_url: null }]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <FormBuilderComponent
        formType="group"
        inputFields={formInputs}
        submitTrigger={formTriggers.submitTrigger}
        onSubmit={handleFormSubmit}
      />
    </PageWrapperComponent>
  );
};

export default MapVoterFieldsTemplateComponent;
