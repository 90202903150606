import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  createEntry,
  getDataApi,
} from "./bug-comments-create.component.service";
import { getBugCommentsFormInputs } from "../model/bug-comments.modal";
import BugCommentTemplateCreateComponent from "./bug-comments-create-template.component";
import {
  menuUrl,
  tableColumn,
} from "../bug-comments-list/model/bug-comments-list.constants";
const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BUG_COMMENTS_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const BugCommentsCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const bugId = new URLSearchParams(location.search).get("bugId");
  const title = new URLSearchParams(location.search).get("title");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let commentPayload = {};
    for (let i = 0; i < values.length; i++) {
      commentPayload[values[i].name] = values[i].value;
    }
    commentPayload["bug_id"] = bugId;

    // INFO : call create api
    const commentResponse = await createEntry(commentPayload);

    // INFO : check api response and show relevant alert message
    if (commentResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: commentResponse.message })
      );
    }
  };

  const bugCommentData = async () => {
    let commentPayload = {};
    commentPayload["bug_id"] = bugId;
    const commentData = await getDataApi(commentPayload);
    console.log({ commentData });
    setTableData(commentData.data.list);
  };

  const templateModal = {
    formTriggers,
    formInputs: getBugCommentsFormInputs(),
    tableData,
    tableColumn,
    title,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    bugCommentData,
  };

  return menuAccess ? (
    <BugCommentTemplateCreateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default BugCommentsCreateComponent;
