import { createAction, createReducer } from '@reduxjs/toolkit';

export const setTableData = createAction('table/setTableData');

export const tableReducer = createReducer(
  {
    tableData: {},
    loading: false,
    error: null,
  },
  {
    [setTableData]: (state, action) =>
      (state = {
        ...state,
        tableData: {
          ...state.tableData,
          [action.payload.tableDataKey]: action.payload.results,
        },
      }),
  },
);
