import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "@constants/api.constants";
import TownPanchayatSwapComponent from "./components/town-panchayat-swap.component";

function TownPanchayatListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableControls,
    swapContentType,
    tableParams,
  },
  templateEvents: {
    getData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
  },
}) {
  const history = useHistory();
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  const ActionContent = ({ handleCreateClick }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={handleCreateClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="plus" size={17} />
          &nbsp;
        </button>
      </>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Town Panchayats"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        handleDownload={handleDownload}
        tableControls={tableControls}
        tableParams={tableParams}
        swapContent={
          swapContentType ? (
            <TownPanchayatSwapComponent type={swapContentType} />
          ) : null
        }
      />
    </PageWrapperComponent>
  );
}

export default TownPanchayatListTemplateComponent;
