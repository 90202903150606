import { Card, CardHeader, CardTitle, Table } from "reactstrap";

// Local Scss
import "@core/scss/react/libs/tables/react-dataTable-component.scss";

// Local Components
import { useEffect } from "react";

import DataGridColumnsComponent from "./data-grid-columns.component";
import DataGridDataComponent from "./data-grid-data.component";

const DataGridTableComponent = ({
  templateModal: {
    title,
    tableColumn,
    tableData,
    tableActions,
    tableConfig,
    disableDelete,
    disableActions,
  },
  templateEvents: { dataRefresh },
}) => {
  useEffect(() => {
    dataRefresh();
  }, []);

  const isSelected = () => {
    return false;
  };

  const createSortHandler = () => {
    return false;
  };

  return (
    <Card className="overflow-hidden" id="data-grid-container">
      <>
        <CardHeader style={{ padding: "17px 5px 17px 30px" }}>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      </>
      <>
        <Table className="table-bordered" responsive>
          <DataGridColumnsComponent
            tableColumn={tableColumn}
            tableData={tableData}
            disableActions={disableActions}
            tableActions={tableActions}
            createSortHandler={createSortHandler}
          />
          <DataGridDataComponent
            tableColumn={tableColumn}
            tableData={tableData}
            disableActions={disableActions}
            tableConfig={tableConfig}
            tableActions={tableActions}
            isSelected={isSelected}
          />
        </Table>
      </>
    </Card>
  );
};

export default DataGridTableComponent;
