import { END_POINT } from "@constants/api.constants";

const tableTitle = "Modules";
const keyColumn = "id";
const menuUrl = "system/modules";

const { MODULES_STATUS_TOGGLE } = END_POINT;

const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "slug",
    numeric: false,
    disablePadding: true,
    label: "Slug",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: MODULES_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Enabled",
      2: "Disabled",
    },
    badgeColor: {
      1: "success",
      2: "danger",
    },
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Modules", menu_url: "/system/modules" },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
