import FeatherIcon from "feather-icons-react";

const tableTitle = "Database Backup";
const keyColumn = "id";
const menuUrl = "system/database-backup";
const tableColumn = ({ handleBackupDownloadClick }) => [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "File name",
    columnIcon: {
      icon: <FeatherIcon icon="download" size={17} />,
      onClick: handleBackupDownloadClick,
      position: "right",
    },
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Database Backup", menu_url: "/system/database-backup" },
];

const filters = [
  {
    name: "file_name",
    id: "fileName",
    label: "File Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
