import { formatDate } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "Version List";
const keyColumn = "id";
const menuUrl = "version";
const tableColumn = [
  { id: "title", numeric: false, disablePadding: false, label: "Title" },

  {
    id: "version_no",
    numeric: false,
    disablePadding: false,
    label: "Version No",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: 150,
    formatData: formatRemarks,
  },
  {
    id: "completed_bugs_count",
    numeric: false,
    disablePadding: false,
    label: "Count",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    width: 80,
    formatData: formatDate,
  },
];
const breadCrumbs = [{ name: "Version", menu_url: "/version" }];

const filters = [
  {
    name: "title",
    id: "title",
    label: "Title",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];
const additionalActions = ({ handleViewDetail }) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
];
export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
