import { APP_NAME } from "@constants/app.constants";
import MenuItemCreateComponent from "@screens/system/menu-items/menu-items-create/menu-items-create.component";
import MenuItemEditComponent from "@screens/system/menu-items/menu-items-edit/menu-items-edit.component";
import MenuItemsListComponent from "@screens/system/menu-items/menu-items-list/menu-items-list.component";

const menuItemsRoutes = [
  {
    name: "Menu Item",
    icon: "list",
    path: "/system/menu-items",
    component: MenuItemsListComponent,
  },
  {
    name: `Create Menu - ${APP_NAME}`,
    path: "/system/menu-items/create",
    component: MenuItemCreateComponent,
  },
  {
    name: `Edit Menu - ${APP_NAME}`,
    path: "/system/menu-items/edit",
    component: MenuItemEditComponent,
  },
];

export default menuItemsRoutes;
