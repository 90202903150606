import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStatesFormInputs } from "../model/states.modal";
import { menuUrl } from "../states-list/model/states-list.constants";
import StatesCreateTemplateComponent from "./states-create-template.component";
import { createEntry } from "../states-list/states-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  STATES_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const StatesCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleStateSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let statesPayload = {};
    for (let i = 0; i < values.length; i++) {
      statesPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const statesResponse = await createEntry(currentElection.id, statesPayload);

    // INFO : check api response and show relevant alert message
    if (statesResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: statesResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getStatesFormInputs({}),
  };

  const templateEvents = {
    handleStateSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <StatesCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default StatesCreateComponent;
