import { APP_NAME } from "@constants/app.constants";
import CandidateCreateComponent from "@screens/catalog-rural/village-panchayat-ward/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog-rural/village-panchayat-ward/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog-rural/village-panchayat-ward/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog-rural/village-panchayat-ward/candidate/candidate-list/candidate-list.component";
import VillagePanchayatWardCreateComponent from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-create/village-panchayat-ward-create";
import VillagePanchayatWardEditComponent from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-edit/village-panchayat-ward-edit";
import VillagePanchayatWardListComponent from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-ward-list/village-panchayat-ward-list";
import RuralVoterCreateComponent from "@screens/catalog-rural/village-panchayat-ward/voter/voter-create/voter-create.component";
import RuralVoterEditComponent from "@screens/catalog-rural/village-panchayat-ward/voter/voter-edit/voter-edit.component";
import RuralVoterFileUploadComponent from "@screens/catalog-rural/village-panchayat-ward/voter/voter-file/voter-file.component";
import RuralVoterListComponent from "@screens/catalog-rural/village-panchayat-ward/voter/voter-list/voter-list.component";

const catalogVillagePanchayatWardRoutes = [
  {
    name: "Village Panchayat Ward List",
    icon: "disc",
    path: "/catalog-rural/village-panchayat-ward",
    component: VillagePanchayatWardListComponent,
  },

  {
    name: `Create Village Panchayat Ward- ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/create",
    component: VillagePanchayatWardCreateComponent,
  },

  {
    name: `Edit Village Panchayat Ward- ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/edit",
    component: VillagePanchayatWardEditComponent,
  },

  {
    name: `Village Panchayat Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/candidate",
    component: CandidateListComponent,
  },

  {
    name: `Create Village Panchayat Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit Village Panchayat Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/candidate/upload",
    component: CandidateFileUploadComponent,
  },

  {
    name: `Village Panchayat Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/voter",
    component: RuralVoterListComponent,
  },

  {
    name: `Create Village Panchayat Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/voter/create",
    component: RuralVoterCreateComponent,
  },
  {
    name: `Edit Village Panchayat Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/voter/edit",
    component: RuralVoterEditComponent,
  },

  {
    name: `Village Panchayat Ward File Upload - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat-ward/voter/upload",
    component: RuralVoterFileUploadComponent,
  },
];

export default catalogVillagePanchayatWardRoutes;
