import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const {
  ELECTION_URBAN_LIST,
  ELECTION_DELETE,
  USER_SET_CURRENT_ELECTION,
  ELECTION_ALL_LIST,
  ELECTION_LIST_BY_STATENAME,
  ELECTION_FILES_UPLOAD,
} = END_POINT;

export const setCurrentElectionAPI = async (electionId) => {
  return await fetch(USER_SET_CURRENT_ELECTION, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ election_id: electionId }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data,
          message: "Current election updated successfully",
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getDataApi = async ({ orderBy, sortBy, skip, limit, filters }) => {
  return await fetch(
    `${ELECTION_URBAN_LIST}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}&election_category=URBAN`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteDataApi = async (ids) => {
  return await fetch(ELECTION_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const getAllElection = async () => {
  return await fetch(ELECTION_ALL_LIST, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getElectionByState = async () => {
  return await fetch(ELECTION_LIST_BY_STATENAME, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const uploadElectionFiles = async (electionFiles) => {
  const formData = new FormData();
  formData.append("election_files", electionFiles);
  return await fetch(ELECTION_FILES_UPLOAD, {
    method: "POST",
    headers: {
      token: store.getState().app.token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return { status: true, data: data.data, message: data.message };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
