import { APP_NAME } from "@constants/app.constants";
import ModulesCreateComponent from "@screens/system/modules/modules-create/modules-create.component";
import ModulesEditComponent from "@screens/system/modules/modules-edit/modules-edit.component";
import ModulesListComponent from "@screens/system/modules/modules-list/modules-list.component";

const modulesRoutes = [
  {
    name: "Modules",
    icon: "grid",
    path: "/system/modules",
    component: ModulesListComponent,
  },
  {
    name: `Create Module - ${APP_NAME}`,
    path: "/system/modules/create",
    component: ModulesCreateComponent,
  },
  {
    name: `Edit Module - ${APP_NAME}`,
    path: "/system/modules/edit",
    component: ModulesEditComponent,
  },
];

export default modulesRoutes;
