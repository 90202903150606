import React from "react";
import FormInputAutoCompleteComponent from "@atoms/form-input/form-input-autocomplete/form-input-autocomplete.component";
import FormInputFileUploadComponent from "@atoms/form-input/form-input-file-upload/form-input-file-upload.component";
import FormInputSelectComponent from "@atoms/form-input/form-input-select/form-input-select.component";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";
import FormRepeaterComponent from "@molecules/form-repeater/form-repeater.component";
import FormInputTextAreaComponent from "@atoms/form-input/form-input-textarea/form-input-textarea.component";
import FormInputDateComponent from "@atoms/form-input/form-input-date/form-input-date.component";
import FormInputRadioButtonComponent from "@atoms/form-input/form-input-radio/form-input-radio.component";
import FormInputRichTextComponent from "@atoms/form-input/form-input-richtext/form-input-richtext.component";
import FormInputSelectGroupComponent from "@atoms/form-input/form-input-select-group/form-input-select-group.component";
import FormInputCheckboxMultipleComponent from "@atoms/form-input/form-input-checkbox/form-input-checkbox-multiple.component";
import FormInputTextAutoCompeleteComponent from "@atoms/form-input/form-input-text-autocomplete/form-input-text-autocomplete.component";
import FormInputCheckboxComponent from "@atoms/form-input/form-input-checkbox/form-input-checkbox.component";
import FormInputCodeComponent from "@atoms/form-input/form-input-code/form-input-code.component";

const FormBuilderRenderInputs = ({
  changeCount,
  index,
  formInput,
  formInputs,
  handleChange,
  handleKeyDown,
  handleFileUpload,
  handleDeleteFile,
  handleImagePreview,
}) => {
  if (formInput.isHide) return <></>;
  const checkRequired = (formInput) => {
    let isRequired = false;
    if (formInput?.validations)
      for (let i = 0; i < formInput.validations.length; i++) {
        if (formInput.validations[i].type === "REQUIRED") isRequired = true;
      }
    return isRequired;
  };

  return (
    <>
      {formInput.type === "TEXT" && (
        <FormInputTextComponent
          label={formInput.label}
          type={"text"}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          placeholder={formInput.placeholder}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          inputIcon={formInput.inputIcon}
          handleInputIconClick={async () => {
            if (formInput.handleInputIconClick)
              await formInput.handleInputIconClick(formInputs);
          }}
          onKeyDown={handleKeyDown}
          isRequired={checkRequired(formInput)}
          onChange={(value) => handleChange(formInput, index, value)}
        />
      )}

      {formInput.type === "PASSWORD" && (
        <FormInputTextComponent
          type={"password"}
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          placeholder={formInput.placeholder}
          onKeyDown={handleKeyDown}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
          onChange={(value) => handleChange(formInput, index, value)}
        />
      )}

      {formInput.type === "SELECT" && (
        <FormInputSelectComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          options={formInput.options}
          placeholder={formInput.placeholder}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          onKeyDown={handleKeyDown}
          isRequired={checkRequired(formInput)}
          onChange={(value) => handleChange(formInput, index, value)}
        />
      )}

      {formInput.type === "CHECKBOX" && (
        <FormInputCheckboxComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          options={formInput.options}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
          onChange={(value, optionIndex) =>
            handleChange(formInput, index, value, {
              isMultiSelect: formInput.isMultiSelect,
              optionIndex,
            })
          }
        />
      )}

      {formInput.type === "CHECKBOX-MULTIPLE" && (
        <FormInputCheckboxMultipleComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          options={formInput.options}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
          onChange={(value, optionIndex) =>
            handleChange(formInput, index, value, {
              isMultiSelect: formInput.isMultiSelect,
              optionIndex,
            })
          }
        />
      )}

      {formInput.type === "SELECT-GROUP" && (
        <FormInputSelectGroupComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          error={formInput.error}
          isSearchable={formInput.isSearchable}
          options={formInput.options}
          placeholder={formInput.placeholder}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
          onChange={(value, optionIndex) =>
            handleChange(formInput, index, value, {
              isMultiSelect: formInput.isMultiSelect,
              optionIndex,
            })
          }
        />
      )}

      {formInput.type === "AUTOCOMPLETE" && (
        <FormInputAutoCompleteComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          options={formInput.options}
          placeholder={formInput.placeholder}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          onChange={(value) => handleChange(formInput, index, value)}
          isRequired={checkRequired(formInput)}
          isMulti={formInput.isMultiple || false}
        />
      )}

      {formInput.type === "TEXT_AUTOCOMPLETE" && (
        <FormInputTextAutoCompeleteComponent
          label={formInput.label}
          id={formInput.id}
          name={formInput.name}
          value={formInput.value}
          error={formInput.error}
          options={formInput.options}
          placeholder={formInput.placeholder}
          inputIcon={formInput.inputIcon}
          handleInputIconClick={() => {
            console.log("coming here");
            if (formInput.handleInputIconClick)
              formInput.handleInputIconClick(formInputs);
          }}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          onKeyDown={handleKeyDown}
          onChange={(value) => handleChange(formInput, index, value)}
          isRequired={checkRequired(formInput)}
          isMulti={formInput.isMultiple || false}
        />
      )}

      {formInput.type === "FILE_UPLOAD" && (
        <FormInputFileUploadComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          value={formInput.value}
          filename={formInput.filename}
          loader={formInput.loading}
          error={formInput.error}
          staticHeader={formInput.staticHeader || false}
          isMultiple={formInput.multipleUpload}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
          handleDeleteFile={(file) => handleDeleteFile(formInput, index, file)}
          handleUpload={(e) =>
            handleFileUpload(formInput, index, e.target.files)
          }
        />
      )}

      {formInput.type === "TEXT_AREA" && (
        <FormInputTextAreaComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          placeholder={formInput.placeholder}
          onChange={(value) => handleChange(formInput, index, value)}
          error={formInput.error}
          value={formInput.value}
          isDisabled={formInput?.isDisabled || false}
          isVertical={formInput.isVertical || false}
          isRequired={checkRequired(formInput)}
          height={formInput?.height || 150}
        />
      )}

      {formInput.type === "CALENDER" && (
        <FormInputDateComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          placeholder={formInput.placeholder}
          onChange={(value) => handleChange(formInput, index, value)}
          error={formInput.error}
          value={formInput.value}
          isTimeEnabled={formInput?.isTimeEnabled || false}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
        />
      )}

      {formInput.type === "FORM_REPEATER" && (
        <FormRepeaterComponent
          formInput={formInput}
          formInputs={formInputs}
          changeCount={changeCount}
          generalInputs={formInput.generalInputs}
          reloadInputs={formInput?.reloadInputs || 0}
          columns={formInput.inputs}
          value={formInput.value}
          onChange={(value) =>
            handleChange(formInput, index, value, null, true)
          }
          handleImagePreview={handleImagePreview}
        />
      )}

      {formInput.type === "RADIO" && (
        <FormInputRadioButtonComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          placeholder={formInput.placeholder}
          onChange={(value) => handleChange(formInput, index, value)}
          error={formInput.error}
          value={formInput.value}
          options={formInput.options}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
        />
      )}

      {formInput.type === "RICH_TEXT" && (
        <FormInputRichTextComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          placeholder={formInput.placeholder}
          onChange={(value) => handleChange(formInput, index, value)}
          error={formInput.error}
          value={formInput.value}
          options={formInput.options}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
        />
      )}

      {formInput.type === "CODE" && (
        <FormInputCodeComponent
          id={formInput.id}
          name={formInput.name}
          label={formInput.label}
          placeholder={formInput.placeholder}
          onChange={(value) => handleChange(formInput, index, value)}
          error={formInput.error}
          value={formInput.value}
          options={formInput.options}
          isVertical={formInput.isVertical || false}
          isDisabled={formInput?.isDisabled || false}
          isRequired={checkRequired(formInput)}
        />
      )}

      {formInput.type === "CUSTOM_COMPONENT" && (
        <formInput.component formInputs={formInputs} />
      )}
    </>
  );
};

export default FormBuilderRenderInputs;
