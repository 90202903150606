import React from "react";

const FormInputSelectComponent = ({
  label,
  id,
  name,
  value,
  placeholder,
  error,
  options,
  onChange,
  isVertical,
}) => {
  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };
  return (
    <div className="col-12">
      <div className={isVertical ? "" : "mb-1 row"}>
        <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
          <label className="col-form-label"> {label} </label>
        </div>
        <div className={isVertical ? "col-sm-12" : "col-sm-9"}>
          <select
            className={`form-select ${error ? "is-invalid" : ""}`}
            name={name}
            id={id}
            onChange={handleChange}
            placeholder={placeholder}
          >
            <option value={""}> - {placeholder} - </option>
            {options.map((option) => (
              <option
                key={option.value}
                selected={`${option.value == value ? "selected" : ""}`}
                value={option.value}
              >
                {option.name}
              </option>
            ))}
          </select>
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default FormInputSelectComponent;
