import { createAction, createReducer } from '@reduxjs/toolkit';

export const setAlertMessage = createAction('alert/setAlertMessage');

export const alertReducer = createReducer(
  {
    alertMessage: null,
    closeAlert: false,
  },
  {
    [setAlertMessage]: (state, action) => (state = { ...state, alertMessage: action.payload }),
  },
);
