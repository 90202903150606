import React from "react";
import ACUrbanDashboardComponent from "../dashboard/dashboard.component";
import ACUrbanChartComponent from "./chart.component";

const ACUrbanSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <ACUrbanChartComponent />}
      {type === "DASHBOARD" && <ACUrbanDashboardComponent />}
    </>
  );
};

export default ACUrbanSwapComponent;
