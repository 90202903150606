import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";

const ACDashboardTemplateComponent = ({ templateModal: { dashboardData } }) => {
  console.log({ dashboardData });
  return (
    <PageWrapperComponent>
      <div className="app-user-list">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalAC}
                    </h3>
                    <p className="card-text">Total AC</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-plus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default ACDashboardTemplateComponent;
