import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { APP_NAME, FORGOT_PASSWORD } from "@constants/app.constants";

const ForgotPasswordWrapperComponent = ({ children }) => {
  return (
    <div className="blank-page">
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="auth-wrapper auth-basic px-2">
              <div className="auth-inner my-2">
                <div className="card mb-0">
                  <div className="card-body">
                    <Link to="/" className="brand-logo">
                      <h2 className="brand-text text-primary">{APP_NAME}</h2>
                    </Link>
                    <h4 className="card-title mb-1"> {FORGOT_PASSWORD} 🔒</h4>
                    <p className="card-text mb-2">
                      Enter your username and we'll send you instructions to reset your password
                    </p>
                    {children}
                    <p className="text-center mt-2">
                      <Link to="/">
                        <FeatherIcon icon="chevron-left" className="rotate-rtl me-25" size={14} />
                        <span className="align-middle fw-bold">Back to login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordWrapperComponent;
