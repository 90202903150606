import { APP } from "@constants/app.constants";
const { MENU_STATUS, MENU_TYPE } = APP;

export const getMenuItemFormInputs = (mainMenus, modules, initialValues) => [
  {
    type: "TEXT",
    label: "Menu Name",
    id: "MenuName",
    name: "menu_name",
    value: initialValues?.menuName || "",
    placeholder: "Enter the Menu name",
    validations: [
      {
        type: "REQUIRED",
        message: "Menu name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Menu Url",
    id: "menuUrl",
    name: "menu_url",
    value: initialValues?.menuUrl || "",
    placeholder: "Enter the Menu Url",
    validations: [
      {
        type: "REQUIRED",
        message: "Menu Url is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Menu Icon",
    id: "menuIcon",
    name: "menu_icon",
    value: initialValues?.menuIcon || "",
    placeholder: "Enter the Menu Icon",
    validations: [
      {
        type: "REQUIRED",
        message: "Menu Icon is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type",
    options: MENU_TYPE,
    value: initialValues?.type || "Menu",
    placeholder: "Select Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Type is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Main Menu",
    id: "parentId",
    name: "parent_id",
    options: mainMenus,
    value: initialValues?.parentId || "",
    placeholder: "Select Main Menu",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Module",
    id: "moduleId",
    name: "module_id",
    options: modules,
    value: initialValues?.moduleId || "",
    placeholder: "Select Module",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Sort Order",
    id: "sortOrder",
    name: "sort_order",
    value: initialValues?.sortOrder || "",
    placeholder: "Enter the Sort Order",
    validations: [
      {
        type: "REQUIRED",
        message: "Sort Order is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Status",
    id: "roleView",
    name: "role_view",
    options: MENU_STATUS,
    value: initialValues?.roleView || 1,
    placeholder: "Select Status",
    validations: [
      {
        type: "REQUIRED",
        message: "Status is required !",
      },
    ],
  },
];
