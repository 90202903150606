import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";
import { APP } from "@constants/app.constants";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";

const { RESERVATION_TYPE_2 } = APP;
const tableTitle = "Town Panchayat";
const keyColumn = "id";
const menuUrl = "master-urban/town-panchayat";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District name",
    width: 150,
    sorting: true,
  },

  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Town Panchayat Id",
    sorting: true,
    width: 100,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Town Panchayat name",
    width: 150,
    sorting: true,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager name",
    width: 150,
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },

  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "total_ward_count",
    numeric: false,
    disablePadding: false,
    label: "Total Ward",
    sorting: true,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Master Urban", menu_url: null },
  { name: "Town Panchayat", menu_url: "/master-urban/town-panchayat" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);

      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "reservation",
    id: "reservation",
    label: "Reservation",
    value: "",
    type: "SELECT",
    placeholder: "Select Reservation",
    options: RESERVATION_TYPE_2,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "Town Panchayat name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
