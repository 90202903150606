import { getDistrictByStateId } from "@screens/catalog-urban/districts/districts-list/districts-list.component.service";
import { getUrbanWardByDistrictId } from "@screens/catalog-urban/urban-ward/urban-ward-list/urban-ward-list.service";
import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "Urban Ward Booth List";
const keyColumn = "id";
const menuUrl = "catalog-urban/urban-ward-booth";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District Name",
  },
  {
    id: "pc_name",
    numeric: false,
    disablePadding: false,
    label: "PC Name",
  },
  {
    id: "ac_name",
    numeric: false,
    disablePadding: false,
    label: "AC Name",
  },
  {
    id: "urban_ward_name",
    numeric: false,
    disablePadding: false,
    label: "Urban Ward Name",
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Part No",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Booth name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "VBooth Name",
    sorting: true,
  },
  {
    id: "booth_type",
    numeric: false,
    disablePadding: false,
    label: "Booth Type",
    sorting: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Village name",
    sorting: true,
  },
  {
    id: "location_regional_name",
    numeric: false,
    disablePadding: false,
    label: "V Village name",
    sorting: true,
  },
  {
    id: "client_count",
    numeric: false,
    disablePadding: false,
    label: "Total Clients",
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Total users",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog Urban", menu_url: null },
  { name: "Urban Ward Booth", menu_url: "/catalog-urban/urban-ward-booth" },
];

const additionalActions = ({ handleUserLoginClick }) => [
  // {
  //   name: "Login Users",
  //   icon: "log-in",
  //   buttonClass: "btn btn-outline-info btn-sm",
  //   onClick: handleUserLoginClick,
  // },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const response = await getUrbanWardByDistrictId(value);
      console.log({ response });
      if (response.status) {
        const formattedUrbanWard = response.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });
        formInputs[2].options = formattedUrbanWard;
      }

      return formInputs;
    },
  },
  {
    name: "urban_ward",
    id: "urban_ward",
    label: "Urban Ward Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Urban Ward",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "govt_id",
    id: "govt_id",
    label: "Part No",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];
const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
