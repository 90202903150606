const MESSAGES = {
  ALERT_TYPES: {
    SUCCESS: "success",
    ERROR: "danger",
  },
  STORAGE_KEYS: {
    INTRO_MESSAGE: "@tri_intro_message",
  },
  MENU_ITEMS_ALERTS: {
    CREATE_ALERT: "Menu item created successfully.",
    EDIT_ALERT: "Menu item edited successfully.",
    DELETE_ALERT: "Menu item(s) deleted successfully",
  },
  SETTINGS_ALERTS: {
    CREATE_ALERT: "Setting created successfully.",
    EDIT_ALERT: "Setting edited successfully.",
    DELETE_ALERT: "Setting(s) deleted successfully",
  },
  ROLE_ALERTS: {
    CREATE_ALERT: "Role created successfully.",
    EDIT_ALERT: "Role edited successfully.",
    DELETE_ALERT: "Role(s) deleted successfully",
  },
  USER_ALERTS: {
    CREATE_ALERT: "User created successfully.",
    EDIT_ALERT: "User edited successfully.",
    DELETE_ALERT: "User(s) deleted successfully",
  },
  EMAIL_TEMPLATE_ALERTS: {
    CREATE_ALERT: "Email template created successfully.",
    EDIT_ALERT: "Email template edited successfully.",
    DELETE_ALERT: "Email template(s) deleted successfully",
  },
  BLOCKED_IP_ALERTS: {
    CREATE_ALERT: "Blocked IP created successfully.",
    EDIT_ALERT: "Blocked IP edited successfully.",
    DELETE_ALERT: "Blocked IP(s) deleted successfully",
  },
  SMS_TEMPLATE_ALERTS: {
    CREATE_ALERT: "SMS template created successfully.",
    EDIT_ALERT: "SMS template edited successfully.",
    DELETE_ALERT: "SMS template(s) deleted successfully",
  },
  MODULES_ALERTS: {
    CREATE_ALERT: "Module created successfully.",
    EDIT_ALERT: "Module edited successfully.",
    DELETE_ALERT: "Module(s) deleted successfully",
  },
  USER_LOG_ALERTS: {
    DELETE_ALERT: "User Log(s) deleted successfully",
  },
  SENT_SMS_ALERTS: {
    DELETE_ALERT: "Sent SmS(s) deleted successfully",
  },
  SENT_EMAIL_ALERTS: {
    DELETE_ALERT: "Sent Email(s) deleted successfully",
  },
  NOTIFICATION_ALERTS: {
    CREATE_ALERT: "Notification created successfully.",
    EDIT_ALERT: "Notification edited successfully.",
    DELETE_ALERT: "Notification(s) deleted successfully",
  },
  DATABASE_BACKUP_ALERTS: {
    CREATE_ALERT:
      "Database backup initiated, may take couple of minutes to complete.",
    EDIT_ALERT: "Database Backup edited successfully.",
    DELETE_ALERT: "Database Backup(s) deleted successfully",
  },
  STATES_ALERTS: {
    CREATE_ALERT: "State created successfully.",
    EDIT_ALERT: "State edited successfully.",
    DELETE_ALERT: "State(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  DISTRICTS_ALERTS: {
    CREATE_ALERT: "District created successfully.",
    EDIT_ALERT: "District edited successfully.",
    DELETE_ALERT: "District(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  MASTER_PC_ALERTS: {
    CREATE_ALERT: "PC created successfully.",
    EDIT_ALERT: "PC edited successfully.",
    DELETE_ALERT: "PC(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  MASTER_AC_ALERTS: {
    CREATE_ALERT: "AC created successfully.",
    EDIT_ALERT: "AC edited successfully.",
    DELETE_ALERT: "AC(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  MASTER_AC_BOOTH_ALERTS: {
    CREATE_ALERT: "AC Booth created successfully.",
    EDIT_ALERT: "AC Booth edited successfully.",
    DELETE_ALERT: "AC Booth(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  AC_VOTER_ALERTS: {
    CREATE_ALERT: "Voter created successfully.",
    EDIT_ALERT: "Voter edited successfully.",
    DELETE_ALERT: "Voter(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    DOWNLOAD_REQUEST:
      "File download request sent, you'll receive a email to : ",
    DELETE_ALL_VOTER_DATA: "Deleting all voter data from ",
    NO_VOTER_DATA: "No voters found in ",
  },
  MASTER_VOTER_FIELDS_ALERTS: {
    CREATE_ALERT: "Voter Field created successfully.",
    EDIT_ALERT: "Voter Field edited successfully.",
    DELETE_ALERT: "Voter Field(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
  },
  ELECTION_ALERTS: {
    CREATE_ALERT: "Election created successfully.",
    EDIT_ALERT: "Election edited successfully.",
    DELETE_ALERT: "Election(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
  },

  ELECTION_CANDIDATE_ALERTS: {
    CREATE_ALERT: "Candidate created successfully.",
    EDIT_ALERT: "Candidate edited successfully.",
    DELETE_ALERT: "Candidate(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
  },
  AC_SECTION_ALERTS: {
    CREATE_ALERT: "AC Section created successfully.",
    EDIT_ALERT: "AC Section edited successfully.",
    DELETE_ALERT: "AC Section(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
  },
  SENT_EMAIL_ALERTS: {
    CREATE_ALERT: "Sent Email created successfully.",
    EDIT_ALERT: "Sent Email edited successfully.",
    DELETE_ALERT: "Sent Email(s) deleted successfully",
  },
  FILE_UPLOAD_REQUEST_ALERTS: {
    DELETE_ALERT: "File Upload Request(s) deleted successfully",
  },

  VERSION_ALERTS: {
    CREATE_ALERT: "Version created successfully.",
    EDIT_ALERT: "Version edited successfully.",
    DELETE_ALERT: "Version(s) deleted successfully",
  },

  POLITICAL_PARTY_ALERTS: {
    CREATE_ALERT: "Political Party created successfully.",
    EDIT_ALERT: "Political Party edited successfully.",
    DELETE_ALERT: "Political Party(s) deleted successfully",
  },

  BUG_ALERTS: {
    CREATE_ALERT: "Bug created successfully.",
    EDIT_ALERT: "Bug edited successfully.",
    DELETE_ALERT: "Bug(s) deleted successfully",
  },

  CLIENT_ALERTS: {
    CREATE_ALERT: "Client created successfully.",
    EDIT_ALERT: "Client edited successfully.",
    DELETE_ALERT: "Client(s) deleted successfully",
  },

  LEADS_ALERTS: {
    CREATE_ALERT: "Lead created successfully.",
    EDIT_ALERT: "Lead edited successfully.",
    DELETE_ALERT: "Lead(s) deleted successfully",
  },

  VENDORS_ALERTS: {
    CREATE_ALERT: "Vendor created successfully.",
    EDIT_ALERT: "Vendor edited successfully.",
    DELETE_ALERT: "Vendor(s) deleted successfully",
  },
  BUG_COMMENTS_ALERTS: {
    CREATE_ALERT: "Comment created successfully.",
  },
  CORPORATION_ALERTS: {
    CREATE_ALERT: "Corporation created successfully.",
    EDIT_ALERT: "Corporation edited successfully.",
    DELETE_ALERT: "Corporation(s) deleted successfully",
  },
  MUNICIPALITY_ALERTS: {
    CREATE_ALERT: "Municipality created successfully.",
    EDIT_ALERT: "Municipality edited successfully.",
    DELETE_ALERT: "Municipality(s) deleted successfully",
  },
  TOWN_PANCHAYAT_ALERTS: {
    CREATE_ALERT: "Town Panchayat created successfully.",
    EDIT_ALERT: "Town Panchayat edited successfully.",
    DELETE_ALERT: "Town Panchayat(s) deleted successfully",
  },
  URBAN_WARD_ALERTS: {
    CREATE_ALERT: "Urban Ward created successfully.",
    EDIT_ALERT: "Urban Ward edited successfully.",
    DELETE_ALERT: "Urban Ward(s) deleted successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  AC_URBAN_ALERTS: {
    CREATE_ALERT: "Ac Urban created successfully.",
    EDIT_ALERT: "Ac Urban edited successfully.",
    DELETE_ALERT: "Ac Urban(s) deleted successfully",
  },
  DISTRICT_PANCHAYAT_UNION_ALERTS: {
    CREATE_ALERT: "District Union created successfully.",
    EDIT_ALERT: "District Union edited successfully.",
    DELETE_ALERT: "District Union(s) deleted successfully",
  },
  PANCHAYAT_UNION_ALERTS: {
    CREATE_ALERT: "Panchayat Union created successfully.",
    EDIT_ALERT: "Panchayat Union edited successfully.",
    DELETE_ALERT: "Panchayat Union(s) deleted successfully",
  },
  VILLAGE_PANCHAYAT_ALERTS: {
    CREATE_ALERT: "Village Panchayat created successfully.",
    EDIT_ALERT: "Village Panchayat edited successfully.",
    DELETE_ALERT: "Village Panchayat(s) deleted successfully",
  },
  VILLAGE_PANCHAYAT_WARD_ALERTS: {
    CREATE_ALERT: "Village Panchayat Ward created successfully.",
    EDIT_ALERT: "Village Panchayat Ward edited successfully.",
    DELETE_ALERT: "Village Panchayat Ward(s) deleted successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  BULK_WHATSAPP_ALERTS: {
    CREATE_ALERT: "Bulk WhatsApp created successfully.",
    EDIT_ALERT: "Bulk WhatsApp edited successfully.",
    DELETE_ALERT: "Bulk WhatsApp(s) deleted successfully",
  },
  BULK_VOICE_CALL_ALERTS: {
    CREATE_ALERT: "Bulk Voice Call created successfully.",
    EDIT_ALERT: "Bulk Voice Call edited successfully.",
    DELETE_ALERT: "Bulk Voice Call(s) deleted successfully",
  },
  BULK_MESSAGE_ALERTS: {
    CREATE_ALERT: "Bulk Message created successfully.",
    EDIT_ALERT: "Bulk Message edited successfully.",
    DELETE_ALERT: "Bulk Message(s) deleted successfully",
  },
  CRM_SYMBOL_ALERTS: {
    CREATE_ALERT: "Symbol created successfully.",
    EDIT_ALERT: "Symbol edited successfully.",
    DELETE_ALERT: "Symbol(s) deleted successfully",
  },
  URBAN_WARD_BOOTH_ALERTS: {
    CREATE_ALERT: "Urban Ward Booth created successfully.",
    EDIT_ALERT: "Urban Ward Booth edited successfully.",
    DELETE_ALERT: "Urban Ward Booth(s) deleted successfully",
    FILE_UPLOAD_ALERT: "File uploaded successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  DISTRICT_UNION_WARD_ALERTS: {
    CREATE_ALERT: "District Union Ward created successfully.",
    EDIT_ALERT: "District Union Ward edited successfully.",
    DELETE_ALERT: "District Union Ward(s) deleted successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  PANCHAYAT_UNION_WARD_ALERTS: {
    CREATE_ALERT: "Panchayat Union Ward created successfully.",
    EDIT_ALERT: "Panchayat Union Ward edited successfully.",
    DELETE_ALERT: "Panchayat Union Ward(s) deleted successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },

  RURAL_WARD_BOOTH_ALERTS: {
    CREATE_ALERT: "Rural Ward Booth created successfully.",
    EDIT_ALERT: "Rural Ward Booth edited successfully.",
    DELETE_ALERT: "Rural Ward Booth(s) deleted successfully",
    REFRESH_ALERT: "Refreshed Successfully",
  },
  MAP_VOTER_FIELDS_ALERTS: {
    CREATE_ALERT: "Fields are mapped and File uploaded successfully.",
  },
};

export default MESSAGES;
