import React, { useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
} from "@screens/user-administration/users/user-log-list/model/user-log-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import { getDataApi } from "./user-log-list.component.service";
import { useSelector } from "react-redux";
import UserLogListTemplateComponent from "./user-log-list-template.component";

const { ROWS_PER_PAGE } = APP;

const UsersLogListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const location = useLocation();
  const adminId = new URLSearchParams(location.search).get("adminId");
  const userName = new URLSearchParams(location.search).get("username");

  const getUsersLogData = async (params = null) => {
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr,
      adminId: adminId,
    });
    if (data.status) {
      console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getUsersLogData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  return menuAccess ? (
    <UserLogListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
        tableParams,
        userName,
      }}
      templateEvents={{
        getUsersLogData,
        handleCreateClick,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default UsersLogListComponent;
