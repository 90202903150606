import { combineReducers } from "redux";

import { appReducer } from "./app.reducer";
import { menuReducer } from "./menu.reducer";
import { tableReducer } from "./table.reducer";
import { filterReducer } from "./filter.reducer";
import { alertReducer } from "@reducers/alert.reducer";
import { electionReducer } from "./election.reducer";

const rootReducer = combineReducers({
  app: appReducer,
  menu: menuReducer,
  table: tableReducer,
  filter: filterReducer,
  alert: alertReducer,
  election: electionReducer,
});

export default rootReducer;
