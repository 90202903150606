import AlertComponent from "@atoms/alert/alert.component";
import HelmetComponent from "@atoms/helmet/helmet.component";
import { APP_NAME } from "@constants/app.constants";
import ForgotPasswordWrapperComponent from "./components/forgot-password-wrapper.component";

const ForgotPasswordTemplateComponent = ({
  templateModal: { username, alertMessage },
  templateEvents: { handleSubmit, handleChange },
}) => {
  return (
    <ForgotPasswordWrapperComponent>
      <HelmetComponent title={`Forgot Password - ${APP_NAME}`} />
      <form className="auth-login-form mt-2" onSubmit={handleSubmit}>
        <div className="mb-1">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            placeholder="Enter Username"
            autoFocus
            required
            value={username || ""}
            onChange={handleChange}
          />
        </div>

        <button className="btn btn-primary w-100" type="submit">
          Reset Password
        </button>
      </form>

      {alertMessage && (
        <AlertComponent
          type={alertMessage.type}
          message={alertMessage.message}
        />
      )}
    </ForgotPasswordWrapperComponent>
  );
};

export default ForgotPasswordTemplateComponent;
