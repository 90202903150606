import MESSAGES from "@constants/messages.constants";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../election-list/model/election-list.constants";
import DashboardTemplateComponent from "./dashboard-template.component";
import {
  getDashboardDataApi,
  getTableOne,
  getTableTwo,
} from "./dashboard.component.service";

const PCDashboardComponent = () => {
  const {
    STORAGE_KEYS: { INTRO_MESSAGE },
  } = MESSAGES;

  const history = useHistory();
  const location = useLocation();
  const storeIntroMessage = localStorage.getItem(INTRO_MESSAGE);
  const [dashboardData, setDashboardData] = useState({ recentLog: [] });
  const electionId = new URLSearchParams(location.search).get("electionId");
  const electionName = new URLSearchParams(location.search).get("electionName");
  const stateId = new URLSearchParams(location.search).get("stateId");
  const [tableOne, setTableOne] = useState([]);
  const [tableTwo, setTableTwo] = useState([]);
  const [introMessage, setIntroMessage] = useState(
    !storeIntroMessage ? true : false
  );
  console.log({ storeIntroMessage, introMessage });
  const clearIntroMessage = () => {
    setIntroMessage(false);
    localStorage.setItem(INTRO_MESSAGE, "cleared");
  };

  const breadCrumbs = [
    { name: "Election", menu_url: "/election" },
    { name: electionName, menu_url: null },
  ];

  const getTableOneData = async () => {
    const tableOneResponse = await getTableOne({ electionId });
    console.log({ tableOneResponse });
    if (tableOneResponse.status) setTableOne(tableOneResponse.data);
  };

  const getTableTwoData = async (pcId) => {
    const tableTwoResponse = await getTableTwo({ electionId, pcId });
    console.log({ tableTwoResponse });
    if (tableTwoResponse.status) {
      setTableOne([]);
      setTableTwo(tableTwoResponse.data);
    }
  };

  const handleTableTwoRedirect = (data, type) => {
    if (type === "ac_name")
      history.push(`/catalog/ac-booth?&acId=${data.ac_id}`);
    if (type === "booth_count")
      history.push(`/catalog/ac-booth?&acId=${data.ac_id}`);
    if (type === "voter_count")
      history.push(
        `/catalog/ac/voter?stateId=${data.state_id}&districtId=${data.district_id}&acId=${data.ac_id}&electionId=${electionId}&acName=${data.name}`
      );
  };

  const handleTableOneRedirect = (data, type) => {
    console.log({ data });
    if (type === "name")
      history.push(
        `/catalog/ac?pcId=${data.pc_id}&electionId=${data.election_id}`
      );
    if (type === "candidate_count")
      history.push(
        `/catalog/pc/candidate?pcId=${data.pc_id}&electionId=${data.election_id}&pcName=${data.name}`
      );
  };

  const handleBackClick = () => {
    setTableTwo([]);
    getTableOneData();
  };

  const handleDashboardBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const onLoad = async () => {
    const dashboardResponse = await getDashboardDataApi({
      electionId: electionId,
      stateId: stateId,
    });
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formatedDashboardData = {
        totalPCCount: dashboardResponse.data.total_pc_count.toString(),
        totalDistricts: dashboardResponse.data.total_districts.toString(),
        totalStates: dashboardResponse.data.total_states.toString(),
        totalBoothCount: dashboardResponse.data.total_booth_count.toString(),
        totalVotersCount: dashboardResponse.data.total_voter_count.toString(),
        totalCandidateCount:
          dashboardResponse.data.total_candidate_count.toString(),
        totalDistrictCount:
          dashboardResponse.data.total_districts_count.toString(),
        totalMaleVotersCount: dashboardResponse.data.total_male_voters.count
          ? dashboardResponse.data.total_male_voters.count
          : "-",
        totalFemaleVotersCount: dashboardResponse.data.total_female_voters.count
          ? dashboardResponse.data.total_female_voters.count
          : "-",
        totalTransgenderVotersCount: dashboardResponse.data.total_other_voters
          .count
          ? dashboardResponse.data.total_other_voters.count
          : "-",
        totalCandidatePhoneCount:
          dashboardResponse.data.total_candidate_phone_count.toString(),
        totalCandidateWhatsAppCount:
          dashboardResponse.data.total_candidate_whatsapp_count.toString(),
        totalUserLoginCount:
          dashboardResponse.data.total_user_login_count.toString(),
        totalUserLoginBoothCount:
          dashboardResponse.data.total_user_login_booth_count.toString(),
        totalPoliticalPartyCandidateCount:
          dashboardResponse.data.total_political_party_candidate.toString(),
        totalIndependentCount:
          dashboardResponse.data.total_independent_candidate.toString(),
        totalPoliticalContestingCount:
          dashboardResponse.data.total_political_contesting_count.toString(),
        totalIndependentContestingCount:
          dashboardResponse.data.total_independent_contesting_count.toString(),
      };
      setDashboardData(formatedDashboardData);
    }

    getTableOneData();
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <DashboardTemplateComponent
        templateModal={{
          introMessage,
          dashboardData,
          tableOne,
          tableTwo,
          breadCrumbs,
        }}
        templateEvents={{
          clearIntroMessage,
          getTableTwoData,
          handleBackClick,
          handleDashboardBackClick,
          handleTableTwoRedirect,
          handleTableOneRedirect,
        }}
      />
    </>
  );
};

export default PCDashboardComponent;
