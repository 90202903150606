import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getACFormInputs } from "../model/ac.modal";
import { menuUrl } from "../ac-list/model/ac-list.constants";
import { editEntry } from "./ac-edit.component.service";
import ACEditTemplateComponent from "./ac-edit-template.component";
import { getAllStates } from "@screens/catalog/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getPcByStateId } from "@screens/catalog/pc/pc-list/pc-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  MASTER_AC_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const ACEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };
  console.log({ currentElection });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let acPayload = {};
    for (let i = 0; i < values.length; i++) {
      acPayload[values[i].name] = values[i].value;
    }
    // INFO : call edit api
    const pcResponse = await editEntry(location?.state?.row?.id, acPayload);

    // INFO : check api response and show relevant alert message
    if (pcResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: pcResponse.message }));
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      inputs[2].options = formattedDistrictList;

      setFormInputs(inputs);
    }
    const pcResponse = await getPcByStateId(value);
    if (pcResponse.status) {
      const formattedPcList = pcResponse.data.map((pc) => {
        return {
          name: pc.name,
          value: pc.id,
        };
      });

      inputs[3].options = formattedPcList;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const value = [];
    for (let i = 1; i <= currentElection.total_phase; i++)
      value.push({ name: i, value: i });

    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const districtResponse = await getDistrictByStateId(
        location?.state?.row?.state_id
      );
      if (districtResponse.status) {
        const formattedDistrictList = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        const pcResponse = await getPcByStateId(location?.state?.row?.state_id);
        if (pcResponse.status) {
          const formattedPcList = pcResponse.data.map((pc) => {
            return {
              name: pc.name,
              value: pc.id,
            };
          });

          const formInputData = getACFormInputs({
            phase: value,
            states: formatedStateList,
            districts: formattedDistrictList,
            pcs: formattedPcList,
            initialValues: {
              govtId: location?.state?.row?.govt_id || "",
              name: location?.state?.row?.name || "",
              stateId: location?.state?.row?.state_id || "",
              districtId: location?.state?.row?.district_id || "",
              pcId: location?.state?.row?.pc_id || "",
              regionalName: location?.state?.row?.regional_name || "",
              phase: location?.state?.row?.phase || "",
              reservation: location?.state?.row?.reservation || "",
              remarks: location?.state?.row?.remarks || "",
              ballotPdf: location?.state?.row?.ballot_pdf || "",
              ballotImage: location?.state?.row?.ballot_image || "",
            },
            onStateChange,
          });
          console.log({ formatedStateList, value, formInputData });
          setFormInputs(formInputData);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <ACEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ACEditComponent;
