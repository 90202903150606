import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getFormInputs } from "../model/symbols.modal";
import { menuUrl } from "../symbols-list/model/symbols-list.constants";
import { editEntry } from "../symbols-list/symbols-list.service";
import SymbolsEditTemplateComponent from "./symbols-edit-template";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  CRM_SYMBOL_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const SymbolsEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: response.message,
        })
      );
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          regionalName: "",
        };
      });

      const formInputData = getFormInputs(formatedStateList, {
        name: location?.state?.row?.name || "",
        file: location?.state?.row?.file || "",
        states_data: location?.state?.row?.states_data || "",
      });
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <SymbolsEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default SymbolsEditComponent;
