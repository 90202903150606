import SentSmsListComponent from "@screens/reports/sent-sms/sent-sms-list/sent-sms-list.component";

const sentSmsRoutes = [
  {
    name: "Sent Sms",
    icon: "phone",
    path: "/reports/sent-sms",
    component: SentSmsListComponent,
  },
];

export default sentSmsRoutes;
