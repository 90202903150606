import { APP_NAME } from "@constants/app.constants";

import ElectionRuralCreateComponent from "@screens/election-rural/election-rural-create/election-rural-create.component";
import ElectionRuralEditComponent from "@screens/election-rural/election-rural-edit/election-rural-edit.component";
import ElectionRuralListComponent from "@screens/election-rural/election-rural-list/election-rural-list.component";

const electionRuralRoutes = [
  {
    name: `Election Rural- ${APP_NAME}`,
    path: "/election-rural",
    icon: "bar-chart-2",
    component: ElectionRuralListComponent,
  },
  {
    name: `Create Election Rural - ${APP_NAME}`,
    path: "/election-rural/create",
    component: ElectionRuralCreateComponent,
  },
  {
    name: `Edit Election Rural - ${APP_NAME}`,
    path: "/election-rural/edit",
    component: ElectionRuralEditComponent,
  },
];

export default electionRuralRoutes;
