import { APP } from "@constants/app.constants";
const { INDIA_STATES } = APP;

export const getVendorsFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Company Name",
    id: "CompanyName",
    name: "company_name",
    value: initialValues?.companyName || "",
    placeholder: "Enter the Company Name",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Designation",
    id: "designation",
    name: "designation",
    value: initialValues?.designation || "",
    placeholder: "Enter the Designation",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Type",
    id: "type",
    name: "type",
    value: initialValues?.type || "",
    placeholder: "Select type",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Address",
    id: "address",
    name: "address",
    value: initialValues?.address || "",
    placeholder: "Enter the Address",
    validations: [],
  },
  {
    type: "TEXT",
    label: "City",
    id: "city",
    name: "city",
    value: initialValues?.city || "",
    placeholder: "Enter the City",
    validations: [],
  },

  {
    type: "AUTOCOMPLETE",
    label: "State",
    id: "state",
    name: "state",
    value: initialValues?.state || "",
    placeholder: "Enter the State ",
    options: INDIA_STATES,
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Contacts",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "contacts",
    value: initialValues?.contacts || [{}],
    inputs: [
      {
        type: "TEXT",
        label: "Name",
        id: "name",
        name: "linkName",
        placeholder: "Name",
        size: 4,
      },

      {
        type: "TEXT",
        label: "Contact No 1",
        id: "contact_number_1",
        name: "contactNo1",
        placeholder: "Contact No 1",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Contact No 2",
        id: "contact_number_2",
        name: "contactNo2",
        placeholder: "Contact No 2",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Email Id",
        id: "email",
        name: "email",
        placeholder: "Email Id",
        size: 3,
      },
    ],
  },
];
