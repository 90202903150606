import { APP_NAME } from "@constants/app.constants";
import SymbolsCreateComponent from "@screens/manage/symbols/symbols-create/symbols-create.component";
import SymbolsEditComponent from "@screens/manage/symbols/symbols-edit/symbols-edit.component";
import SymbolsListComponent from "@screens/manage/symbols/symbols-list/symbols-list.component";

const manageSymbolsRoutes = [
  {
    name: `Symbols - ${APP_NAME}`,
    path: "/manage/symbols",
    icon: "target",
    component: SymbolsListComponent,
  },
  {
    name: `Create Symbol - ${APP_NAME}`,
    path: "/manage/symbols/create",
    component: SymbolsCreateComponent,
  },
  {
    name: `Edit Symbol- ${APP_NAME}`,
    path: "/manage/symbols/edit",
    component: SymbolsEditComponent,
  },
];

export default manageSymbolsRoutes;
