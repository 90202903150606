import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Village Panchayat Ward";
const keyColumn = "id";
const menuUrl = "catalog-rural/village-panchayat-ward";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_union_name",
    numeric: false,
    disablePadding: false,
    label: "District Union name",
    width: 150,
    sorting: true,
  },
  {
    id: "panchayat_union_name",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union name",
    width: 150,
    sorting: true,
  },
  {
    id: "village_panchayat_name",
    numeric: false,
    disablePadding: false,
    label: "Village Panchayat name",
    width: 150,
    sorting: true,
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Village Panchayat Ward Id",
    sorting: true,
    width: 100,
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Village Panchayat Ward name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },

  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Catalog", menu_url: null },
  {
    name: "Village Panchayat Ward ",
    menu_url: "/catalog-rural/village-panchayat-ward",
  },
];

const additionalActions = ({
  handleViewDetail,
  handleCandidateClick,
  handleVoterClick,
}) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
  {
    name: "Candidates",
    icon: "users",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleCandidateClick,
  },
  {
    name: "Voters",
    icon: "speaker",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleVoterClick,
  },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Village Panchayat Ward name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
  // {
  //   name: "Dashboard",
  //   icon: "grid",
  //   status: 0,
  //   onClick: null,
  // },
];

const tableConfig = {
  actionWidth: 170,
};

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
  tableConfig,
};
