import { END_POINT } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const {
  CATALOG_RURAL_WARD_CANDIDATE_STATUS_TOGGLE,
  CATALOG_RURAL_WARD_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE,
} = END_POINT;
const { CANDIDATE_STATUS } = APP;
const tableTitle = "Candidate";
const keyColumn = "id";
const menuUrl = "catalog-rural/panchayat-union-ward";
const tableColumn = [
  {
    id: "govt_id",
    numeric: false,
    disablePadding: true,
    label: "Candidate Id",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Candidate name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: true,
    label: "Regional name",
    sorting: true,
  },
  {
    id: "party_type",
    numeric: false,
    disablePadding: true,
    label: "Political Type",
    sorting: true,
  },
  {
    id: "party_name",
    numeric: false,
    disablePadding: true,
    label: "Political Party",
    sorting: true,
  },
  {
    id: "symbol_name",
    numeric: false,
    disablePadding: true,
    label: "Symbol",
    sorting: true,
  },
  {
    id: "age",
    numeric: false,
    disablePadding: true,
    label: "Age",
    sorting: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
    sorting: true,
  },
  {
    id: "education",
    numeric: false,
    disablePadding: true,
    label: "Education",
    sorting: true,
  },
  {
    id: "personal_mobile",
    numeric: false,
    disablePadding: false,
    label: "Contact No",
    sorting: true,
  },
  {
    id: "whatsapp_number",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp No 1",
    sorting: true,
  },
  {
    id: "whatsapp_number2",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp No 2",
    sorting: true,
  },
  {
    id: "whatsapp_response",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp Response",
    isBadge: true,
    badgeClick: CATALOG_RURAL_WARD_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      Yes: "Yes",
      No: "No",
    },
    badgeColor: {
      Yes: "success",
      No: "danger",
    },
  },
  {
    id: "official_mobile",
    numeric: false,
    disablePadding: false,
    label: "Official No",
    sorting: true,
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
    sorting: true,
  },
  {
    id: "asset",
    numeric: false,
    disablePadding: true,
    label: "Asset",
    sorting: true,
  },
  {
    id: "epic_id",
    numeric: false,
    disablePadding: true,
    label: "Epic Id",
    sorting: true,
  },
  {
    id: "ac_govt_id_name",
    numeric: false,
    disablePadding: true,
    label: "AC No",
    sorting: true,
    width: 100,
  },
  {
    id: "part_no",
    numeric: false,
    disablePadding: true,
    label: "Part No",
    sorting: true,
  },
  {
    id: "serial_no",
    numeric: false,
    disablePadding: true,
    label: "Serial No",
    sorting: true,
  },
  {
    id: "criminal_case",
    numeric: false,
    disablePadding: true,
    label: "Criminal Case",
    sorting: true,
  },
  {
    id: "facebook",
    numeric: false,
    disablePadding: true,
    label: "Facebook",
    sorting: true,
  },

  {
    id: "instagram",
    numeric: false,
    disablePadding: true,
    label: "Instagram",
    sorting: true,
  },
  {
    id: "linkedin",
    numeric: false,
    disablePadding: true,
    label: "LinkedIn",
    sorting: true,
  },
  {
    id: "twitter",
    numeric: false,
    disablePadding: true,
    label: "Twitter",
    sorting: true,
  },
  {
    id: "elected_member",
    numeric: false,
    disablePadding: false,
    label: "Candidate Status",
    isBadge: true,
    badgeClick: CATALOG_RURAL_WARD_CANDIDATE_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      APPLIED: "APPLIED",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED",
      WITHDRAWN: "WITHDRAWN",
      CONTESTING: "CONTESTING",
      RUNNER: "RUNNER",
      WINNER: "WINNER",
    },
    badgeColor: {
      APPLIED: "primary",
      ACCEPTED: "info",
      REJECTED: "danger",
      WITHDRAWN: "dark",
      CONTESTING: "warning",
      RUNNER: "info",
      WINNER: "success",
    },
    sorting: true,
  },
  {
    id: "total_votes",
    numeric: false,
    disablePadding: false,
    label: "Total Votes",
    sorting: true,
  },

  {
    id: "total_affidavit",
    numeric: false,
    disablePadding: false,
    label: "No.of.Affidavit",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: true,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog Rural", menu_url: null },
  {
    name: "Panchayat Union Ward",
    menu_url: "/catalog-rural/panchayat-union-ward",
  },
];

const additionalActions = ({ handleViewDetail }) => [
  // {
  //   name: "View",
  //   icon: "eye",
  //   buttonColor: "default",
  //   onClick: handleViewDetail,
  // },
];

const filters = ({ parties }) => [
  {
    name: "name",
    id: "name",
    label: "Candidate name",
    placeholder: "Enter Candidate name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "mobile",
    id: "mobile",
    label: "Candidate mobile",
    placeholder: "Enter Candidate mobile number",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "candidate_status",
    id: "candidate_status",
    label: "Candidate Status",
    value: "",
    type: "SELECT",
    placeholder: "Select Candidate Status",
    options: CANDIDATE_STATUS,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "political_party_id",
    id: "political_party_id",
    label: "Political Party",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Political Party",
    options: parties,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Dashboard",
  //   icon: "grid",
  //   status: 0,
  //   onClick: null,
  // },
];
const tableParams = {
  sortBy: "desc",
  orderBy: "elected_member",
};

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
  tableParams,
};
