import { APP } from "@constants/app.constants";
import { electionYear } from "@utils/common";
import { formatDateUtil } from "@utils/date.util";
const { ELECTION_CATEGORY, AC_URBAN_TYPE, ELECTION_STATUS, YEAR } = APP;
const tableTitle = "Election Rural List";
const keyColumn = "id";
const menuUrl = "election-rural";
const tableColumn = [
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Election Id",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Election name",
    sorting: true,
  },
  {
    id: "rural_types",
    numeric: false,
    disablePadding: false,
    label: "Rural Types",
    sorting: true,
    width: 120,
  },
  {
    id: "type_category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    width: 80,
  },
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "year_and_month",
    numeric: false,
    disablePadding: false,
    label: "Year & Month",
    width: 100,
  },
  {
    id: "selection_type",
    numeric: false,
    disablePadding: false,
    label: "Selection Type",
    width: 80,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sorting: true,
  },
  {
    id: "total_polling_station",
    numeric: false,
    disablePadding: false,
    label: "No.of Polling Station",
    sorting: true,
  },
  {
    id: "total_phase",
    numeric: false,
    disablePadding: false,
    label: "No.of Phase",
    sorting: true,
  },
  {
    id: "total_pink_booth",
    numeric: false,
    disablePadding: false,
    label: "No.of Pink Booth",
    sorting: true,
  },
  {
    id: "total_voters",
    numeric: false,
    disablePadding: false,
    label: "No.of Voters",
    sorting: true,
  },
  {
    id: "total_male_voters",
    numeric: false,
    disablePadding: false,
    label: "No.of Male Voters",
    sorting: true,
  },
  {
    id: "total_female_voters",
    numeric: false,
    disablePadding: false,
    label: "No.of Female Voters",
    sorting: true,
  },
  {
    id: "total_transgender_voters",
    numeric: false,
    disablePadding: false,
    label: "No.of Transgender Voters",
    sorting: true,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [{ name: "Election Rural", menu_url: "/election-rural" }];

const additionalActions = ({ handleDashboardClick, handleCurrentElection }) => [
  {
    name: "Dashboard",
    icon: "grid",
    buttonColor: "default",
    onClick: handleDashboardClick,
  },
  {
    name: "Default",
    icon: "box",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleCurrentElection,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  // {
  //   name: "type",
  //   id: "type",
  //   label: "Type",
  //   value: "",
  //   type: "SELECT",
  //   placeholder: "Select Type",
  //   options: AC_URBAN_TYPE,
  //   onChange: null,
  //   validations: [],
  //   isVertical: true,
  //   groupClass: "col-md-3",
  // },

  {
    name: "year",
    id: "year",
    label: "Year",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Type",
    options: YEAR,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "category",
    id: "category",
    label: "Category",
    value: "",
    type: "SELECT",
    placeholder: "Select Category",
    options: ELECTION_CATEGORY,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    value: "",
    type: "SELECT",
    placeholder: "Select Status",
    options: ELECTION_STATUS,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "name",
    id: "name",
    label: "Election name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  {
    name: "Chart",
    icon: "pie-chart",
    status: 0,
    onClick: null,
  },
];

const tableParams = {};

const tableConfig = {
  actionWidth: 120,
};

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
  tableParams,
  tableConfig,
};
