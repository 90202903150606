import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";

const UrbanWardDashboardTemplateComponent = ({
  templateModal: { dashboardData },
}) => {
  console.log({ dashboardData });
  return (
    <PageWrapperComponent>
      <div className="app-user-list">
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalStates}
                    </h3>
                    <p className="card-text">OverAll States</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="archive" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalTypeState}
                    </h3>
                    <p className="card-text">Total States</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="anchor" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalTypeUnionTerritory}
                    </h3>
                    <p className="card-text">Total Union Territory</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="aperture" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="fw-bolder mb-75">
                        {dashboardData?.highestDistrictState} -{" "}
                        {dashboardData?.totalHighestStateDistrictCount}
                      </h5>
                      <br></br>

                      <p className="card-text">Highest Districts State</p>
                    </div>
                    <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                      <div className="avatar-content">
                        <FeatherIcon icon="arrow-up" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="fw-bolder mb-75">
                        {dashboardData?.lowestDistrictState} -{" "}
                        {dashboardData?.totalLowestStateDistrictCount}
                      </h5>
                      <br></br>

                      <p className="card-text">Lowest Districts State</p>
                    </div>
                    <div className="avatar avatar-stats p-50 m-0 bg-light-warning">
                      <div className="avatar-content">
                        <FeatherIcon icon="arrow-down" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default UrbanWardDashboardTemplateComponent;
