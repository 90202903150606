import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCorporationFormInputs } from "../model/corporation.modal";
import { useEffect } from "react";
import { editEntry } from "./corporation-edit.component.service";
import { menuUrl } from "../corporation-list/model/corporation-list.constants";
import CorporationEditTemplateComponent from "./corporation-edit-template.component";
import { getAllStates } from "@screens/catalog-urban/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog-urban/districts/districts-list/districts-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  CORPORATION_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const CorporationEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const { currentElection } = useSelector((state) => state.election);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    payload["election_id"] = currentElection.id;

    // INFO : call create api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };
  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[2].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const districtResponse = await getDistrictByStateId(
        location?.state?.row?.state_id || null
      );
      if (districtResponse.status) {
        const formattedDistrictList = districtResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const formInputData = getCorporationFormInputs({
          states: formatedStateList,
          districts: formattedDistrictList,
          initialValues: {
            govtId: location?.state?.row?.govt_id || "",
            name: location?.state?.row?.name || "",
            regionalName: location?.state?.row?.regional_name || "",
            reservation: location?.state?.row?.reservation || "",
            stateId: location?.state?.row?.state_id || "",
            districtId: location?.state?.row?.district_id || "",
            totalWardCount: location?.state?.row?.total_ward_count || "",
            noOfZones: location?.state?.row?.no_of_zones || "",
            remarks: location?.state?.row?.remarks || "",
          },
          onStateChange,
        });
        setFormInputs(formInputData);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <CorporationEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default CorporationEditComponent;
