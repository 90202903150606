import { APP_NAME } from "@constants/app.constants";
import StatesCreateComponent from "@screens/master/states/states-create/states-create.component";
import StatesEditComponent from "@screens/master/states/states-edit/states-edit.component";
import StatesFileUploadComponent from "@screens/master/states/states-file/states-file.component";
import StatesListComponent from "@screens/master/states/states-list/states-list.component";

const masterStatesRoutes = [
  {
    name: "States List",
    icon: "box",
    path: "/master/states",
    component: StatesListComponent,
  },

  {
    name: `Create State - ${APP_NAME}`,
    path: "/master/states/create",
    component: StatesCreateComponent,
  },

  {
    name: `Edit State - ${APP_NAME}`,
    path: "/master/states/edit",
    component: StatesEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master/states/upload-state",
    component: StatesFileUploadComponent,
  },
];

export default masterStatesRoutes;
