import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";

const CandidateDashboardTemplateComponent = ({
  templateModal: { dashboardData },
}) => {
  console.log({ dashboardData });
  return (
    <PageWrapperComponent>
      <div className="app-user-list">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidate}
                    </h3>
                    <p className="card-text">Total Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-plus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalAcceptedCandidate}
                    </h3>
                    <p className="card-text">Total Accepted Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="thumbs-up" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalRejectedCandidate}
                    </h3>
                    <p className="card-text">Total Rejected Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-x" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalWithdrawnCandidate}
                    </h3>
                    <p className="card-text">Total Withdrawn Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-warning">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-minus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalPoliticalPartyCandidate}
                    </h3>
                    <p className="card-text">Total Political Party Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                    <div className="avatar-content">
                      <FeatherIcon icon="users" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalIndependentPartyCandidate}
                    </h3>
                    <p className="card-text">Total Independent Candidate</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-warning">
                    <div className="avatar-content">
                      <FeatherIcon icon="user" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalPoliticalPartyCandidateContesting}
                    </h3>
                    <p className="card-text">
                      Total Political Party Candidate Contesting
                    </p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalIndependentCandidateContesting}
                    </h3>
                    <p className="card-text">
                      Total Independent Party Candidate Contesting
                    </p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="user-check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default CandidateDashboardTemplateComponent;
