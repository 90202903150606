import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "../menu-items-list/menu-items-list.component.service";

const {
  MENU_ITEMS_CREATE,
  MENU_ITEM_LIST,
  MODULES_ALL_LIST,
  SUB_MENU_ITEM_LIST,
} = END_POINT;

export const createMenuItemsApi = async (payload) => {
  return await fetch(MENU_ITEMS_CREATE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getMainMenus = async () => {
  return await fetch(MENU_ITEM_LIST, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getModules = async () => {
  return await fetch(MODULES_ALL_LIST, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getSubMenus = async (mainMenuId) => {
  return await fetch(SUB_MENU_ITEM_LIST + mainMenuId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
