import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getDistrictsFormInputs } from "../model/districts.modal";
import { menuUrl } from "../districts-list/model/districts-list.constants";
import DistrictsEditTemplateComponent from "./districts-edit-template.component";
import { getAllStates } from "@screens/catalog-rural/states/states-list/states-list.component.service";
import { editEntry } from "../districts-list/districts-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  DISTRICTS_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const DistrictsEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let districtsPayload = {};
    for (let i = 0; i < values.length; i++) {
      districtsPayload[values[i].name] = values[i].value;
    }
    // INFO : call edit api
    const districtsResponse = await editEntry(
      location?.state?.row?.id,
      districtsPayload
    );

    // INFO : check api response and show relevant alert message
    if (districtsResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: districtsResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getDistrictsFormInputs(formatedStateList, {
        govtId: location?.state?.row?.govt_id || "",
        name: location?.state?.row?.district_name || "",
        stateId: location?.state?.row?.state_id || "",
        regionalName: location?.state?.row?.regional_name || "",
        districtHeadQuater: location?.state?.row?.district_head_quater || "",
        dob: location?.state?.row?.dob || "",
        remarks: location?.state?.row?.remarks || "",
      });
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <DistrictsEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default DistrictsEditComponent;
