import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCandidateFormInputs } from "../model/candidate.modal";
import CandidateCreateTemplateComponent from "./candidate-create-template.component";
import { menuUrl } from "../candidate-list/model/candidate-list.constants";
import { BASE_URL } from "@constants/api.constants";
import { getPoliticalPartyByState } from "@screens/political-party/political-party-list/political-party-list.component.service";
import { getAllSymbols } from "@screens/manage/symbols/symbols-list/symbols-list.service";
import {
  createEntry,
  uploadAffidavit,
  uploadCandidateImage,
} from "@screens/catalog-rural/candidate-voter/candidate-service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_CANDIDATE_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const CandidateCreateComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const villagePanchayatWardId = new URLSearchParams(location.search).get(
    "villagePanchayatWardId"
  );
  const electionId = new URLSearchParams(location.search).get("electionId");
  const villagePanchayatWardName = new URLSearchParams(location.search).get(
    "villagePanchayatWardName"
  );
  const stateGovtId = new URLSearchParams(location.search).get("stateGovtId");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleCandidateFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadCandidateResponse = await uploadCandidateImage(file[0]);
    if (uploadCandidateResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file:
          BASE_URL +
          "app-source/candidate-image/" +
          uploadCandidateResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      console.log({ copyFormInputs2 });

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };
  const handleAffidavitFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadAffadavitResponse = await uploadAffidavit(file[0]);
    console.log({ uploadAffadavitResponse });
    if (uploadAffadavitResponse.status) {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      copyFormInputs3[index].filename = file[0].name;
      copyFormInputs3[index].value =
        BASE_URL + "app-source/affidavit/" + uploadAffadavitResponse.data;

      setFormInputs(copyFormInputs3);
    } else {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      setFormInputs(copyFormInputs3);
      alert("Invalid File Format");
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog-rural/village-panchayat-ward/candidate?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}&stateGovtId=${stateGovtId}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let candidatePayload = { panchayat_union_ward_id: villagePanchayatWardId };
    for (let i = 0; i < values.length; i++) {
      candidatePayload[values[i].name] = values[i].value;
    }
    candidatePayload["ward_id"] = villagePanchayatWardId;
    candidatePayload["rural_type"] = "VILLAGE PANCHAYAT WARD";

    // INFO : call create api
    const candidateResponse = await createEntry(
      currentElection.id,
      candidatePayload
    );

    // INFO : check api response and show relevant alert message
    if (candidateResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/catalog-rural/village-panchayat-ward/candidate?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}&stateGovtId=${stateGovtId}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: candidateResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const politicalPartyResponse = await getPoliticalPartyByState(stateGovtId);
    const symbolsResponse = await getAllSymbols();
    let formatedPoliticalPartyList;
    let formatedSymbolsList;
    console.log({ politicalPartyResponse, symbolsResponse });

    if (politicalPartyResponse.status && politicalPartyResponse.status) {
      formatedPoliticalPartyList = politicalPartyResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }

    if (symbolsResponse.status) {
      formatedSymbolsList = symbolsResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    const formInputData = getCandidateFormInputs({
      formatedPoliticalPartyList,
      formatedSymbolsList,
      handleCandidateFileUpload,
      handleAffidavitFileUpload,
    });
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
    stateGovtId,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <CandidateCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default CandidateCreateComponent;
