import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const HelmetComponent = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
    </Helmet>
  );
};

HelmetComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HelmetComponent;
