import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import CatalogStatesSwapComponent from "./components/states-swap.component";

function StatesListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableActions,
    tableData,
    totalCount,
    filters,
    tableControls,
    swapContentType,
    tableParams,
  },
  templateEvents: {
    getStatesData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  const ActionContent = ({ handleCreateClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm"
        onClick={handleCreateClick}
        style={{ marginRight: 10 }}
      >
        <FeatherIcon icon="plus" size={17} />
      </button>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All States"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getStatesData}
        editLink={settingFormEdit}
        tableActions={tableActions}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        //handleDownload={handleDownload}
        handleDashboard={handleDashboard}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <CatalogStatesSwapComponent type={swapContentType} />
          ) : null
        }
        tableParams={tableParams}
      />
    </PageWrapperComponent>
  );
}

export default StatesListTemplateComponent;
