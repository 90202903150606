import { APP_NAME } from "@constants/app.constants";
import SmsTemplateCreateComponent from "@screens/system/sms-template/sms-template-create/sms-template-create.component";
import SmsTemplateEditComponent from "@screens/system/sms-template/sms-template-edit/sms-template-edit.component";
import SmsTemplateListComponent from "@screens/system/sms-template/sms-template-list/sms-template-list.component";

const smsTemplateRoutes = [
  {
    name: "Sms Template",
    icon: "message-square",
    path: "/system/sms-template",
    component: SmsTemplateListComponent,
  },
  {
    name: `Create Sms Template - ${APP_NAME}`,
    path: "/system/sms-template/create",
    component: SmsTemplateCreateComponent,
  },
  {
    name: `Edit Sms Template - ${APP_NAME}`,
    path: "/system/sms-template/edit",
    component: SmsTemplateEditComponent,
  },
];

export default smsTemplateRoutes;
