import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRolesFormInputs } from "../model/roles.modal";
import { menuUrl } from "../roles-list/model/roles-list.constants";
import {
  createRolesApi,
  getAllMenus,
} from "../role-form/role-form.component.service";
import MESSAGES from "@constants/messages.constants";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../roles-list/model/roles-list.constants";
import RolePermissionTableComponent from "../components/role-permission-table.component";
import RoleFormComponent from "../role-form/role-form.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ROLE_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const RolesCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menus, setMenus] = useState();
  const [roleName, setRoleName] = useState({
    role: "",
    description: "",
    type: "",
  });
  const [error, setError] = useState("");
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const formInputs = getRolesFormInputs({});

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const [checkBoxState, setCheckBoxState] = useState({
    readPermission: false,
    createPermission: false,
    editPermission: false,
    deletePermission: false,
  });

  const [intermediateState, setIntermediateState] = useState({
    readPermission: false,
    createPermission: false,
    editPermission: false,
    deletePermission: false,
  });

  const handleChange = (e) => {
    if (e.target.value === "") {
      setError("Role name is required!");
    }
    setError("");
    setRoleName({
      ...roleName,
      role: e.target.value,
    });
  };

  const handleTypeChange = (event) => {
    console.log({ value: event.target.value });
    setRoleName({
      ...roleName,
      type: event.target.value,
    });
  };

  const handleDescriptionChange = (value) => {
    setRoleName({
      ...roleName,
      description: value,
    });
  };

  console.log({ roleName });
  const onLoadData = async () => {
    const menuResponse = await getAllMenus();
    if (menuResponse.status) setMenus(menuResponse.data);
  };

  const handleRolesSubmit = async () => {
    if (roleName.role === "") {
      setError("Role name is required!");
      return;
    }
    console.log({ menus, roleName });

    const formatedMenu = menus.map((menu) => {
      return {
        menu_id: menu.admin_menu_id,
        read_permission: menu.readPermission,
        create_permission: menu.createPermission,
        edit_permission: menu.editPermission,
        delete_permission: menu.deletePermission,
      };
    });

    console.log({ roleName, formatedMenu });
    // INFO : call create api
    const rolesResponse = await createRolesApi({
      name: roleName.role,
      type: roleName.type,
      description: roleName.description,
      menus: formatedMenu,
    });
    console.log({ rolesResponse });
    // INFO : check api response and show relevant alert message
    if (rolesResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: rolesResponse.message })
      );
    }
  };

  useEffect(() => {
    onLoadData();
  }, []);

  return menuAccess ? (
    <>
      <PageHeadComponent
        title={"Create Role"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Create ", menu_url: "/user-administration/roles/create" },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleRolesSubmit()}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <RoleFormComponent
          roleName={roleName}
          error={error}
          handleChange={handleChange}
          handleDescriptionChange={handleDescriptionChange}
          handleTypeChange={handleTypeChange}
        />
      </CardComponent>
      <CardComponent>
        <RolePermissionTableComponent
          templateModal={{ menus, checkBoxState, intermediateState }}
          templateEvents={{
            setMenus,
            setCheckBoxState,
            setIntermediateState,
          }}
        />
      </CardComponent>
    </>
  ) : (
    <PermissionDeniedComponent />
  );
};

export default RolesCreateComponent;
