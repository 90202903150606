import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

const TabComponent = ({ data, active = 1, setActiveTab }) => {
  return (
    <>
      <div>
        <Nav tabs>
          {data.map((tab) => (
            <NavItem>
              <NavLink
                className={classnames({ active: active === tab.id })}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
              >
                {tab.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={active}>
          {data.map((tab) => (
            <TabPane tabId={tab.id}>
              <Row>
                <Col sm="12">{tab.content}</Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  );
};

export default TabComponent;
