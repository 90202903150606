import { APP_NAME } from "@constants/app.constants";
import StatesCreateComponent from "@screens/catalog-rural/states/states-create/states-create.component";
import StatesEditComponent from "@screens/catalog-rural/states/states-edit/states-edit.component";
import StatesListComponent from "@screens/catalog-rural/states/states-list/states-list.component";
import UsersCreateComponent from "@screens/catalog-rural/states/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog-rural/states/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog-rural/states/user/users-list/users-list.component";

const catalogRuralStatesRoutes = [
  {
    name: `Rural States - ${APP_NAME}`,
    path: "/catalog-rural/states",
    icon: "box",
    component: StatesListComponent,
  },
  {
    name: `Create State - ${APP_NAME}`,
    path: "/catalog-rural/states/create",
    component: StatesCreateComponent,
  },
  {
    name: `Edit State - ${APP_NAME}`,
    path: "/catalog-rural/states/edit",
    component: StatesEditComponent,
  },

  {
    name: `State User - ${APP_NAME}`,
    path: "/catalog-rural/states/user",
    component: UsersListComponent,
  },
  {
    name: `Create State User - ${APP_NAME}`,
    path: "/catalog-rural/states/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit State User - ${APP_NAME}`,
    path: "/catalog-rural/states/user/edit",
    component: UsersEditComponent,
  },
];

export default catalogRuralStatesRoutes;
