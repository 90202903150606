import React from "react";
import FeatherIcon from "feather-icons-react";

const FormInputTextComponent = ({
  type,
  label,
  id,
  name,
  value,
  placeholder,
  error,
  onChange,
  onKeyDown,
  onFocus,
  isRequired = false,
  isVertical = false,
  isDisabled = false,
  inputStyles,
  inputIcon = null,
  handleInputIconClick,
}) => {
  const handleChange = (e) => {
    // console.log("change", { e });
    if (onChange) onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (onKeyDown) onKeyDown({ keyCode: e.keyCode });
  };

  const handleFocus = (e) => {
    // console.log("focus", { e });
    if (onChange) onChange(e.target.value);
    if (onFocus) onFocus();
  };

  const onInputIconClick = () => {
    if (handleInputIconClick) handleInputIconClick(value);
  };

  return (
    <div className="col-12">
      <div
        className={`${isVertical ? "" : "mb-1 row "} ${
          inputIcon ? "input-group" : ""
        }`}
      >
        {label && (
          <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
            <label className="col-form-label">
              {label}
              {isRequired && <> * </>}
            </label>
          </div>
        )}

        <div className={isVertical ? "col-sm-12" : "col-sm-10"}>
          <div className={inputIcon && !error ? "input-group height-40" : ""}>
            <input
              className={`form-control ${error ? "is-invalid" : ""}`}
              type={type}
              name={name}
              id={id}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              placeholder={placeholder}
              disabled={isDisabled}
              autocomplete="off"
              style={inputStyles ? inputStyles : {}}
            />
            {error && <div className="invalid-feedback">{error}</div>}

            {inputIcon && !error && (
              <span
                className="cursor-pointer input-group-text height-40"
                onClick={onInputIconClick}
              >
                <FeatherIcon icon={inputIcon} width={10} />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInputTextComponent;
