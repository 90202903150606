import { APP_NAME } from "@constants/app.constants";
import PanchayatUnionCreateComponent from "@screens/catalog-rural/panchayat-union/panchayat-union-create/panchayat-union-create.component";
import PanchayatUnionEditComponent from "@screens/catalog-rural/panchayat-union/panchayat-union-edit/panchayat-union-edit.component";
import PanchayatUnionListComponent from "@screens/catalog-rural/panchayat-union/panchayat-union-list/panchayat-union-list";

const catalogPanchayatUnionRoutes = [
  {
    name: "Panchayat Union List",
    icon: "tablet",
    path: "/catalog-rural/panchayat-union",
    component: PanchayatUnionListComponent,
  },

  {
    name: `Create Village Panchayat - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union/create",
    component: PanchayatUnionCreateComponent,
  },

  {
    name: `Edit Village Panchayat - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union/edit",
    component: PanchayatUnionEditComponent,
  },
];

export default catalogPanchayatUnionRoutes;
