import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import moment from "moment";
import React from "react";

const DataGridFilters2Component = ({ filters, handleFilterSubmit }) => {
  const formatValue = (filter) => {
    if (filter.type === "CALENDER" && filter.value)
      return moment(filter.value).format("YYYY-MM-DD");

    if (filter.type === "AUTOCOMPLETE" && filter.isMultiple) {
      return filter.value.map((v) => v.value);
    }

    return filter.value;
  };

  const handleSubmit = async ({ values, errors }) => {
    const filters = values.map((filter) => {
      return {
        ...filter,
        value: formatValue(filter),
      };
    });

    handleFilterSubmit(filters);
  };

  return (
    <>
      <FormBuilderComponent
        formType="default"
        inputFields={filters}
        onSubmit={handleSubmit}
        isSubmitVisible={true}
        submitButton={{
          text: "submit",
          size: "col-md-3",
        }}
      />
    </>
  );
};

export default DataGridFilters2Component;
