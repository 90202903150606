import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useHistory, useLocation } from "react-router-dom";
import {
  createFavoriteMenuApi,
  deleteFavoriteMenuApi,
  getFavoriteMenuApi,
} from "./favorite-menu.component.service";
import { useDispatch, useSelector } from "react-redux";
import { setFavoriteMenus } from "@reducers/app.reducer";
import { appRoutes } from "@src/routes";

const FavoriteMenuComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeFavoriteMenu, setActiveFavoriteMenu] = useState(false);
  const [hideStar, setHideStart] = useState(false);
  const { favoriteMenus } = useSelector((state) => state.app);

  const handleStartClick = async () => {
    const checkMenu =
      favoriteMenus && favoriteMenus.find((menu) => menu.menu_url === location.pathname);

    if (checkMenu) {
      removeFavoriteMenu(checkMenu);
      return;
    }

    let fill = undefined;
    let color = undefined;
    const createRoute = location.pathname.includes("create");
    const editRoute = location.pathname.includes("edit");
    if (createRoute) {
      fill = "#7966ee";
    }
    if (editRoute) {
      fill = "white";
      color = "#7966ee";
    }
    const currentRoute = appRoutes.find((route) => route.path === location.pathname);

    const payload = {
      menu_url: location.pathname,
      meta_data: {
        icon: currentRoute?.icon || "link",
        fill,
        color,
        name: currentRoute?.name || "",
      },
    };

    const createReponse = await createFavoriteMenuApi(payload);
    if (createReponse.status) {
      setActiveFavoriteMenu(true);
      getFavoriteMenus();
    }
  };

  const getFavoriteMenus = async () => {
    const listResponse = await getFavoriteMenuApi();
    if (listResponse.status) {
      const formattedMenus = listResponse.data.map((menu) => {
        return {
          ...menu,
          meta_data: JSON.parse(menu.meta_data),
        };
      });
      dispatch(setFavoriteMenus(formattedMenus));
    }
  };

  const removeFavoriteMenu = async (menu) => {
    const payload = { ids: [menu.id] };
    const deleteResponse = await deleteFavoriteMenuApi(payload);
    if (deleteResponse.status) {
      getFavoriteMenus();
      setActiveFavoriteMenu(false);
    }
  };

  const checkActiveFavoriteMenu = () => {
    const checkMenu =
      favoriteMenus && favoriteMenus.find((menu) => menu.menu_url === location.pathname);
    if (checkMenu) setActiveFavoriteMenu(true);
    else setActiveFavoriteMenu(false);

    // INFO : hide star
    const editRoute = location.pathname.includes("edit");
    if (editRoute) setHideStart(true);
    else setHideStart(false);
  };

  useEffect(() => {
    getFavoriteMenus();
  }, []);

  useEffect(() => {
    checkActiveFavoriteMenu();
  }, [location.pathname]);
  return (
    <>
      <ul className="nav navbar-nav bookmark-icons">
        {favoriteMenus &&
          favoriteMenus.map((favoriteMenu, index) => (
            <li className="nav-item d-none d-lg-block">
              <a
                className="nav-link"
                onClick={() => history.push(favoriteMenu.menu_url)}
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={favoriteMenu.meta_data.name}
                key={index}
              >
                <FeatherIcon
                  fill={favoriteMenu.meta_data.color}
                  icon={favoriteMenu.meta_data.icon}
                  style={{ width: 20 }}
                />
              </a>
            </li>
          ))}
      </ul>

      {!hideStar && (
        <ul className="nav navbar-nav">
          <li className="nav-item d-none d-lg-block" onClick={handleStartClick}>
            <a className="nav-link bookmark-star">
              <FeatherIcon
                icon={"star"}
                style={{ width: 20 }}
                fill={activeFavoriteMenu ? "white" : undefined}
              />
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default FavoriteMenuComponent;
