import React from "react";
import VillagePanchayatDashboardComponent from "../dashboard/dashboard.component";
import VillagePanchayatChartComponent from "./chart.component";

const VillagePanchayatSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <VillagePanchayatChartComponent />}
      {type === "DASHBOARD" && <VillagePanchayatDashboardComponent />}
    </>
  );
};

export default VillagePanchayatSwapComponent;
