import { lazy } from "react";
import { retry } from "@utils/common";

import blockedIpRoutes from "./blocked-ip.routes";
import databaseBackupRoutes from "./database-backup.routes";
import emailTemplateRoutes from "./email-template.routes";
import menuItemsRoutes from "./menu-items.routes";
import modulesRoutes from "./modules.routes";
import notificationRoutes from "./notification.routes";
import rolesRoutes from "./roles.routes";
import sentEmailRoutes from "./sent-email.routes";
import sentSmsRoutes from "./sent-sms.routes";
import settingRoutes from "./setting.routes";
import smsTemplateRoutes from "./sms-template.routes";
import userLogRoutes from "./user-log.routes";
import usersRoutes from "./users.routes";
import masterStatesRoutes from "./master-states.routes";
import LoginComponent from "@screens/login/login.component";
import ForgotPasswordComponent from "@screens/forgot-password/forgot-password.component";
import DashboardComponent from "@screens/dashboard/dashboard.component";
import masterDistrictsRoutes from "./districts.route";
import masterPcRoutes from "./master-pc.routes";
import masterAcRoutes from "./master-ac.routes";
import electionRoutes from "./election.routes";
import catalogStatesRoutes from "./catalog-states.routes";
import catalogDistrictsRoutes from "./catalog-districts.routes";
import catalogPcRoutes from "./catalog-pc.routes";
import catalogAcRoutes from "./catalog-ac-routes";
import catalogAcBoothRoutes from "./catalog-ac-booth.routes";
import voterFieldRoutes from "./voter-field.routes";
import versionRoutes from "./version.route";
import voterFileUploadRequestRoutes from "./catalog-voter-file-upload-request.routes";
import upcomingElectionRoutes from "./upcoming-election.route";
import crmCandidateRoutes from "./crm-candidate.routes";
import politicalPartyRoutes from "./political-party.route";
import bugTrackerRoutes from "./bug-tracker.routes";
import clientRoutes from "./client.routes";
import leadsRoutes from "./leads.routes";
import vendorsRoutes from "./vendors.routes";
import bulkWhatsAppRoutes from "./bulk-whatsapp.routes";
import bulkMessageRoutes from "./bulk-message.routes";
import bulkVoiceCallRoutes from "./bulk-voice-call-routes";
import LoginV2Component from "@screens/loginv2/login-v2.component";
import masterACUrbanRoutes from "./master-ac-urban.routes";
import masterCorporationRoutes from "./master-corporation.routes";
import masterMunicipalityRoutes from "./master-municipality.routes";
import masterTownPanchayatRoutes from "./master-town-panchayat.routes";
import masterVillagePanchayatRoutes from "./master-village-panchayat.routes";
import masterUrbanListRoutes from "./master-urban-ward.routes";
import masterDistrictUnionRoutes from "./master-district-union.routes";
import masterPanchayatUnionRoutes from "./master-panchayat-union-routes";
import manageSymbolsRoutes from "./manage-symbols.routes";
import electionUrbanRoutes from "./election-urban.routes";
import catalogUrbanStatesRoutes from "./catalog-urban-states.routes";
import catalogUrbanDistrictsRoutes from "./catalog-urban-districts.routes";
import catalogUrbanPcRoutes from "./catalog-urban-pc.routes";
import catalogUrbanAcRoutes from "./catalog-urban-ac.routes";
import catalogCorporationRoutes from "./catalog-corporation-routes";
import catalogMunicipalityRoutes from "./catalog-municipality.routes";
import catalogTownPanchayatRoutes from "./catalog-town-panchayat.routes";
import catalogUrbanListRoutes from "./catalog-urban-ward.route";
import masterVillagePanchayatWardRoutes from "./master-village-panchayat-ward.routes";
import catalogUrbanWardBoothRoutes from "./urban-ward-booth.routes";
import refreshCatalogCountRoutes from "./refresh-catalog-count";
import downloadRequestsRoutes from "./download-requests.routes";
import masterDistrictUnionWardRoutes from "./master-district-union-ward.routes";
import masterPanchayatUnionWardRoutes from "./master-panchayat-union-ward-routes";
import electionRuralRoutes from "./election-rural.routes";
import catalogRuralStatesRoutes from "./catalog-rural-states.routes";
import catalogRuralDistrictsRoutes from "./catalog-rural-districts.routes";
import catalogRuralDistrictUnionRoutes from "./catalog-district-union.routes";
import catalogDistrictUnionWardRoutes from "./catalog-district-union-ward.routes";
import catalogPanchayatUnionRoutes from "./catalog-panchayat-union.routes";
import catalogPanchayatUnionWardRoutes from "./catalog-panchayat-union-ward.routes";
import catalogVillagePanchayatRoutes from "./catalog-village-panchayat.routes";
import catalogVillagePanchayatWardRoutes from "./catalog-village-panchayat-ward.routes";
import catalogRuralWardBoothRoutes from "./rural-ward-booth.routes";
import homePageRoutes from "./home.routes";
import LaunchPadComponent from "@screens/launchpad/launchpad.component";

const index = [
  {
    name: "Login",
    path: "/",
    component: <LoginV2Component />,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: <ForgotPasswordComponent />,
  },
];

export const appRoutes = [
  {
    name: "Dashboard",
    icon: "database",
    path: "/dashboard",
    component: DashboardComponent,
  },
  // {
  //   name: "Launchpad",
  //   icon: "database",
  //   path: "/launchpad",
  //   component: LaunchPadComponent,
  // },
  ...settingRoutes,
  ...menuItemsRoutes,
  ...emailTemplateRoutes,
  ...blockedIpRoutes,
  ...smsTemplateRoutes,
  ...rolesRoutes,
  ...usersRoutes,
  ...userLogRoutes,
  ...sentEmailRoutes,
  ...sentSmsRoutes,
  ...modulesRoutes,
  ...notificationRoutes,
  ...databaseBackupRoutes,
  ...masterStatesRoutes,
  ...masterDistrictsRoutes,
  ...masterPcRoutes,
  ...masterAcRoutes,
  ...electionRoutes,
  ...catalogStatesRoutes,
  ...catalogDistrictsRoutes,
  ...catalogPcRoutes,
  ...catalogAcRoutes,
  ...catalogAcBoothRoutes,
  ...voterFieldRoutes,
  ...versionRoutes,
  ...voterFileUploadRequestRoutes,
  ...upcomingElectionRoutes,
  ...crmCandidateRoutes,
  ...politicalPartyRoutes,
  ...bugTrackerRoutes,
  ...clientRoutes,
  ...leadsRoutes,
  ...vendorsRoutes,
  ...bulkWhatsAppRoutes,
  ...bulkMessageRoutes,
  ...bulkVoiceCallRoutes,
  ...masterACUrbanRoutes,
  ...masterCorporationRoutes,
  ...masterMunicipalityRoutes,
  ...masterTownPanchayatRoutes,
  ...masterVillagePanchayatRoutes,
  ...masterUrbanListRoutes,
  ...masterDistrictUnionRoutes,
  ...masterPanchayatUnionRoutes,
  ...manageSymbolsRoutes,
  ...electionUrbanRoutes,
  ...catalogUrbanStatesRoutes,
  ...catalogUrbanDistrictsRoutes,
  ...catalogUrbanPcRoutes,
  ...catalogUrbanAcRoutes,
  ...catalogCorporationRoutes,
  ...catalogMunicipalityRoutes,
  ...catalogTownPanchayatRoutes,
  ...catalogUrbanListRoutes,
  ...masterVillagePanchayatWardRoutes,
  ...catalogUrbanWardBoothRoutes,
  ...refreshCatalogCountRoutes,
  ...downloadRequestsRoutes,
  ...masterDistrictUnionWardRoutes,
  ...masterPanchayatUnionWardRoutes,
  ...electionRuralRoutes,
  ...catalogRuralStatesRoutes,
  ...catalogRuralDistrictsRoutes,
  ...catalogRuralDistrictUnionRoutes,
  ...catalogDistrictUnionWardRoutes,
  ...catalogPanchayatUnionRoutes,
  ...catalogPanchayatUnionWardRoutes,
  ...catalogVillagePanchayatRoutes,
  ...catalogVillagePanchayatWardRoutes,
  ...catalogRuralWardBoothRoutes,
  ...homePageRoutes,
];

export default index;
