import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../candidate-list/model/candidate-list.constants";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const CandidateEditTemplateComponent = ({
  templateModal: {
    formTriggers,
    formInputs,
    pcId,
    electionId,
    pcName,
    stateGovtId,
  },
  templateEvents: { handleSubmit, handleTriggerClick, handleBackClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Edit Candidate"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: pcName + " - Candidate",
            menu_url: `/catalog/pc/candidate?pcId=${pcId}&electionId=${electionId}&pcName=${pcName}&stateGovtId=${stateGovtId}`,
          },
          { name: "Edit", menu_url: null },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <FormBuilderComponent
        formType="group"
        inputFields={formInputs}
        submitTrigger={formTriggers.submitTrigger}
        onSubmit={handleSubmit}
      />
    </PageWrapperComponent>
  );
};

export default CandidateEditTemplateComponent;
