import { APP_NAME } from "@constants/app.constants";
import BlockedIpCreateComponent from "@screens/system/blocked-ip/blocked-ip-create/blocked-ip-create.component";
import BlockedIpEditComponent from "@screens/system/blocked-ip/blocked-ip-edit/blocked-ip-edit.component";
import BlockedIpListComponent from "@screens/system/blocked-ip/blocked-ip-list/blocked-ip-list.component";

const blockedIpRoutes = [
  {
    name: "Blocked IP",
    icon: "slash",
    path: "/system/blocked-ip",
    component: BlockedIpListComponent,
  },
  {
    name: `Create Blocked IP - ${APP_NAME}`,
    path: "/system/blocked-ip/create",
    component: BlockedIpCreateComponent,
  },
  {
    name: `Edit Blocked IP - ${APP_NAME}`,
    path: "/system/blocked-ip/edit",
    component: BlockedIpEditComponent,
  },
];

export default blockedIpRoutes;
