import React, { useState } from "react";
import { useSelector } from "react-redux";
import FormInputCheckboxComponent from "../form-input-checkbox/form-input-checkbox.component";
import FormInputTextComponent from "../form-input-text/form-input-text.component";

const FormInputSelectGroupComponent = ({
  id,
  name,
  label,
  options,
  onChange,
  isVertical,
  isRequired,
  isSearchable,
}) => {
  const { theme } = useSelector((state) => state.app);
  const [search, setSearch] = useState("");

  const handleChange = ({ value, index }) => {
    console.log({ value, index });
    if (onChange) onChange(value, index);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };
  return (
    <>
      <div className="col-12">
        <div className={isVertical ? "" : "mb-1 row"}>
          {label && (
            <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
              <label className="col-form-label">
                {label}
                {isRequired && <> * </>}
              </label>
            </div>
          )}

          <div className={isVertical ? "col-sm-12" : "col-sm-10"}>
            <div
              className="border"
              style={{
                height: 200,
                border: "1px solid " + theme === "dark" ? "#404656" : "#d8d6de",
                borderRadius: 5,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {isSearchable && (
                <FormInputTextComponent
                  isVertical={true}
                  name={`txtSearch${name}`}
                  id={`txtSearchId${id}`}
                  value={search}
                  placeholder={"Search"}
                  onChange={handleSearchChange}
                />
              )}

              <div style={{ marginTop: 10 }}>
                {options.map((option, index) => {
                  const searchStatus =
                    search === ""
                      ? true
                      : option.name
                          .toLowerCase()
                          .includes(search.toLowerCase());

                  if (searchStatus)
                    return (
                      <div
                        key={index}
                        style={{ marginLeft: 5, marginBottom: 5 }}
                      >
                        <FormInputCheckboxComponent
                          isInLine
                          isVertical
                          label={option.name}
                          id={`checkId${option.value}`}
                          name={`checkName${option.value}`}
                          checked={option.checked}
                          onChange={(name, value) =>
                            handleChange({ index, name, value })
                          }
                        />
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormInputSelectGroupComponent;
