import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../candidate-list/model/candidate-list.constants";

const ActionContent = ({ handleSaveClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleSaveClick}
    >
      <FeatherIcon icon="save" size={17} />
    </button>
  );
};

const CandidateFileUploadTemplateComponent = ({
  templateModal: {
    formInputs,
    formTriggers,
    panchayatUnionWardId,
    electionId,
    panchayatUnionWardName,
    stateGovtId,
  },
  templateEvents: { handleFormSubmit, handleTriggerClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Upload UrbanWard Candidate"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: panchayatUnionWardName + " - Candidate(s)",
            menu_url: `/catalog-rural/panchayat-union-ward/candidate?panchayatUnionWardId=${panchayatUnionWardId}&electionId=${electionId}&panchayatUnionWardName=${panchayatUnionWardName}&stateGovtId=${stateGovtId}`,
          },
          { name: "File Upload", menu_url: null },
        ]}
        actionContent={
          <ActionContent handleSaveClick={() => handleTriggerClick("SUBMIT")} />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleFormSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default CandidateFileUploadTemplateComponent;
