import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../users-list/model/users-list.constants";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const UsersCreateTemplateComponent = ({
  templateModal: {
    formTriggers,
    formInputs,
    districtId,
    electionId,
    districtName,
  },
  templateEvents: { handleUsersSubmit, handleTriggerClick, handleBackClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Create User"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: districtName + " - User(s)",
            menu_url: `/catalog/districts/user?districtId=${districtId}&electionId=${electionId}&districtName=${districtName}`,
          },
          { name: "Create", menu_url: null },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleUsersSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default UsersCreateTemplateComponent;
