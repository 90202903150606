import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import DataGridTableComponent from "@molecules/data-grid/components/data-grid-table.component";
import { breadCrumbs } from "../bug-comments-list/model/bug-comments-list.constants";
const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const BugCommentTemplateCreateComponent = ({
  templateModal: { formTriggers, formInputs, tableData, tableColumn, title },
  templateEvents: {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    bugCommentData,
  },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Create Comment"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Create ", menu_url: "/bug-comments/create" },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSubmit}
        />
      </CardComponent>
      <DataGridTableComponent
        templateModal={{
          title: title + " - Comments",
          tableColumn,
          tableData,
          disableActions: [],
        }}
        templateEvents={{
          dataRefresh: bugCommentData,
        }}
      />
    </PageWrapperComponent>
  );
};

export default BugCommentTemplateCreateComponent;
