import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { breadCrumbs } from "../version-list/model/version-list.constants";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import DataGridTableComponent from "@molecules/data-grid/components/data-grid-table.component";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const VersionTemplateCreateComponent = ({
  templateModal: { formTriggers, formInputs, tableData, tableColumn },
  templateEvents: {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    bugData,
  },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Create Version"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Create ", menu_url: "/version/create" },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSubmit}
        />
      </CardComponent>
      <DataGridTableComponent
        templateModal={{
          title: "Completed Bug List",
          tableColumn,
          tableData,
          disableActions: [],
        }}
        templateEvents={{
          dataRefresh: bugData,
        }}
      />
    </PageWrapperComponent>
  );
};

export default VersionTemplateCreateComponent;
