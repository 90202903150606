import { APP_NAME } from "@constants/app.constants";

import RuralWardBoothCreateComponent from "@screens/catalog-rural/rural-ward-booth/rural-ward-booth-create/rural-ward-booth-create.component";
import RuralWardBoothEditComponent from "@screens/catalog-rural/rural-ward-booth/rural-ward-booth-edit/rural-ward-booth-edit.component";
import RuralWardBoothListComponent from "@screens/catalog-rural/rural-ward-booth/rural-ward-booth-list/rural-ward-booth-list.component";

const catalogRuralWardBoothRoutes = [
  {
    name: `Rural Ward Booth - ${APP_NAME}`,
    path: "/catalog-rural/rural-ward-booth",
    icon: "briefcase",
    component: RuralWardBoothListComponent,
  },
  {
    name: `Create Rural Ward Booth - ${APP_NAME}`,
    path: "/catalog-rural/rural-ward-booth/create",
    component: RuralWardBoothCreateComponent,
  },
  {
    name: `Edit Rural Ward Booth- ${APP_NAME}`,
    path: "/catalog-rural/rural-ward-booth/edit",
    component: RuralWardBoothEditComponent,
  },

  //   {
  //     name: `RuralWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/rural-ward-booth/user",
  //     component: UsersListComponent,
  //   },
  //   {
  //     name: `Create RuralWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/rural-ward-booth/user/create",
  //     component: UsersCreateComponent,
  //   },
  //   {
  //     name: `Edit RuralWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/rural-ward-booth/user/edit",
  //     component: UsersEditComponent,
  //   },
];

export default catalogRuralWardBoothRoutes;
