import React from "react";
import FeatherIcon from "feather-icons-react";
import ModalComponent from "@atoms/modal/modal.component";
import RenderInputsComponent from "./components/render-inputs.component";
import { isMobile } from "react-device-detect";

const FormRepeaterComponentTemplate = ({
  templateModal: { columns, rows, deleteModalData, formInputs, staticHeader },
  templateEvents: {
    handleChange,
    handleAddRow,
    handleDeleteRow,
    handleKeyDown,
    handleFileUpload,
    handleDeleteFile,
    handleDeleteFileConfirm,
    handleDeleteFileCancel,
    handleImagePreview,
  },
}) => {
  return (
    <div className="container-fluid container-scroll">
      <div className="row mb-1">
        {staticHeader &&
          columns
            .filter((col) => !col.isHide)
            .map((column) => (
              <div className={`col-md-${column.size}`}>{column.label}</div>
            ))}
      </div>
      <div>
        {rows.map((row, rowIndex) => {
          return (
            <div className="row mb-1">
              {row.map((column, columnIndex) => (
                <>
                  {!column.isHide && (
                    <RenderInputsComponent
                      staticHeader={staticHeader}
                      column={column}
                      formInputs={formInputs}
                      columnIndex={columnIndex}
                      rowIndex={rowIndex}
                      handleChange={handleChange}
                      handleKeyDown={({ keyCode }) =>
                        handleKeyDown({ keyCode, columnIndex, rowIndex })
                      }
                      handleFileUpload={handleFileUpload}
                      handleDeleteFile={handleDeleteFile}
                    />
                  )}
                </>
              ))}

              <div className={`col-md-1`}>
                {rowIndex === rows.length - 1 ? (
                  <>
                    <button
                      type="button"
                      id="buttonAddNew"
                      className="btn btn-primary btn-sm"
                      style={{
                        marginTop: staticHeader ? 4 : 37,
                        padding: "7px 7px",
                        marginRight: 7,
                      }}
                      onClick={handleAddRow}
                    >
                      <FeatherIcon icon="plus" size={15} />
                    </button>
                    {rows.length > 1 && (
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        style={{
                          marginTop: staticHeader ? 4 : 37,
                          padding: "7px 7px",
                        }}
                        onClick={() => handleDeleteRow(rowIndex)}
                      >
                        <FeatherIcon icon="trash" size={15} />
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    style={{
                      marginTop: staticHeader ? 4 : 37,
                      padding: "6px 32px",
                    }}
                    onClick={() => handleDeleteRow(rowIndex)}
                  >
                    <FeatherIcon icon="trash" size={15} />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <ModalComponent
        open={deleteModalData > -1 ? true : false}
        title="Delete !"
        message={"Are you sure to delete this entry."}
        primaryText="Delete"
        primaryAction={handleDeleteFileConfirm}
        secondaryText="Cancel"
        secondaryAction={handleDeleteFileCancel}
      />
    </div>
  );
};

export default FormRepeaterComponentTemplate;
