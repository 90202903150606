import moment from "moment";

export const formatDateUtil = (date) => {
  if (date) return moment(date).format("DD-MMM-YYYY HH:mm:ss").toUpperCase();
  return "-";
};

export const formatDate = (date) => {
  if (date) return moment(date).format("DD-MMM-YYYY").toUpperCase();
  return "-";
};
