import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
  tableControls as rawControls,
} from "@screens/catalog-rural/village-panchayat-ward/voter/voter-list/model/voter-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import {
  deleteDataApi,
  getDataApi,
} from "@screens/catalog-rural/candidate-voter/voter-service";
import RuralWardVoterListTemplateComponent from "./voter-list-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";
import { getAllRuralWardBooth } from "@screens/catalog-rural/rural-ward-booth/rural-ward-booth-list/rural-ward-booth-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const RuralVoterListComponent = () => {
  const location = useLocation();
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const [tableActions, setTableActions] = useState([]);
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [basicData, setBasicData] = useState([]);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { currentElection } = useSelector((state) => state.election);
  const villagePanchayatWardId = new URLSearchParams(location.search).get(
    "villagePanchayatWardId"
  );
  const villagePanchayatWardName = new URLSearchParams(location.search).get(
    "villagePanchayatWardName"
  );
  const electionId = new URLSearchParams(location.search).get("electionId");
  const [filterState, setFilterState] = useState([]);
  const [voterFields, setVoterFields] = useState([]);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const tableParams = getParamsData(filterList, menuUrl);

  const getData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr ? queryStr : `&ward_id=${villagePanchayatWardId || ""}`,
      electionId: currentElection.id,
      wardId: villagePanchayatWardId,
      type: "VILLAGE PANCHAYAT WARD",
    });
    if (data.status) {
      // console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(
      `/${menuUrl}/voter/create?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`
    );
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({
            type: ERROR,
            message: response.message,
            viewed: 0,
          })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };
  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    // console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
      type: "VILLAGE PANCHAYAT WARD",
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      return {
        ...control,
        onClick: handleChartClick,
      };
    });
    setTableControls(updatedTableControls);

    const ruralWardBoothResponse = await getAllRuralWardBooth(
      currentElection.id
    );
    // console.log({ ruralWardBoothResponse });

    const copyFilters = rawFilters({});
    console.log({ copyFilters });
    setFilterState(copyFilters);

    // Voter List
    const voterFieldsResponse = await allVoterFields();
    // console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      const allVoterFields = voterFieldsResponse.data.map((field) => {
        return {
          id: field.name,
          numeric: false,
          disablePadding: false,
          label: field.name,
        };
      });
      setVoterFields(allVoterFields);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <RuralWardVoterListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn: [...tableColumn, ...voterFields],
        tableData,
        totalCount,
        filters: filters ? filters : filterState,
        tableActions,
        openDetailPanel,
        basicData,
        electionId,
        villagePanchayatWardId,
        villagePanchayatWardName,
        tableControls,
        swapContentType,
        tableParams,
      }}
      templateEvents={{
        getData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
        handleDashboard,
        handleBackClick,
      }}
    />
  ) : (
    <> </>
  );
};

export default RuralVoterListComponent;
