import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProfileTemplateComponent from "./profile-template.component";
import { profileUpdateApi } from "./profile.component.service";
import { setUserToken } from "@reducers/app.reducer";
import { setAlertMessage } from "@reducers/alert.reducer";

const ProfileComponent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const [data, setData] = useState(user || {});
  const [error, setError] = useState({
    errorCount: 0,
    errorMessage: "",
  });

  const handleChange = (value, name) => {
    let copyUserData = { ...data };
    copyUserData[name] = value;
    console.log(copyUserData);
    setData(copyUserData);
  };

  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    console.log(data);
    const response = await profileUpdateApi(data);
    console.log(response);
    if (response.status) {
      dispatch(setAlertMessage({ type: "success", message: response.message }));
      dispatch(setUserToken(data));
    }
  };

  const templateModal = {
    user: data,
    error,
  };

  const templateEvents = {
    handleProfileSubmit,
    handleChange,
  };

  return <ProfileTemplateComponent templateModal={templateModal} templateEvents={templateEvents} />;
};

export default ProfileComponent;
