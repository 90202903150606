import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createEntry } from "./election-urban-create.component.service";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";
import { getAcByStateId } from "@screens/master/ac/ac-list/ac-list.component.service";
import {
  setByElectionPCList,
  setByElectionACList,
} from "@reducers/election.reducer";
import { BASE_URL } from "@constants/api.constants";
import ElectionUrbanCreateTemplateComponent from "./election-urban-create-template.component";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import { getElectionFormInputs } from "../model/election.modal";
import { menuUrl } from "../election-urban-list/model/election-urban-list.constants";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ElectionUrbanCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [pcList, setPCList] = useState([]);
  const [acList, setACList] = useState([]);
  const { byElectionPcList, byElectionAcList } = useSelector(
    (state) => state.election
  );

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let electionPayload = {};
    for (let i = 0; i < values.length; i++) {
      electionPayload[values[i].name] = values[i].value;
    }
    console.log({ electionPayload });
    electionPayload["election_category"] = "URBAN";
    electionPayload["type_of_election"] = "URBAN";
    // INFO : call create api
    const electionResponse = await createEntry(electionPayload);

    // INFO : check api response and show relevant alert message
    if (electionResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: electionResponse.message })
      );
    }
  };

  const handleElectionFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadElectionResponse = await uploadElectionFiles(file[0]);
    if (uploadElectionResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file:
          BASE_URL + "app-source/election-files/" + uploadElectionResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      console.log({ copyFormInputs2 });

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      inputs[4].options = formattedDistrictList;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getElectionFormInputs({
        states: formatedStateList,
        districts: [],
        onStateChange,
        initialValues: {},
      });
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ElectionUrbanCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ElectionUrbanCreateComponent;
