import { Spinner } from "reactstrap";
import React from "react";

const SpinnerComponent = () => {
  return (
    <>
      <Spinner style={{ color: "#8278f2" }}></Spinner>
      <span style={{ marginLeft: 12, marginTop: 5, position: "absolute" }}>
        Loading
      </span>
    </>
  );
};

export default SpinnerComponent;
