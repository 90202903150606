import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUrbanWardBoothFormInputs } from "../model/urban-ward-booth.modal";
import { editEntry } from "./urban-ward-booth-edit.component.service";
import UrbanWardBoothEditTemplateComponent from "./urban-ward-booth-edit-template.component";
import { getAllStates } from "@screens/catalog-urban/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog-urban/districts/districts-list/districts-list.component.service";
import {
  getUrbanWardByAcId,
  getUrbanWardByDistrictId,
} from "@screens/catalog-urban/urban-ward/urban-ward-list/urban-ward-list.service";
import { menuUrl } from "../urban-ward-booth-list/model/urban-ward-booth-list.constants";
import { getPcByStateId } from "@screens/catalog-urban/pc/pc-list/pc-list.component.service";
import { getAcByPcId } from "@screens/catalog-urban/ac/ac-list/ac-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  URBAN_WARD_BOOTH_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const UrbanWardBoothEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    // INFO : call edit api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[2].options = formattedDistrictList;
      setFormInputs(inputs);
    }
    const pcResponse = await getPcByStateId(value);
    if (pcResponse.status) {
      const formattedPcList = pcResponse.data.map((pc) => {
        return {
          name: pc.name,
          value: pc.id,
        };
      });

      inputs[3].options = formattedPcList;
      setFormInputs(inputs);
    }
  };

  const onPcChange = async (value, inputs) => {
    const acResponse = await getAcByPcId(value);
    if (acResponse.status) {
      const formattedAcList = acResponse.data.map((ac) => {
        return {
          name: ac.name,
          value: ac.id,
        };
      });

      inputs[4].options = formattedAcList;
      setFormInputs(inputs);
    }
  };

  const onAcChange = async (value, inputs) => {
    const urbanWardResponse = await getUrbanWardByAcId(value);
    if (urbanWardResponse.status) {
      const formattedUrbanWardList = urbanWardResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[5].options = formattedUrbanWardList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    let states;
    let districts;
    let pcs = [];
    let acs = [];
    let formattedUrbanWardList;
    let pcResponse = [];
    let acResponse = [];

    if (location.state.row.state_id)
      pcResponse = await getPcByStateId(location?.state?.row?.state_id);
    if (location?.state?.row?.pc_id)
      acResponse = await getAcByPcId(location?.state?.row?.pc_id);

    const statesResponse = await getAllStates(currentElection.id);
    if (statesResponse.status) {
      states = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    const districtResponse = await getDistrictByStateId(
      location?.state?.row?.state_id
    );
    if (districtResponse.status) {
      districts = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });
    }
    if (location?.state?.row?.pc_id) {
      if (pcResponse.status) {
        pcs = pcResponse.data.map((pc) => {
          return {
            name: pc.name,
            value: pc.id,
          };
        });
      }

      if (acResponse.status) {
        acs = acResponse.data.map((ac) => {
          return {
            name: ac.name,
            value: ac.id,
          };
        });
      }
    }
    const urbanWardResponse = await getUrbanWardByDistrictId(
      location?.state?.row?.district_id
    );
    if (urbanWardResponse.status) {
      formattedUrbanWardList = urbanWardResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }

    const formInputData = getUrbanWardBoothFormInputs({
      states: states,
      districts: districts,
      pcs: pcs,
      acs: acs,
      urbanWards: formattedUrbanWardList,
      initialValues: {
        govtId: location?.state?.row?.govt_id || "",
        name: location?.state?.row?.name || "",
        location: location?.state?.row?.location || "",
        stateId: location?.state?.row?.state_id || "",
        districtId: location?.state?.row?.district_id || "",
        pcId: location?.state?.row?.pc_id || "",
        acId: location?.state?.row?.ac_id || "",
        urbanWardId: location?.state?.row?.urban_ward_id || "",
        regionalName: location?.state?.row?.regional_name || "",
        boothType: location?.state?.row?.booth_type || "",
        locationRegionalName:
          location?.state?.row?.location_regional_name || "",
        remarks: location?.state?.row?.remarks || "",
      },
      onStateChange,
      onPcChange,
      onAcChange,
    });
    console.log({ formInputData });
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UrbanWardBoothEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UrbanWardBoothEditComponent;
