import SlidingPanel from "react-sliding-side-panel";
import FeatherIcon from "feather-icons-react";
import "react-sliding-side-panel/lib/index.css";
import { useSelector } from "react-redux";

const DetailPanelComponent = ({
  anchor = "right",
  heading = "Details Panel",
  open,
  toggleDrawer,
  children,
  drawerWidth = 30,
}) => {
  const { theme } = useSelector((state) => state.app);
  return (
    <div>
      <SlidingPanel
        panelClassName={`detail-panel-class ${
          theme === "light" ? "light-theme" : "dark-theme"
        }`}
        type={anchor}
        isOpen={open}
        size={drawerWidth}
      >
        <div>
          <div className="row">
            <div
              style={{
                // color: "#111422",
                fontSize: 18,
                marginLeft: 20,
                paddingTop: 15,
                paddingBottom: 15,
                float: "left",
                width: "80%",
              }}
            >
              {heading}
            </div>
            <div style={{ width: "5%", float: "right" }}>
              <a
                style={{ paddingTop: 10 }}
                className=""
                onClick={() => toggleDrawer(false)}
              >
                <FeatherIcon icon={"x"} style={{ marginTop: 14 }} />
              </a>
            </div>
          </div>
          <div className="row" style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div className="col-md-12">{children}</div>
          </div>
        </div>
      </SlidingPanel>
    </div>
  );
};

export default DetailPanelComponent;
