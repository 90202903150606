import { APP_NAME } from "@constants/app.constants";
import UpcomingElectionListComponent from "@screens/master/upcoming-election/upcoming-election-list/upcoming-election-list.component";

const upcomingElectionRoutes = [
  {
    name: `Upcoming Election - ${APP_NAME}`,
    path: "/master/upcoming-election",
    icon: "anchor",
    component: UpcomingElectionListComponent,
  },
];

export default upcomingElectionRoutes;
