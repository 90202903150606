import { APP_NAME } from "@constants/app.constants";
import ProfileComponent from "@screens/profile/profile.component";
import SettingCreateComponent from "@screens/system/settings/setting-create/setting-create.component";
import SettingEditComponent from "@screens/system/settings/setting-edit/setting-edit.component";
import SettingListComponent from "@screens/system/settings/setting-list/setting-list.component";

const settingRoutes = [
  {
    name: "Settings",
    icon: "tool",
    path: "/system/settings",
    component: SettingListComponent,
  },
  {
    name: `Create Setting - ${APP_NAME}`,
    path: "/system/settings/create",
    component: SettingCreateComponent,
  },
  {
    name: `Edit Setting - ${APP_NAME}`,
    path: "/system/settings/edit",
    component: SettingEditComponent,
  },
  {
    name: `Profile`,
    path: "/profile",
    component: ProfileComponent,
  },
];

export default settingRoutes;
