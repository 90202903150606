import moment from "moment";

export const getHex = (color) => {
  return window.getComputedStyle(document.body).getPropertyValue(color).trim();
};

export const retry = async (fn, retriesLeft = 5, interval = 1000) => {
  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval));
      return retry(fn, retriesLeft - 1);
    }
    throw error;
  }
};

export const formatMenuData = ({ menuList, currentElection }) => {
  let copyMenu = [...menuList];
  console.log("common", currentElection, menuList, copyMenu);
  let restrictedMenu;
  if (currentElection) {
    switch (currentElection.type_of_election) {
      case "PC":
        {
          restrictedMenu = ["District"];
        }
        break;
      case "AC":
        {
          restrictedMenu = ["PC."];
        }
        break;
    }
    const catalogIndex = copyMenu.findIndex(
      (menu) => menu.menu_name === "Catalog"
    );
    const filteredMenu = [];
    if (
      copyMenu[catalogIndex].sub_menus &&
      copyMenu[catalogIndex].sub_menus.length > 0
    ) {
      for (let i = 0; i < copyMenu[catalogIndex].sub_menus.length; i++) {
        const menuCheck = restrictedMenu.find(
          (menu) => menu === copyMenu[catalogIndex].sub_menus[i].menu_name
        );
        if (!menuCheck) filteredMenu.push(copyMenu[catalogIndex].sub_menus[i]);
      }
    }
    copyMenu[catalogIndex] = {
      ...copyMenu[catalogIndex],
      sub_menus: filteredMenu,
    };
  }

  return copyMenu;
};

const UTILS = {
  userGreeting: () => {
    let d = new Date();
    let time = d.getHours();
    if (time < 12) {
      return "Good Morning";
    }
    if (time >= 12 && time < 16) {
      return "Good Afternoon";
    }
    return "Good Evening";
  },
  descendingComparator: (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  },

  getComparator: (order, orderBy) => {
    return order === "desc"
      ? (a, b) => UTILS.descendingComparator(a, b, orderBy)
      : (a, b) => -UTILS.descendingComparator(a, b, orderBy);
  },

  stableSort: (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  },
};

export const objectToString = (array, separator) => {
  return Object.keys(array)
    .map((key) => key + "=" + array[key])
    .join(separator);
};

export const getFilterDataByMenu = (existingFilter, menuUrl) => {
  const filterData = existingFilter.find(
    (filter) => filter.menuUrl === menuUrl
  );
  return filterData;
};

export const getFilterData = (
  existingFilter,
  menuUrl,
  type = "queryString"
) => {
  const filterData = existingFilter.find(
    (filter) => filter.menuUrl === menuUrl
  );
  if (type === "filter") return filterData?.filters || null;
  if (filterData) {
    // filterData.filter.reduce((x, y) => ((x[y] = 1), x), {});
    const initialValue = {};
    const queryString = filterData.filters.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item["value"],
      };
    }, initialValue);
    return queryString ? "&" + objectToString(queryString, "&") : "";
  }
  return {};
};

export const checkFilterForValue = (filters) => {
  let filterFound = false;
  if (filters && filters.length > 0)
    for (let i = 0; i < filters.length; i++)
      if (filters[i].value != "") filterFound = true;
  return filterFound;
};

export const getMenuFilters = (allFilterList, menuUrl) => {
  return allFilterList.find((filter) => filter.menuUrl === menuUrl);
};

const formatFilterValue = (filter) => {
  if (
    filter.type === "autocomplete" &&
    filter.isMultiple &&
    filter.value &&
    filter.value.length > 0
  ) {
    const valArray = filter.value.map((val) => val.value);
    return valArray.toString();
  }
  return filter.value;
};

export const filtersToQuery = (filters) => {
  const initialValue = {};
  const queryString = filters.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: formatFilterValue(item),
    };
  }, initialValue);
  return queryString ? "&" + objectToString(queryString, "&") : "";
};

export const constructFilterData = (existingFilter, menuUrl, filterData) => {
  let filterArray = existingFilter.map((filter) => {
    return {
      ...filter,
    };
  });
  const filterCheckIndex = existingFilter.findIndex(
    (filter) => filter.menuUrl === menuUrl
  );
  console.log({ filterCheckIndex });
  if (filterCheckIndex > -1) {
    filterArray[filterCheckIndex].filters = filterData;
  } else filterArray.push({ menuUrl, filters: filterData });
  console.log({ filterArray });
  return filterArray;
};

export const dateStringToObject = (dateStr) => {
  const dateTimeArray = dateStr.split(" ");
  const dateArray = dateTimeArray[0].split("-");
  // const formatedDate = dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2];
  //
  // return formatedDate;

  console.log(
    dateArray,
    dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2],
    moment(dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2])
  );

  // console.log(dayjs(dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2]));
  // return dayjs(dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2]);
  // return moment(dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2]);
  const dateObject = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  // const dateObject = new Date(dateArray[0] + '/' + dateArray[1] + '/' + dateArray[2]);
  console.log(dateObject);
  return dateObject;
};

export const electionYear = () => {
  const value = [];
  for (let i = 2000; i <= 2040; i++) value.push({ name: i, value: i });
  return value;
};

export default UTILS;

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary75: "red",
    primary50: "#7367f0",
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const copyText = async (text) => {
  await navigator.clipboard.writeText(text);
};

export const redirectToLink = (url) => {
  window.open(url);
};

export const electionPhase = () => {
  const value = [];
  for (let i = 1; i <= 10; i++) value.push({ name: i, value: i });
  return value;
};

export const sortArrayOfObject = (array, object, type) => {
  if (type === "ASC")
    return array.sort((a, b) => (a[object] < b[object] ? 1 : -1));
  else return array.sort((a, b) => (a[object] > b[object] ? 1 : -1));
};
