import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";
import { APP } from "@constants/app.constants";

const { RESERVATION, AC_URBAN_TYPE } = APP;
const tableTitle = "AC Urban";
const keyColumn = "id";
const menuUrl = "master-urban/ac-urban";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "pc_name",
    numeric: false,
    disablePadding: false,
    label: "PC name",
    width: 150,
    sorting: true,
  },
  {
    id: "ac_name",
    numeric: false,
    disablePadding: false,
    label: "AC name",
    width: 150,
    sorting: true,
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "AC Urban Id",
    sorting: true,
    width: 100,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "AC Urban name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: 150,
    sorting: true,
  },
  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "total_ward_count",
    numeric: false,
    disablePadding: false,
    label: "Total Ward",
    sorting: true,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Master Urban", menu_url: null },
  { name: "AC Urban", menu_url: "/master-urban/ac-urban" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "autocomplete",
    placeholder: "Select State",
    options: [],
  },
  {
    name: "pc",
    id: "pc",
    label: "PC",
    value: "",
    type: "autocomplete",
    placeholder: "Select PC",
    options: [],
  },
  {
    name: "ac",
    id: "ac",
    label: "AC",
    value: "",
    type: "autocomplete",
    placeholder: "Select AC",
    options: [],
  },
  {
    name: "name",
    id: "name",
    label: "AC Urban name",
    value: "",
    type: "text",
  },
  {
    name: "type",
    id: "type",
    label: "Type",
    value: "",
    type: "dropdown",
    placeholder: "Select Type",
    options: AC_URBAN_TYPE,
  },
  {
    name: "reservation",
    id: "reservation",
    label: "Reservation",
    value: "",
    type: "dropdown",
    placeholder: "Select Reservation",
    options: RESERVATION,
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
