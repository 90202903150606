export const removeLastComma = (strng) => {
  var n = strng.lastIndexOf(",");
  var a = strng.substring(0, n);
  return a;
};

export const formatRemarks = (value) => {
  return <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>;
};

export const getFileNameFromUrl = (url) => {
  if (!url) return "";
  return url.substring(url.lastIndexOf("/") + 1);
};

export const getFileTypeFromUrl = (url) => {
  console.log({ url });
  if (!url) return "";
  const type = url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
  switch (type) {
    case "png":
    case "jpg":
    case "jpeg":
      return "image";
    case "pdf":
      return "pdf";
    case "xlsx":
      return "xlsx";
    case "xls":
      return "xls";
    case "xlsm":
      return "xlsm";
    case "xltx":
      return "xltx";
    case "docx":
      return "docx";
    case "doc":
      return "doc";
    case "csv":
      return "csv";
    case "txt":
      return "txt";

    default:
      return "";
  }
};

export const stringShortner = (string, length = 6, charLength = 4) => {
  if (string.length >= length) {
    const firstHalf = string.slice(0, charLength).concat("...");

    const lastHalf = string.substr(string.length - charLength);

    return firstHalf + lastHalf;
  } else return string;
};

export const sortByKey = (array, key) => {
  if (array && key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  return null;
};
