import React from "react";
import PCDashboardComponent from "../dashboard/dashboard.component";
import MasterPCChartComponent from "./chart.component";

const MasterPcSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <MasterPCChartComponent />}
      {type == "DASHBOARD" && <PCDashboardComponent />}
    </>
  );
};

export default MasterPcSwapComponent;
