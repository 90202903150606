import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";
import { getDistrictByStateId } from "@screens/catalog-rural/districts/districts-list/districts-list.component.service";
import { getDistrictUnionByDistrictId } from "@screens/catalog-rural/district-union/district-union-list/district-union-list.component.service";
import { getPanchayatUnionByDistrictUnionId } from "@screens/catalog-rural/panchayat-union/panchayat-union-list/panchayat-union-list.service";

const tableTitle = "Panchayat Union Wards";
const keyColumn = "id";
const menuUrl = "catalog-rural/panchayat-union-ward";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_union_name",
    numeric: false,
    disablePadding: false,
    label: "District Union name",
    width: 150,
    sorting: true,
  },
  {
    id: "panchayat_union_name",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union name",
    width: 150,
    sorting: true,
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union Ward Id",
    sorting: true,
    width: 100,
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union Ward name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },

  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "total_ward_count",
    numeric: false,
    disablePadding: false,
    label: "Total Ward",
    sorting: true,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Catalog", menu_url: null },
  {
    name: "Panchayat Union Ward",
    menu_url: "/catalog-rural/panchayat-union-ward",
  },
];

const additionalActions = ({
  handleViewDetail,
  handleCandidateClick,
  handleVoterClick,
}) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
  {
    name: "Candidates",
    icon: "users",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleCandidateClick,
  },
  {
    name: "Voters",
    icon: "speaker",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleVoterClick,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    onChange: async (value, formInputs) => {
      const districtUnionResponse = await getDistrictUnionByDistrictId(value);
      console.log({ districtUnionResponse });
      if (districtUnionResponse.status) {
        const formattedDistrictUnion = districtUnionResponse.data.map(
          (data) => {
            return {
              name: data.name,
              value: data.id,
            };
          }
        );

        formInputs[2].options = formattedDistrictUnion;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district_union",
    id: "district_union",
    label: "District Union",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District Union",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const panchayatUnionResponse = await getPanchayatUnionByDistrictUnionId(
        value
      );
      console.log({ panchayatUnionResponse });
      if (panchayatUnionResponse.status) {
        const formattedPanchayatUnion = panchayatUnionResponse.data.map(
          (data) => {
            return {
              name: data.name,
              value: data.id,
            };
          }
        );

        formInputs[3].options = formattedPanchayatUnion;
      }

      return formInputs;
    },
  },
  {
    name: "panchayat_union",
    id: "panchayat_union",
    label: "Panchayat Union",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Panchayat Union",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "Panchayat Union Ward name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
  // {
  //   name: "Dashboard",
  //   icon: "grid",
  //   status: 0,
  //   onClick: null,
  // },
];
const tableConfig = {
  actionWidth: 170,
};

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
  tableConfig,
};
