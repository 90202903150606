import { List, ListInlineItem } from "reactstrap";

const ListComponent = ({ items }) => {
  return (
    <>
      {items &&
        items.map((item) => (
          <div className="row">
            <div className="col-md-6"> {item.key}</div>
            <div className="col-md-6" style={{ textAlign: "right" }}>
              {item.value}
            </div>
          </div>
        ))}
    </>
  );
};

export default ListComponent;
