import { APP_NAME } from "@constants/app.constants";
import VillagePanchayatCreateComponent from "@screens/catalog-rural/village-panchayat/village-panchayat-create/village-panchayat-create";
import VillagePanchayatEditComponent from "@screens/catalog-rural/village-panchayat/village-panchayat-edit/village-panchayat-edit";
import VillagePanchayatListComponent from "@screens/catalog-rural/village-panchayat/village-panchayat-list/village-panchayat-list";

const catalogVillagePanchayatRoutes = [
  {
    name: "Village Panchayat List",
    icon: "disc",
    path: "/catalog-rural/village-panchayat",
    component: VillagePanchayatListComponent,
  },

  {
    name: `Create Village Panchayat - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat/create",
    component: VillagePanchayatCreateComponent,
  },

  {
    name: `Edit Village Panchayat - ${APP_NAME}`,
    path: "/catalog-rural/village-panchayat/edit",
    component: VillagePanchayatEditComponent,
  },
];

export default catalogVillagePanchayatRoutes;
