import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LoginV2TemplateComponent from "./login-v2-template.component";
import { setUserTokenAction } from "@reducers/app.reducer";
import service from "./login.component.service";
import { setAlertMessage } from "@reducers/alert.reducer";
import MESSAGES from "@constants/messages.constants";
import { setCurrentElection } from "@reducers/election.reducer";
import "@styles/login-v2.css";

const {
  ALERT_TYPES: { ERROR },
} = MESSAGES;

const LoginComponent = () => {
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { alertMessage } = useSelector((state) => state.alert);
  const app = useSelector((state) => state.app);
  const { username, password } = data;
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  console.log({ pathname, app });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const response = await service.loginProcess(data);
    if (response.status) {
      setIsLoading(false);
      dispatch(
        setUserTokenAction({ token: response.token, user: response.user })
      );
      if (response.user.election_data) {
        const electionData = {
          ...response.user.election_data,
          state_name: response.user.election_data.name,
        };
        console.log({ electionData });
        dispatch(setCurrentElection(electionData));
      } else {
        dispatch(setCurrentElection(null));
      }
      history.push("/launchpad");
    } else {
      setIsLoading(false);
      dispatch(
        setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
      );
    }
  };

  const checkLoggedInUser = () => {
    if (app && app?.token) {
      history.push("/launchpad");
    }
  };

  useEffect(() => {
    if (pathname === "/" || pathname === "/login") {
      document.body.classList.remove("dark-layout");
      document.body.classList.add("light-layout");
    }
  }, []);

  useEffect(() => {
    checkLoggedInUser();
    if (app.theme === "light") {
      document.body.classList.remove("dark-layout");
      document.body.classList.add("light-layout");
    } else {
      document.body.classList.remove("light-layout");
      document.body.classList.add("dark-layout");
    }
  }, []);

  useEffect(() => {
    document.body.style.zoom = "90%";
  }, []);

  return (
    <LoginV2TemplateComponent
      templateModal={{
        username,
        password,
        isLoading,
        alertMessage,
        passwordVisibility,
      }}
      templateEvents={{ handleChange, handleSubmit, handleTogglePassword }}
    />
  );
};

export default LoginComponent;
