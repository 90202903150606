import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  tableControls as rawControls,
} from "@screens/crm/urban-candidates/candidate-list/model/candidate-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import CandidateTemplateComponent from "./candidate-list-template.component";
import { getElectionByCategory } from "@screens/election/election-list/election-list.component.service";
import { deleteDataApi, getDataApi } from "./candidate-list.component.service";
import { getAllPoliticalParty } from "@screens/political-party/political-party-list/political-party-list.component.service";
import { createDataDownloadRequestEntry } from "@screens/catalog-urban/urban-ward/candidate/candidate-list/candidate-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_CANDIDATE_ALERTS: { DELETE_ALERT },
  AC_VOTER_ALERTS: { DOWNLOAD_REQUEST },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const UrbanWardCandidateListComponent = () => {
  const location = useLocation();
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const { appRowsPerPage } = useSelector((state) => state.app);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const tableParams = getParamsData(filterList, menuUrl);
  const [filterState, setFilterState] = useState([]);
  const { user } = useSelector((state) => state?.app);

  const getCandidateData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const candidateData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (candidateData.status) {
      console.log(candidateData);
      setTableData(candidateData.data);
      setTotalCount(candidateData.totalCount);
    }
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getCandidateData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);

    const downloadResponse = await createDataDownloadRequestEntry(queryStr, {
      request_type: "DOWNLOAD_URBAN_WARD_CANDIDATE_DATA",
      status: 1,
    });
    if (downloadResponse.status) {
      dispatch(
        setAlertMessage({
          type: SUCCESS,
          message: DOWNLOAD_REQUEST + user.email,
          viewed: 0,
        })
      );
    }
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getCandidateData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };
  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      return {
        ...control,
        onClick: handleChartClick,
      };
    });
    setTableControls(updatedTableControls);

    const electionResponse = await getElectionByCategory("URBAN");
    const politicalPartyResponse = await getAllPoliticalParty();
    console.log({ electionResponse, politicalPartyResponse });

    if (electionResponse.status && politicalPartyResponse.status) {
      const formattedElection = electionResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formatedPoliticalPartyList = politicalPartyResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      const copyFilters = rawFilters({
        elections: formattedElection,
        parties: formatedPoliticalPartyList,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <CandidateTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : filterState,
        swapContentType,
        tableParams,
      }}
      templateEvents={{
        getCandidateData,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default UrbanWardCandidateListComponent;
