import { APP_NAME } from "@constants/app.constants";

import ACUrbanCreateComponent from "@screens/master-urban/ac-urban/ac-urban-create/ac-urban-create.component";
import ACUrbanEditComponent from "@screens/master-urban/ac-urban/ac-urban-edit/ac-urban-edit.component";
import ACUrbanFileUploadComponent from "@screens/master-urban/ac-urban/ac-urban-file/ac-urban-file.component";
import ACUrbanListComponent from "@screens/master-urban/ac-urban/ac-urban-list/ac-urban-list.component";

const masterACUrbanRoutes = [
  {
    name: "AC Urban List",
    icon: "inbox",
    path: "/master-urban/ac-urban",
    component: ACUrbanListComponent,
  },

  {
    name: `Create AC Urban - ${APP_NAME}`,
    path: "/master-urban/ac-urban/create",
    component: ACUrbanCreateComponent,
  },

  {
    name: `Edit AC Urban - ${APP_NAME}`,
    path: "/master-urban/ac-urban/edit",
    component: ACUrbanEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-urban/ac-urban/upload",
    component: ACUrbanFileUploadComponent,
  },
];

export default masterACUrbanRoutes;
