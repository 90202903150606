import DownloadRequestListComponent from "@screens/download-request/download-request-list/download-request-list.component";

const downloadRequestsRoutes = [
  {
    name: "Download Requests",
    icon: "phone",
    path: "/data-download-requests",
    component: DownloadRequestListComponent,
  },
];

export default downloadRequestsRoutes;
