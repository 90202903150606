const FormInputCheckboxComponent = ({ id, label, name, checked, onChange }) => {
  const handleChange = (event) => {
    if (onChange) onChange(name, event.target.checked);
  };
  return (
    <div class="form-check mb-1">
      <input
        id={id || name}
        type="checkbox"
        name={name}
        class="form-check-input"
        checked={checked}
        onChange={handleChange}
      />
      <label for={id || name} class="form-check-label form-label">
        {label}
      </label>
    </div>
  );
};

export default FormInputCheckboxComponent;
