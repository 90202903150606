import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import ButtonGroupComponent from "@atoms/button-group/button-group.component";

const ElectionDashboardTemplateComponent = ({
  templateModal: { dashboardData,breadCrumbs,type },
  templateEvents: {      
    handleDashboardBackClick,
    handleRefreshClick,
    handleTypeToggle,
  },
}) => {
  console.log({ dashboardData });
  const ActionContent = ({ handleDashboardBackClick }) => {
    return (
     <>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleDashboardBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleRefreshClick}
        >
        <FeatherIcon icon="refresh-cw" size={17} />
      </button>
     </>
    );
  };
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        breadCrumbs={breadCrumbs}
        actionContent={
          <ActionContent handleDashboardBackClick={handleDashboardBackClick} />
        }
      />

        <ButtonGroupComponent
              buttons={[{ name: "Overview" }, { name: "Detail Page" }]}
              active={type}
              size="md"
              handleClick={handleTypeToggle}
            />        
    </PageWrapperComponent>
  );
};

export default ElectionDashboardTemplateComponent;
