import UserLogListComponent from "@screens/user-administration/user-log/user-log-list/user-log-list.component";

const userLogRoutes = [
  {
    name: "User Log",
    icon: "layers",
    path: "/user-administration/user-log",
    component: UserLogListComponent,
  },
];

export default userLogRoutes;
