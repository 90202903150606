import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

function AccordionComponent({ items }) {
  const [open, setOpen] = useState("1");
  const { theme } = useSelector((state) => state.app);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        {items &&
          items.map((item) => (
            <AccordionItem
              className={`${theme === "dark" ? "text-body" : ""}`}
              key={item.id}
            >
              <AccordionHeader targetId={item.id}>
                {item.heading}
              </AccordionHeader>
              <AccordionBody accordionId={item.id}>
                {item.content}
              </AccordionBody>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
}

export default AccordionComponent;
