import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import { getDistrictUnionWardByDistrictUnionIds } from "@screens/master-rural/district-union-ward/district-union-ward-list/district-union-ward-list.component.service";
import { getDistrictUnionByDistrictId } from "@screens/master-rural/district-union/district-union-list/district-union-list.component.service";
import { getPanchayatUnionWardByPanchyatUnionIds } from "@screens/master-rural/panchayat-union-ward/panchayat-union-ward-list/panchayat-union-ward-list.component.service";
import { getPanchayatUnionByDistrictId } from "@screens/master-rural/panchayat-union/panchayat-union-list/panchayat-union-list.service";
import { getVillagePanchayatWardByVillagePanchayatIds } from "@screens/master-rural/village-panchayat-ward/village-panchayat-ward-list/village-panchayat-ward-list.service";
import { getVillagePanchayatByDistrictId } from "@screens/master-rural/village-panchayat/village-panchayat-list/village-panchayat-list.service";
import { redirectToLink } from "@utils/common";

const {
  ELECTION_CATEGORY,
  ELECTION_STATUS,
  MONTHS,
  YEAR,
  ELECTION_PHASE,
  POLL_DATA,
  SELECTION_TYPE,
} = APP;
export const phaseRepeaterSize = {
  1: {
    eventName: 5,
    date: 5,
  },
  2: {
    eventName: 6,
    date: 2,
  },
  3: {
    eventName: 4,
    date: 2,
  },
  4: {
    eventName: 3,
    date: 2,
  },
  5: {
    eventName: 6,
    date: 1,
  },
  6: {
    eventName: 5,
    date: 1,
  },
  7: {
    eventName: 4,
    date: 1,
  },
  8: {
    eventName: 3,
    date: 1,
  },
  9: {
    eventName: 2,
    date: 1,
  },
  10: {
    eventName: 1,
    date: 1,
  },
};

export const getElectionFormInputs = ({
  states,
  districts,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "Election ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter Election ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Election ID is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Election Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter Election Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Name is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    onChange: onStateChange,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Category",
    id: "category",
    name: "category",
    options: ELECTION_CATEGORY,
    value: initialValues?.category || "",
    placeholder: "Select category",
    validations: [
      {
        type: "REQUIRED",
        message: "Category is required !",
      },
    ],
    onChange: (value, copyInputs, additionalValues) => {
      console.log(value, copyInputs);
      const districtIndex = copyInputs[4];
      const ruralTypeIndex = copyInputs[5];

      if (value === "General") {
        console.log({ value });
        ruralTypeIndex.isHide = true;
      } else {
        ruralTypeIndex.isHide = false;
      }
      if (value === "By-Election") {
        console.log({ value });
        districtIndex.isHide = false;
      } else {
        districtIndex.isHide = true;
      }
    },
  },
  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      // {
      //   type: "REQUIRED",
      //   message: "District Name is required !",
      // },
    ],
    isHide: true,
  },
  {
    type: "CHECKBOX-MULTIPLE",
    label: "Rural Type",
    id: "ruralTypes",
    name: "rural_types",
    isHide: true,
    options: [
      { name: "DISTRICT UNION", value: "DISTRICT UNION", checked: false },
      { name: "PANCHAYAT UNION", value: "PANCHAYAT UNION", checked: false },
      { name: "VILLAGE PANCHAYAT", value: "VILLAGE PANCHAYAT", checked: false },
    ],
    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const categoryIndex = copyInputs[3];
      const districtIndex = copyInputs[4];
      const ruralTypeIndex = copyInputs[5];
      const districtUnionIndex = copyInputs[6];
      const districtUnionWardIndex = copyInputs[7];
      const panchayatUnionIndex = copyInputs[8];
      const panchayatUnionWardIndex = copyInputs[9];
      const villagePanchayatIndex = copyInputs[10];
      const villagePanchayatWardIndex = copyInputs[11];

      ruralTypeIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = ruralTypeIndex.options.filter(
        (option) => option.checked
      );

      ruralTypeIndex.value = selectedValues;

      if (districtIndex.value && categoryIndex.value === "By-Election") {
        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "DISTRICT UNION" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === true
        ) {
          const districtUnionResponse = await getDistrictUnionByDistrictId(
            districtIndex.value
          );
          console.log({ districtUnionResponse });
          if (districtUnionResponse.status && districtUnionResponse.status) {
            const formatedDistrictUnion = districtUnionResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                  checked: false,
                };
              }
            );
            districtUnionIndex.isHide = false;
            districtUnionIndex.options = formatedDistrictUnion;
            console.log({ formatedDistrictUnion });
          }
        }
        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "DISTRICT UNION" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === false
        ) {
          districtUnionIndex.isHide = true;
          districtUnionIndex.value = [];
          districtUnionWardIndex.isHide = true;
          districtUnionWardIndex.value = [];
        }

        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "PANCHAYAT UNION" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === true
        ) {
          const panchayatUnionResponse = await getPanchayatUnionByDistrictId(
            districtIndex.value
          );
          console.log({ panchayatUnionResponse });
          if (panchayatUnionResponse.status && panchayatUnionResponse.status) {
            const formattedPanchayatUnion = panchayatUnionResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                  checked: false,
                };
              }
            );
            panchayatUnionIndex.isHide = false;
            panchayatUnionIndex.options = formattedPanchayatUnion;
          }
        }
        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "PANCHAYAT UNION" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === false
        ) {
          panchayatUnionIndex.isHide = true;
          panchayatUnionIndex.value = [];
          panchayatUnionWardIndex.isHide = true;
          panchayatUnionWardIndex.value = [];
        }

        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "VILLAGE PANCHAYAT" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === true
        ) {
          const villagePanchayatResponse =
            await getVillagePanchayatByDistrictId(districtIndex.value);
          console.log({ villagePanchayatResponse });
          if (
            villagePanchayatResponse.status &&
            villagePanchayatResponse.status
          ) {
            const formatedTownPanchayat = villagePanchayatResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                  checked: false,
                };
              }
            );
            villagePanchayatIndex.isHide = false;
            villagePanchayatIndex.options = formatedTownPanchayat;
          }
        }
        if (
          ruralTypeIndex.options[additionalValues.optionIndex].value ===
            "VILLAGE PANCHAYAT" &&
          ruralTypeIndex.options[additionalValues.optionIndex].checked === false
        ) {
          villagePanchayatIndex.isHide = true;
          villagePanchayatIndex.value = [];
          villagePanchayatWardIndex.isHide = true;
          villagePanchayatWardIndex.value = [];
        }
      }

      return copyInputs;
    },
    value: "",
    validations: [],
  },
  {
    type: "SELECT-GROUP",
    label: "District Unions",
    id: "district_unions",
    name: "district_unions",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const districtUnionIndex = copyInputs[6];
      const districtUnionWardIndex = copyInputs[7];

      districtUnionIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = districtUnionIndex.options.filter(
        (option) => option.checked
      );

      districtUnionIndex.value = selectedValues;
      const districtUnionIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ districtUnionIds });

      if (districtUnionIndex.value && categoryIndex.value === "By-Election") {
        console.log({ districtUnion: districtUnionIndex });
        const districtUnionWardResponse =
          await getDistrictUnionWardByDistrictUnionIds(districtUnionIds);
        if (
          districtUnionWardResponse.status &&
          districtUnionWardResponse.status
        ) {
          const formatedDistrictUnionWard = districtUnionWardResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            }
          );
          districtUnionWardIndex.isHide = false;
          districtUnionWardIndex.options = formatedDistrictUnionWard;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },

  {
    type: "SELECT-GROUP",
    label: "District Union Wards",
    id: "district_union_wards",
    name: "district_union_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const districtUnionWardIndex = copyInputs[7];
      districtUnionWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = districtUnionWardIndex.options.filter(
        (option) => option.checked
      );

      districtUnionWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Panchayat Unions",
    id: "panchayat_unions",
    name: "panchayat_unions",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const panchayatUnionIndex = copyInputs[8];
      const panchayatUnionWardIndex = copyInputs[9];

      panchayatUnionIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = panchayatUnionIndex.options.filter(
        (option) => option.checked
      );

      panchayatUnionIndex.value = selectedValues;

      const panchayatUnionIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ panchayatUnionIds });

      if (panchayatUnionIndex.value && categoryIndex.value === "By-Election") {
        console.log({ panchayatUnion: panchayatUnionIndex });
        const panchayatUnionWardResponse =
          await getPanchayatUnionWardByPanchyatUnionIds(panchayatUnionIds);
        if (
          panchayatUnionWardResponse.status &&
          panchayatUnionWardResponse.status
        ) {
          const formattedPanchayatUnionWard =
            panchayatUnionWardResponse.data.map((data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            });
          panchayatUnionWardIndex.isHide = false;
          panchayatUnionWardIndex.options = formattedPanchayatUnionWard;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Panchayat Union Wards",
    id: "panchayat_union_wards",
    name: "panchayat_union_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const panchayatUnionWardIndex = copyInputs[9];
      panchayatUnionWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = panchayatUnionWardIndex.options.filter(
        (option) => option.checked
      );

      panchayatUnionWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Village Panchayats",
    id: "village_panchayats",
    name: "village_panchayats",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const villagePanchayatIndex = copyInputs[10];
      const villagePanchayatWardIndex = copyInputs[11];
      villagePanchayatIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = villagePanchayatIndex.options.filter(
        (option) => option.checked
      );

      villagePanchayatIndex.value = selectedValues;

      const villagePanchayatIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ villagePanchayatIds });

      if (
        villagePanchayatIndex.value &&
        categoryIndex.value === "By-Election"
      ) {
        console.log({ panchayatUnion: villagePanchayatIndex });
        const villagePanchayatWardResponse =
          await getVillagePanchayatWardByVillagePanchayatIds(
            villagePanchayatIds
          );
        if (
          villagePanchayatWardResponse.status &&
          villagePanchayatWardResponse.status
        ) {
          const formatedVillagePanchayatWards =
            villagePanchayatWardResponse.data.map((data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            });
          villagePanchayatWardIndex.isHide = false;
          villagePanchayatWardIndex.options = formatedVillagePanchayatWards;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Village Panchayat Wards",
    id: "villagePanchayatWards",
    name: "village_panchayat_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const villagePanchayatWardIndex = copyInputs[11];
      villagePanchayatWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = villagePanchayatWardIndex.options.filter(
        (option) => option.checked
      );

      villagePanchayatWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT",
    label: "Selection Type",
    id: "selectionType",
    name: "selection_type",
    options: SELECTION_TYPE,
    value: initialValues?.selectionType || "DIRECT",
    placeholder: "Select Selection Type",
  },

  {
    type: "AUTOCOMPLETE",
    label: "Year",
    id: "yearOfElection",
    name: "year_of_election",
    options: YEAR,
    value: initialValues?.year_of_election || "",
    placeholder: "Select year ",
    validations: [
      {
        type: "REQUIRED",
        message: "Election year is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "Month",
    id: "monthOfElection",
    name: "month_of_election",
    options: MONTHS,
    value: initialValues?.month_of_election || "",
    placeholder: "Select month",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Month is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: ELECTION_STATUS,
    value: initialValues?.status || "ONGOING",
    placeholder: "Select status",
  },

  {
    type: "TEXT",
    label: "No.of Polling Station",
    id: "totalPollingStation",
    name: "total_polling_station",
    value: initialValues?.totalPollingStation || "",
    placeholder: "Enter No.of Polling Station",
    validations: [],
  },

  {
    type: "SELECT",
    label: "No of Phase",
    id: "totalPhase",
    name: "total_phase",
    options: ELECTION_PHASE,
    value: initialValues?.totalPhase || 1,
    placeholder: "No of Phase",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Phase is required !",
      },
    ],
    onChange: (value, formInputs) => {
      const pollDataIndex = formInputs.findIndex(
        (input) => input.name === "poll_data"
      );

      const newRepeaterInputs = [];

      newRepeaterInputs.push({
        ...formInputs[pollDataIndex].inputs[0],
        size: phaseRepeaterSize[value].eventName,
      });

      for (let i = 1; i <= value; i++) {
        console.log("increase repeater length");
        newRepeaterInputs.push({
          ...formInputs[pollDataIndex].inputs[1],
          name: "date" + i,
          label: "Date " + i,
          id: "date" + i,
          size: phaseRepeaterSize[value].date,
        });
      }

      formInputs[pollDataIndex].inputs = newRepeaterInputs;
    },
  },
  {
    type: "TEXT",
    label: "No.of Pink Booth",
    id: "totalPinkBooth",
    name: "total_pink_booth",
    value: initialValues?.totalPinkBooth || "",
    placeholder: "Enter No.of Pink Booth",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Voters",
    id: "totalVoters",
    name: "total_voters",
    value: initialValues?.totalVoters || "",
    placeholder: "Enter No.of Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Male Voters",
    id: "totalMaleVoters",
    name: "total_male_voters",
    value: initialValues?.totalMaleVoters || "",
    placeholder: "Enter No.of Male Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Female Voters",
    id: "totalFemaleVoters",
    name: "total_female_voters",
    value: initialValues?.totalFemaleVoters || "",
    placeholder: "Enter No.of Female Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Transgender Voters",
    id: "totalTransgenderVoters",
    name: "total_transgender_voters",
    value: initialValues?.totalTransgenderVoters || "",
    placeholder: "Enter No.of Transgender Voters",
    validations: [],
  },
  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Poll Data",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "poll_data",
    value: initialValues?.poll_data || POLL_DATA,
    inputs: [
      {
        type: "TEXT",
        label: "Poll Event",
        id: "pollEvent",
        name: "pollEvent",
        placeholder: "Poll Event",
        size: 6,
      },
      {
        type: "CALENDER",
        label: "Date",
        id: "date",
        name: "date",
        placeholder: "Date",
        size: 5,
      },
    ],
  },
  {
    groupName: "Relevant Links",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "relevant_link",
    value: initialValues?.relevant_link || [{}],
    inputs: [
      {
        type: "TEXT",
        label: "Relevant Link Name",
        id: "link_name",
        name: "linkName",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Sort Order",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Link",
        id: "link",
        name: "link",
        placeholder: "Link",
        size: 5,
        inputIcon: "link",
        handleInputIcon: redirectToLink,
      },
    ],
  },
  {
    groupName: "Election Files",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "election_files",
    value: initialValues?.election_files || [{}],
    inputs: [
      {
        type: "TEXT",
        id: "filename",
        name: "fileName",
        placeholder: "File Name",
        size: 3,
      },
      {
        type: "TEXT",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "FILE_UPLOAD",
        id: "file",
        name: "file",
        placeholder: "File",
        handleSelfUpload: uploadElectionFiles,
        filePrefix: BASE_URL + "app-source/election-files/",
        size: 3,
      },
      {
        type: "TEXT",
        id: "remarks",
        name: "remarks",
        placeholder: "Remarks",
        size: 3,
      },
    ],
  },
];
