import { APP } from "@constants/app.constants";
const { NOTIFICATION_STATUS } = APP;

export const getNotificationFormInputs = (users, initialValues) => [
  {
    type: "SELECT",
    label: "User",
    id: "adminId",
    name: "admin_id",
    options: users,
    value: initialValues?.adminId || "",
    placeholder: "Select User",
    validations: [
      {
        type: "REQUIRED",
        message: "User is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Message",
    id: "message",
    name: "message",
    value: initialValues?.message || "",
    placeholder: "Enter the Message",
    validations: [
      {
        type: "REQUIRED",
        message: "Message is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Redirect",
    id: "redirect",
    name: "redirect",
    value: initialValues?.redirect || "",
    placeholder: "Enter the Redirect Url",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: NOTIFICATION_STATUS,
    value: initialValues?.status || 1,
    placeholder: "Select Status",
    validations: [
      {
        type: "REQUIRED",
        message: "Status is required !",
      },
    ],
  },
];
