import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";

const ActionContent = ({ handleCreateClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleCreateClick}
    >
      <FeatherIcon icon="plus" size={17} />
    </button>
  );
};

function DatabaseBackupTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
  },
  templateEvents: {
    getDatabaseBackupData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  console.log({ filters });

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Database Backup"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getDatabaseBackupData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        disableActions
      />
    </PageWrapperComponent>
  );
}

export default DatabaseBackupTemplateComponent;
