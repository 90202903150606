import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUrbanWardVoterFormInputs } from "../model/urban-ward-voter.modal";
import { editEntry } from "./urban-ward-voter-edit.component.service";
import UrbanWardVoterEditTemplateComponent from "./urban-ward-voter-edit-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";
import { menuUrl } from "../urban-ward-voter-list/model/urban-ward-voter-list.constants";
import { getUrbanWardBoothByUrbanWardId } from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-list/urban-ward-booth-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const UrbanWardVoterEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const urbanWardId = new URLSearchParams(location.search).get("urbanWardId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const urbanWardName = new URLSearchParams(location.search).get(
    "urbanWardName"
  );
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog-urban/urban-ward/voter?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}`
    );
  };

  console.log({ location });
  const [voterFields, setVoterFields] = useState([]);
  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;
    console.log({ voterFields, values });
    // INFO :  construct setting payload
    let acVoterPayload = {};
    let voterField = {};
    for (let i = 0; i < values.length; i++) {
      acVoterPayload[values[i].name] = values[i].value;
      const voterFieldsValue = location?.state?.row?.voter_fields || {};
      voterFields.map((field) => {
        if (values[i].name == field.name)
          voterField[field.name] = values[i].value
            ? values[i].value
            : voterFieldsValue[field.value];
      });
    }
    acVoterPayload["urban_ward_id"] = urbanWardId;
    acVoterPayload["voter_fields"] = voterField;

    // INFO : call edit api
    const acVoterResponse = await editEntry(
      location?.state?.row?.id,
      urbanWardId,
      acVoterPayload
    );

    // INFO : check api response and show relevant alert message
    if (acVoterResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(
        `/catalog-urban/urban-ward/voter?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: acVoterResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const catalogUrbanWardBoothResponse = await getUrbanWardBoothByUrbanWardId(
      urbanWardId
    );

    let formatedUrbanWardBoothList;
    if (catalogUrbanWardBoothResponse.status) {
      formatedUrbanWardBoothList = catalogUrbanWardBoothResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );
    }

    // Voter List
    let voterFieldsData = [];
    const voterFieldsResponse = await allVoterFields();
    console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      voterFieldsResponse.data.map((field) => {
        const voterFieldsValue = location?.state?.row?.voter_fields || {};
        const formatVoterFields = {
          id: field.id,
          label: field.name,
          type: "TEXT",
          name: field.name,
          placeholder: `Enter the ${field.name}`,
          value: voterFieldsValue[field.name],
        };
        voterFieldsData.push(formatVoterFields);
      });
      setVoterFields(voterFieldsData);
    }

    const formInputData = getUrbanWardVoterFormInputs({
      urbanBooths: formatedUrbanWardBoothList || [{ name: "-", value: null }],
      initialValues: {
        govtId: location?.state?.row?.govt_id,
        urbanWardBoothId: location?.state?.row?.urban_ward_booth_id,
        urbanWardBoothName: location?.state?.row?.urban_ward_booth_name,
        sectionId: location?.state?.row?.section_govt_id,
        sectionName: location?.state?.row?.section_name,
        sectionRegionalName: location?.state?.row?.section_regional_name,
        name: location?.state?.row?.name,
        lastName: location?.state?.row?.last_name,
        regionalName: location?.state?.row?.regional_name,
        regionalLastName: location?.state?.row?.regional_last_name,
        slnoinpart: location?.state?.row?.slnoinpart,
        epicId: location?.state?.row?.epic_id,
        gender: location?.state?.row?.gender,
        age: location?.state?.row?.age,
        mobileNo: location?.state?.row?.mobile,
        emailId: location?.state?.row?.email_id,
        address: location?.state?.row?.address,
        addressRegionalName: location?.state?.row?.address_tamil,
        dob: location?.state?.row?.dob,
        rlnType: location?.state?.row?.rln_type,
        relationFirstName: location?.state?.row?.relation_first_name,
        relationLastName: location?.state?.row?.relation_last_name,
        relationFirstRegionalName:
          location?.state?.row?.relation_first_regional_name,
        relationLastRegionalName:
          location?.state?.row?.relation_last_regional_name,
        remarks: location?.state?.row?.remarks,
        whatsappNumber: location?.state?.row?.whatsapp_number,
        city: location?.state?.row?.city,
        pincode: location?.state?.row?.pincode,
        relation: location?.state?.row?.relation,
        religion: location?.state?.row?.religion,
        caste: location?.state?.row?.caste,
        color: location?.state?.row?.color,
        language: location?.state?.row?.language,
      },
    });
    console.log({ formatedUrbanWardBoothList, formInputData });
    if (voterFieldsData && voterFieldsData.length > 0) {
      setFormInputs([...formInputData, ...voterFieldsData]);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
    urbanWardId,
    electionId,
    urbanWardName,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UrbanWardVoterEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UrbanWardVoterEditComponent;
