import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { readFile } from "@screens/catalog-rural/candidate-voter/voter-service";
import { menuUrl } from "../voter-list/model/voter-list.constants";
import MapVoterFieldsTemplateComponent from "@screens/map-voter-fields/file-upload/map-voter-fields.template";
import { getMapVoterFieldsFormInputs } from "@screens/map-voter-fields/constants/map-voter-fields-form.constants";

const {
  AC_VOTER_ALERTS: { FILE_UPLOAD_ALERT },
  ALERT_TYPES: { SUCCESS },
} = MESSAGES;

const RuralVoterFileUploadComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const electionId = new URLSearchParams(location.search).get("electionId");
  const villagePanchayatWardId = new URLSearchParams(location.search).get(
    "villagePanchayatWardId"
  );
  const villagePanchayatWardName = new URLSearchParams(location.search).get(
    "villagePanchayatWardName"
  );

  const [formInputs, setFormInputs] = useState([]);

  const onLoad = async () => {
    const formInputData = getMapVoterFieldsFormInputs();
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);
  const handleFormSubmit = async ({ values, errors }) => {
    console.log({ values, errors });

    if (errors.length > 0) {
      alert("file is mandatory");
      return;
    }

    let payload = {};

    for (let i = 0; i < values.length; i++) {
      if (values[i].name === "file_name")
        payload[values[i].name] = values[i].value.split("/").pop();
    }
    payload["ward_id"] = villagePanchayatWardId;
    payload["rural_type"] = "VILLAGE PANCHAYAT WARD";

    console.log({ payload });
    const response = await readFile(electionId, payload);
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: FILE_UPLOAD_ALERT }));
      history.push(
        `/${menuUrl}/voter?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`
      );
    } else {
      alert(response.message);
    }
  };

  const handleBackClick = () => {
    history.push(
      `/${menuUrl}/voter?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`
    );
  };

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
    handleBackClick,
  };

  const templateEvents = {
    handleTriggerClick,
    handleFormSubmit,
  };

  return menuAccess ? (
    <MapVoterFieldsTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default RuralVoterFileUploadComponent;
