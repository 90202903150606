import { APP_NAME } from "@constants/app.constants";
import BugVersionListComponent from "@screens/bug-tracker/bug-tracker-version-list/bug-tracker-version-list-component";
import VersionCreateComponent from "@screens/version/version-create/version-create.component";
import VersionEditComponent from "@screens/version/version-edit/version-edit.component";
import VersionListComponent from "@screens/version/version-list/version-list.component";

const versionRoutes = [
  {
    name: `Version - ${APP_NAME}`,
    path: "/version",
    icon: "layout",
    component: VersionListComponent,
  },
  {
    name: `Create version - ${APP_NAME}`,
    path: "/version/create",
    component: VersionCreateComponent,
  },
  {
    name: `Edit Version- ${APP_NAME}`,
    path: "/version/edit",
    component: VersionEditComponent,
  },
  {
    name: `Version Bugs- ${APP_NAME}`,
    path: "/version/bug-list",
    component: BugVersionListComponent,
  },
];

export default versionRoutes;
