import React from "react";

function PageNotFoundTemplateComponent({ goBack }) {
  return (
    <div className="misc-wrapper">
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">Page Not Found</h2>
          <p className="mb-2">The requested page not found in the server</p>
          <a className="btn btn-primary mb-2 btn-sm-block" onClick={goBack}>
            Back
          </a>
        </div>
      </div>
    </div>
  );
}

export default PageNotFoundTemplateComponent;
