import { APP } from "@constants/app.constants";
const { STATUS, EMAIL_TEMPLATE_TYPE } = APP;

export const getEmailTemplateFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Email Content",
    id: "emailContent",
    name: "email_content",
    value: initialValues?.emailContent || "",
    placeholder: "Enter the Content",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Default",
    id: "default",
    name: "default",
    options: EMAIL_TEMPLATE_TYPE,
    value: initialValues?.default || 1,
    placeholder: "Select template type",
    validations: [
      {
        type: "REQUIRED",
        message: "EMail type is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: STATUS,
    value: initialValues?.status || 1,
    placeholder: "Select Status",
    validations: [
      {
        type: "REQUIRED",
        message: "Status is required !",
      },
    ],
  },
];
