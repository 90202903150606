import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { createRuralWardVoterDataDownloadRequestEntry } from "@screens/catalog-rural/candidate-voter/voter-service";

const {
  AC_VOTER_ALERTS: { DOWNLOAD_REQUEST, DELETE_ALL_VOTER_DATA, NO_VOTER_DATA },
  ALERT_TYPES: { SUCCESS, ERROR },
} = MESSAGES;

const RuralVoterListContentComponent = ({
  rowData,
  toggleDetailPanel,
  handleTrashVotersClick,
  type,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const { currentElection } = useSelector((state) => state.election);

  const handleDownload = async () => {
    toggleDetailPanel(false);

    console.log({ rowData, user, type });
    let queryStr =
      "&state=" + rowData.state_id + "&ward_id=" + rowData.id + "&type=" + type;

    const response = await createRuralWardVoterDataDownloadRequestEntry(
      rowData.id,
      currentElection.id,
      queryStr,
      {
        request_type: "DOWNLOAD_RURAL_WARD_VOTER_DATA",
        status: 1,
      }
    );

    if (response.status) {
      dispatch(
        setAlertMessage({
          type: SUCCESS,
          message: DOWNLOAD_REQUEST + user.email,
          viewed: 0,
        })
      );
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: NO_VOTER_DATA + rowData.name,
          viewed: 0,
        })
      );
    }
  };

  const handleDelete = () => {
    toggleDetailPanel(false);
    handleTrashVotersClick(true);
  };

  return (
    <>
      {rowData && Number(rowData?.voters_count) > 0 ? (
        <>
          <div item xs={12} style={{ marginBottom: 20, marginLeft: 200 }}>
            <button
              onClick={handleDownload}
              type={"button"}
              className="btn btn-primary btn-sm"
            >
              <FeatherIcon
                icon="download"
                size={17}
                style={{ marginRight: 5 }}
              />{" "}
              Download Voter Data &nbsp;
            </button>
          </div>
          <div item xs={12} style={{ marginBottom: 20, marginLeft: 200 }}>
            <button
              onClick={handleDelete}
              type={"button"}
              className="btn btn-primary btn-sm"
              style={{ width: 200 }}
            >
              <FeatherIcon icon="trash" size={17} style={{ marginRight: 5 }} />
              Delete Voter Data
            </button>
          </div>
        </>
      ) : (
        <p className="text-center">No data found</p>
      )}
    </>
  );
};

export default RuralVoterListContentComponent;
