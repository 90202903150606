import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../leads-list/model/leads-list.constants";
import { editEntry } from "./leads-edit.component.service";
import { getLeadsFormInputs } from "../model/leads.modal";
import LeadsEditTemplateComponent from "./leads-edit-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  LEADS_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const LeadsEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let leadsPayload = {};
    for (let i = 0; i < values.length; i++) {
      leadsPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const leadsResponse = await editEntry(
      location?.state?.row?.id,
      leadsPayload
    );

    // INFO : check api response and show relevant alert message
    if (leadsResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: leadsResponse.message,
        })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getLeadsFormInputs({
      name: location?.state?.row?.name || "",
      companyName: location?.state?.row?.company_name || "",
      type: location?.state?.row?.type || "",
      website: location?.state?.row?.website || "",
      email: location?.state?.row?.email || "",
      contacts: location?.state?.row?.contacts || "",
      address: location?.state?.row?.address || "",
      city: location?.state?.row?.city || "",
      state: location?.state?.row?.state || "",
      remarks: location?.state?.row?.remarks || "",
      designation: location?.state?.row?.designation || "",
    }),
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <LeadsEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default LeadsEditComponent;
