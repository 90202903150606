import { APP } from "@constants/app.constants";
const { RESERVATION_TYPE_2, AC_URBAN_TYPE } = APP;

export const getUrbanWardFormInputs = ({
  states,
  districts,
  pcs,
  acs,
  corporations,
  municipalities,
  townPanchayats,
  initialValues,
  onStateChange,
  onDistrictChange,
  onPcChange,
}) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Urban Ward ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Urban Ward ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    type: "AUTOCOMPLETE",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    onChange: onDistrictChange,
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "PC Name",
    id: "pcId",
    name: "pc_id",
    options: pcs,
    value: initialValues?.pcId || "",
    placeholder: "Select PC",
    onChange: onPcChange,
    validations: [
      {
        type: "REQUIRED",
        message: "PC Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "AC Name",
    id: "acId",
    name: "ac_id",
    options: acs,
    value: initialValues?.acId || "",
    placeholder: "Select AC",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Name is required !",
      },
    ],
  },
  {
    type: "RADIO",
    label: "Type",
    id: "type",
    name: "type",
    options: AC_URBAN_TYPE,
    value: initialValues?.type || "CORPORATION",
    validations: [],
    onChange: (value, formInputs) => {
      console.log({ value, formInputs });

      const corporationIndex = formInputs.findIndex(
        (option) => option.label === "Corporation Name"
      );
      const municipalityIndex = formInputs.findIndex(
        (option) => option.label === "Municipality Name"
      );

      const townPanchayatIndex = formInputs.findIndex(
        (option) => option.label === "Town Panchayat Name"
      );

      if (value === "CORPORATION") {
        formInputs[corporationIndex].isHide = false;
        formInputs[municipalityIndex].isHide = true;
        formInputs[townPanchayatIndex].isHide = true;
      } else formInputs[corporationIndex].isHide = true;

      if (value === "MUNICIPALITY") {
        formInputs[municipalityIndex].isHide = false;
        formInputs[corporationIndex].isHide = true;
        formInputs[townPanchayatIndex].isHide = true;
      } else formInputs[municipalityIndex].isHide = true;

      if (value === "TOWN PANCHAYAT") {
        formInputs[townPanchayatIndex].isHide = false;
        formInputs[municipalityIndex].isHide = true;
        formInputs[corporationIndex].isHide = true;
      } else formInputs[townPanchayatIndex].isHide = true;

      return formInputs;
    },
  },
  {
    type: "AUTOCOMPLETE",
    label: "Corporation Name",
    id: "corporationId",
    name: "corporation_id",
    options: corporations,
    value: initialValues?.corporationId || "",
    placeholder: "Select Corporation",
    isHide:
      !initialValues.type || initialValues?.type === "CORPORATION"
        ? false
        : true,
    validations: [],
  },
  {
    type: "AUTOCOMPLETE",
    label: "Municipality Name",
    id: "municipalityId",
    name: "municipality_id",
    options: municipalities,
    value: initialValues?.municipalityId || "",
    isHide: initialValues?.type === "MUNICIPALITY" ? false : true,
    placeholder: "Select Municipality",
    validations: [],
  },
  {
    type: "AUTOCOMPLETE",
    label: "Town Panchayat Name",
    id: "townPanchayatId",
    name: "town_panchayat_id",
    options: townPanchayats,
    value: initialValues?.townPanchayatId || "",
    isHide: initialValues?.type === "TOWN PANCHAYAT" ? false : true,
    placeholder: "Select Town Panchayat",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Urban Ward Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Urban Ward Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Urban Ward Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION_TYPE_2,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Zone No",
    id: "zoneNo",
    name: "zone_no",
    value: initialValues?.zoneNo || "",
    placeholder: "Enter the Zone No",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Total Booth",
    id: "totalBoothCount",
    name: "total_booth_count",
    value: initialValues?.totalBoothCount || "",
    placeholder: "Enter the Total Booth",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Total Voters",
    id: "totalVoters",
    name: "total_voters",
    value: initialValues?.totalVoters || "",
    placeholder: "Enter the Total Voters",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
