import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadImageFile } from "@utils/upload.util";
const { LEADS_TYPE, INDIA_STATES } = APP;

export const getFormInputs = (states, initialValues) => [
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },

  {
    type: "FILE_UPLOAD",
    label: "Select File",
    id: "file",
    name: "file",
    placeholder: "Select File",
    handleSelfUpload: uploadImageFile,
    filePrefix: BASE_URL + "app-source/files/",
    value: initialValues?.file,
    validations: [],
  },

  {
    groupName: "States Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "states_data",
    value: initialValues?.states_data || states,
    inputs: [
      {
        type: "TEXT",
        label: "Name",
        id: "name",
        name: "name",
        placeholder: "Name",
        size: 5,
      },

      {
        type: "TEXT",
        label: "Symbol Regional Name",
        id: "regional_name",
        name: "regonialName",
        placeholder: "Symbol Regional Name",
        size: 5,
      },
    ],
  },
];
