import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
} from "@screens/system/blocked-ip/blocked-ip-list/model/blocked-ip-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import {
  deleteBlockedIpDataApi,
  getBlockedIpDataApi,
} from "./blocked-ip-list.component.service";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import BlockedIpListTemplateComponent from "./blocked-ip-list-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BLOCKED_IP_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const BlockedIpListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");

  const getBlockedIpData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const blockedIpData = await getBlockedIpDataApi({
      ...params,
      filters: queryStr,
    });
    if (blockedIpData.status) {
      console.log(blockedIpData);
      setTableData(blockedIpData.data);
      setTotalCount(blockedIpData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteBlockedIpDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getBlockedIpData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    // console.log({
    //   url: `${SETTING_EXPORT}?token=${token}${queryStr}`,
    // });
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getBlockedIpData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  return menuAccess ? (
    <BlockedIpListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
      }}
      templateEvents={{
        getBlockedIpData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default BlockedIpListComponent;
