import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getFormInputs } from "../model/rural-ward-booth.modal";
import { editEntry } from "./rural-ward-booth-edit.component.service";
import RuralWardBoothEditTemplateComponent from "./rural-ward-booth-edit-template.component";
import { getAllStates } from "@screens/catalog-rural/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog-rural/districts/districts-list/districts-list.component.service";
import { menuUrl } from "../rural-ward-booth-list/model/rural-ward-booth-list.constants";
import { getDistrictUnionWardByDistrictId } from "@screens/catalog-rural/district-union-ward/district-union-ward-list/district-union-ward-list.component.service";
import { getPanchayatUnionWardByDistrictId } from "@screens/catalog-rural/panchayat-union-ward/panchayat-union-ward-list/panchayat-union-ward-list.component.service";
import { getVillagePanchayatWardByDistrictId } from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-ward-list/village-panchayat-ward-list.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  RURAL_WARD_BOOTH_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const RuralWardBoothEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let acPayload = {};
    for (let i = 0; i < values.length; i++) {
      acPayload[values[i].name] = values[i].value;
    }
    // INFO : call edit api
    const pcResponse = await editEntry(location?.state?.row?.id, acPayload);

    // INFO : check api response and show relevant alert message
    if (pcResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: pcResponse.message }));
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      inputs[2].options = formattedDistrictList;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    let formatedStateList;

    console.log({ statesResponse });
    if (statesResponse.status) {
      formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }

    const districtResponse = await getDistrictByStateId(
      location?.state?.row?.state_id
    );
    let formattedDistrictList;

    if (districtResponse.status) {
      formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }

    let wardOptions = [];
    if (location.state.row.rural_type === "DISTRICT UNION WARD") {
      const districtUnionWardResponse = await getDistrictUnionWardByDistrictId(
        location?.state?.row?.district_id
      );
      if (districtUnionWardResponse.status) {
        wardOptions = districtUnionWardResponse.data.map((ward) => {
          return {
            name: ward.name,
            value: ward.id,
          };
        });
      }
    }

    if (location.state.row.rural_type === "PANCHAYAT UNION WARD") {
      const panchayatUnionWardResponse =
        await getPanchayatUnionWardByDistrictId(
          location?.state?.row?.district_id
        );
      if (panchayatUnionWardResponse.status) {
        wardOptions = panchayatUnionWardResponse.data.map((ward) => {
          return {
            name: ward.name,
            value: ward.id,
          };
        });
      }
      console.log({ panchayatUnionWardResponse });
    }

    if (location.state.row.rural_type === "VILLAGE PANCHAYAT WARD") {
      const villagePanchayatWardResponse =
        await getVillagePanchayatWardByDistrictId(
          location?.state?.row?.district_id
        );
      if (villagePanchayatWardResponse.status) {
        wardOptions = villagePanchayatWardResponse.data.map((ward) => {
          return {
            name: ward.name,
            value: ward.id,
          };
        });
      }
      console.log({ villagePanchayatWardResponse });
    }

    console.log({ wardOptions });

    const formInputData = getFormInputs({
      states: formatedStateList,
      districts: formattedDistrictList,
      wards: wardOptions,
      initialValues: {
        govtId: location?.state?.row?.govt_id || "",
        name: location?.state?.row?.name || "",
        location: location?.state?.row?.location || "",
        stateId: location?.state?.row?.state_id || "",
        districtId: location?.state?.row?.district_id || "",
        ruralType: location?.state?.row?.rural_type || "",
        wardId: location?.state?.row?.ward_id || "",
        regionalName: location?.state?.row?.regional_name || "",
        boothType: location?.state?.row?.booth_type || "",
        locationRegionalName:
          location?.state?.row?.location_regional_name || "",
        remarks: location?.state?.row?.remarks || "",
      },
      onStateChange,
    });
    console.log({ formatedStateList, formInputData });
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <RuralWardBoothEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default RuralWardBoothEditComponent;
