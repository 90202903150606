import React from "react";
import TownPanchayatDashboardComponent from "../dashboard/dashboard.component";
import TownPanchayatChartComponent from "./chart.component";

const TownPanchayatSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <TownPanchayatChartComponent />}
      {type === "DASHBOARD" && <TownPanchayatDashboardComponent />}
    </>
  );
};

export default TownPanchayatSwapComponent;
