import MESSAGES from "@constants/messages.constants";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { menuUrl } from "@screens/catalog/ac/ac-list/model/ac-list.constants";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { readFile } from "@screens/catalog/ac-voter/ac-voter-file/ac-voter-file.component.service";
import MapVoterFieldsTemplateComponent from "@screens/map-voter-fields/file-upload/map-voter-fields.template";
import { getMapVoterFieldsFormInputs } from "@screens/map-voter-fields/constants/map-voter-fields-form.constants";

const {
  MAP_VOTER_FIELDS_ALERTS: { CREATE_ALERT },
  ALERT_TYPES: { SUCCESS },
} = MESSAGES;

const AcVoterFileUploadComponent = () => {
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const acId = new URLSearchParams(location.search).get("acId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const acName = new URLSearchParams(location.search).get("acName");
  const [formInputs, setFormInputs] = useState([]);

  const handleFormSubmit = async ({ values, errors }) => {
    console.log({ values, errors });

    if (errors.length > 0) {
      alert("file is mandatory");
      return;
    }

    let payload = {};

    for (let i = 0; i < values.length; i++) {
      if (values[i].name === "file_name")
        payload[values[i].name] = values[i].value.split("/").pop();
    }
    payload["ac_id"] = acId;

    console.log({ payload });
    const response = await readFile(electionId, payload, acId);
    console.log({ response });
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      window.history.back();
    } else {
      alert(response.message);
    }
  };

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const onLoad = async () => {
    const formInputData = getMapVoterFieldsFormInputs();
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
    handleBackClick,
  };

  const templateEvents = {
    handleTriggerClick,
    handleFormSubmit,
  };

  return menuAccess ? (
    <MapVoterFieldsTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default AcVoterFileUploadComponent;
