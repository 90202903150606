export const getFileUploadInputs = (handleUpload) => [
  {
    type: "FILE_UPLOAD",
    name: "file_name",
    id: "file",
    label: "Select File",
    loading: false,
    value: null,
    handleUpload: handleUpload,
    validations: [
      {
        type: "REQUIRED",
        message: "File is required !",
      },
    ],
  },
];
