import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { breadCrumbs } from "../ac-booth-list/model/ac-booth-list.constants";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";

const ActionContent = ({
  handlePrintClick,
  handleBackClick,
  handleDownloadClick,
}) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handlePrintClick}
      >
        <FeatherIcon icon="printer" size={17} />
      </button>
      {/* &nbsp; &nbsp;
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleDownloadClick}
      >
        <FeatherIcon icon="download" size={17} />
      </button> */}
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const ACBoothCandidatesTemplateComponent = ({
  templateModal: { formTriggers, formInputs },
  templateEvents: {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    handlePrintClick,
    handleDownloadClick,
  },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Select Candidate"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: "Select Candidate ",
            menu_url: "/catalog/ac-booth/select-candidate",
          },
        ]}
        actionContent={
          <ActionContent
            handlePrintClick={handlePrintClick}
            handleBackClick={handleBackClick}
            handleDownloadClick={handleDownloadClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default ACBoothCandidatesTemplateComponent;
