import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../version-list/model/version-list.constants";
import { editEntry } from "./version-edit.component.service";
import VersionEditTemplateComponent from "./version-edit-template.component";
import { getVersionFormInputs } from "../model/version.modal";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  VERSION_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const VersionEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let versionPayload = {};
    for (let i = 0; i < values.length; i++) {
      versionPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const versionResponse = await editEntry(
      location?.state?.row?.id,
      versionPayload
    );

    // INFO : check api response and show relevant alert message
    if (versionResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: versionResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getVersionFormInputs({
      title: location?.state?.row?.title || "",
      description: location?.state?.row?.description || "",
      versionNo: location?.state?.row?.version_no || "",
      date: location?.state?.row?.date || "",
    }),
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <VersionEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default VersionEditComponent;
