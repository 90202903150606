import React from "react";
import DistrictDashboardComponent from "../dashboard/dashboard.component";
import MasterDistrictChartComponent from "./chart.component";

const MasterDistrictsSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <MasterDistrictChartComponent />}
      {type === "DASHBOARD" && <DistrictDashboardComponent />}
    </>
  );
};

export default MasterDistrictsSwapComponent;
