import { APP_NAME } from "@constants/app.constants";
import UsersListComponent from "@screens/user-administration/users/users-list/users-list.component";
import UsersCreateComponent from "@screens/user-administration/users/users-create/users-create.component";
import UsersEditComponent from "@screens/user-administration/users/users-edit/users-edit.component";
import UsersLogListComponent from "@screens/user-administration/users/user-log-list/user-log-list.component";

const usersRoutes = [
  {
    name: "Users",
    icon: "users",
    path: "/user-administration/users",
    component: UsersListComponent,
  },
  {
    name: `Create Users - ${APP_NAME}`,
    path: "/user-administration/users/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit Users - ${APP_NAME}`,
    path: "/user-administration/users/edit",
    component: UsersEditComponent,
  },

  {
    name: `Logs - ${APP_NAME}`,
    path: "/user-administration/users/log",
    component: UsersLogListComponent,
  },
];

export default usersRoutes;
