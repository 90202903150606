import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getVillagePanchayatWardFormInputs } from "../model/village-panchayat-ward.modal";
import { useEffect } from "react";
import { getAllStates } from "@screens/catalog-rural/states/states-list/states-list.component.service";
import VillagePanchayatWardEditTemplateComponent from "./village-panchayat-ward-edit-template";
import { getDistrictByStateId } from "@screens/catalog-rural/districts/districts-list/districts-list.component.service";
import { getDistrictUnionByDistrictId } from "@screens/catalog-rural/district-union/district-union-list/district-union-list.component.service";
import { getPanchayatUnionByDistrictUnionId } from "@screens/catalog-rural/panchayat-union/panchayat-union-list/panchayat-union-list.service";
import { getVillagePanchayatByPanchayatUnionId } from "@screens/catalog-rural/village-panchayat/village-panchayat-list/village-panchayat-list.service";
import { menuUrl } from "../village-panchayat-ward-list/model/village-panchayat-ward-list.constants";
import { editEntry } from "../village-panchayat-ward-list/village-panchayat-ward-list.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  VILLAGE_PANCHAYAT_WARD_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const VillagePanchayatWardEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const { currentElection } = useSelector((state) => state.election);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    payload["election_id"] = currentElection.id;

    // INFO : call create api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };
  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[2].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };
  const onDistrictChange = async (value, inputs) => {
    const districtUnionResponse = await getDistrictUnionByDistrictId(value);
    if (districtUnionResponse.status) {
      const formattedDistrictUnionList = districtUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[3].options = formattedDistrictUnionList;
      setFormInputs(inputs);
    }
  };

  const onDistrictUnionChange = async (value, inputs) => {
    const panchayatUnionResponse = await getPanchayatUnionByDistrictUnionId(
      value
    );
    if (panchayatUnionResponse.status) {
      const formattedPanchayatUnionList = panchayatUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[4].options = formattedPanchayatUnionList;
      setFormInputs(inputs);
    }
  };
  const onPanchayatUnionChange = async (value, inputs) => {
    const VillagePanchayatResponse =
      await getVillagePanchayatByPanchayatUnionId(value);
    if (VillagePanchayatResponse.status) {
      const formattedVillagePanchayatList = VillagePanchayatResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[5].options = formattedVillagePanchayatList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const districtResponse = await getDistrictByStateId(
        location?.state?.row?.state_id || null
      );
      if (districtResponse.status) {
        const formattedDistrictList = districtResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const districtUnionResponse = await getDistrictUnionByDistrictId(
          location?.state?.row?.district_id || null
        );
        if (districtUnionResponse.status) {
          const formattedDistrictUnionList = districtUnionResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
              };
            }
          );

          const panchayatUnionResponse =
            await getPanchayatUnionByDistrictUnionId(
              location?.state?.row?.district_union_id || null
            );
          if (panchayatUnionResponse.status) {
            const formattedPanchayatUnionList = panchayatUnionResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                };
              }
            );
            const VillagePanchayatResponse =
              await getVillagePanchayatByPanchayatUnionId(
                location?.state?.row?.panchayat_union_id || null
              );
            if (VillagePanchayatResponse.status) {
              const formattedVillagePanchayatList =
                VillagePanchayatResponse.data.map((data) => {
                  return {
                    name: data.name,
                    value: data.id,
                  };
                });

              const formInputData = getVillagePanchayatWardFormInputs({
                states: formatedStateList,
                districts: formattedDistrictList,
                districtUnions: formattedDistrictUnionList,
                panchayatUnions: formattedPanchayatUnionList,
                villagePanchayats: formattedVillagePanchayatList,
                initialValues: {
                  govtId: location?.state?.row?.govt_id || "",
                  name: location?.state?.row?.name || "",
                  regionalName: location?.state?.row?.regional_name || "",
                  reservation: location?.state?.row?.reservation || "",
                  stateId: location?.state?.row?.state_id || "",
                  districtId: location?.state?.row?.district_id || "",
                  districtUnionId:
                    location?.state?.row?.district_union_id || "",
                  panchayatUnionId:
                    location?.state?.row?.panchayat_union_id || "",
                  villagePanchayatId:
                    location?.state?.row?.village_panchayat_id || "",
                  remarks: location?.state?.row?.remarks || "",
                },
                onStateChange,
                onDistrictChange,
                onDistrictUnionChange,
                onPanchayatUnionChange,
              });
              setFormInputs(formInputData);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <VillagePanchayatWardEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default VillagePanchayatWardEditComponent;
