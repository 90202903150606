import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { editEntry } from "./candidate-edit.component.service";
import CandidateEditTemplateComponent from "./candidate-edit-template.component";
import { menuUrl } from "../candidate-list/model/candidate-list.constants";
import { BASE_URL } from "@constants/api.constants";
import {
  uploadAffidavit,
  uploadCandidateImage,
} from "../candidate-create/candidate-create.component.service";
import { getPoliticalPartyByState } from "@screens/political-party/political-party-list/political-party-list.component.service";
import { getCandidateEditFormInputs } from "../model/candidate.modal";
import { getAllSymbols } from "@screens/manage/symbols/symbols-list/symbols-list.service";
import { getAllAc } from "@screens/catalog-urban/ac/ac-list/ac-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_CANDIDATE_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const CandidateEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const urbanWardId = new URLSearchParams(location.search).get("urbanWardId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const urbanWardName = new URLSearchParams(location.search).get(
    "urbanWardName"
  );
  const stateGovtId = new URLSearchParams(location.search).get("stateGovtId");
  const { currentElection } = useSelector((state) => state.election);
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleCandidateFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadCandidateResponse = await uploadCandidateImage(file[0]);
    console.log({ uploadCandidateResponse });
    if (uploadCandidateResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file:
          BASE_URL +
          "app-source/candidate-image/" +
          uploadCandidateResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };
  const handleAffidavitFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadAffadavitResponse = await uploadAffidavit(file[0]);
    console.log({ uploadAffadavitResponse });
    if (uploadAffadavitResponse.status) {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      copyFormInputs3[index].filename = file[0].name;
      copyFormInputs3[index].value =
        BASE_URL + "app-source/affidavit/" + uploadAffadavitResponse.data;

      setFormInputs(copyFormInputs3);
    } else {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      setFormInputs(copyFormInputs3);
      alert("Invalid File Format");
    }
  };
  const onLoad = async () => {
    const acResponse = await getAllAc(currentElection.id);
    console.log({ acResponse });
    if (acResponse.status && acResponse.status) {
      const formatedAcList = acResponse.data.map((data) => {
        return {
          name: data.govt_id + "-" + data.name,
          value: data.id,
        };
      });
      const politicalPartyResponse = await getPoliticalPartyByState(
        stateGovtId
      );
      console.log({ politicalPartyResponse });
      if (politicalPartyResponse.status && politicalPartyResponse.status) {
        const formatedPoliticalPartyList = politicalPartyResponse.data.map(
          (data) => {
            return {
              name: data.name,
              value: data.id,
            };
          }
        );
        const symbolsResponse = await getAllSymbols();
        console.log({ symbolsResponse });
        if (symbolsResponse.status) {
          const formatedSymbolsList = symbolsResponse.data.map((data) => {
            return {
              name: data.name,
              value: data.id,
            };
          });
          const formInputData = getCandidateEditFormInputs({
            formatedPoliticalPartyList,
            formatedAcList,
            formatedSymbolsList,
            initialValues: {
              id: location?.state?.row?.id,
              govtId: location?.state?.row?.govt_id,
              name: location?.state?.row?.name,
              designation: location?.state?.row?.designation,
              age: location?.state?.row?.age,
              gender: location?.state?.row?.gender,
              partyType: location?.state?.row?.party_type,
              partyName: location?.state?.row?.political_party_id,
              personalMobile: location?.state?.row?.personal_mobile,
              whatsappNumber: location?.state?.row?.whatsapp_number,
              whatsappNumber2: location?.state?.row?.whatsapp_number2,
              officialMobile: location?.state?.row?.official_mobile,
              residentialAddress: location?.state?.row?.residential_address,
              education: location?.state?.row?.education,
              asset: location?.state?.row?.asset,
              emailId: location?.state?.row?.email_id,
              panNo: location?.state?.row?.pan_no,
              epicId: location?.state?.row?.epic_id,
              acNo: location?.state?.row?.ac_no_id,
              acGovtIdName: location?.state?.row?.ac_govt_id_name,
              acNoName: location?.state?.row?.ac_no_name,
              partNo: location?.state?.row?.part_no,
              serialNo: location?.state?.row?.serial_no,
              criminalCase: location?.state?.row?.criminal_case,
              regionalName: location?.state?.row?.regional_name,
              facebook: location?.state?.row?.facebook,
              instagram: location?.state?.row?.instagram,
              linkedin: location?.state?.row?.linkedin,
              twitter: location?.state?.row?.twitter,
              remarks: location?.state?.row?.remarks,
              candidateImage: location?.state?.row?.candidate_image,
              totalAffidavit: location?.state?.row?.total_affidavit,
              affidavit: location?.state?.row?.affidavit,
              status: location?.state?.row?.elected_member,
              symbol: location?.state?.row?.symbol,
              regionalName: location?.state?.row?.regional_name || "",
              totalVotes: location?.state?.row?.total_votes || "",
              symbolId: location?.state?.row?.symbol_id || "",
            },
            handleCandidateFileUpload,
            handleAffidavitFileUpload,
          });
          setFormInputs(formInputData);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleBackClick = () => {
    history.push(
      `/catalog-urban/urban-ward/candidate?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}&stateGovtId=${stateGovtId}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let candidatePayload = {};
    for (let i = 0; i < values.length; i++) {
      candidatePayload[values[i].name] = values[i].value;
    }
    candidatePayload["urban_ward_id"] = urbanWardId;
    // INFO : call edit api
    const candidateResponse = await editEntry(
      location?.state?.row?.id,
      candidatePayload
    );

    // INFO : check api response and show relevant alert message
    if (candidateResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(
        `/catalog-urban/urban-ward/candidate?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}&stateGovtId=${stateGovtId}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: candidateResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
    urbanWardId,
    electionId,
    urbanWardName,
    stateGovtId,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <CandidateEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default CandidateEditComponent;
