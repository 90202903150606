export const getACBoothFormInputs = ({
  states,
  districts,
  acs,
  initialValues,
  onStateChange,
  onDistrictChange,
}) => [
  {
    type: "TEXT",
    label: "Part No",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Part No",
    validations: [
      {
        type: "REQUIRED",
        message: "Part No is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    onChange: onDistrictChange,
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "AC Name",
    id: "acId",
    name: "ac_id",
    options: acs,
    value: initialValues?.acId || "",
    placeholder: "Select AC",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Booth Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Booth Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Booth Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "V Booth Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the V Booth Name",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Booth Type",
    id: "boothType",
    name: "booth_type",
    value: initialValues?.boothType || "",
    placeholder: "Enter the Booth Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Booth Type is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Village Name",
    id: "location",
    name: "location",
    value: initialValues?.location || "",
    placeholder: "Enter the Village Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Village is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "V Village Name",
    id: "locationRegionalName",
    name: "location_regional_name",
    value: initialValues?.locationRegionalName || "",
    placeholder: "Enter the V Village Name",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
