import React from "react";

const FormInputCheckboxMultipleComponent = ({
  id,
  label,
  name,
  checked,
  onChange,
  options,
  isVertical,
  isRequired = false,
}) => {
  const handleChange = ({ value, index }) => {
    if (onChange) onChange(value, index);
  };
  return (
    <div className="row">
      <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
        {label && (
          <label className="col-form-label" style={{ marginBottom: 5 }}>
            {label}
            {isRequired && <> * </>}
          </label>
        )}
      </div>
      <div
        className={
          isVertical ? "col-sm-12 cursor-pointer" : "col-sm-10 cursor-pointer"
        }
        style={{ marginTop: 6 }}
        onClick={() => onChange(!checked)}
      >
        {options.map((option, index) => (
          <div key={index} style={{ float: "left", marginRight: 8 }}>
            <input
              id={option.name}
              type="checkbox"
              name={option.name}
              class="form-check-input"
              checked={option.checked}
              onChange={(e) => handleChange({ value: e.target.checked, index })}
            />
            <span style={{ marginLeft: 10 }}>{option.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormInputCheckboxMultipleComponent;
