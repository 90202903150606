import { APP_NAME } from "@constants/app.constants";
import BulkMessageCreateComponent from "@screens/campaign/bulk-message/bulk-message-create/bulk-message-create";
import BulkMessageEditComponent from "@screens/campaign/bulk-message/bulk-message-edit/bulk-message-edit";
import BulkMessageEntriesListComponent from "@screens/campaign/bulk-message/bulk-message-entries-list/bulk-message-entries-list-component";
import BulkMessageListComponent from "@screens/campaign/bulk-message/bulk-message-list/bulk-message-list.component";

const bulkMessageRoutes = [
  {
    name: `Bulk Message - ${APP_NAME}`,
    path: "/campaign/bulk-message",
    icon: "folder",
    component: BulkMessageListComponent,
  },
  {
    name: `Create Bulk Message - ${APP_NAME}`,
    path: "/campaign/bulk-message/create",
    component: BulkMessageCreateComponent,
  },
  {
    name: `Edit Bulk Message - ${APP_NAME}`,
    path: "/campaign/bulk-message/edit/",
    component: BulkMessageEditComponent,
  },

  {
    name: `Entries- ${APP_NAME}`,
    path: "/campaign/bulk-message/entries",
    component: BulkMessageEntriesListComponent,
  },
];

export default bulkMessageRoutes;
