import { END_POINT } from "@constants/api.constants";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";
import { store } from "@src/store";

const { MAP_VOTER_FIELDS_LIST } = END_POINT;

export const uploadVoterFieldsFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await fetch(MAP_VOTER_FIELDS_LIST, {
    method: "POST",
    headers: {
      token: store.getState().app.token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.file_name,
          message: data.message,
          fields: data.data.fieldsArray,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
