import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

// Local Imports
import HeaderComponent from "@components/organisms/header/header.component";
import SideMenuComponent from "@components/organisms/sidemenu/sidemenu.component";

import "@core/scss/base/core/menu/menu-types/vertical-menu.scss";
import "@core/scss/base/core/menu/menu-types/vertical-overlay-menu.scss";
import AlertComponent from "@atoms/alert/alert.component";
import { APP_NAME, APP_VERSION } from "@constants/app.constants";

const LayoutWrapperComponent = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { menuCollapsed } = useSelector((state) => state?.app);
  const { alertMessage } = useSelector((state) => state.alert);
  const location = useLocation();

  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false);
    }
  }, [location]);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", handleWindowWidth);
    }
  }, [windowWidth]);
  return (
    <div
      className={classNames("wrapper vertical-layout navbar-floating footer-static", {
        // Modern Menu
        "vertical-menu-modern": windowWidth >= 1200,
        "menu-collapsed": menuCollapsed && windowWidth >= 1200,
        "menu-expanded": !menuCollapsed && windowWidth > 1200,

        // Overlay Menu
        "vertical-overlay-menu": windowWidth < 1200,
        "menu-hide": !menuVisibility && windowWidth < 1200,
        "menu-open": menuVisibility && windowWidth < 1200,
      })}
      options={{ wheelPropagation: false }}
    >
      <SideMenuComponent setMenuVisibility={setMenuVisibility} />
      <HeaderComponent setMenuVisibility={setMenuVisibility} />
      {children && (
        <>
          <div className="app-content content overflow-hidden">
            <div className="content-overlay"></div>
            <div className="header-navbar-shadow"></div>
            <div className="content-wrapper container-xxl p-0 animate__animated animate__fadeIn">
              <div className="row">
                <div className="col-sm-12">
                  {alertMessage && (
                    <AlertComponent type={alertMessage.type} message={alertMessage.message} />
                  )}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <footer className="footer footer-light footer-static">
            <p className="clearfix mb-0">
              <span className="float-md-start d-block d-md-inline-block mt-25">
                {APP_NAME} {APP_VERSION} © {new Date().getFullYear()}{" "}
              </span>
            </p>
          </footer>
        </>
      )}
    </div>
  );
};

export default LayoutWrapperComponent;
