import superCharge from "@utils/service-base";
import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const { MENUS, MODULES } = END_POINT;

export const sideMenuService = superCharge({
  getMenus: async (currentRoleId) => {
    const url = currentRoleId ? MENUS + "?id=" + currentRoleId : MENUS;
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: store.getState().app.token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status) return { status: true, data: response.data };
        else return { status: false, message: response.message };
      })
      .catch((error) => {
        return {
          status: false,
          message: "Something went wrong",
          error,
        };
      });
  },
});

export const getAppModules = async () => {
  return await fetch(MODULES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status) return { status: true, data: response.data };
      else return { status: false, message: response.message };
    })
    .catch((error) => {
      return {
        status: false,
        message: "Something went wrong",
        error,
      };
    });
};
