export const getResetFormInputs = (initialValues) => [
  {
    type: "PASSWORD",
    label: "Current Password",
    id: "currentPassword",
    name: "current_password",
    value: initialValues?.current_password || "",
    placeholder: "Enter the current password",
    validations: [
      {
        type: "REQUIRED",
        message: "Current Password is required !",
      },
    ],
  },
  {
    type: "PASSWORD",
    label: "New Password",
    id: "newPassword",
    name: "new_password",
    value: initialValues?.new_password || "",
    placeholder: "Enter the new password",
    validations: [
      {
        type: "REQUIRED",
        message: "New Password is required !",
      },
    ],
  },
  {
    type: "PASSWORD",
    label: "Confirm Password",
    id: "confirmPassword",
    name: "confirm_password",
    value: initialValues?.confirm_password || "",
    placeholder: "Enter the confirm password",
    validations: [
      {
        type: "REQUIRED",
        message: "Confirm Password is required !",
      },
    ],
  },
];
