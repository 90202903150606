import React from "react";
import LoadingComponent from "@atoms/loading/loading.component";
import AlertComponent from "@atoms/alert/alert.component";
import LoginV2WrapperComponent from "./components/login-v2-wrapper.component";
import HelmetComponent from "@atoms/helmet/helmet.component";
import { APP_NAME } from "@constants/app.constants";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";

const LoginV2TemplateComponent = ({
  templateModal: {
    username,
    password,
    isLoading,
    alertMessage,
    passwordVisibility,
  },
  templateEvents: { handleChange, handleSubmit, handleTogglePassword },
}) => {
  return (
    <>
      {isLoading && <LoadingComponent />}
      <LoginV2WrapperComponent>
        <HelmetComponent title={`Login Screen - ${APP_NAME}`} />
        <form
          className="auth-login-form mt-2 auth-login-form-v2"
          onSubmit={handleSubmit}
        >
          <div className="mb-1">
            <label
              htmlFor="username"
              className="form-label color-v2 v2-font-14 v2-fw-500"
            >
              Username
            </label>
            <input
              type="text"
              className="form-control form-control-v2"
              id="username"
              name="username"
              placeholder="Enter Username"
              autoFocus
              value={username}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-1">
            <label
              className="form-label color-v2 v2-font-14 v2-fw-500"
              htmlFor="password"
            >
              Password
            </label>
            <FormInputTextComponent
              type={passwordVisibility ? "text" : "password"}
              className="form-control"
              id="password"
              name="password"
              placeholder="Enter Password"
              value={password}
              onChange={(value) =>
                handleChange({ target: { value, name: "password" } })
              }
              inputIcon={passwordVisibility ? "eye" : "eye-off"}
              handleInputIconClick={handleTogglePassword}
              isVertical={true}
              isRequired={true}
              inputStyles={{ backgroundColor: "white" }}
            />
          </div>
          <div className="mb-2 mt-2">
            <div className="form-check" style={{ paddingBottom: "7px" }}>
              <input
                className="form-check-input"
                type="checkbox"
                id="remember-me"
                tabIndex="3"
              />
              <label
                className="form-check-label"
                htmlFor="remember-me"
                style={{ color: "#000", fontWeight: 500 }}
              >
                {" "}
                Remember Me{" "}
              </label>
              <label className="form-check-label float-end color-v2 fw-bold">
                Forgot Password?{" "}
              </label>
            </div>
          </div>

          <button className="btn btn-primary w-100 submit-btn" type="submit">
            Sign in
          </button>
        </form>
        {alertMessage && (
          <AlertComponent
            type={alertMessage.type}
            message={alertMessage.message}
          />
        )}
      </LoginV2WrapperComponent>
    </>
  );
};

export default LoginV2TemplateComponent;
