import { APP } from "@constants/app.constants";
import React from "react";
import ReactPaginate from "react-paginate";

const { PAGE_VIEW_OPTIONS } = APP;

const DataGridFooterComponent = ({
  page,
  totalCount,
  rowsPerPage,
  handlePagination,
  handleChangeRowsPerPage,
}) => {
  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <div
          className="d-flex align-items-center mb-0 mt-1"
          style={{ padding: " 0 0 10px 10px" }}
        >
          <span className="text-nowrap "> Rows per page </span>
          <select
            onChange={(e) => handleChangeRowsPerPage(e.target.value)}
            className="mx-1 form-control form-control-sm"
            style={{ width: 60 }}
            id="__BVID__1165"
          >
            {PAGE_VIEW_OPTIONS.map((option) => (
              <option
                selected={option == rowsPerPage ? "selected" : ""}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
          <span>
            {" "}
            Showing {page === 0 ? 1 : page * rowsPerPage + 1} to{" "}
            {page === 0 ? rowsPerPage : page - 1 * rowsPerPage}{" "}
          </span>
        </div>
      </div>

      <div className="col-md-6 col-sm-12">
        <div style={{ float: "right" }}>
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            pageCount={Math.ceil(totalCount / rowsPerPage)}
            breakLabel={"..."}
            initialPage={page}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            onPageChange={handlePagination}
            activeClassName={"active"}
            pageClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            previousClassName={"page-item prev"}
            previousLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName={
              "pagination react-paginate pagination-sm justify-content-end pe-1 mt-1"
            }
          />
        </div>
        <div style={{ float: "right", marginTop: 17, marginRight: 16 }}>
          Total Rows - {totalCount}
        </div>
      </div>
    </div>
  );
};

export default DataGridFooterComponent;
