import { APP_NAME } from "@constants/app.constants";

import DistrictsListComponent from "@src/screens/master/districts/districts-list/districts-list.component";
import DistrictsCreateComponent from "@src/screens/master/districts/districts-create/districts-create.component";
import DistrictsEditComponent from "@src/screens/master/districts/districts-edit/districts-edit.component";
import DistrictsFileUploadComponent from "@screens/master/districts/districts-file/districts-file.component";

const masterDistrictsRoutes = [
  {
    name: `District - ${APP_NAME}`,
    icon: "map-pin",
    path: "/master/districts",
    component: DistrictsListComponent,
  },
  {
    name: `Create District - ${APP_NAME}`,
    path: "/master/districts/create",
    component: DistrictsCreateComponent,
  },
  {
    name: `Edit District - ${APP_NAME}`,
    path: "/master/districts/edit",
    component: DistrictsEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master/districts/upload-district",
    component: DistrictsFileUploadComponent,
  },
];

export default masterDistrictsRoutes;
