import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import FeatherIcon from "feather-icons-react";
import DetailPanelComponent from "@molecules/detail-panel/detail-panel.component";
import ElectionSwapComponent from "./components/election-swap.component";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";
import ModalComponent from "@atoms/modal/modal.component";

function ElectionListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    tableActions,
    totalCount,
    filters,
    openDetailPanel,
    tableControls,
    swapContentType,
    electionDialog,
    counter,
    password,
    error,
    tableParams,
    tableConfig,
  },
  templateEvents: {
    getElectionData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    setOpenDetailPanel,
    handleDeleteRecordsOverride,
    cancelPasswordDialog,
    submitPasswordDialog,
    handleChangePassword,
  },
}) {
  const settingFormEdit = `${breadCrumbs[0].menu_url}/edit`;

  const ActionContent = ({ handleCreateClick }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleCreateClick}
        >
          <FeatherIcon icon="plus" size={17} />
          &nbsp;
        </button>
      </>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Election"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableActions={tableActions}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getElectionData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <ElectionSwapComponent type={swapContentType} />
          ) : null
        }
        tableParams={tableParams}
        handleDeleteRecordsOverride={handleDeleteRecordsOverride}
        tableConfig={tableConfig}
      />

      <DetailPanelComponent
        open={openDetailPanel}
        toggleDrawer={setOpenDetailPanel}
      />
      <ModalComponent
        open={electionDialog}
        title={"Password !"}
        message={counter === 10 && "Please enter your password to delete!"}
        primaryText={counter >= 10 ? "Submit" : ""}
        secondaryText={"Cancel"}
        content={
          counter >= 10 ? (
            <div className="mt-2">
              <FormInputTextComponent
                label="Password"
                type={"password"}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(value) => handleChangePassword(value)}
                error={error}
              />
            </div>
          ) : (
            <p>Your data will delete in {counter} seconds</p>
          )
        }
        primaryAction={submitPasswordDialog}
        secondaryAction={cancelPasswordDialog}
      />
    </PageWrapperComponent>
  );
}

export default ElectionListTemplateComponent;
