import React from "react";
import { useEffect, useState } from "react";
import FormRepeaterComponentTemplate from "./form-repeater.component.template";

const constructRows = async ({ value, rawColumns }) => {
  console.log({ value, rawColumns });
  if (!value) return [rawColumns];
  if (value.length === 0) return [rawColumns];
  const rowsArray = [];
  for (let i = 0; i < value.length; i++) {
    const currentRow = [];
    for (let j = 0; j < rawColumns.length; j++) {
      let onLoadUpdatedValues = {};
      if (rawColumns[j].onLoad)
        onLoadUpdatedValues = await rawColumns[j].onLoad({
          currentRow,
          currentColumn: {
            ...rawColumns[j],
            value: value[i][rawColumns[j].name],
          },
          value: value[i],
        });

      currentRow.push({
        ...rawColumns[j],
        ...onLoadUpdatedValues,
        value: value[i][rawColumns[j].name],
      });
    }
    rowsArray.push(currentRow);
  }
  console.log({ rowsArray });
  return rowsArray;
};

const FormRepeaterComponent = ({
  changeCount = 0,
  formInput,
  formInputs,
  columns: rawColumns,
  value,
  onChange,
  reloadInputs,
  handleImagePreview,
  staticHeader = true,
}) => {
  const [columns, setColumns] = useState(rawColumns);
  const [rows, setRows] = useState([]);

  const [deleteModalData, setDeleteModalData] = useState(-1);

  const handleAddRow = async () => {
    const emptyValueColumn = columns.map((col) => {
      return {
        ...col,
        value: null,
      };
    });
    let addedRow = [...rows, emptyValueColumn];

    if (formInput?.onLoad) {
      const response = await formInput.onLoad({
        formInputs,
        rows: addedRow,
      });
      if (response) addedRow = response;
    }

    setRows(addedRow);

    // Focus goes to last row first column
    setTimeout(() => {
      const rowLastIndex = rows.length;
      const id = columns[0].id + rowLastIndex;
      const inputElement = document.getElementById(id);
      if (inputElement) inputElement.focus();
    }, 200);
  };

  const handleDeleteRow = (rowIndex) => {
    setDeleteModalData(rowIndex);
  };

  const handleChange = async ({ rowIndex, columnIndex, value }) => {
    let rowArray = [];
    for (let i = 0; i < rows.length; i++) {
      const currentRow = [];
      for (let j = 0; j < rows[i].length; j++) {
        if (i === rowIndex && j === columnIndex)
          currentRow.push({ ...rows[i][j], value });
        else currentRow.push(rows[i][j]);
      }
      rowArray.push(currentRow);
    }
    console.log("from repeater", { formInputs, rowIndex, columnIndex });
    if (rows[rowIndex][columnIndex].onChange) {
      const onChangeResponse = await rows[rowIndex][columnIndex].onChange(
        value,
        rowArray,
        {
          rowIndex,
          columnIndex,
          formInputs,
        }
      );
      // console.log({ onChangeResponse });
      if (onChangeResponse) {
        rowArray = onChangeResponse;
      }
    }

    setRows(rowArray);
  };

  const handleKeyDown = ({ keyCode, rowIndex, columnIndex }) => {
    // { rowIndex, columnIndex, keyCode }
    // console.log({ value });
    if (keyCode === 13) {
      // console.log({ rowIndex, columnIndex });
      if (columns.length - 1 === columnIndex) {
        const addNewButton = document.getElementById("buttonAddNew");
        if (addNewButton) addNewButton.focus();
      } else if (columns.length > columnIndex) {
        let nextIndex = columnIndex + 1;
        let id;
        let status = false;
        for (let i = 0; i < columns.length; i++) {
          // console.log({ column: columns[i], nextIndex, i });
          if (!status && !columns[i].isHide && i >= nextIndex) {
            status = true;
            nextIndex = i;
          }
        }
        id = columns[nextIndex].id + rowIndex;
        // console.log({ id });
        const inputElement = document.getElementById(id);
        if (inputElement) inputElement.focus();
      }
    }
  };

  const updateRepeaterData = ({ rowIndex, columnIndex, rowData }) => {
    let rowArray = [];
    for (let i = 0; i < rows.length; i++) {
      const currentRow = [];
      for (let j = 0; j < rows[i].length; j++) {
        if (i === rowIndex && j === columnIndex)
          currentRow.push({ ...rows[i][j], ...rowData });
        else currentRow.push(rows[i][j]);
      }
      rowArray.push(currentRow);
    }
    return rowArray;
  };

  const handleFileUpload = async ({ columnIndex, rowIndex, file, column }) => {
    let rowArray = updateRepeaterData({
      columnIndex,
      rowIndex,
      rowData: { loading: true, error: "" },
    });

    setRows(rowArray);

    rowArray = [];

    const uploadResponse = await column.handleSelfUpload(
      file[0],
      column.prefix || "file"
    );

    console.log({ uploadResponse });

    if (uploadResponse.status) {
      rowArray = updateRepeaterData({
        columnIndex,
        rowIndex,
        rowData: {
          loading: false,
          value: column.filePrefix + uploadResponse.data,
          error: "",
        },
      });
    } else {
      rowArray = updateRepeaterData({
        columnIndex,
        rowIndex,
        rowData: { loading: false, error: uploadResponse.message, value: "" },
      });
    }

    // console.log("from repeater", { formInputs, rowIndex, columnIndex });
    if (rows[rowIndex][columnIndex].onChange) {
      const onChangeResponse = await rows[rowIndex][columnIndex].onChange(
        value,
        rowArray,
        {
          rowIndex,
          columnIndex,
          formInputs,
        }
      );
      if (onChangeResponse) {
        rowArray = onChangeResponse;
      }
    }

    setRows(rowArray);
  };
  const handleDeleteFile = ({ columnIndex, rowIndex }) => {
    // let copyRows = [...rows];
    // copyRows[rowIndex][columns[columnIndex].id] = "";
    // setRows(copyRows);

    let rowArray = updateRepeaterData({
      columnIndex,
      rowIndex,
      rowData: { value: "" },
    });

    setRows(rowArray);
  };

  const handleDeleteFileConfirm = () => {
    const copyRows = [...rows];
    copyRows.splice(deleteModalData, 1);
    setRows(copyRows);
    setDeleteModalData(-1);
  };

  const handleDeleteFileCancel = () => {
    setDeleteModalData(-1);
  };

  const onLoad = async () => {
    let constructedRows = await constructRows({ value, rawColumns });

    if (formInput?.onLoad) {
      const response = await formInput.onLoad({
        formInputs,
        rows: constructedRows,
      });
      if (response) constructedRows = response;
    }

    setRows(constructedRows);
  };

  const handleOnRefreshFormInput = async (rows) => {
    let updatedRows = [...rows];

    // console.log({ formInput });

    if (formInput?.refreshOnFormInputChange) {
      const response = await formInput.refreshOnFormInputChange({
        rows,
        formInputs,
      });
      if (response) updatedRows = response;
    }

    // console.log({ rows, updatedRows });
    setRows(updatedRows);
  };

  useEffect(() => {
    const rowValuesArray = [];
    console.log({ rows });
    for (let i = 0; i < rows.length; i++) {
      let currentValueRow = [];
      for (let j = 0; j < rows[i].length; j++) {
        currentValueRow = {
          ...currentValueRow,
          [rows[i][j].name]: rows[i][j].value || "",
        };
      }
      rowValuesArray.push(currentValueRow);
    }
    onChange(rowValuesArray);
  }, [rows]);

  useEffect(() => {
    onLoad();
    console.log({ reloadInputs });
  }, [reloadInputs]);

  useEffect(() => {
    handleOnRefreshFormInput(rows);
  }, [changeCount]);

  const templateModal = {
    columns,
    rows,
    deleteModalData,
    formInputs,
    staticHeader,
  };

  const templateEvents = {
    handleChange,
    handleAddRow,
    handleDeleteRow,
    handleKeyDown,
    handleFileUpload,
    handleDeleteFile,
    handleDeleteFileConfirm,
    handleDeleteFileCancel,
    handleImagePreview,
  };
  return (
    <FormRepeaterComponentTemplate
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  );
};

export default FormRepeaterComponent;
