import RefreshCatalogCountListComponent from "@screens/refresh-catalog-count/refresh-catalog-count-list/refresh-catalog-count-list.component";

const refreshCatalogCountRoutes = [
  {
    name: "Refresh Catalog Count",
    icon: "phone",
    path: "/refresh-catalog-count",
    component: RefreshCatalogCountListComponent,
  },
];

export default refreshCatalogCountRoutes;
