import React, { useEffect, useState } from "react";
import FormBuilderTemplateComponent from "./form-builder-template.component";

const FormBuilderComponent = ({
  loading,
  formType = "default",
  inputFields,
  onSubmit,
  submitTrigger,
  submitButton = null,
}) => {
  const [formInputs, setFormInputs] = useState(inputFields);
  const [activeTab, setActiveTab] = useState(1);
  const groups = [...new Set(inputFields.map((item) => item.groupName))];
  const [imagePreview, setImagePreview] = useState(null);
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [changeCount, setChangeCount] = useState(0);

  const handleChange = async (
    formInput,
    index,
    value,
    addtionalValues,
    preventChangeCount
  ) => {
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].value = value;
    copyFormInputs[index].error = "";
    copyFormInputs[index].touched = true;

    if (
      copyFormInputs[index].type === "CHECKBOX-MULTIPLE" ||
      copyFormInputs[index].type === "SELECT-GROUP"
    ) {
      copyFormInputs[index].options[addtionalValues.optionIndex] = {
        ...copyFormInputs[index].options[addtionalValues.optionIndex],
        checked: value,
      };
      const selectedValues = copyFormInputs[index].options.filter(
        (option) => option.checked
      );

      copyFormInputs[index].value = selectedValues;
    }

    const validations = copyFormInputs[index].validations;

    formInput = validateField({
      validations,
      formInput: copyFormInputs[index],
    });

    if (formInput.onChange) {
      const onChangeResponse = await formInput.onChange(
        value,
        copyFormInputs,
        addtionalValues
      );
      if (onChangeResponse) {
        copyFormInputs = onChangeResponse;
      }
    }

    // console.log({ formInput, index, value, copyFormInputs });

    setFormInputs(copyFormInputs);
    // console.log({ preventChangeCount });
    if (!preventChangeCount) setChangeCount(changeCount + 1);
  };

  const handleFileUpload = async (formInput, index, file) => {
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    copyFormInputs[index].error = "";
    copyFormInputs[index].touched = true;

    if (formInput.handleSelfUpload) {
      setFormInputs(copyFormInputs);
      // console.log("inside file upload", file);

      const uploadResponse = await formInput.handleSelfUpload(file[0]);
      // console.log({ uploadResponse });
      let copyFormInputs2 = [...formInputs];
      if (uploadResponse.status) {
        copyFormInputs2[index].loading = false;
        copyFormInputs2[index].error = "";
        copyFormInputs2[index].value = formInput.multipleUpload
          ? [
              ...copyFormInputs2[index].value,
              {
                name: file[0].name,
                file: formInput.filePrefix + uploadResponse.data,
              },
            ]
          : formInput.filePrefix + uploadResponse.data;
        copyFormInputs2[index].touched = true;
      } else {
        let copyFormInputs2 = [...formInputs];
        // console.log({ copyFormInputs2 });
        copyFormInputs2[index].loading = false;
        // setFormInputs(copyFormInputs2);
        alert(uploadResponse.message);
      }

      if (formInput.onChange) {
        const onChangeResponse = await formInput.onChange(
          copyFormInputs2[index].value,
          copyFormInputs2,
          { uploadResponse }
        );
        console.log({ onChangeResponse });
        if (onChangeResponse) {
          copyFormInputs2 = onChangeResponse;
        }
      }

      setFormInputs(copyFormInputs2);

      setChangeCount(changeCount + 1);
    }

    if (formInput.handleUpload)
      formInput.handleUpload({
        file,
        formInput,
        index,
        formInputs: copyFormInputs,
      });

    // setFormInputs(copyFormInputs);
  };

  const handleDeleteFile = (formInput, index, file) => {
    // console.log(formInput, index, file);
    const files = formInputs[index].multipleUpload
      ? formInputs[index].value.filter(
          (currentFile, currentIndex) => currentIndex != file.index
        )
      : "";

    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = false;
    copyFormInputs[index].error = "";
    copyFormInputs[index].touched = true;
    copyFormInputs[index].value = files;
    setFormInputs(copyFormInputs);
  };

  const validateField = ({ validations, formInput }) => {
    if (validations)
      for (let i = 0; i < validations.length; i++) {
        formInput.error = "";
        if (validations[i].type === "REQUIRED" && formInput.value === "") {
          formInput.error = validations[i].message;
          return formInput;
        }
        if (
          validations[i].type === "REGEX" &&
          !validations[i].expression.test(formInput.value)
        ) {
          formInput.error = validations[i].message;
          return formInput;
        }
      }
    return formInput;
  };

  const validate = () => {
    let copyFormInputs = [...formInputs];
    for (let i = 0; i < copyFormInputs.length; i++) {
      const validations = copyFormInputs[i].validations;

      copyFormInputs[i] = validateField({
        validations,
        formInput: copyFormInputs[i],
      });
    }

    setFormInputs(copyFormInputs);

    const errors = copyFormInputs.filter(
      (formInput) => formInput.error && formInput.error != ""
    );

    return errors;
  };
  const handleImagePreview = (imageToView) => {
    setImagePreview(imageToView);
    setIsImagePreview(true);
  };

  const cancelImagePreview = () => {
    setIsImagePreview(false);
    setImagePreview(null);
  };

  const handleSubmit = () => {
    const errors = validate();
    if (onSubmit) onSubmit({ values: formInputs, errors });

    //INFO: if its a tab form change active tab based on the error
    if (formType === "tab") {
      let errorTab = 0;
      groups.map((group, index) => {
        const inputWithError = formInputs.filter(
          (input) => input.error && input.groupName === group
        );
        // console.log({ inputWithError, errorTab, formInputs });
        if (errorTab === 0 && inputWithError && inputWithError.length > 0)
          errorTab = index + 1;
      });

      // console.log({ errorTab });
      if (errorTab > 0) setActiveTab(errorTab);
    }
  };

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      handleSubmit();
    }
  };

  const handleClear = () => {
    let copyFormInputs = JSON.parse(JSON.stringify(formInputs));
    const formattedInputs = copyFormInputs.map((input) => {
      return {
        ...input,
        value: "",
      };
    });
    setFormInputs(formattedInputs);
    if (onSubmit) onSubmit({ values: formattedInputs, errors: [] });
  };

  useEffect(() => {
    if (submitTrigger > 0) handleSubmit();
  }, [submitTrigger]);

  useEffect(() => {
    // // console.log({ inputFields });
    if (submitTrigger <= 0) setFormInputs(inputFields);
  }, [inputFields]);

  const templateModal = {
    formType,
    groups,
    formInputs,
    loading,
    activeTab,
    imagePreview,
    isImagePreview,
    changeCount,
    submitButton,
  };

  // console.log({ changeCount });

  const templateEvents = {
    handleChange,
    handleFileUpload,
    handleDeleteFile,
    setActiveTab,
    handleImagePreview,
    cancelImagePreview,
    handleKeyDown,
    handleSubmit,
    handleClear,
  };

  return (
    <FormBuilderTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  );
};

export default FormBuilderComponent;
