import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";
import { store } from "@src/store";

export const badgeClickApi = async ({ action, id, status }) => {
  return await fetch(action, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ id, status }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
