import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNotificationFormInputs } from "../model/notification.modal";
import { menuUrl } from "../notification-list/model/notification-list.constants";
import {
  createNotificationApi,
  getAllUsers,
} from "./notification-create.component.service";
import UsersCreateTemplateComponent from "./notification-create-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  NOTIFICATION_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const NotificationCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleNotificationSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct roles payload
    let notificationPayload = {};
    for (let i = 0; i < values.length; i++) {
      notificationPayload[values[i].name] = values[i].value;
    }

    // INFO : call edit api
    const notificationResponse = await createNotificationApi(
      notificationPayload
    );

    // INFO : check api response and show relevant alert message
    if (notificationResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: notificationResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const usersListResponse = await getAllUsers();
    console.log({ usersListResponse });
    if (usersListResponse.status) {
      const formatedUserList = usersListResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getNotificationFormInputs(formatedUserList, {});
      console.log({ formatedUserList, formInputData });
      setFormInputs(formInputData);
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleNotificationSubmit,
    handleTriggerClick,
  };

  return menuAccess ? (
    <UsersCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default NotificationCreateComponent;
