import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const FormInputTextAutoCompeleteComponent = ({
  id,
  name,
  placeholder,
  value,
  options = [],
  onChange,
  threshold = 300,
  isVertical,
  inputIcon,
  label,
  isRequired,
  i,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    value ? { ...value } : {}
  );

  const [showOptions, setShowOptions] = useState(false);
  const [optionPanelTimeOut, setOptionPanelTimeOut] = useState();

  const handleChange = (e) => {
    const updatedValue = {
      ...selectedOption,
      name: e.target.value,
      value: null,
    };
    setSelectedOption(updatedValue);
    console.log({ rawValue: e.target.value });
    if (!e.target.value || e.target.value == "") setShowOptions(false);
    // else setShowOptions(false);
    if (optionPanelTimeOut) clearTimeout(optionPanelTimeOut);
    const optionTimer = setTimeout(() => {
      if (onChange) onChange(updatedValue);
    }, threshold);

    setOptionPanelTimeOut(optionTimer);
  };

  const handleOptionClick = (option) => {
    const updatedValue = {
      ...selectedOption,
      name: option.name,
      value: option.value,
    };
    console.log({ updatedValue, option });
    setShowOptions(false);
    setSelectedOption(updatedValue);
    onChange(updatedValue);
  };

  useEffect(() => {
    console.log({ options, selectedOption });
    const suggestionStatus =
      options?.length > 0 && selectedOption.name && !selectedOption.value
        ? true
        : false;
    setShowOptions(suggestionStatus);
  }, [options, selectedOption]);

  return (
    <>
      <div className="col-12">
        <div
          className={`${isVertical ? "" : "mb-1 row "} ${
            inputIcon ? "input-group" : ""
          }`}
        >
          {label && (
            <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
              <label className="col-form-label">
                {label}
                {isRequired && <> * </>}
              </label>
            </div>
          )}

          <div className={isVertical ? "col-sm-12" : "col-sm-10"}>
            <div className={inputIcon ? "input-group" : ""}>
              <div className="autocomplete" style={{ width: "100%" }}>
                <input
                  className="form-control"
                  id={id}
                  type="text"
                  name={name}
                  value={selectedOption.name}
                  placeholder={placeholder}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={`autocomplete-items ${
                    showOptions > 0 ? "" : "hidden"
                  }`}
                >
                  {options.map((option) => (
                    <div onClick={() => handleOptionClick(option)}>
                      {option.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormInputTextAutoCompeleteComponent;
