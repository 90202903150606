import { END_POINT } from "@constants/api.constants";
import { PRIMARY_KEY } from "@constants/app.constants";

const { BULK_VOICE_CALL_STATUS_TOGGLE } = END_POINT;
const keyColumn = PRIMARY_KEY;
const tableTitle = "Bulk Voice Call";
const menuUrl = "campaign/bulk-voice-call";
const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Bulk Voice Call",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "total_entries",
    numeric: false,
    disablePadding: true,
    label: "Total",
  },
  {
    id: "total_sent_entries",
    numeric: false,
    disablePadding: true,
    label: "Sent Count",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: BULK_VOICE_CALL_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Enabled",
      2: "Disabled",
    },
    badgeColor: {
      1: "success",
      2: "danger",
    },
  },
];
const breadCrumbs = [
  { name: "Campaign", menu_url: null },
  { name: "Bulk Voice Call", menu_url: "/campaign/bulk-voice-call" },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Bulk Voice Call ",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const additionalActions = ({ handleViewDetail }) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
