import React, { useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
} from "@screens/refresh-catalog-count/refresh-catalog-count-list/model/refresh-catalog-count-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { checkMenuAccess } from "@utils/menu-auth";
import { useSelector } from "react-redux";
import { getDataApi } from "./refresh-catalog-count-list.component.service";
import RefreshCatalogCountListTemplateComponent from "./refresh-catalog-count-list-template.component";
import { APP } from "@constants/app.constants";

const { ROWS_PER_PAGE } = APP;

const RefreshCatalogCountListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const { appRowsPerPage } = useSelector((state) => state.app);

  const getData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (data.status) {
      console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const deleteRecords = async (selected) => {};

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  return menuAccess ? (
    <RefreshCatalogCountListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
        tableParams,
      }}
      templateEvents={{
        getData,
        deleteRecords,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default RefreshCatalogCountListComponent;
