import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "@core/scss/react/libs/flatpickr/flatpickr.scss";

const FormInputDateComponent = ({
  label,
  id,
  name,
  value,
  placeholder,
  error,
  onChange,
  isVertical = false,
}) => {
  const handleDateChange = (selectedDates, dateStr, instance) => {
    console.log(selectedDates, dateStr, instance);
    if (onChange) onChange(dateStr);
  };
  return (
    <div className="col-12">
      <div className={isVertical ? "" : "mb-1 row"}>
        {label && (
          <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
            <label className="col-form-label"> {label} </label>
          </div>
        )}

        <div className={isVertical ? "col-sm-12" : "col-sm-9"}>
          <Flatpickr
            id="default-picker"
            name={name}
            className={`form-control ${error ? "is-invalid" : ""}`}
            value={value}
            placeholder={placeholder}
            onChange={(selectedDates, dateStr, instance) =>
              handleDateChange(selectedDates, dateStr, instance)
            }
            dateFormat="YYYY-MM-DD"
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default FormInputDateComponent;
