import { APP } from "@constants/app.constants";
const { DELETE_PROTECTED, USER_TYPE } = APP;

export const getUserFormInputs = (
  roles,
  clients,
  initialValues,
  handleUserImageFileUpload
) => [
  {
    type: "SELECT",
    label: "Client",
    id: "clientId",
    name: "client_id",
    options: clients,
    value: initialValues?.clientId || "",
    placeholder: "Select Client",
    validations: [
      {
        type: "REQUIRED",
        message: "Client is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "User Name",
    id: "email",
    name: "email",
    value: initialValues?.email || "",
    placeholder: "Enter the User Name",
    validations: [
      {
        type: "REQUIRED",
        message: "User Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Mobile No",
    id: "mobile",
    name: "mobile",
    value: initialValues?.mobile || "",
    placeholder: "Enter the Mobile",
    validations: [],
  },
  {
    type: "TEXT",
    label: "WhatsApp No",
    id: "whatsappNumber",
    name: "whatsapp_number",
    value: initialValues?.whatsappNumber || "",
    placeholder: "Enter the Whatsapp No",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Password",
    id: "password",
    name: "password",
    value: initialValues?.password || "",
    placeholder: "Enter the Password",
    validations: [
      {
        type: "REQUIRED",
        message: "Password is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type",
    options: USER_TYPE,
    value: initialValues?.type || "",
    placeholder: "Select Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Type is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Role",
    id: "roleId",
    name: "role_id",
    options: roles,
    value: initialValues?.roleId || "",
    placeholder: "Select Role",
    validations: [
      {
        type: "REQUIRED",
        message: "Role is required !",
      },
    ],
  },
  {
    type: "CALENDER",
    label: "Expiry Date",
    id: "expiryDate",
    name: "expiry_date",
    value: initialValues?.expiryDate || "",
    placeholder: "Select Date",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Delete Protected",
    id: "deleteProtected",
    name: "delete_protected",
    options: DELETE_PROTECTED,
    value: initialValues?.deleteProtected || 2,
    placeholder: "Select Delete Protected",
    validations: [],
    isHide: true,
  },

  {
    type: "FILE_UPLOAD",
    name: "user_image",
    id: "userImage",
    label: "Image",
    loading: false,
    value: initialValues?.userImage || "",
    handleUpload: handleUserImageFileUpload,
    validations: [],
  },
];
