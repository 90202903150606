import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import React from "react";

const FormInputRichTextComponent = ({
  label,
  id,
  name,
  value,
  error,
  onChange,
  isVertical = false,
  isRequired = false,
}) => {
  const handleChange = (value) => {
    if (onChange) onChange(value);
  };

  return (
    <>
      <div className="col-12">
        <div className={isVertical ? "" : "mb-1 row"}>
          {label && (
            <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
              <label className="col-form-label">
                {label} {isRequired && <> * </>}
              </label>
            </div>
          )}

          <div className={isVertical ? "col-sm-12" : "col-sm-10"}>
            <CKEditor
              editor={ClassicEditor}
              data="<p> Vins </p>"
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "ckfinder",
                  "|",
                  "imageTextAlternative",
                  "imageUpload",
                  "imageStyle:full",
                  "imageStyle:side",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              onInit={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
                console.log(
                  "toolbar: ",
                  Array.from(editor.ui.componentFactory.names())
                );
                console.log(
                  "plugins: ",
                  ClassicEditor.builtinPlugins.map(
                    (plugin) => plugin.pluginName
                  )
                );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleChange(data);
                // console.log({ event, editor, data });
              }}
              onBlur={(editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(editor) => {
                console.log("Focus.", editor);
              }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormInputRichTextComponent;
