import { APP } from "@constants/app.constants";
import { getDistrictUnionWardByDistrictId } from "@screens/catalog-rural/district-union-ward/district-union-ward-list/district-union-ward-list.component.service";
import { getPanchayatUnionWardByDistrictId } from "@screens/catalog-rural/panchayat-union-ward/panchayat-union-ward-list/panchayat-union-ward-list.component.service";
import { getVillagePanchayatWardByDistrictId } from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-ward-list/village-panchayat-ward-list.service";

const { RURAL_TYPE } = APP;

export const getFormInputs = ({
  states,
  districts,
  wards,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "Part No",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Part No",
    validations: [
      {
        type: "REQUIRED",
        message: "Part No is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    // onChange: onDistrictChange,
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "Rural Type",
    id: "ruralType",
    name: "rural_type",
    options: RURAL_TYPE,
    value: initialValues?.ruralType || "",
    placeholder: "Select Rural Type",
    onChange: async (selectedOption, formInputs) => {
      console.log({ selectedOption });
      let options = [];
      if (selectedOption == "DISTRICT UNION WARD") {
        const districtUnionWardResponse =
          await getDistrictUnionWardByDistrictId(formInputs[2].value);
        if (districtUnionWardResponse.status) {
          options = districtUnionWardResponse.data.map((ward) => {
            return {
              name: ward.name,
              value: ward.id,
            };
          });
        }

        console.log({ districtUnionWardResponse });
      }
      if (selectedOption == "PANCHAYAT UNION WARD") {
        const panchayatUnionWardResponse =
          await getPanchayatUnionWardByDistrictId(formInputs[2].value);
        if (panchayatUnionWardResponse.status) {
          options = panchayatUnionWardResponse.data.map((ward) => {
            return {
              name: ward.name,
              value: ward.id,
            };
          });
        }

        console.log({ panchayatUnionWardResponse });
      }
      if (selectedOption == "VILLAGE PANCHAYAT WARD") {
        const villagePanchayatWardResponse =
          await getVillagePanchayatWardByDistrictId(formInputs[2].value);
        if (villagePanchayatWardResponse.status) {
          options = villagePanchayatWardResponse.data.map((ward) => {
            return {
              name: ward.name,
              value: ward.id,
            };
          });
        }

        console.log({ villagePanchayatWardResponse });
      }
      formInputs[4].options = options;
    },
    validations: [
      {
        type: "REQUIRED",
        message: "Rural Type is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "Ward Name",
    id: "wardId",
    name: "ward_id",
    value: initialValues?.wardId || "",
    options: wards,
    placeholder: "Select Ward",
    validations: [
      {
        type: "REQUIRED",
        message: "Ward Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Booth Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Booth Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Booth Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "V Booth Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the V Booth Name",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Booth Type",
    id: "boothType",
    name: "booth_type",
    value: initialValues?.boothType || "",
    placeholder: "Enter the Booth Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Booth Type is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Village Name",
    id: "location",
    name: "location",
    value: initialValues?.location || "",
    placeholder: "Enter the Village Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Village is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "V Village Name",
    id: "locationRegionalName",
    name: "location_regional_name",
    value: initialValues?.locationRegionalName || "",
    placeholder: "Enter the V Village Name",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
