import { APP_NAME } from "@constants/app.constants";

import MunicipalityCreateComponent from "@screens/master-urban/municipality/municipality-create/municipality-create";
import MunicipalityEditComponent from "@screens/master-urban/municipality/municipality-edit/municipality-edit";
import MunicipalityFileUploadComponent from "@screens/master-urban/municipality/municipality-file/municipality-file";
import MunicipalityListComponent from "@screens/master-urban/municipality/municipality-list/municipality-list";

const masterMunicipalityRoutes = [
  {
    name: "Municipality List",
    icon: "check-square",
    path: "/master-urban/municipality",
    component: MunicipalityListComponent,
  },

  {
    name: `Create Corporation - ${APP_NAME}`,
    path: "/master-urban/municipality/create",
    component: MunicipalityCreateComponent,
  },

  {
    name: `Edit Corporation - ${APP_NAME}`,
    path: "/master-urban/municipality/edit",
    component: MunicipalityEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-urban/municipality/upload",
    component: MunicipalityFileUploadComponent,
  },
];

export default masterMunicipalityRoutes;
