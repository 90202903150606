import React from "react";
import ApexCharts from "apexcharts";
import { useEffect } from "react";

const UrbanWardChartComponent = () => {
  const onLoad = () => {
    var options = {
      chart: {
        height: 380,
        width: "100%",
        type: "line",
      },
      series: [
        {
          name: "Series 1",
          data: [
            [1, 34],
            [3.8, 43],
            [5, 31],
            [10, 43],
            [13, 33],
            [15, 43],
            [18, 33],
            [20, 52],
          ],
        },
      ],
      xaxis: {
        type: "numeric",
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return val.toFixed(1);
          },
        },
      },
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);

    chart.render();
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div>
      <div id="chart"></div>
    </div>
  );
};

export default UrbanWardChartComponent;
