import { APP_NAME } from "@constants/app.constants";

import PCCreateComponent from "@screens/catalog-urban/pc/pc-create/pc-create.component";
import PCEditComponent from "@screens/catalog-urban/pc/pc-edit/pc-edit.component";
import PCListComponent from "@screens/catalog-urban/pc/pc-list/pc-list.component";
import UsersCreateComponent from "@screens/catalog-urban/pc/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog-urban/pc/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog-urban/pc/user/users-list/users-list.component";

const catalogUrbanPcRoutes = [
  {
    name: `PC - ${APP_NAME}`,
    path: "/catalog-urban/pc",
    icon: "columns",
    component: PCListComponent,
  },

  {
    name: `Create PC - ${APP_NAME}`,
    path: "/catalog-urban/pc/create",
    component: PCCreateComponent,
  },

  {
    name: `Edit PC - ${APP_NAME}`,
    path: "/catalog-urban/pc/edit",
    component: PCEditComponent,
  },
  {
    name: `PC User - ${APP_NAME}`,
    path: "/catalog-urban/pc/user",
    component: UsersListComponent,
  },
  {
    name: `Create PC User - ${APP_NAME}`,
    path: "/catalog-urban/pc/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit PC User - ${APP_NAME}`,
    path: "/catalog-urban/pc/user/edit",
    component: UsersEditComponent,
  },
];

export default catalogUrbanPcRoutes;
