export const getRolesFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Role Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Role Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Role Name is required !",
      },
    ],
  },
];
