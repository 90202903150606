import { END_POINT } from "@constants/api.constants";
import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Menu Items";
const keyColumn = "id";
const menuUrl = "system/menu-items";

const { MENU_ITEMS_STATUS_TOGGLE } = END_POINT;

const tableColumn = [
  {
    id: "menu_name",
    numeric: false,
    disablePadding: true,
    label: "Menu Name",
  },
  {
    id: "menu_url",
    numeric: false,
    disablePadding: false,
    label: "Menu Url",
  },
  {
    id: "menu_icon",
    numeric: false,
    disablePadding: false,
    label: "Menu Icon",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isBadge: true,
    badgeText: {
      Menu: "Menu",
      Permission: "Permission",
    },
    badgeColor: {
      Menu: "info",
      Permission: "warning",
    },
  },
  {
    id: "sort_order",
    numeric: false,
    disablePadding: false,
    label: "Sort Order",
  },
  {
    id: "role_view",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: MENU_ITEMS_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      0: "Disabled",
      1: "Enabled",
    },
    badgeColor: {
      0: "danger",
      1: "success",
    },
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Menu Items", menu_url: "/system/menu-items" },
];

const filters = [
  {
    name: "menu_name",
    id: "menu_name",
    label: "Menu Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "menu_url",
    id: "menu_url",
    label: "Menu Url",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
