const tableTitle = "Blocked IP";
const keyColumn = "id";
const menuUrl = "system/blocked-ip";
const tableColumn = [
  {
    id: "ip_address",
    numeric: false,
    disablePadding: true,
    label: "IP Address",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Blocked IP", menu_url: "/system/blocked-ip" },
];

const filters = [
  {
    name: "ip_address",
    id: "ipAddress",
    label: "IP Address",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
