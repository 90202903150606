import React from "react";
import logo from "@assets/images/blupage-white.png";
import thedalImage from "@assets/images/blupage-blue.png";
import info from "@assets/images/blupage-info2.png";

const LoginV2WrapperComponent = ({ children }) => {
  return (
    <div className="login-container">
      <div className="row height-100per">
        <div className="col-md-6 login-v2-form">
          <div className="height-100per">
            <div className="row height-100per align-items-center">
              <div className="col-md-6 col-sm-6 col-sx-6  mx-auto">
                <div className="card bg-white box-shadow-v2">
                  <div className="card-body pb-5">
                    <div className="brand-logo d-flex justify-content-center pb-3 pt-2">
                      <img src={logo} alt="" width={300} height={85} />
                    </div>

                    <h4 className="card-title mb-1 color-v2 fw-bolder font-23">
                      Welcome to BLUPAGE! 👋
                    </h4>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      Please sign in to your acount and start the journey
                    </p>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 login-v2-image d-xs-none d-sm-done">
          <div className="login-content container" style={{ marginTop: 30 }}>
            <img src={info} style={{ width: "95%", paddingTop: 100 }} />
          </div>
          {/* <div className="voter-hands">
            <img src={votersImage} />
            <img src={thunderImage} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoginV2WrapperComponent;
