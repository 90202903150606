import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalComponent = ({
  open,
  title,
  message,
  primaryAction,
  secondaryAction,
  primaryText,
  secondaryText,
  content,
  children,
}) => {
  return (
    <div className="basic-modal">
      <Modal
        className="modal-primary"
        isOpen={open}
        toggle={secondaryAction}
        backdrop="static"
      >
        <ModalHeader toggle={secondaryAction}>{title}</ModalHeader>
        <ModalBody>
          <p style={{ marginTop: 11 }}>
            {" "}
            {message}
            {content && <>{content}</>}
            {children && <> {children}</>}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="outline-primary" onClick={secondaryAction}>
            {secondaryText}
          </Button>
          {primaryText && (
            <Button color="primary" onClick={primaryAction}>
              {primaryText}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalComponent;
