import { APP } from "@constants/app.constants";
const { RESERVATION_TYPE_2 } = APP;

export const getVillagePanchayatFormInputs = ({
  states,
  districts,
  districtUnions,
  panchayatUnions,
  initialValues,
  onStateChange,
  onDistrictChange,
  onDistrictUnionChange,
}) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Village Panchayat ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Village Panchayat ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    type: "AUTOCOMPLETE",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    onChange: onDistrictChange,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "District Union Name",
    id: "districtUnionId",
    name: "district_union_id",
    options: districtUnions,
    onChange: onDistrictUnionChange,
    value: initialValues?.districtUnionId || "",
    placeholder: "Select District Union",
    validations: [
      {
        type: "REQUIRED",
        message: "District Union Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "Panchayat Union Name",
    id: "panchayatUnionId",
    name: "panchayat_union_id",
    options: panchayatUnions,
    value: initialValues?.panchayatUnionId || "",
    placeholder: "Select Panchayat Union",
    validations: [
      {
        type: "REQUIRED",
        message: "Panchayat Union Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Village Panchayat Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Village Panchayat Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Village Panchayat Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION_TYPE_2,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Total Ward",
    id: "totalWardCount",
    name: "total_ward_count",
    value: initialValues?.totalWardCount || "",
    placeholder: "Enter the Total Ward",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
