import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const {
  CATALOG_URBAN_PC_LIST,
  CATALOG_URBAN_PC_DELETE,
  CATALOG_URBAN_STATE_PC_LIST,
  CATALOG_URBAN_PC_ALL_LIST,
} = END_POINT;

export const getDataApi = async ({
  orderBy,
  sortBy,
  skip,
  limit,
  filters,
  electionId,
}) => {
  return await fetch(
    `${CATALOG_URBAN_PC_LIST}${electionId}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteDataApi = async (ids) => {
  return await fetch(CATALOG_URBAN_PC_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getPcByStateId = async (stateId) => {
  return await fetch(`${CATALOG_URBAN_STATE_PC_LIST}${stateId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const getAllPc = async (electionId) => {
  let url = CATALOG_URBAN_PC_ALL_LIST + electionId;
  //if (districtIds) url = `${url}?district_ids=${districtIds}`;
  return await fetch(CATALOG_URBAN_PC_ALL_LIST + electionId, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
