import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getModulesFormInputs } from "../model/modules.modal";
import { menuUrl } from "../modules-list/model/modules-list.constants";
import { createModulesApi } from "./modules-create.component.service";
import ModulesCreateTemplateComponent from "./modules-create-template.component";
const {
  ALERT_TYPES: { SUCCESS, ERROR },
  MODULES_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ModulesCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleModulesSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct email template payload
    let modulesPayload = {};
    for (let i = 0; i < values.length; i++) {
      modulesPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const modulesResponse = await createModulesApi(modulesPayload);

    // INFO : check api response and show relevant alert message
    if (modulesResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: modulesResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getModulesFormInputs({}),
  };

  const templateEvents = {
    handleModulesSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <ModulesCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ModulesCreateComponent;
