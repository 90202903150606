import React from "react";
import PermissionDeniedTemplateComponent from "./permission-denied-template.component";

function PermissionDeniedComponent() {
  const goBack = () => {
    window.history.back();
  };
  return <PermissionDeniedTemplateComponent goBack={goBack} />;
}

export default PermissionDeniedComponent;
