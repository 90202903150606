import React from "react";
import CorporationDashboardComponent from "../dashboard/dashboard.component";
import CorporationChartComponent from "./chart.component";

const CorporationSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <CorporationChartComponent />}
      {type === "DASHBOARD" && <CorporationDashboardComponent />}
    </>
  );
};

export default CorporationSwapComponent;
