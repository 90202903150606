import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuUrl } from "../vendors-list/model/vendors-list.constants";
import { createEntry } from "./vendors-create.component.service";
import { getVendorsFormInputs } from "../model/vendors.modal";
import VendorsTemplateCreateComponent from "./vendors-create-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  VENDORS_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const VendorsCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let vendorsPayload = {};
    for (let i = 0; i < values.length; i++) {
      vendorsPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const vendorsResponse = await createEntry(vendorsPayload);

    // INFO : check api response and show relevant alert message
    if (vendorsResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: vendorsResponse.message,
        })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getVendorsFormInputs({}),
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <VendorsTemplateCreateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default VendorsCreateComponent;
