import { APP_NAME } from "@constants/app.constants";
import DistrictsCreateComponent from "@screens/catalog-rural/districts/districts-create/districts-create.component";
import DistrictsEditComponent from "@screens/catalog-rural/districts/districts-edit/districts-edit.component";
import DistrictsListComponent from "@screens/catalog-rural/districts/districts-list/districts-list.component";
import UsersCreateComponent from "@screens/catalog-rural/districts/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog-rural/districts/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog-rural/districts/user/users-list/users-list.component";

const catalogRuralDistrictsRoutes = [
  {
    name: `Rural Districts - ${APP_NAME}`,
    path: "/catalog-rural/districts",
    icon: "map-pin",
    component: DistrictsListComponent,
  },
  {
    name: `Create District - ${APP_NAME}`,
    path: "/catalog-rural/districts/create",
    component: DistrictsCreateComponent,
  },
  {
    name: `Edit District - ${APP_NAME}`,
    path: "/catalog-rural/districts/edit",
    component: DistrictsEditComponent,
  },
  {
    name: `District User - ${APP_NAME}`,
    path: "/catalog-rural/districts/user",
    component: UsersListComponent,
  },
  {
    name: `Create District User - ${APP_NAME}`,
    path: "/catalog-rural/districts/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit District User - ${APP_NAME}`,
    path: "/catalog-rural/districts/user/edit",
    component: UsersEditComponent,
  },
];

export default catalogRuralDistrictsRoutes;
