export const getCurrentElectionName = (electionData) => {
  let typeOfElection = "";
  if (electionData.type_of_election) {
    typeOfElection = " - " + electionData.type_of_election;
  }

  let urbanOrRuralTypes = "";
  if (electionData.election_category === "URBAN") {
    urbanOrRuralTypes = " - " + electionData.urban_types;
  } else if (electionData.election_category === "RURAL") {
    urbanOrRuralTypes = " - " + electionData.rural_types;
  }

  return (
    electionData.govt_id +
    " - " +
    electionData.name +
    " - " +
    electionData.category +
    typeOfElection +
    urbanOrRuralTypes +
    " - " +
    electionData.month_of_election +
    " - " +
    electionData.year_of_election
  );
};
