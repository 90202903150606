import {
  faCheckSquare,
  faCoffee,
  faEllipsisV,
  faTable,
  faHome,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";

export const fontAwesomeIcons = [
  faCheckSquare,
  faCoffee,
  faEllipsisV,
  faTable,
  faHome,
  faHouse,
];
