import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { redirectToLink } from "@utils/common";
import { uploadElectionFiles } from "../election-list/election-list.component.service";
const {
  ELECTION_CATEGORY,
  ELECTION_TYPE,
  ELECTION_STATUS,
  MONTHS,
  YEAR,
  ELECTION_PHASE,
  POLL_DATA,
} = APP;

export const phaseRepeaterSize = {
  1: {
    eventName: 5,
    date: 5,
  },
  2: {
    eventName: 6,
    date: 2,
  },
  3: {
    eventName: 4,
    date: 2,
  },
  4: {
    eventName: 3,
    date: 2,
  },
  5: {
    eventName: 6,
    date: 1,
  },
  6: {
    eventName: 5,
    date: 1,
  },
  7: {
    eventName: 4,
    date: 1,
  },
  8: {
    eventName: 3,
    date: 1,
  },
  9: {
    eventName: 2,
    date: 1,
  },
  10: {
    eventName: 1,
    date: 1,
  },
};

export const getElectionFormInputs = (
  states,
  initialValues,
  onChangeShowByElection,
  { handleElectionFileUpload }
) => [
  {
    type: "TEXT",
    label: "Election ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter Election ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Election ID is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Election Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter Election Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Category",
    id: "category",
    name: "category",
    options: ELECTION_CATEGORY,
    value: initialValues?.category || "",
    placeholder: "Select category",
    validations: [
      {
        type: "REQUIRED",
        message: "Category is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type_of_election",
    options: ELECTION_TYPE,
    value: initialValues?.type_of_election || "",
    placeholder: "Select type",
    onChange: onChangeShowByElection,
    validations: [
      {
        type: "REQUIRED",
        message: "Type is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Year",
    id: "yearOfElection",
    name: "year_of_election",
    options: YEAR,
    value: initialValues?.year_of_election || "",
    placeholder: "Select year ",
    validations: [
      {
        type: "REQUIRED",
        message: "Election year is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Month",
    id: "monthOfElection",
    name: "month_of_election",
    options: MONTHS,
    value: initialValues?.month_of_election || "",
    placeholder: "Select month",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Month is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: ELECTION_STATUS,
    value: initialValues?.status || "ONGOING",
    placeholder: "Select status",
  },

  {
    type: "TEXT",
    label: "No.of Polling Station",
    id: "totalPollingStation",
    name: "total_polling_station",
    value: initialValues?.totalPollingStation || "",
    placeholder: "Enter No.of Polling Station",
    validations: [],
  },

  {
    type: "SELECT",
    label: "No of Phase",
    id: "totalPhase",
    name: "total_phase",
    options: ELECTION_PHASE,
    value: initialValues?.totalPhase || 1,
    placeholder: "No of Phase",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Phase is required !",
      },
    ],
    onChange: (value, formInputs) => {
      const pollDataIndex = formInputs.findIndex(
        (input) => input.name === "poll_data"
      );

      const newRepeaterInputs = [];

      newRepeaterInputs.push({
        ...formInputs[pollDataIndex].inputs[0],
        size: phaseRepeaterSize[value].eventName,
      });

      for (let i = 1; i <= value; i++) {
        console.log("increase repeater length");
        newRepeaterInputs.push({
          ...formInputs[pollDataIndex].inputs[1],
          name: "date" + i,
          label: "Date " + i,
          id: "date" + i,
          size: phaseRepeaterSize[value].date,
        });
      }

      formInputs[pollDataIndex].inputs = newRepeaterInputs;
    },
  },
  {
    type: "TEXT",
    label: "No.of Pink Booth",
    id: "totalPinkBooth",
    name: "total_pink_booth",
    value: initialValues?.totalPinkBooth || "",
    placeholder: "Enter No.of Pink Booth",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Voters",
    id: "totalVoters",
    name: "total_voters",
    value: initialValues?.totalVoters || "",
    placeholder: "Enter No.of Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Male Voters",
    id: "totalMaleVoters",
    name: "total_male_voters",
    value: initialValues?.totalMaleVoters || "",
    placeholder: "Enter No.of Male Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Female Voters",
    id: "totalFemaleVoters",
    name: "total_female_voters",
    value: initialValues?.totalFemaleVoters || "",
    placeholder: "Enter No.of Female Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Transgender Voters",
    id: "totalTransgenderVoters",
    name: "total_transgender_voters",
    value: initialValues?.totalTransgenderVoters || "",
    placeholder: "Enter No.of Transgender Voters",
    validations: [],
  },
  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Poll Data",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "poll_data",
    value: initialValues?.poll_data || POLL_DATA,
    inputs: [
      {
        type: "TEXT",
        label: "Poll Event",
        id: "pollEvent",
        name: "pollEvent",
        placeholder: "Poll Event",
        size: 6,
      },
      {
        type: "CALENDER",
        label: "Date",
        id: "date",
        name: "date",
        placeholder: "Date",
        size: 5,
      },
    ],
  },
  {
    groupName: "Relevant Links",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "relevant_link",
    value: initialValues?.relevant_link || [{}],
    inputs: [
      {
        type: "TEXT",
        label: "Relevant Link Name",
        id: "link_name",
        name: "linkName",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Sort Order",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Link",
        id: "link",
        name: "link",
        placeholder: "Link",
        size: 5,
        inputIcon: "link",
        handleInputIcon: redirectToLink,
      },
    ],
  },
  {
    groupName: "Election Files",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "election_files",
    value: initialValues?.election_files || [{}],
    inputs: [
      {
        type: "TEXT",
        id: "filename",
        name: "fileName",
        placeholder: "File Name",
        size: 3,
      },
      {
        type: "TEXT",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "FILE_UPLOAD",
        id: "file",
        name: "file",
        placeholder: "File",
        handleSelfUpload: uploadElectionFiles,
        filePrefix: BASE_URL + "app-source/election-files/",
        size: 3,
      },
      {
        type: "TEXT",
        id: "remarks",
        name: "remarks",
        placeholder: "Remarks",
        size: 3,
      },
    ],
  },
];
