import React from "react";
import ACDashboardComponent from "../dashboard/dashboard.component";
import MasterACChartComponent from "./chart.component";

const MasterAcSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <MasterACChartComponent />}
      {type == "DASHBOARD" && <ACDashboardComponent />}
    </>
  );
};

export default MasterAcSwapComponent;
