import React from "react";
import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import { useSelector } from "react-redux";

import darkTheme from "prism-react-renderer/themes/vsDark";
import lightTheme from "prism-react-renderer/themes/vsLight";

const darkThemeStyle = {
  codeTheme: darkTheme,
  root: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 16,
    borderRadius: 5,
    ...darkTheme.plain,
    backgroundColor: "inherit",
  },
  backgroundColor:
    "linear-gradient(to left bottom, rgb(55, 65, 81), rgb(17, 24, 39), rgb(0, 0, 0))",
};

const lightThemeStyle = {
  codeTheme: lightTheme,
  root: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 16,
    borderRadius: 5,
    ...lightTheme.plain,
    backgroundColor: "inherit",
  },
  backgroundColor:
    "linear-gradient(to right bottom, rgb(243, 244, 246), rgb(209, 213, 219))",
};

const highlight = (code, theme) => (
  <Highlight
    {...defaultProps}
    theme={
      theme === "dark" ? darkThemeStyle.codeTheme : lightThemeStyle.codeTheme
    }
    code={code}
    language="tsx"
  >
    {({ tokens, getLineProps, getTokenProps }) => (
      <>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </>
    )}
  </Highlight>
);

const FormInputCodeComponent = ({
  id,
  name,
  label,
  error,
  value,
  onChange,
  isRequired,
  isVertical,
}) => {
  const { theme } = useSelector((state) => state.app);
  const handleChange = (updatedValue) => {
    if (onChange) onChange(updatedValue);
  };

  return (
    <div className="col-12">
      <div className={`${isVertical ? "" : "mb-1 row "}`}>
        {label && (
          <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
            <label className="col-form-label">
              {label}
              {isRequired && <> * </>}
            </label>
          </div>
        )}

        <div className={isVertical ? "col-sm-12" : "col-sm-10"}>
          <div className="border" style={{ borderRadius: 5 }}>
            <Editor
              value={value}
              onValueChange={(value) => handleChange(value)}
              highlight={(value) => highlight(value, theme)}
              padding={10}
              style={
                theme === "dark" ? darkThemeStyle.root : lightThemeStyle.root
              }
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInputCodeComponent;
