import { selectThemeColors } from "@utils/common";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Select from "react-select";

const FormInputAutoCompleteComponent = ({
  label,
  id,
  name,
  value,
  placeholder,
  error,
  onChange,
  onKeyDown,
  options,
  isMulti = false,
  isLoading = false,
  isVertical = false,
}) => {
  const ref = useRef();
  const handleChange = (value) => {
    if (onChange) onChange(isMulti ? value : value.value);
  };
  const handleKeyDown = (e) => {
    if (onKeyDown) onKeyDown({ keyCode: e.keyCode, ref });
  };
  const [formattedOptions, setFormattedOptions] = useState([]);
  const handleInputChange = (e) => {
    // // console.log(e);
  };
  const handleOptions = (options) => {
    // // console.log({ options });
    const formatedOptionsList = options.map((data) => {
      return {
        label: data.name,
        value: data.value,
      };
    });
    setFormattedOptions(formatedOptionsList);
  };

  let selectedValue = isMulti
    ? value
    : formattedOptions.find((option) => option.value === value);

  // // console.log({ selectedValue, value });

  useEffect(() => {
    // ref.current.focus();
    // // console.log(ref.current);
    handleOptions(options);
  }, [options]);
  // // console.log({ selectedValue });
  return (
    <div className="col-12">
      <div className={isVertical ? "" : "mb-1 row"}>
        {label && (
          <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
            <label className="col-form-label"> {label} </label>
          </div>
        )}

        <div className={isVertical ? "col-sm-12" : "col-sm-9"}>
          <Select
            ref={ref}
            id={id}
            name={name}
            className={`react-select ${error ? "is-invalid" : ""}`}
            classNamePrefix="select"
            theme={selectThemeColors}
            value={selectedValue || ""}
            isMulti={isMulti}
            isSearchable
            isLoading={isLoading}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInputChange={handleInputChange}
            options={formattedOptions}
            placeholder={placeholder}
            required
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default FormInputAutoCompleteComponent;
