import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../users-list/model/users-list.constants";
import UsersCreateTemplateComponent from "./users-create-template.component";
import { getUserFormInputs } from "@screens/catalog/users/model/users.modal";
import {
  createUsersApi,
  getAllRoles,
} from "@screens/catalog/users/users-form.component.service";
import { uploadUserImage } from "@screens/user-administration/users/users-list/users-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { getAllClients } from "@screens/user-administration/client/client-list/client-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  USER_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const UsersCreateComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const pcId = new URLSearchParams(location.search).get("pcId");
  const pcName = new URLSearchParams(location.search).get("pcName");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const { currentElection } = useSelector((state) => state.election);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog-urban/pc/user?pcId=${pcId}&electionId=${electionId}&pcName=${pcName}`
    );
  };

  const handleUsersSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct roles payload
    let usersPayload = {};
    for (let i = 0; i < values.length; i++) {
      usersPayload[values[i].name] = values[i].value;
    }
    usersPayload["election_id"] = currentElection.id;
    usersPayload["pc_id"] = pcId;

    // INFO : call create api
    const usersResponse = await createUsersApi(usersPayload);

    // INFO : check api response and show relevant alert message
    if (usersResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/catalog-urban/pc/user?pcId=${pcId}&electionId=${electionId}&pcName=${pcName}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: usersResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const rolesListResponse = await getAllRoles();
    console.log({ rolesListResponse });
    if (rolesListResponse.status) {
      const formatedRoleList = rolesListResponse.data.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });

      const clientDataResponse = await getAllClients();
      console.log({ clientDataResponse });
      if (clientDataResponse.status && clientDataResponse.status) {
        const formatedClientList = clientDataResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const formInputData = getUserFormInputs(
          formatedRoleList,
          formatedClientList,
          {},
          handleUserImageFileUpload
        );
        console.log({ formatedRoleList, formInputData });
        setFormInputs(formInputData);
      }
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleUserImageFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadUserResponse = await uploadUserImage(file[0]);
    console.log({ uploadUserResponse });
    if (uploadUserResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value =
        BASE_URL + "app-source/user-image/" + uploadUserResponse.data;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
    pcId,
    electionId,
    pcName,
  };

  const templateEvents = {
    handleUsersSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UsersCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UsersCreateComponent;
