import { useHistory, useLocation } from "react-router-dom";

function BreadcrumbComponent({ breadCrumbs }) {
  const { pathname } = useLocation();
  const history = useHistory();

  const redirect = (url) => {
    history.push(url);
  };
  return (
    <div className="row breadcrumbs-top">
      <div className="col-12">
        <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
          <nav className="" aria-label="breadcrumb">
            {breadCrumbs ? (
              <ol className="breadcrumb">
                {breadCrumbs.map((item) => (
                  <>
                    {pathname === `${item.menu_url}` ? (
                      <li className="breadcrumb-item">{item.name}</li>
                    ) : (
                      <li className="breadcrumb-item link-primary">
                        <a onClick={() => redirect(item.menu_url)}>
                          {item.name}
                        </a>
                      </li>
                    )}
                  </>
                ))}
              </ol>
            ) : (
              ""
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbComponent;
