import { createAction, createReducer } from "@reduxjs/toolkit";

export const setCurrentElection = createAction("election/setCurrentElection");
export const setByElectionPCList = createAction("election/setByElectionPCList");
export const setByElectionACList = createAction("election/setByElectionACList");

export const electionReducer = createReducer(
  {
    currentElection: null,
    byElectionPcList: [],
  },
  {
    [setCurrentElection]: (state, action) =>
      (state = { ...state, currentElection: action.payload }),
    [setByElectionPCList]: (state, action) =>
      (state = { ...state, byElectionPcList: action.payload }),
    [setByElectionACList]: (state, action) =>
      (state = { ...state, byElectionAcList: action.payload }),
  }
);
