import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../candidate-list/model/candidate-list.constants";
import { getFileUploadInputs } from "../model/file-upload.modal";
import CandidateFileUploadTemplateComponent from "./candidate-file-template.component";
import {
  readFile,
  uploadFile,
} from "@screens/catalog-rural/candidate-voter/candidate-service";

const {
  AC_VOTER_ALERTS: { FILE_UPLOAD_ALERT },
  ALERT_TYPES: { SUCCESS },
} = MESSAGES;

const CandidateFileUploadComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const villagePanchayatWardId = new URLSearchParams(location.search).get(
    "villagePanchayatWardId"
  );
  const electionId = new URLSearchParams(location.search).get("electionId");
  const villagePanchayatWardName = new URLSearchParams(location.search).get(
    "villagePanchayatWardName"
  );
  const stateGovtId = new URLSearchParams(location.search).get("stateGovtId");

  const handleFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setInitialFormInputs(copyFormInputs);

    const uploadResponse = await uploadFile(file[0]);
    console.log({ uploadResponse });
    if (uploadResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value = uploadResponse.data;

      setInitialFormInputs(copyFormInputs2);
    } else {
    }
  };

  const [initialFormInputs, setInitialFormInputs] = useState(
    getFileUploadInputs(handleFileUpload)
  );

  const handleFormSubmit = async ({ values, errors }) => {
    console.log({ values, errors });

    if (errors.length > 0) {
      alert("file is mandatory");
      return;
    }

    let payload = {};

    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    payload["ward_id"] = villagePanchayatWardId;
    payload["rural_type"] = "VILLAGE PANCHAYAT WARD";

    console.log({ payload });
    const response = await readFile(
      electionId,
      payload,
      villagePanchayatWardId
    );
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: FILE_UPLOAD_ALERT }));
      history.push(
        `/catalog-rural/village-panchayat-ward/candidate?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}&stateGovtId=${stateGovtId}`
      );
    } else {
      alert(response.message);
    }
  };

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: initialFormInputs,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
    stateGovtId,
  };

  const templateEvents = {
    handleTriggerClick,
    handleFormSubmit,
  };

  return menuAccess ? (
    <CandidateFileUploadTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default CandidateFileUploadComponent;
