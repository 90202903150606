import DatabaseBackupListComponent from "@screens/system/database-backup/database-backup-list/database-backup-list.component";

const databaseBackupRoutes = [
  {
    name: "Database Backup",
    icon: "hard-drive",
    path: "/system/database-backup",
    component: DatabaseBackupListComponent,
  },
];

export default databaseBackupRoutes;
