import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

const ActionContent = ({ handleCreateClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleCreateClick}
    >
      <FeatherIcon icon="plus" size={17} />
    </button>
  );
};

function UsersListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    stateId,
    electionId,
    stateName,
    tableParams,
  },
  templateEvents: {
    getUsersData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/user/edit?stateId=${stateId}&electionId=${electionId}&stateName=${stateName}`;

  console.log({ breadCrumbs });

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Users"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: stateName + " - User(s)", menu_url: null },
        ]}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getUsersData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        tableParams={tableParams}
      />
    </PageWrapperComponent>
  );
}

export default UsersListTemplateComponent;
