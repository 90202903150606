import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "@constants/api.constants";
import PanchayatUnionSwapComponent from "@screens/catalog-rural/panchayat-union/panchayat-union-list/components/panchayat-union-swap.component";
import ElectedMemberComponent from "@src/components/organisms/elected-member/elected-member.component";
import ListComponent from "@atoms/list/list.component";
import DetailPanelComponent from "@molecules/detail-panel/detail-panel.component";
import ModalComponent from "@atoms/modal/modal.component";
import AccordionComponent from "@molecules/accordion/accordion.component";
import RuralVoterListContentComponent from "@src/components/organisms/voter-list-content/rural-ward-voter-list-content.component";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";

function PanchayatUnionWardListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableControls,
    swapContentType,
    tableParams,
    tableActions,
    openDetailPanel,
    basicData,
    electedMember,
    rowData,
    currentElection,
    openTrashModal,
    password,
    tableConfig,
  },
  templateEvents: {
    getData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    setOpenDetailPanel,
    toggleDetailPanel,
    handleChange,
    confirmTrashData,
    cancelTrashData,
    handleTrashVotersClick,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;
  const detailedPanelFormat = ({
    electedMember,
    basicData,
    rowData,
    toggleDetailPanel,
    handleTrashVotersClick,
  }) => {
    {
      return [
        {
          id: 1,
          heading: "Elected Member",
          expanded: true,
          content: electedMember ? (
            <ElectedMemberComponent memberData={electedMember} />
          ) : (
            <> </>
          ),
        },
        {
          id: 2,
          heading: "Basic Details",
          content: <ListComponent items={basicData} />,
        },
        {
          id: 3,
          heading: "Voter List",
          content: (
            <RuralVoterListContentComponent
              from="Panchayat Union Ward"
              rowData={rowData}
              type={"PANCHAYAT UNION WARD"}
              toggleDetailPanel={toggleDetailPanel}
              handleTrashVotersClick={handleTrashVotersClick}
            />
          ),
        },
      ];
    }
  };

  const ActionContent = ({ handleCreateClick }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={handleCreateClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="plus" size={17} />
          &nbsp;
        </button>
      </>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Panchayat Union Wards"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        handleDownload={handleDownload}
        tableControls={tableControls}
        tableParams={tableParams}
        tableActions={tableActions}
        tableConfig={tableConfig}
        swapContent={
          swapContentType ? (
            <PanchayatUnionSwapComponent type={swapContentType} />
          ) : null
        }
      />
      <DetailPanelComponent
        open={openDetailPanel}
        toggleDrawer={setOpenDetailPanel}
        drawerWidth={40}
      >
        <AccordionComponent
          items={detailedPanelFormat({
            electedMember,
            basicData,
            rowData,
            toggleDetailPanel,
            currentElection,
            handleTrashVotersClick,
          })}
        />
      </DetailPanelComponent>
      <ModalComponent
        open={openTrashModal}
        title={"Delete !"}
        message={
          "Are you sure to delete the voter data from - " + rowData?.name + "?"
        }
        primaryText={"Delete"}
        secondaryText={"Cancel"}
        content={
          <div className="test">
            <FormInputTextComponent
              label={"Password"}
              name={"password"}
              id={"password"}
              variant={"outlined"}
              type={"password"}
              value={password}
              onChange={handleChange}
              size={"small"}
            />
          </div>
        }
        primaryAction={confirmTrashData}
        secondaryAction={cancelTrashData}
      />
    </PageWrapperComponent>
  );
}

export default PanchayatUnionWardListTemplateComponent;
