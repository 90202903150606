import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadlogo } from "@screens/political-party/political-party-list/political-party-list.component.service";
const { CANDIDATE_STATUS, GENDER } = APP;

export const getCandidateFormInputs = ({
  initialValues,
  formatedPoliticalPartyList,
  formatedAcList,
  formatedSymbolsList,
  handleCandidateFileUpload,
  handleAffidavitFileUpload,
}) => [
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Candidate name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Candidate Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Candidate Name is required !",
      },
    ],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Address",
    id: "residentialAddress",
    name: "residential_address",
    value: initialValues?.residentialAddress || "",
    placeholder: "Enter the Address",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "SELECT",
    label: "Gender",
    id: "gender",
    name: "gender",
    options: GENDER,
    value: initialValues?.gender || "MALE",
    placeholder: "Select Gender",
  },

  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Age",
    id: "age",
    name: "age",
    value: initialValues?.age || "",
    placeholder: "Enter the Age",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Education",
    id: "education",
    name: "education",
    value: initialValues?.education || "",
    placeholder: "Enter the Education",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Designation",
    id: "designation",
    name: "designation",
    value: initialValues?.designation || "",
    placeholder: "Enter the Designation",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "PAN Card No",
    id: "panNo",
    name: "pan_no",
    value: initialValues?.panNo || "",
    placeholder: "Enter the Pan No",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Asset",
    id: "asset",
    name: "asset",
    value: initialValues?.asset || "",
    placeholder: "Enter the asset",
    validations: [],
  },

  {
    groupName: "Personal Profile",
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "FILE_UPLOAD",
    name: "candidate_image",
    id: "candidateImage",
    label: "Candidate Image",
    loading: false,
    multipleUpload: false,
    value: initialValues?.candidateImage || "",
    handleUpload: handleCandidateFileUpload,
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Official Number",
    id: "officialMobile",
    name: "official_mobile",
    value: initialValues?.officialMobile || "",
    placeholder: "Enter the Official Mobile No",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Personal Mobile",
    id: "personalMobile",
    name: "personal_mobile",
    value: initialValues?.personalMobile || "",
    placeholder: "Enter the Mobile Number",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "WhatsApp Number 1",
    id: "whatsappNumber",
    name: "whatsapp_number",
    value: initialValues?.whatsappNumber || "",
    placeholder: "Enter the WhatsApp Number 1",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "WhatsApp Number 2",
    id: "whatsappNumber 2",
    name: "whatsapp_number2",
    value: initialValues?.whatsappNumber2 || "",
    placeholder: "Enter the WhatsApp Number 2",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Email Id",
    id: "emailId",
    name: "email_id",
    value: initialValues?.emailId || "",
    placeholder: "Enter the Email ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Facebook",
    id: "facebook",
    name: "facebook",
    value: initialValues?.facebook || "",
    placeholder: "Enter the Facebook ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Instagram",
    id: "instagram",
    name: "instagram",
    value: initialValues?.instagram || "",
    placeholder: "Enter the Instagram ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "LinkedIn",
    id: "linkedin",
    name: "linkedin",
    value: initialValues?.linkedin || "",
    placeholder: "Enter the LinkedIn ID",
    validations: [],
  },

  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Twitter",
    id: "twitter",
    name: "twitter",
    value: initialValues?.twitter || "",
    placeholder: "Enter the Twitter ID",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "RADIO",
    label: "Party type",
    id: "partyType",
    name: "party_type",
    onChange: (value, formInputs) => {
      console.log({ value, formInputs });

      const partyNameIndex = formInputs.findIndex(
        (option) => option.label === "Party Name"
      );
      const symbolIndex = formInputs.findIndex(
        (option) => option.label === "Symbol (Old)"
      );

      if (value === "INDEPENDENT") formInputs[symbolIndex].isHide = false;
      else formInputs[symbolIndex].isHide = true;

      if (value === "INDEPENDENT") formInputs[partyNameIndex].isHide = true;
      else formInputs[partyNameIndex].isHide = false;

      return formInputs;
    },
    options: [
      { name: "POLITICAL", value: "POLITICAL" },
      { name: "INDEPENDENT", value: "INDEPENDENT" },
    ],
    value: initialValues?.partyType || "POLITICAL",
    validations: [
      {
        type: "REQUIRED",
        message: "Party Type is required !",
      },
    ],
  },

  {
    groupName: "Political Profile",
    type: "FILE_UPLOAD",
    label: "Symbol (Old)",
    id: "flag",
    isHide: initialValues?.partyType === "INDEPENDENT" ? false : true,
    name: "symbol",
    value: initialValues?.symbol || "",
    placeholder: "Please select the symbol",
    validations: [],
    handleSelfUpload: uploadlogo,
    filePrefix: BASE_URL + "app-source/political-party/",
  },

  {
    groupName: "Political Profile",
    type: "AUTOCOMPLETE",
    label: "Party Name",
    id: "partyName",
    isHide: initialValues?.partyType === "INDEPENDENT" ? true : false,
    name: "party_name",
    options: formatedPoliticalPartyList,
    value: initialValues?.partyName || "",
    placeholder: "Select the Party Name",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "AUTOCOMPLETE",
    label: "Symbol",
    id: "symbolId",
    name: "symbol_id",
    options: formatedSymbolsList,
    value: initialValues?.symbolId || "",
    placeholder: "Select the Symbol",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Epic ID",
    id: "epicId",
    name: "epic_id",
    value: initialValues?.epicId || "",
    placeholder: "Enter the Epic ID",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "SELECT",
    label: "AC No",
    id: "acNo",
    name: "ac_no",
    options: formatedAcList,
    value: initialValues?.acNo || "",
    placeholder: "Enter the AC No",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Part No",
    id: "partNo",
    name: "part_no",
    value: initialValues?.partNo || "",
    placeholder: "Enter the Part No",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Serial Number",
    id: "serialNo",
    name: "serial_no",
    value: initialValues?.serialNo || "",
    placeholder: "Enter the Serial Number",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "RADIO",
    label: "Pending Criminal Case",
    id: "criminalCase",
    name: "criminal_case",
    options: [
      { name: "YES", value: "YES" },
      { name: "NO", value: "NO" },
    ],
    value: initialValues?.criminalCase || "NO",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "SELECT",
    label: "Candidate Status",
    id: "status",
    name: "status",
    options: CANDIDATE_STATUS,
    value: initialValues?.status || "APPLIED",
    placeholder: "Select status",
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Total Votes",
    id: "totalVotes",
    name: "total_votes",
    value: initialValues?.totalVotes || "",
    placeholder: "Enter the Total Votes",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "No.of.Affidavit",
    id: "totalAffidavit",
    name: "total_affidavit",
    value: initialValues?.totalAffidavit || 1,
    placeholder: "Enter the No.of.Affidavit",
    validations: [],
  },

  {
    groupName: "Political Profile",
    hideGroupName: true,
    type: "FILE_UPLOAD",
    name: "affidavit",
    id: "affidavit",
    label: "Affidavit",
    loading: false,
    value: initialValues?.affidavit || "",
    handleUpload: handleAffidavitFileUpload,
    validations: [],
  },
];

export const getCandidateEditFormInputs = ({
  initialValues,
  formatedPoliticalPartyList,
  formatedAcList,
  formatedSymbolsList,
  handleCandidateFileUpload,
  handleAffidavitFileUpload,
}) => [
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Candidate ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Candidate ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Candidate ID is required !",
      },
    ],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Candidate name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Candidate Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Candidate Name is required !",
      },
    ],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Address",
    id: "residentialAddress",
    name: "residential_address",
    value: initialValues?.residentialAddress || "",
    placeholder: "Enter the Address",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "SELECT",
    label: "Gender",
    id: "gender",
    name: "gender",
    options: GENDER,
    value: initialValues?.gender || "MALE",
    placeholder: "Select Gender",
  },

  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Age",
    id: "age",
    name: "age",
    value: initialValues?.age || "",
    placeholder: "Enter the Age",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Education",
    id: "education",
    name: "education",
    value: initialValues?.education || "",
    placeholder: "Enter the Education",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Designation",
    id: "designation",
    name: "designation",
    value: initialValues?.designation || "",
    placeholder: "Enter the Designation",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "PAN Card No",
    id: "panNo",
    name: "pan_no",
    value: initialValues?.panNo || "",
    placeholder: "Enter the Pan No",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "TEXT",
    label: "Asset",
    id: "asset",
    name: "asset",
    value: initialValues?.asset || "",
    placeholder: "Enter the asset",
    validations: [],
  },

  {
    groupName: "Personal Profile",
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Personal Profile",
    type: "FILE_UPLOAD",
    name: "candidate_image",
    id: "candidateImage",
    label: "Candidate Image",
    loading: false,
    multipleUpload: false,
    value: initialValues?.candidateImage || "",
    handleUpload: handleCandidateFileUpload,
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Official Number",
    id: "officialMobile",
    name: "official_mobile",
    value: initialValues?.officialMobile || "",
    placeholder: "Enter the Official Mobile No",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Personal Mobile",
    id: "personalMobile",
    name: "personal_mobile",
    value: initialValues?.personalMobile || "",
    placeholder: "Enter the Mobile Number",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "WhatsApp Number 1",
    id: "whatsappNumber",
    name: "whatsapp_number",
    value: initialValues?.whatsappNumber || "",
    placeholder: "Enter the WhatsApp Number 1",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "WhatsApp Number 2",
    id: "whatsappNumber 2",
    name: "whatsapp_number2",
    value: initialValues?.whatsappNumber2 || "",
    placeholder: "Enter the WhatsApp Number 2",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Email Id",
    id: "emailId",
    name: "email_id",
    value: initialValues?.emailId || "",
    placeholder: "Enter the Email ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Facebook",
    id: "facebook",
    name: "facebook",
    value: initialValues?.facebook || "",
    placeholder: "Enter the Facebook ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Instagram",
    id: "instagram",
    name: "instagram",
    value: initialValues?.instagram || "",
    placeholder: "Enter the Instagram ID",
    validations: [],
  },
  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "LinkedIn",
    id: "linkedin",
    name: "linkedin",
    value: initialValues?.linkedin || "",
    placeholder: "Enter the LinkedIn ID",
    validations: [],
  },

  {
    groupName: "Contact Details",
    type: "TEXT",
    label: "Twitter",
    id: "twitter",
    name: "twitter",
    value: initialValues?.twitter || "",
    placeholder: "Enter the Twitter ID",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "RADIO",
    label: "Party type",
    id: "partyType",
    name: "party_type",
    onChange: (value, formInputs) => {
      console.log({ value, formInputs });

      const partyNameIndex = formInputs.findIndex(
        (option) => option.label === "Party Name"
      );
      const symbolIndex = formInputs.findIndex(
        (option) => option.label === "Symbol (Old)"
      );

      if (value === "INDEPENDENT") formInputs[symbolIndex].isHide = false;
      else formInputs[symbolIndex].isHide = true;

      if (value === "INDEPENDENT") formInputs[partyNameIndex].isHide = true;
      else formInputs[partyNameIndex].isHide = false;

      return formInputs;
    },
    options: [
      { name: "POLITICAL", value: "POLITICAL" },
      { name: "INDEPENDENT", value: "INDEPENDENT" },
    ],
    value: initialValues?.partyType || "POLITICAL",
    validations: [
      {
        type: "REQUIRED",
        message: "Party Type is required !",
      },
    ],
  },

  {
    groupName: "Political Profile",
    type: "FILE_UPLOAD",
    label: "Symbol (Old)",
    id: "flag",
    isHide: initialValues?.partyType === "INDEPENDENT" ? false : true,
    name: "symbol",
    value: initialValues?.symbol || "",
    placeholder: "Please select the symbol",
    validations: [],
    handleSelfUpload: uploadlogo,
    filePrefix: BASE_URL + "app-source/political-party/",
  },

  {
    groupName: "Political Profile",
    type: "AUTOCOMPLETE",
    label: "Party Name",
    id: "partyName",
    isHide: initialValues?.partyType === "INDEPENDENT" ? true : false,
    name: "party_name",
    options: formatedPoliticalPartyList,
    value: initialValues?.partyName || "",
    placeholder: "Select the Party Name",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "AUTOCOMPLETE",
    label: "Symbol",
    id: "symbolId",
    name: "symbol_id",
    options: formatedSymbolsList,
    value: initialValues?.symbolId || "",
    placeholder: "Select the Symbol",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Epic ID",
    id: "epicId",
    name: "epic_id",
    value: initialValues?.epicId || "",
    placeholder: "Enter the Epic ID",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "SELECT",
    label: "AC No",
    id: "acNo",
    name: "ac_no",
    options: formatedAcList,
    value: initialValues?.acNo || "",
    placeholder: "Enter the AC No",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Part No",
    id: "partNo",
    name: "part_no",
    value: initialValues?.partNo || "",
    placeholder: "Enter the Part No",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Serial Number",
    id: "serialNo",
    name: "serial_no",
    value: initialValues?.serialNo || "",
    placeholder: "Enter the Serial Number",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "RADIO",
    label: "Pending Criminal Case",
    id: "criminalCase",
    name: "criminal_case",
    options: [
      { name: "YES", value: "YES" },
      { name: "NO", value: "NO" },
    ],
    value: initialValues?.criminalCase || "NO",
    validations: [],
  },
  {
    groupName: "Political Profile",
    type: "SELECT",
    label: "Candidate Status",
    id: "status",
    name: "status",
    options: CANDIDATE_STATUS,
    value: initialValues?.status || "APPLIED",
    placeholder: "Select status",
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "Total Votes",
    id: "totalVotes",
    name: "total_votes",
    value: initialValues?.totalVotes || "",
    placeholder: "Enter the Total Votes",
    validations: [],
  },

  {
    groupName: "Political Profile",
    type: "TEXT",
    label: "No.of.Affidavit",
    id: "totalAffidavit",
    name: "total_affidavit",
    value: initialValues?.totalAffidavit || 1,
    placeholder: "Enter the No.of.Affidavit",
    validations: [],
  },

  {
    groupName: "Political Profile",
    hideGroupName: true,
    type: "FILE_UPLOAD",
    name: "affidavit",
    id: "affidavit",
    label: "Affidavit",
    loading: false,
    value: initialValues?.affidavit || "",
    handleUpload: handleAffidavitFileUpload,
    validations: [],
  },
];
