import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

function SentSmsListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
  },
  templateEvents: { getSentSmsData, deleteRecords, submitFilter },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  console.log({ filters });

  return (
    <PageWrapperComponent>
      <PageHeadComponent title={"Sent SMS List"} breadCrumbs={breadCrumbs} />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getSentSmsData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        disableActions
      />
    </PageWrapperComponent>
  );
}

export default SentSmsListTemplateComponent;
