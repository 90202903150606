import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadFile, uploadImageFile } from "@utils/upload.util";
const { RESERVATION } = APP;

export const getACFormInputs = ({
  phase,
  states,
  districts,
  pcs,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "AC ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the AC ID",
    validations: [
      {
        type: "REQUIRED",
        message: "AC ID is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "PC Name",
    id: "pcId",
    name: "pc_id",
    options: pcs,
    value: initialValues?.pcId || "",
    placeholder: "Select PC",
    validations: [
      {
        type: "REQUIRED",
        message: "PC Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "AC Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the AC Name",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Phase",
    id: "phase",
    name: "phase",
    options: phase,
    value: initialValues?.phase || "",
    placeholder: "Select Phase",
    validations: [
      {
        type: "REQUIRED",
        message: "Phase is required !",
      },
    ],
  },

  {
    type: "FILE_UPLOAD",
    label: "Ballot PDF",
    id: "ballotPdf",
    name: "ballot_pdf",
    placeholder: "Ballot PDF",
    handleSelfUpload: uploadFile,
    filePrefix: BASE_URL + "app-source/ballot-pdf/",
    value: initialValues?.ballotPdf || "",
  },
  {
    type: "FILE_UPLOAD",
    label: "Ballot Image",
    id: "ballotImage",
    name: "ballot_image",
    placeholder: "Ballot Image",
    handleSelfUpload: uploadImageFile,
    filePrefix: BASE_URL + "app-source/ballot-images/",
    value: initialValues?.ballotImage || "",
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
