import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandDrawerAction,
  resetUserTokenAction,
  handleMenuCollapsed,
  setModulesAction,
} from "@reducers/app.reducer";
import { setAllMenuList, setMenuListAction } from "@reducers/menu.reducer";
import { updateMenuList } from "@reducers/menu.reducer";
import { getAppModules, sideMenuService } from "./sidemenu.component.service";
import SideMenuTemplateComponent from "./sidemenu-template.component";
import { useState } from "react";
import { formatElectionMenus } from "@utils/menu-auth";

function SideMenuComponent({ setMenuVisibility }) {
  const { theme, expandDrawer, menuCollapsed, modules, currentRoleId } =
    useSelector((state) => state?.app);
  const { currentElection } = useSelector((state) => state.election);
  const { menuList } = useSelector((state) => state?.menu);
  const [moduleList, setModuleList] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [previousMenu, setPrevioudMenu] = useState({
    previousModuleId: null,
    previousMenuId: null,
  });

  const handleShowDrawer = () => {
    dispatch(setExpandDrawerAction(true));
  };

  const handleHideDrawer = () => {
    dispatch(setExpandDrawerAction(false));
  };

  const handleOpenSubMenu = (moduleIndex, index) => {
    let copyModules = [...moduleList];

    console.log({
      moduleIndex,
      index,
      previousMenu,
      moduleList,
      status: copyModules[moduleIndex].menus[index].subMenuOpen,
    });

    if (previousMenu.previousModuleId || previousMenu.previousMenuId) {
      // console.error("coming ghere");
      copyModules[previousMenu.previousModuleId].menus[
        previousMenu.previousMenuId
      ].subMenuOpen = false;
    }

    copyModules[moduleIndex].menus[index].subMenuOpen = copyModules[moduleIndex]
      .menus[index].subMenuOpen
      ? false
      : true;

    console.log({ copyModules });

    setPrevioudMenu({ previousModuleId: moduleIndex, previousMenuId: index });

    setModuleList(copyModules);
    // dispatch(updateMenuList({ index, status: !menuList[index].subMenuOpen }));
  };

  const setMenuCollapsed = (value) => {
    dispatch(handleMenuCollapsed(value));
  };

  const currentActiveGroup = (menu, currentPath, index) => {
    if (menu && menu?.sub_menus?.length > 0) {
      const currentRoute = menu.sub_menus.find(
        (item) => currentPath === `/${item.menu_url}`
      );
      if (currentRoute) return true;
      return false;
    }
  };

  const formatModuleMenu = ({ modules, menus }) => {
    console.log({ modules, menus });
    const moduleArray = [];
    for (let i = 0; i < modules?.length; i++) {
      const filteredMenus = menus.filter(
        (menu) => menu.module_id === modules[i].id
      );
      const moduleData = {
        ...modules[i],
        menus: filteredMenus.map((menu) => {
          return { ...menu, subMenuOpen: false };
        }),
      };
      if (filteredMenus.length > 0) moduleArray.push(moduleData);
    }
    return moduleArray;
  };

  const onLoad = async () => {
    const modules = await getAppModules();
    const menus = await sideMenuService.getMenus(currentRoleId);
    if (menus.status) {
      const menuListData = formatElectionMenus(menus.data, currentElection);

      console.log({ menuListData });

      dispatch(setMenuListAction(menuListData));
      dispatch(setAllMenuList(menus.data));
      dispatch(setModulesAction(modules.data));
    } else if (
      menus.message === "Token expired" ||
      menus.message === "Invalid token"
    ) {
      dispatch(resetUserTokenAction());
      history.push("/");
    }
  };

  const redirectMainMenu = (menu) => {
    history.push("/" + menu.menu_url);
  };

  // useEffect(() => {
  //   dispatch(updateMenuList({ index: 0, status: false }));
  // }, [location]);

  const formatMenuData = () => {
    const formattedModuleMenus = formatModuleMenu({
      modules,
      menus: menuList,
    });
    setModuleList(formattedModuleMenus);
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (menuList?.length > 0) {
      formatMenuData();
    }
  }, [menuList]);

  return (
    <SideMenuTemplateComponent
      templateEvents={{
        handleShowDrawer,
        handleHideDrawer,
        setMenuCollapsed,
        handleOpenSubMenu,
        currentActiveGroup,
        setMenuVisibility,
        redirectMainMenu,
      }}
      templateModal={{
        theme,
        expandDrawer,
        menuList,
        moduleList,
        menuCollapsed,
      }}
    />
  );
}

export default SideMenuComponent;
