import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import { getPanchayatUnionFormInputs } from "../model/panchayat-union.modal";
import { editEntry } from "./panchayat-union-edit.component.service";
import PanchayatUnionEditTemplateComponent from "./panchayat-union-edit-template.component";
import { menuUrl } from "../panchayat-union-list/model/panchayat-union-list.constants";
import { getDistrictUnionByDistrictId } from "@screens/master-rural/district-union/district-union-list/district-union-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  PANCHAYAT_UNION_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const PanchayatUnionEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: response.message,
        })
      );
    }
  };
  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[3].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };

  const onDistrictChange = async (value, inputs) => {
    const districtUnionResponse = await getDistrictUnionByDistrictId(value);
    if (districtUnionResponse.status) {
      const formattedDistrictUnionList = districtUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[4].options = formattedDistrictUnionList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const districtResponse = await getDistrictByStateId(
        location?.state?.row?.state_id || null
      );
      if (districtResponse.status) {
        const formattedDistrictList = districtResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const districtUnionResponse = await getDistrictUnionByDistrictId(
          location?.state?.row?.district_id || null
        );
        if (districtUnionResponse.status) {
          const formattedDistrictUnionList = districtUnionResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
              };
            }
          );

          const formInputData = getPanchayatUnionFormInputs({
            states: formatedStateList,
            districts: formattedDistrictList,
            districtUnions: formattedDistrictUnionList,
            initialValues: {
              govtId: location?.state?.row?.govt_id || "",
              stateId: location?.state?.row?.state_id || "",
              districtId: location?.state?.row?.district_id || "",
              districtUnionId: location?.state?.row?.district_union_id || "",
              name: location?.state?.row?.name || "",
              type: location?.state?.row?.type,
              regionalName: location?.state?.row?.regional_name || "",
              reservation: location?.state?.row?.reservation || "",
              totalNoOfWards: location?.state?.row?.total_no_of_wards || "",
              remarks: location?.state?.row?.remarks || "",
              manager: location?.state?.row?.manager || "",
            },
            onStateChange,
            onDistrictChange,
          });
          setFormInputs(formInputData);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <PanchayatUnionEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default PanchayatUnionEditComponent;
