import { END_POINT } from "@constants/api.constants";

const { SENT_SMS_STATUS_TOGGLE } = END_POINT;

const tableTitle = "Sent SMS List";
const keyColumn = "id";
const menuUrl = "reports/sent-sms";
const tableColumn = [
  {
    id: "mobile",
    numeric: false,
    disablePadding: true,
    label: "Mobile number",
  },
  { id: "content", numeric: false, disablePadding: true, label: "Content" },
  { id: "template", numeric: false, disablePadding: true, label: "Template" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isToggleChip: true,
    toggleAction: SENT_SMS_STATUS_TOGGLE,
    primaryKey: keyColumn,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];
const breadCrumbs = [
  { name: "Reports", menu_url: null },
  { name: "Sent SMS", menu_url: "/reports/sent-sms" },
];

const filters = [
  {
    name: "mobile",
    id: "mobile",
    label: "Mobile",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
