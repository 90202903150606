import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getFormInputs } from "../model/bulk-whatsapp.modal";
import { PRIMARY_KEY } from "@constants/app.constants";
import BulkWhatsAppEditTemplate from "./bulk-whatsapp-edit-template";
import { menuUrl } from "../bulk-whatsapp-list/model/bulk-whatsapp-list.constants";
import {
  editApi,
  getWatiTemplatesApi,
} from "../bulk-whatsapp-list/bulk-whatsapp-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BULK_WHATSAPP_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const BulkWhatsAppEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call edit api
    const response = await editApi(location?.state?.row.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getFormInputs({
      name: location?.state?.row?.name || "",
      template: location?.state?.row?.template || "",
      description: location?.state?.row?.description || "",
      status: location?.state?.row?.status || "",
      file: location?.state?.row?.file || "",
      templates,
    }),
  };

  const onLoad = async () => {
    const response = await getWatiTemplatesApi();
    if (response.status) {
      const formattedData = response.data
        .filter((template) => template.status === "APPROVED")
        .map((template) => {
          return {
            name: template.elementName,
            value: template.elementName,
            body: template.body,
          };
        });
      setTemplates(formattedData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <BulkWhatsAppEditTemplate
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default BulkWhatsAppEditComponent;
