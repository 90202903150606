import { APP_NAME } from "@constants/app.constants";

import CorporationCreateComponent from "@screens/catalog-urban/corporation/corporation-create/corporation-create.component";
import CorporationEditComponent from "@screens/catalog-urban/corporation/corporation-edit/corporation-edit.component";
import CorporationListComponent from "@screens/catalog-urban/corporation/corporation-list/corporation-list.component";

const catalogCorporationRoutes = [
  {
    name: "Corporation List",
    icon: "check-circle",
    path: "/catalog-urban/corporation",
    component: CorporationListComponent,
  },

  {
    name: `Create Corporation - ${APP_NAME}`,
    path: "/catalog-urban/corporation/create",
    component: CorporationCreateComponent,
  },

  {
    name: `Edit Corporation - ${APP_NAME}`,
    path: "/catalog-urban/corporation/edit",
    component: CorporationEditComponent,
  },
];

export default catalogCorporationRoutes;
