import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { END_POINT } from "@constants/api.constants";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CatalogRuralWardVoterSwapComponent from "./components/urban-ward-voter-swap.component";

function RuralWardVoterListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
    tableControls,
    swapContentType,
    tableParams,
  },
  templateEvents: {
    getData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
    handleBackClick,
  },
}) {
  const history = useHistory();
  const settingFormEdit = `${breadCrumbs[1].menu_url}/voter/edit?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`;
  const settingsFormUploadExcel = `${breadCrumbs[1].menu_url}/voter/upload?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`;
  const handleFormUploadClick = () => {
    history.push(settingsFormUploadExcel);
  };
  const { CATALOG_AC_VOTER_TEMPLATE_DOWNLOAD } = END_POINT;
  const { token } = useSelector((state) => state.app);
  const downloadFile = () => {
    console.log("download triggered");

    window.open(`${CATALOG_AC_VOTER_TEMPLATE_DOWNLOAD}?token=${token}`);
  };
  const ActionContent = ({ handleCreateClick, handleBackClick }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleFormUploadClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="upload" size={17} />
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={handleCreateClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="plus" size={17} />
          &nbsp;
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={downloadFile}
        >
          <FeatherIcon icon="download" size={17} />
          &nbsp;
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm ml-2"
          onClick={handleBackClick}
        >
          <FeatherIcon icon="arrow-left" size={17} />
        </button>
      </>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Voters"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: villagePanchayatWardName + " - Voter(s)", menu_url: null },
        ]}
        actionContent={
          <ActionContent
            handleCreateClick={handleCreateClick}
            handleBackClick={handleBackClick}
          />
        }
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        // handleDashboard={handleDashboard}
        //handleDownload={handleDownload}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <CatalogRuralWardVoterSwapComponent type={swapContentType} />
          ) : null
        }
        tableParams={tableParams}
      />
    </PageWrapperComponent>
  );
}

export default RuralWardVoterListTemplateComponent;
