import React from "react";
import FeatherIcon from "feather-icons-react";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";
import { getFileNameFromUrl, stringShortner } from "@utils/string.utils";

const previewStyle = {
  width: 30,
  height: 30,
  borderRadius: 4,
  marginRight: 13,
};

const FormInputFileUploadPreview = ({
  index,
  fileType,
  file,
  name,
  onDeleteClick,
  handleImagePreview,
}) => {
  return (
    <>
      <ToolTipComponent content={name}>
        {fileType === "image" ? (
          <a key={index} onClick={() => handleImagePreview(file)}>
            {stringShortner(getFileNameFromUrl(name), 16, 10)}
          </a>
        ) : (
          <a key={index} target={"__blank"} href={file}>
            {/* {fileType === "image" && <img src={file} style={previewStyle} />} */}

            {fileType === "pdf" && (
              <img
                src={require("@assets/images/pdf.png")}
                style={previewStyle}
              />
            )}

            {fileType === "csv" && (
              <img
                src={require("@assets/images/csv.png")}
                style={previewStyle}
              />
            )}

            {fileType === "xlsx" && (
              <img
                src={require("@assets/images/xlsx.png")}
                style={previewStyle}
              />
            )}
            {fileType === "xls" && (
              <img
                src={require("@assets/images/xls.png")}
                style={previewStyle}
              />
            )}
            {fileType === "xlsm" && (
              <img
                src={require("@assets/images/xlsm.png")}
                style={previewStyle}
              />
            )}
            {fileType === "xltx" && (
              <img
                src={require("@assets/images/xltx.png")}
                style={previewStyle}
              />
            )}

            {fileType === "doc" && (
              <img
                src={require("@assets/images/doc.png")}
                style={previewStyle}
              />
            )}
            {fileType === "docx" && (
              <img
                src={require("@assets/images/docx.png")}
                style={previewStyle}
              />
            )}

            {stringShortner(getFileNameFromUrl(name), 16, 10)}
          </a>
        )}
      </ToolTipComponent>
      <button
        type="button"
        style={{ padding: "0 5px 0 5px" }}
        className="wave-effect rounded-circle btn btn-icon btn-flat-danger btn-sm ml-2"
        onClick={() => onDeleteClick({ index, file })}
      >
        <FeatherIcon icon={"trash"} width={10} />
      </button>
    </>
  );
};

export default FormInputFileUploadPreview;
