import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  tableControls as rawControls,
} from "@screens/master-rural/district-union-ward/district-union-ward-list/model/district-union-ward-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import {
  deleteDataApi,
  getDataApi,
} from "./district-union-ward-list.component.service";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import DistrictPanchayatUnionWardListTemplateComponent from "./district-union-ward-list-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  DISTRICT_UNION_WARD_ALERTS: { DELETE_ALERT, REFRESH_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const DistrictPanchayatUnionWardListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { MASTER_DISTRICT_PANCHAYAT_UNION_WARD_EXPORT } = END_POINT;
  const [filterState, setFilterState] = useState(filters);

  console.log({ tableParams });

  const getData = async (params = null) => {
    const queryStr = getFilterQueryString(menuUrl, params);
    const districtPanchaytUnionData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (districtPanchaytUnionData.status) {
      console.log(districtPanchaytUnionData);
      setTableData(districtPanchaytUnionData.data);
      setTotalCount(districtPanchaytUnionData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${MASTER_DISTRICT_PANCHAYAT_UNION_WARD_EXPORT}?token=${token}${queryStr}`,
    });
    window.open(
      `${MASTER_DISTRICT_PANCHAYAT_UNION_WARD_EXPORT}?token=${token}${queryStr}`
    );
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "master-district-union",
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const handleDashbordClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "DASHBOARD" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      switch (control.name) {
        case "Chart":
          return {
            ...control,
            onClick: handleChartClick,
          };
        case "Refresh":
          return {
            ...control,
            onClick: handleRefreshClick,
          };
        case "Dashboard":
          return {
            ...control,
            onClick: handleDashbordClick,
          };
      }
    });
    setTableControls(updatedTableControls);

    const stateResponse = await getAllStates();

    if (stateResponse.status) {
      const formattedStates = stateResponse.data.map((state) => {
        return {
          name: state.name,
          value: state.id,
        };
      });

      const copyFilters = rawFilters({
        states: formattedStates,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <DistrictPanchayatUnionWardListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filterState,
        tableControls,
        swapContentType,
        tableParams,
      }}
      templateEvents={{
        getData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default DistrictPanchayatUnionWardListComponent;
