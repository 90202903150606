import React, { useState, useEffect } from "react";
import { setThemeAction } from "@reducers/app.reducer";
import { useSelector, useDispatch } from "react-redux";
import { resetUserTokenAction } from "@reducers/app.reducer";
import HeaderTemplateComponent from "./header-template.component";
import { useHistory, useLocation } from "react-router-dom";
import { setAlertMessage } from "@reducers/alert.reducer";
import { appRoutes } from "@src/routes";
import {
  updateNotificationListCount,
  getNotificationListCount,
} from "@screens/user-administration/notification/notification-list/notification-list.component.service";
import { setNotifications } from "@reducers/app.reducer";

const HeaderComponent = ({ setMenuVisibility }) => {
  const { theme, token, user, notifications } = useSelector(
    (state) => state?.app
  );
  const { currentElection } = useSelector((state) => state?.election);
  const [openProfile, setOpenProfile] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [openNotifications, setOpenNotifications] = useState(false);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const { alertMessage } = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const handleOpenProfile = () => {
    setOpenProfile(!openProfile);
  };

  const handleProfilePage = () => {
    history.push("/profile");
  };

  const handleThemeClick = () => {
    if (theme === "dark") {
      dispatch(setThemeAction("light"));
    } else {
      dispatch(setThemeAction("dark"));
    }
  };

  const themeScriptChange = () => {
    if (theme === "dark") {
      document.body.classList.remove("light-layout");
      document.body.classList.add("dark-layout");
    } else {
      document.body.classList.remove("dark-layout");
      document.body.classList.add("light-layout");
    }
  };

  const handleResetPassword = () => {
    console.log("coming----");
    setOpenResetPassword(!openResetPassword);
  };

  const logoutModal = () => {
    setLogoutOpen(true);
  };

  const logoutCancel = () => {
    setLogoutOpen(false);
  };

  const handleOpenNotifications = () => {
    setOpenNotifications(!openNotifications);
  };

  const confirmLogout = () => {
    dispatch(resetUserTokenAction());
    dispatch(setAlertMessage(null));
    history.push("/");
  };

  const checkTokenExists = () => {
    if (!token) {
      history.push("/");
    }
  };

  const updateNotificationCount = async (e, item) => {
    e.preventDefault();
    if (item.redirect === "" || item.redirect === null) {
      setOpenNotifications(false);
      const status = 2;
      const response = await updateNotificationListCount(item.id, status);
      if (response.status) {
        getNotification();
      }
    } else {
      setOpenNotifications(false);
      history.push(item.redirect);
    }
  };

  const getPageTitle = () => {
    const routeData = appRoutes.filter((route) => route.path === pathname);
    if (routeData && routeData.length > 0) {
      setPageTitle(routeData[0].name);
    }
  };

  const getNotification = async () => {
    const response = await getNotificationListCount();
    console.log({ response });
    if (response.status) {
      dispatch(setNotifications(response.data));
    }
  };

  const handleRedirectToElection = () => {
    history.push("/election");
  };

  useEffect(() => {
    const viewedStatus = alertMessage?.viewed || 0;
    if (viewedStatus === 1) dispatch(setAlertMessage(null));
    else if (alertMessage) {
      dispatch(setAlertMessage({ ...alertMessage, viewed: 0 }));
    }
    getPageTitle();
    if (pathname === "/dashboard") {
      getNotification();
    }
  }, [pathname]);

  useEffect(() => {
    checkTokenExists();
  }, []);

  useEffect(() => {
    themeScriptChange();
  }, [theme]);

  useEffect(() => {
    document.body.style.zoom = "90%";
  }, []);

  return (
    <HeaderTemplateComponent
      templateModal={{
        openProfile,
        logoutOpen,
        user,
        pageTitle,
        openResetPassword,
        notifications,
        openNotifications,
        currentElection,
      }}
      templateEvents={{
        handleThemeClick,
        handleOpenProfile,
        logoutModal,
        logoutCancel,
        confirmLogout,
        handleProfilePage,
        handleResetPassword,
        setMenuVisibility,
        updateNotificationCount,
        handleOpenNotifications,
        handleRedirectToElection,
      }}
    />
  );
};

export default HeaderComponent;
