export const getBugCommentsFormInputs = () => [
  {
    type: "TEXT_AREA",
    label: "Comment",
    id: "comment",
    name: "comment",
    value: "",
    placeholder: "Enter the Comment",
    validations: [],
  },
];
