import { APP_NAME } from "@constants/app.constants";
import DistrictsCreateComponent from "@screens/catalog-urban/districts/districts-create/districts-create.component";
import DistrictsEditComponent from "@screens/catalog-urban/districts/districts-edit/districts-edit.component";
import DistrictsListComponent from "@screens/catalog-urban/districts/districts-list/districts-list.component";
import UsersCreateComponent from "@screens/catalog-urban/districts/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog-urban/districts/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog-urban/districts/user/users-list/users-list.component";

const catalogUrbanDistrictsRoutes = [
  {
    name: `District - ${APP_NAME}`,
    path: "/catalog-urban/districts",
    icon: "layout",
    component: DistrictsListComponent,
  },
  {
    name: `Create District - ${APP_NAME}`,
    path: "/catalog-urban/districts/create",
    component: DistrictsCreateComponent,
  },
  {
    name: `Edit District - ${APP_NAME}`,
    path: "/catalog-urban/districts/edit",
    component: DistrictsEditComponent,
  },
  {
    name: `District User - ${APP_NAME}`,
    path: "/catalog-urban/districts/user",
    component: UsersListComponent,
  },
  {
    name: `Create District User - ${APP_NAME}`,
    path: "/catalog-urban/districts/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit District User - ${APP_NAME}`,
    path: "/catalog-urban/districts/user/edit",
    component: UsersEditComponent,
  },
];

export default catalogUrbanDistrictsRoutes;
