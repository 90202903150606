import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
  tableConfig,
  tableControls as rawControls,
} from "@screens/catalog/ac/ac-list/model/ac-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import ACListTemplateComponent from "./ac-list-template.component";
import {
  deleteDataApi,
  getACByDistrictId,
  getAcReservationByStateId,
  getDataApi,
} from "./ac-list.component.service";
import {
  createRefreshEntry,
  getAllStates,
} from "@screens/catalog/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getPcByStateId } from "@screens/catalog/pc/pc-list/pc-list.component.service";
import { deleteAllAcVoterData } from "@screens/catalog/ac-voter/ac-voter-list/ac-voter-list.component.service";
import { formatDateUtil } from "@utils/date.util";
import { getElectedMember } from "../candidate/candidate-list/candidate-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  MASTER_AC_ALERTS: { DELETE_ALERT, REFRESH_ALERT },
  AC_VOTER_ALERTS: { DELETE_ALL_VOTER_DATA },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;
const { CATALOG_AC_EXPORT } = END_POINT;

const ACListComponent = () => {
  const location = useLocation();
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const tableParams = getParamsData(filterList, menuUrl);
  const [filterState, setFilterState] = useState(filters);
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [basicData, setBasicData] = useState([]);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { currentElection } = useSelector((state) => state.election);
  const [electedMember, setElectedMember] = useState({});
  const [rowData, setRowData] = useState(null);
  const [openTrashModal, setTrashModal] = useState(false);
  const [password, setPassword] = useState("");
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);

  const getACData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr,
      electionId: currentElection.id,
    });
    if (data.status) {
      console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getACData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleUserLoginClick = (row) => {
    console.log({ row });
    history.push(
      `/catalog/ac/user?acId=${row.id}&electionId=${currentElection.id}&acName=${row.name}`
    );
  };

  const handleVoterClick = (row) => {
    console.log({ row });
    history.push(
      `/catalog/ac/voter?acId=${row.id}&electionId=${currentElection.id}&acName=${row.name}`
    );
  };

  const handleCandidateClick = (row) => {
    console.log({ row });
    history.push(
      `/catalog/ac/candidate?acId=${row.id}&electionId=${currentElection.id}&acName=${row.name}&stateGovtId=${row.state_govt_id}`
    );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${CATALOG_AC_EXPORT}${currentElection.id}?token=${token}${queryStr}`,
    });
    window.open(
      `${CATALOG_AC_EXPORT}${currentElection.id}?token=${token}${queryStr}`
    );
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getACData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "catalog-ac",
      election_id: currentElection.id,
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      switch (control.name) {
        case "Chart":
          return {
            ...control,
            onClick: handleChartClick,
          };
        case "Refresh":
          return {
            ...control,
            onClick: handleRefreshClick,
          };
      }
    });
    setTableControls(updatedTableControls);

    const stateResponse = await getAllStates(currentElection.id);
    console.log({ stateResponse });

    if (stateResponse.status) {
      const formattedStates = stateResponse.data.map((state) => {
        return {
          name: state.name,
          value: state.id,
        };
      });

      const copyFilters = rawFilters({
        states: formattedStates,
      });

      setFilterState(copyFilters);
    }
  };

  const handleStateChange = async (name, value, filters) => {
    // API call to get district data based on state id
    // value contains state id
    let copyFilter = [...filters];
    //INFO: as filter values are getting refreshed we need to update the default value again for state.
    // 0 index for state
    copyFilter[0] = { ...copyFilter[0], value };

    console.log({ name, value, filters });
    const districtResponse = await getDistrictByStateId(value);
    console.log({ districtResponse });
    if (districtResponse.status) {
      const formattedDistrict = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      copyFilter[1] = { ...copyFilter[1], options: formattedDistrict };
    }

    setFilterState(copyFilter);
  };

  const handleViewDetail = async (row) => {
    console.log({ row });
    const candidateData = await getElectedMember({
      acId: row.id,
      electionId: currentElection.id,
    });

    setRowData(row);
    setOpenDetailPanel(true);
    const data = [
      { key: "AC Id", value: row.govt_id },
      { key: "AC Name", value: row.name },
      { key: "Reservation", value: row.reservation },
      { key: "Regional Name", value: row.regional_name },
      { key: "Election Id", value: row.election_govt_id },
      { key: "Election Name", value: row.election_name },
      { key: "Phase", value: row.phase },
      { key: "State Id", value: row.state_govt_id },
      { key: "State Name", value: row.state_name },
      { key: "District Id", value: row.district_govt_id },
      { key: "District Name", value: row.district_name },
      { key: "PC Id", value: row.pc_govt_id },
      { key: "PC Name", value: row.pc_name },
      { key: "Remarks", value: row.remarks },
      {
        key: "CreatedAt",
        value: row?.created_at ? formatDateUtil(row?.created_at) : "",
      },
      {
        key: "UpdatedAt",
        value: row?.updated_at ? formatDateUtil(row?.updated_at) : "",
      },
      { key: "Admin", value: row.admin_name },
    ];
    setBasicData(data);

    if (candidateData.data.result != null) {
      console.log({ candidateData });
      setElectedMember({
        image: "",
        avatarLabel: candidateData.data.result.name,
        details: [
          { key: "Candidate Name", value: candidateData.data.result.name },
          {
            key: "Candidate Regional Name",
            value: candidateData.data.result.regional_name,
          },
          { key: "Designation", value: candidateData.data.result.designation },
          {
            key: "Personal Mobile",
            value: candidateData.data.result.personal_mobile,
          },
          {
            key: "Official Mobile",
            value: candidateData.data.result.official_mobile,
          },
          { key: "Email Id", value: candidateData.data.result.email_id },
          {
            key: "Residential Address",
            value: candidateData.data.result.residential_address,
          },
          {
            key: "Official Address",
            value: candidateData.data.result.official_address,
          },
          { key: "Remarks", value: candidateData.data.result.remarks },
          {
            key: "Candidate Image",
            value: candidateData.data.result.candidate_image,
          },
          { key: "Affidavit", value: candidateData.data.result.affidavit },
          {
            key: "CreatedAt",
            value: candidateData.data.result.created_at
              ? formatDateUtil(candidateData.data.result.created_at)
              : "",
          },
          {
            key: "UpdatedAt",
            value: candidateData.data.result.updated_at
              ? formatDateUtil(candidateData.data.result.updated_at)
              : "",
          },
        ],
      });
    } else setElectedMember("");
  };
  const toggleDetailPanel = (status) => {
    setOpenDetailPanel(status);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTrashAcVotersClick = (status) => {
    setTrashModal(status);
  };

  const confirmTrashData = async () => {
    dispatch(
      setAlertMessage({
        type: SUCCESS,
        message: DELETE_ALL_VOTER_DATA + " " + rowData.name,
        viewed: 0,
      })
    );

    const deleteResponse = await deleteAllAcVoterData(rowData.id, password);

    if (deleteResponse.status) handleTrashAcVotersClick(false);
    else {
      alert(deleteResponse.message);
    }
  };

  const cancelTrashData = () => {
    setTrashModal(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ACListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filterState,
        tableActions: additionalActions({
          handleUserLoginClick,
          handleVoterClick,
          handleCandidateClick,
          handleViewDetail,
          typeOfElection: currentElection?.type_of_election,
        }),
        openDetailPanel,
        basicData,
        tableConfig,
        electedMember,
        rowData,
        currentElection,
        openTrashModal,
        tableParams,
        tableControls,
        swapContentType,
      }}
      templateEvents={{
        getACData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
        setOpenDetailPanel,
        toggleDetailPanel,
        handleChange,
        confirmTrashData,
        cancelTrashData,
        handleTrashAcVotersClick,
      }}
    />
  ) : (
    <> </>
  );
};

export default ACListComponent;
