import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { resetUserTokenAction } from "@reducers/app.reducer";

const { SMS_TEMPLATE_LIST, SMS_TEMPLATE_DELETE } = END_POINT;

export const getSmsTemplateDataApi = async ({
  orderBy,
  sortBy,
  skip,
  limit,
  filters,
}) => {
  return await fetch(
    `${SMS_TEMPLATE_LIST}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteSmsTemplateDataApi = async (ids) => {
  return await fetch(SMS_TEMPLATE_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const checkForExpiredToken = (message) => {
  if (message === "Token expired" || message === "Invalid token")
    store.dispatch(resetUserTokenAction());
};
