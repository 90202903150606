import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const { REFRESH_CATALOG_COUNT_LIST } = END_POINT;

export const getDataApi = async ({ orderBy, sortBy, skip, limit, filters }) => {
  return await fetch(
    `${REFRESH_CATALOG_COUNT_LIST}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
