import React from "react";
import FeatherIcon from "feather-icons-react";

const DataGridColumnsComponent = ({
  tableColumn,
  tableData,
  selected,
  order,
  orderBy,
  disableActions,
  handleSelectAllClick,
  createSortHandler,
}) => {
  return (
    <thead>
      <tr>
        <th style={{ width: 10 }}>
          <input
            type="checkbox"
            className="form-check-input"
            checked={
              selected && tableData && selected.length > 0
                ? selected.length === tableData.length
                : false
            }
            onClick={handleSelectAllClick}
          />
        </th>
        {tableColumn.map((column) => {
          if (column.isVisible)
            return (
              <th style={{ padding: "12px 8px 0px 14px", height: "50px" }}>
                <a
                  onClick={column.sorting ? createSortHandler(column.id) : null}
                >
                  {column.label}
                  {column.sorting && (
                    <>
                      {orderBy === column.id && (
                        <span style={{ marginLeft: 10 }}>
                          {order === "asc" ? (
                            <FeatherIcon width={20} icon={"arrow-up"} />
                          ) : (
                            <FeatherIcon width={20} icon={"arrow-down"} />
                          )}
                        </span>
                      )}
                    </>
                  )}
                </a>
              </th>
            );
        })}
        {!disableActions && (
          <th style={{ textAlign: "right" }} className="sticky-col action-col">
            Action
          </th>
        )}
      </tr>
    </thead>
  );
};

export default DataGridColumnsComponent;
