import { APP_NAME } from "@constants/app.constants";

import TownPanchayatCreateComponent from "@screens/catalog-urban/town-panchayat/town-panchayat-create/town-panchayat-create";
import TownPanchayatEditComponent from "@screens/catalog-urban/town-panchayat/town-panchayat-edit/town-panchayat-edit";
import TownPanchayatListComponent from "@screens/catalog-urban/town-panchayat/town-panchayat-list/town-panchayat-list.component";

const catalogTownPanchayatRoutes = [
  {
    name: "Town Panchayat List",
    icon: "crosshair",
    path: "/catalog-urban/town-panchayat",
    component: TownPanchayatListComponent,
  },

  {
    name: `Create Town Panchayat - ${APP_NAME}`,
    path: "/catalog-urban/town-panchayat/create",
    component: TownPanchayatCreateComponent,
  },

  {
    name: `Edit Town Panchayat - ${APP_NAME}`,
    path: "/catalog-urban/town-panchayat/edit",
    component: TownPanchayatEditComponent,
  },
];

export default catalogTownPanchayatRoutes;
