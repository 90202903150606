import React, { useState } from "react";
import {
  menuUrl,
  breadCrumbs,
  filters as rawFilters,
} from "./model/upcoming-election-list.constants";
import { checkMenuAccess } from "@utils/menu-auth";

import UpcomingElectionListTemplateComponent from "./upcoming-election-list-template.component";

const UpcomingElectionListComponent = () => {
  const menuAccess = checkMenuAccess(menuUrl, "read");

  return menuAccess ? (
    <UpcomingElectionListTemplateComponent
      templateModal={{
        breadCrumbs,
      }}
    />
  ) : (
    <> </>
  );
};

export default UpcomingElectionListComponent;
