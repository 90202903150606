import { APP } from "@constants/app.constants";
const { DELETE_PROTECTED, USER_TYPE } = APP;

export const getUserEditFormInputs = (
  pcRoles,
  acRoles,
  urbanRoles,
  ruralRoles,
  adminRoles,
  elections,
  clients,
  initialValues,
  onChangeShowByElection,
  handleUserImageFileUpload
) => [
  {
    type: "SELECT",
    label: "Admin Role",
    id: "adminRoleId",
    name: "admin_role_id",
    options: adminRoles,
    value: initialValues?.adminRoleId || "",
    placeholder: "Select Role",
    validations: [],
  },
  {
    type: "SELECT",
    label: "PC Election Role",
    id: "pcRoleId",
    name: "pc_role_id",
    options: pcRoles,
    value: initialValues?.pcRoleId || "",
    placeholder: "Select Role",
    validations: [],
  },
  {
    type: "SELECT",
    label: "AC Election Role",
    id: "acRoleId",
    name: "ac_role_id",
    options: acRoles || [],
    value: initialValues?.acRoleId || "",
    placeholder: "Select Role",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Urban Election Role",
    id: "urbanRoleId",
    name: "urban_role_id",
    options: urbanRoles,
    value: initialValues?.urbanRoleId || "",
    placeholder: "Select Role",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Rural Election Role",
    id: "ruralRoleId",
    name: "rural_role_id",
    options: ruralRoles || [],
    value: initialValues?.ruralRoleId || "",
    placeholder: "Select Role",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Election ",
    id: "electionId",
    name: "election_id",
    options: elections,
    value: initialValues?.electionId || "",
    placeholder: "Select Election",
    onChange: onChangeShowByElection,
    validations: [
      {
        type: "REQUIRED",
        message: "Election is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Client",
    id: "clientId",
    name: "client_id",
    options: clients,
    value: initialValues?.clientId || "",
    placeholder: "Select Client",
    validations: [],
  },
  {
    type: "TEXT",
    label: "User Name",
    id: "email",
    name: "email",
    value: initialValues?.email || "",
    placeholder: "Enter the Email Id",
    validations: [
      {
        type: "REQUIRED",
        message: "Email id is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Mobile No",
    id: "mobile",
    name: "mobile",
    value: initialValues?.mobile || "",
    placeholder: "Enter the Mobile",
    validations: [],
  },
  {
    type: "TEXT",
    label: "WhatsApp No",
    id: "whatsappNumber",
    name: "whatsapp_number",
    value: initialValues?.whatsappNumber || "",
    placeholder: "Enter the Whatsapp No",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Reset Password",
    id: "resetPassword",
    name: "reset_password",
    value: null,
    placeholder: "Enter the Password to Reset",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type",
    options: USER_TYPE,
    value: initialValues?.type || "",
    placeholder: "Select Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Type is required !",
      },
    ],
  },
  {
    type: "CALENDER",
    label: "Expiry Date",
    id: "expiryDate",
    name: "expiry_date",
    value: initialValues?.expiryDate || "",
    placeholder: "Select Date",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Delete Protected",
    id: "deleteProtected",
    name: "delete_protected",
    options: DELETE_PROTECTED,
    value: initialValues?.deleteProtected || 2,
    placeholder: "Select Delete Protected",
    validations: [],
    isHide: true,
  },

  {
    type: "FILE_UPLOAD",
    name: "user_image",
    id: "userImage",
    label: "Image",
    loading: false,
    value: initialValues?.userImage || "",
    handleUpload: handleUserImageFileUpload,
    validations: [],
  },
];
