import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

const ActionContent = ({ handleCreateClick }) => {};

function UserLogListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableParams,
    userName,
  },
  templateEvents: { getUsersLogData, handleCreateClick, submitFilter },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  console.log({ filters });

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Log"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: userName + " - Log(s)", menu_url: null },
        ]}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getUsersLogData}
        editLink={settingFormEdit}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        tableParams={tableParams}
        disableActions
        disableDelete
      />
    </PageWrapperComponent>
  );
}

export default UserLogListTemplateComponent;
