import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getStatesFormInputs } from "../model/states.modal";
import { menuUrl } from "../states-list/model/states-list.constants";
import { editEntry } from "./states-edit.component.service";
import StatesEditTemplateComponent from "./states-edit-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  STATES_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const StatesEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleStateSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let statesPayload = {};
    for (let i = 0; i < values.length; i++) {
      statesPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const stateResponse = await editEntry(
      location?.state?.row?.id,
      statesPayload
    );

    // INFO : check api response and show relevant alert message
    if (stateResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: stateResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getStatesFormInputs({
      govtId: location?.state?.row?.govt_id || "",
      name: location?.state?.row?.state_name || "",
      type: location?.state?.row?.type || "State",
      regionalName: location?.state?.row?.regional_name || "",
      officialLanguage: location?.state?.row?.official_language || "",
      rulingParty: location?.state?.row?.ruling_party || "",
      chiefMinister: location?.state?.row?.chief_minister || "",
      remarks: location?.state?.row?.remarks || "",
    }),
  };

  const templateEvents = {
    handleStateSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <StatesEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default StatesEditComponent;
