import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const {
  CATALOG_RURAL_WARD_VOTER_LIST,
  CATALOG_RURAL_WARD_VOTER_DELETE,
  CATALOG_RURAL_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST,
  CATALOG_RURAL_WARD_VOTER_DELETE_ALL,
  CATALOG_RURAL_WARD_VOTER_CREATE,
  CATALOG_RURAL_WARD_VOTER_EDIT,
  CATALOG_AC_VOTER_UPLOAD_FILE,
  CATALOG_RURAL_WARD_VOTER_READ_FILE,
} = END_POINT;

export const getDataApi = async ({
  orderBy,
  sortBy,
  skip,
  limit,
  filters,
  electionId,
  wardId,
  type,
}) => {
  return await fetch(
    `${CATALOG_RURAL_WARD_VOTER_LIST}${electionId}/${wardId}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}&type=${type}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteDataApi = async (ids) => {
  return await fetch(CATALOG_RURAL_WARD_VOTER_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const createRuralWardVoterDataDownloadRequestEntry = async (
  wardId,
  id,
  queryStr,
  payload
) => {
  return await fetch(
    `${CATALOG_RURAL_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST}${wardId}/${id}?dummy=test${queryStr}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: store.getState().app.token,
      },
      body: JSON.stringify(payload),
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteAllRuralWardVoterData = async (id, type) => {
  return await fetch(`${CATALOG_RURAL_WARD_VOTER_DELETE_ALL}?type=${type}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ id: [id] }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const createEntry = async (electionId, payload) => {
  return await fetch(`${CATALOG_RURAL_WARD_VOTER_CREATE}${electionId}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const editEntry = async (id, payload) => {
  return await fetch(`${CATALOG_RURAL_WARD_VOTER_EDIT}${id}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await fetch(CATALOG_AC_VOTER_UPLOAD_FILE, {
    method: "POST",
    headers: {
      token: store.getState().app.token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return { status: true, data: data.data, message: data.message };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const readFile = async (id, payload) => {
  return await fetch(`${CATALOG_RURAL_WARD_VOTER_READ_FILE}${id}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
