import { APP_NAME } from "@constants/app.constants";
import CandidateCreateComponent from "@screens/catalog-rural/panchayat-union-ward/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog-rural/panchayat-union-ward/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog-rural/panchayat-union-ward/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog-rural/panchayat-union-ward/candidate/candidate-list/candidate-list.component";
import PanchayatUnionWardCreateComponent from "@screens/catalog-rural/panchayat-union-ward/panchayat-union-ward-create/panchayat-union-ward-create.component";
import PanchayatUnionWardEditComponent from "@screens/catalog-rural/panchayat-union-ward/panchayat-union-ward-edit/panchayat-union-ward-edit.component";
import PanchayatUnionWardListComponent from "@screens/catalog-rural/panchayat-union-ward/panchayat-union-ward-list/panchayat-union-ward-list.component";
import RuralVoterCreateComponent from "@screens/catalog-rural/panchayat-union-ward/voter/voter-create/voter-create.component";
import RuralVoterEditComponent from "@screens/catalog-rural/panchayat-union-ward/voter/voter-edit/voter-edit.component";
import RuralVoterFileUploadComponent from "@screens/catalog-rural/panchayat-union-ward/voter/voter-file/voter-file.component";
import RuralVoterListComponent from "@screens/catalog-rural/panchayat-union-ward/voter/voter-list/voter-list.component";

const catalogPanchayatUnionWardRoutes = [
  {
    name: "Panchayat Union Ward List",
    icon: "framer",
    path: "/catalog-rural/panchayat-union-ward",
    component: PanchayatUnionWardListComponent,
  },

  {
    name: `Create Panchayat Union Ward - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/create",
    component: PanchayatUnionWardCreateComponent,
  },

  {
    name: `Edit Panchayat Union Ward- ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/edit",
    component: PanchayatUnionWardEditComponent,
  },

  {
    name: `Panchayat Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/candidate",
    component: CandidateListComponent,
  },

  {
    name: `Create Panchayat Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit Panchayat Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/candidate/upload",
    component: CandidateFileUploadComponent,
  },
  {
    name: `Panchayat Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/voter",
    component: RuralVoterListComponent,
  },
  {
    name: `Create Panchayat Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/voter/create",
    component: RuralVoterCreateComponent,
  },
  {
    name: `Edit Panchayat Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/voter/edit",
    component: RuralVoterEditComponent,
  },
  {
    name: `Panchayat Union Ward Voter File Upload - ${APP_NAME}`,
    path: "/catalog-rural/panchayat-union-ward/voter/upload",
    component: RuralVoterFileUploadComponent,
  },
];

export default catalogPanchayatUnionWardRoutes;
