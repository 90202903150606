import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableColumn = [
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    label: "Comment",
    formatData: formatRemarks,
    width: 200,
  },
  {
    id: "admin_name",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    sorting: true,
    width: 100,
  },

  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];

const menuUrl = "bug-tracker";

const breadCrumbs = [{ name: "Bug Tracker", menu_url: "/bug-tracker" }];

export { tableColumn, menuUrl, breadCrumbs };
