import { Lightbox } from "react-modal-image";

const ImagePreviewComponent = ({ open, image, cancelImagePreview }) => {
  return (
    open && (
      <Lightbox
        small={image}
        large={image}
        alt=""
        onClose={cancelImagePreview}
      />
    )
  );
};

export default ImagePreviewComponent;
