import { getBugDataByVersionApi } from "../bug-tracker-list/bug-tracker-list.component.service";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { tableColumn } from "../bug-tracker-list/model/bug-tracker-list.constants";
import DataGridTableComponent from "@molecules/data-grid/components/data-grid-table.component";
import { breadCrumbs } from "@screens/version/version-list/model/version-list.constants";

const BugVersionListComponent = () => {
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const versionId = new URLSearchParams(location.search).get("versionId");
  const title = new URLSearchParams(location.search).get("title");
  console.log({ versionId });
  const bugData = async () => {
    const bugStatusData = await getBugDataByVersionApi(versionId);
    console.log({ bugStatusData });
    setTableData(bugStatusData.data.list);
  };
  const history = useHistory();
  const handleBackClick = () => {
    history.push(`/version`);
  };
  const ActionContent = ({ handleBackClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    );
  };
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Bugs List"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: title + " - Bug List(s)", menu_url: null },
        ]}
        actionContent={<ActionContent handleBackClick={handleBackClick} />}
      />

      <DataGridTableComponent
        templateModal={{
          title: "Bugs List",
          tableColumn,
          tableData,
          disableActions: [],
        }}
        templateEvents={{
          dataRefresh: bugData,
        }}
      />
    </PageWrapperComponent>
  );
};

export default BugVersionListComponent;
