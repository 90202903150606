import { store } from "@src/store";

export const checkMenuAccess = (url, type = "read") => {
  const menuList = formatMenuList();
  const menuAccess = menuList.filter((menu) => menu.menu_url === url);
  // console.log({ menuAccess, type });
  if (menuAccess && menuAccess.length > 0) {
    if (type === "read")
      return menuAccess[0].permission.permission_read === "on" ? true : false;
    if (type === "create" || type === "write")
      return menuAccess[0].permission.permission_write === "on" ? true : false;
    if (type === "edit")
      return menuAccess[0].permission.permission_edit === "on" ? true : false;
    if (type === "delete")
      return menuAccess[0].permission.permission_delete === "on" ? true : false;
    return false;
  }
  return false;
};

export const formatMenuList = () => {
  const menu = store.getState().menu;
  const menus = (menu && menu.menuList) || [];
  const allMenus = [];
  // console.log(menus);
  let menuData = {};
  for (let i = 0; i < menus.length; i++) {
    menuData = {
      menu_name: menus[i].menu_name,
      menu_url: menus[i].menu_url,
      permission: menus[i].permission,
    };

    for (let j = 0; j < menus[i].sub_menus.length; j++) {
      menuData = {
        menu_name: menus[i].sub_menus[j].menu_name,
        menu_url: menus[i].sub_menus[j].menu_url,
        permission: menus[i].sub_menus[j].permission,
      };
      allMenus.push(menuData);
    }
    if (menus[i].sub_menus.length === 0) allMenus.push(menuData);
  }
  return allMenus;
};

export const formatElectionMenus = (menuList, currentElection) => {
  const typeOfElection = currentElection && currentElection.type_of_election;
  console.log({ menuList, typeOfElection });
  const catalogIndex = menuList.findIndex(
    (menu) => menu.menu_url === "catalog"
  );
  const catalogSubMenus = menuList[catalogIndex]?.sub_menus.map((menu) => {
    return {
      ...menu,
      subMenuOpen: false,
    };
  });
  if (typeOfElection === "AC") {
    const pcIndex = catalogSubMenus?.findIndex(
      (subMenu) => subMenu.menu_url === "catalog/pc"
    );
    console.log({ catalogSubMenus, catalogIndex });
    if (pcIndex > -1) {
      catalogSubMenus.splice(pcIndex, 1);
    }

    return menuList.map((menu, index) => {
      return index === catalogIndex
        ? { ...menu, sub_menus: catalogSubMenus }
        : menu;
    });
  } else if (typeOfElection === "PC") {
    return menuList.map((menu, index) => {
      return index === catalogIndex
        ? { ...menu, sub_menus: catalogSubMenus }
        : menu;
    });
  }
  return menuList;
};
