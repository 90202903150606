import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import { copyText, redirectToLink } from "@utils/common";
import { uploadFile } from "@utils/upload.util";
const { STATE_TYPE, BALLOT_PAPER_DATA } = APP;

export const getStatesFormInputs = (initialValues, handleUpload) => [
  {
    groupName: "Basic Info",
    hideGroupName: true,
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the State ID",
    validations: [
      {
        type: "REQUIRED",
        message: "State ID is required !",
      },
    ],
  },
  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "State Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the State Name",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    groupName: "Basic Info",
    type: "RADIO",
    label: "Type",
    id: "type",
    name: "type",
    options: STATE_TYPE,
    value: initialValues?.type || "STATE",
    validations: [],
  },
  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "Official Language",
    id: "officialLanguage",
    name: "official_language",
    value: initialValues?.officialLanguage || "",
    placeholder: "Enter the Official Language",
    validations: [],
  },

  {
    groupName: "Basic Info",
    type: "CALENDER",
    label: "Previous Election",
    id: "previousElection",
    name: "previous_election",
    value: initialValues?.previousElection || "",
    placeholder: "Select Date",
    validations: [],
  },

  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "Total PC",
    id: "totalPc",
    name: "total_pc",
    value: initialValues?.totalPc || "",
    placeholder: "Enter the Total PC",
    validations: [],
  },
  {
    groupName: "Basic Info",
    type: "TEXT",
    label: "Total AC",
    id: "totalAc",
    name: "total_ac",
    value: initialValues?.totalAc || "",
    placeholder: "Enter the Total AC",
    validations: [],
  },
  {
    groupName: "Basic Info",
    type: "FILE_UPLOAD",
    id: "file",
    name: "file",
    label: "File",
    placeholder: "File",
    value: initialValues?.file || "",
    handleUpload: handleUpload,
    size: 5,
  },

  {
    groupName: "Basic Info",
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Files",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "state_files",
    value: initialValues?.state_files,
    inputs: [
      {
        type: "TEXT",
        label: "Name",
        id: "name",
        name: "name",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "FILE_UPLOAD",
        id: "file",
        name: "file",
        placeholder: "File",
        handleSelfUpload: uploadFile,
        filePrefix: BASE_URL + "app-source/files/",
        size: 7,
      },
    ],
  },
  {
    groupName: "Relevant Links",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "relevant_link",
    value: initialValues?.relevant_link || null,
    inputs: [
      {
        type: "TEXT",
        label: "Relevant Link Name",
        id: "link_name",
        name: "linkName",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Sort Order",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Link",
        id: "link",
        name: "link",
        placeholder: "Link",
        size: 5,
        inputIcon: "link",
        handleInputIcon: redirectToLink,
      },
    ],
  },
  {
    groupName: "Ballot Paper Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "ballot_paper_data",
    value: initialValues?.ballot_paper_data || BALLOT_PAPER_DATA,
    inputs: [
      {
        type: "TEXT",
        label: "Name",
        id: "name",
        name: "name",
        placeholder: "Name",
        size: 6,
      },
      {
        type: "TEXT",
        label: "Regional Name",
        id: "regionalName",
        name: "regionalName",
        placeholder: "Regional Name",
        size: 5,
      },
    ],
  },
];
