import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ConfirmDialogComponent({
  templateModal: { logoutOpen },
  templateEvents: { logoutCancel, confirmLogout },
}) {
  return (
    <div className="basic-modal">
      <Modal className="modal-primary" isOpen={logoutOpen} toggle={logoutOpen}>
        <ModalHeader toggle={logoutCancel}>Logout!</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to logout?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="outline-primary" onClick={logoutCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={confirmLogout}>
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmDialogComponent;
