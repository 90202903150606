import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBlockedIpFormInputs } from "../model/blocked-ip.modal";
import { menuUrl } from "../blocked-ip-list/model/blocked-ip-list.constants";
import { createBlockedIpApi } from "./blocked-ip-create.component.service";
import BlockedIpCreateTemplateComponent from "./blocked-ip-create-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BLOCKED_IP_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const BlockedIpCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBlockedIpSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct blocked ip payload
    let blockedIpPayload = {};
    for (let i = 0; i < values.length; i++) {
      blockedIpPayload[values[i].name] = values[i].value;
    }

    // INFO : call edit api
    const blockedIpResponse = await createBlockedIpApi(blockedIpPayload);

    // INFO : check api response and show relevant alert message
    if (blockedIpResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: blockedIpResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: getBlockedIpFormInputs({}),
  };

  const templateEvents = {
    handleBlockedIpSubmit,
    handleTriggerClick,
  };

  return menuAccess ? (
    <BlockedIpCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default BlockedIpCreateComponent;
