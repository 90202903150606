import React from "react";
import information from "@assets/images/blupage-information.png";
import logo from "@assets/images/blupage-white.png";

const LaunchPadTemplate = ({
  templateModal: { cards },
  templateEvents: { handleModuleClick, handleLogoutClick },
}) => {
  return (
    <>
      <div style={{ padding: "50px 100px 0px 100px" }}>
        <div style={{ textAlign: "center", marginBottom: 50 }}>
          <img src={logo} style={{ width: 250 }} />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              {cards.map((card, index) => (
                <div
                  className={`${
                    index === cards.length - 1 ? "col-12" : "col-6"
                  } cursor-pointer`}
                  onClick={() => handleModuleClick(card)}
                >
                  <div
                    className="card"
                    style={{
                      textAlign: "center",
                      padding: 20,
                      alignItems: "center",
                    }}
                  >
                    <img style={{ width: 200 }} src={card.image} />
                    {card.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <img src={information} />
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: 30,
        }}
      >
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleLogoutClick}
        >
          Logout
        </span>

        {/* <p
          className="card"
          style={{ height: 40, paddingTop: 9, paddingLeft: 15 }}
        >
          Thedal Digital Politics - All Rights Reserved @2023
        </p> */}
      </div>
    </>
  );
};

export default LaunchPadTemplate;
