import { electionPhase, electionYear } from "@utils/common";

export const APP_NAME = "BluPage";
export const APP_VERSION = "3.0";
export const FORGOT_PASSWORD = "Forgot Password?";
export const PRIMARY_KEY = "id";

export const APP = {
  ROWS_PER_PAGE: 10,
  PAGE_VIEW_OPTIONS: [10, 50, 100, 150],
  MENU_STATUS: [
    { name: "Enabled", value: 1 },
    { name: "Disabled", value: 0 },
  ],
  MENU_TYPE: [
    { name: "Menu", value: "Menu" },
    { name: "Permission", value: "Permission" },
  ],
  STATUS: [
    { name: "Enabled", value: 1 },
    { name: "Disabled", value: 0 },
  ],
  NOTIFICATION_STATUS: [
    { name: "Pending", value: 1 },
    { name: "Viewed", value: 2 },
  ],
  EMAIL_TEMPLATE_TYPE: [
    { name: "Default", value: 1 },
    { name: "None", value: 0 },
  ],
  ELECTION_CATEGORY: [
    { name: "GENERAL", value: "General" },
    { name: "BY-ELECTION", value: "By-Election" },
  ],
  ELECTION_TYPE: [
    { name: "PC", value: "PC" },
    { name: "AC", value: "AC" },
  ],
  VOTER_FIELD_TYPE: [
    { name: "Text", value: "Text" },
    { name: "Dropdown", value: "Dropdown" },
  ],
  USER_TYPE: [
    { name: "Super Admin", value: "Super Admin" },
    { name: "Supervisor", value: "Supervisor" },
    { name: "Admin", value: "Admin" },
  ],
  FILE_TYPE: [
    { name: "Normal", value: "Normal" },
    { name: "Large", value: "Large" },
  ],
  DELETE_PROTECTED: [
    { name: "Yes", value: 1 },
    { name: "No", value: 2 },
  ],
  ELECTION_STATUS: [
    { name: "UPCOMING", value: "UPCOMING" },
    { name: "ONGOING", value: "ONGOING" },
    { name: "AWAITING RESULT", value: "AWAITING RESULT" },
    { name: "COMPLETED", value: "COMPLETED" },
    { name: "CANCELLED", value: "CANCELLED" },
    { name: "POSTPONED", value: "POSTPONED" },
  ],
  MONTHS: [
    { name: "JAN", value: "JAN" },
    { name: "FEB", value: "FEB" },
    { name: "MAR", value: "MAR" },
    { name: "APR", value: "APR" },
    { name: "MAY", value: "MAY" },
    { name: "JUN", value: "JUN" },
    { name: "JUL", value: "JUL" },
    { name: "AUG", value: "AUG" },
    { name: "SEP", value: "SEP" },
    { name: "OCT", value: "OCT" },
    { name: "NOV", value: "NOV" },
    { name: "DEC", value: "DEC" },
  ],

  YEAR: electionYear(),

  RESERVATION: [
    { name: "GENERAL", value: "GENERAL" },
    { name: "GENERAL SC", value: "GENERAL SC" },
    { name: "GENERAL ST", value: "GENERAL ST" },
  ],
  CANDIDATE_STATUS: [
    { name: "APPLIED", value: "APPLIED" },
    { name: "ACCEPTED", value: "ACCEPTED" },
    { name: "REJECTED", value: "REJECTED" },
    { name: "WITHDRAWN", value: "WITHDRAWN" },
    { name: "CONTESTING", value: "CONTESTING" },
    { name: "RUNNER", value: "RUNNER" },
    { name: "WINNER", value: "WINNER" },
    { name: "LOST", value: "LOST" },
  ],
  GENDER: [
    { name: "MALE", value: "MALE" },
    { name: "FEMALE", value: "FEMALE" },
    { name: "TRANSGENDER", value: "TRANSGENDER" },
  ],
  STATE_TYPE: [
    { name: "STATE", value: "STATE" },
    { name: "UNION TERRITORY", value: "UNION TERRITORY" },
  ],
  ELECTION_PHASE: electionPhase(),
  PARTY_TYPE: [
    { name: "NATIONAL R", value: "NATIONAL R" },
    { name: "NATIONAL UR", value: "NATIONAL UR" },
    { name: "REGIONAL R", value: "REGIONAL R" },
    { name: "REGIONAL UR", value: "REGIONAL UR" },
  ],

  POLL_DATA: [
    { pollEvent: "Notification Date", date: "" },
    { pollEvent: "Last Date for Filing Nomination", date: "" },
    { pollEvent: "Scrutiny of Nomination", date: "" },
    { pollEvent: "Last date for Withdrawal of Nomination", date: "" },
    { pollEvent: "Date of Poll", date: "" },
    { pollEvent: "Date of Counting of Votes", date: "" },
  ],

  CLIENT_ACCESS_LEVEL: [
    { name: "STATE", value: "STATE" },
    { name: "DISTRICT", value: "DISTRICT" },
    { name: "PC", value: "PC" },
    { name: "AC", value: "AC" },
    { name: "AC-BOOTH", value: "AC-BOOTH" },
  ],

  LEADS_TYPE: [
    { name: "B2B", value: "B2B" },
    { name: "CLIENT", value: "CLIENT" },
    { name: "FRANCHISEE", value: "FRANCHISEE" },
    { name: "INFLUENCERS", value: "INFLUENCERS" },
    { name: "PEERS", value: "PEERS" },
  ],

  INDIA_STATES: [
    { name: "ANDRA PRADESH", value: "ANDRA PRADESH" },
    { name: "ARUNACHAL PRADESH", value: "ARUNACHAL PRADESH" },
    { name: "ASSAM", value: "ASSAM" },
    { name: "BIHAR", value: "BIHAR" },
    { name: "CHATTISGARH", value: "CHATTISGARH" },
    { name: "GOA", value: "GOA" },
    { name: "GUJARAT", value: "GUJARAT" },
    { name: "HARYANA", value: "HARYANA" },
    { name: "HIMACHAL PRADESH", value: "HIMACHAL PRADESH" },
    { name: "JAMMU AND KASHMIR", value: "JAMMU AND KASHMIR" },
    { name: "JHARKHAND", value: "JHARKHAND" },
    { name: "KARNATAKA", value: "KARNATAKA" },
    { name: "KERALA", value: "KERALA" },
    { name: "MADYA PRADESH", value: "MADYA PRADESH" },
    { name: "MAHARASHTRA", value: "MAHARASHTRA" },
    { name: "MANIPUR", value: "MANIPUR" },
    { name: "MEGHALAYA", value: "MEGHALAYA" },
    { name: "MIZORAM", value: "MIZORAM" },
    { name: "NAGALAND", value: "NAGALAND" },
    { name: "ODISHA", value: "ODISHA" },
    { name: "PUNJAB", value: "PUNJAB" },
    { name: "RAJASTHAN", value: "RAJASTHAN" },
    { name: "SIKKIM", value: "SIKKIM" },
    { name: "TAMIL NADU", value: "TAMIL NADU" },
    { name: "TELANGANA", value: "TELANGANA" },
    { name: "UTTAR PRADESH", value: "UTTAR PRADESH" },
    { name: "UTTARAKHAND", value: "UTTARAKHAND" },
    { name: "WEST BENGAL", value: "WEST BENGAL" },
    {
      name: "ANDAMAN AND NICOBAR ISLANDS",
      value: "ANDAMAN AND NICOBAR ISLANDS",
    },
    { name: "CHANDIGARH", value: "CHANDIGARH" },
    { name: "DADRA AND NAGAR HAVELI", value: "DADRA AND NAGAR HAVELI" },
    { name: "DAMAN AND DIU", value: "DAMAN AND DIU" },
    { name: "DELHI", value: "DELHI" },
    { name: "LADAKH", value: "LADAKH" },
    { name: "LAKSHADWEEP", value: "LAKSHADWEEP" },
    { name: "PUDUCHERRY", value: "PUDUCHERRY" },
  ],
  AC_URBAN_TYPE: [
    { name: "CORPORATION", value: "CORPORATION" },
    { name: "MUNICIPALITY", value: "MUNICIPALITY" },
    { name: "TOWN PANCHAYAT", value: "TOWN PANCHAYAT" },
  ],
  RESERVATION_TYPE_2: [
    { name: "GENERAL", value: "GENERAL" },
    { name: "GENERAL SC", value: "GENERAL SC" },
    { name: "GENERAL ST", value: "GENERAL ST" },
    { name: "WOMEN", value: "WOMEN" },
    { name: "WOMEN SC", value: "WOMEN SC" },
    { name: "WOMEN ST", value: "WOMEN ST" },
  ],
  BALLOT_PAPER_DATA: [
    { name: "SNO", regionalName: "" },
    { name: "CANDIDATE NAME", regionalName: "" },
    { name: "PHOTO", regionalName: "" },
    { name: "SYMBOL", regionalName: "" },
    { name: "DATE", regionalName: "" },
    { name: "TOTAL CANDIDATE", regionalName: "" },
    { name: "INDEPENDENT", regionalName: "" },
    { name: "PARTY", regionalName: "" },
  ],

  SELECTION_TYPE: [
    { name: "DIRECT", value: "DIRECT" },
    { name: "INDIRECT", value: "INDIRECT" },
  ],

  RURAL_TYPE: [
    { name: "DISTRICT UNION WARD", value: "DISTRICT UNION WARD" },
    { name: "PANCHAYAT UNION WARD", value: "PANCHAYAT UNION WARD" },
    { name: "VILLAGE PANCHAYAT WARD", value: "VILLAGE PANCHAYAT WARD" },
  ],

  VOTER_FIELDS_DATA: [
    { name: "PART_NO", field: "" },
    { name: "SERIAL_NO", field: "" },
    { name: "SECTION_NO", field: "" },
    { name: "Section Name", field: "" },
    { name: "V Section Name", field: "" },
    { name: "Booth Name", field: "" },
    { name: "VBooth Name", field: "" },
    { name: "Village Name", field: "" },
    { name: "V Village Name", field: "" },
    { name: "SLNOINPART", field: "" },
    { name: "C_HOUSE_NO", field: "" },
    { name: "C_HOUSE_NO_V1", field: "" },
    { name: "FM_NAME_EN", field: "" },
    { name: "LASTNAME_EN", field: "" },
    { name: "FM_NAME_V1", field: "" },
    { name: "LASTNAME_V1", field: "" },
    { name: "RLN_TYPE", field: "" },
    { name: "RLN_FM_NM_EN", field: "" },
    { name: "RLN_L_NM_EN", field: "" },
    { name: "RLN_FM_NM_V1", field: "" },
    { name: "RLN_L_NM_V1", field: "" },
    { name: "EPIC_NO", field: "" },
    { name: "GENDER", field: "" },
    { name: "AGE", field: "" },
    { name: "DOB", field: "" },
    { name: "MOBILE_NO", field: "" },
    { name: "WHATSAPP_NUMBER", field: "" },
    { name: "EMAIL_ID", field: "" },
    { name: "CITY", field: "" },
    { name: "RELIGION", field: "" },
    { name: "CASTE", field: "" },
    { name: "LANGUAGE", field: "" },
    { name: "RELATION", field: "" },
    { name: "COLOR", field: "" },
    { name: "PINCODE", field: "" },
    { name: "remarks", field: "" },
    { name: "verified", field: "" },
  ],
};
