import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCandidateFormInputs } from "../model/candidate.modal";
import {
  createEntry,
  uploadAffidavit,
  uploadCandidateImage,
} from "./candidate-create.component.service";
import CandidateCreateTemplateComponent from "./candidate-create-template.component";
import { menuUrl } from "../candidate-list/model/candidate-list.constants";
import { getPoliticalPartyByState } from "@screens/political-party/political-party-list/political-party-list.component.service";
import { getAllSymbols } from "@screens/manage/symbols/symbols-list/symbols-list.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_CANDIDATE_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const CandidateCreateComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const pcId = new URLSearchParams(location.search).get("pcId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const pcName = new URLSearchParams(location.search).get("pcName");
  const [formInputs, setFormInputs] = useState([]);
  const stateGovtId = new URLSearchParams(location.search).get("stateGovtId");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleCandidateFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadCandidateResponse = await uploadCandidateImage(file[0]);
    console.log({ uploadCandidateResponse });
    if (uploadCandidateResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value = uploadCandidateResponse.data;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };
  const handleAffidavitFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadAffadavitResponse = await uploadAffidavit(file[0]);
    console.log({ uploadAffadavitResponse });
    if (uploadAffadavitResponse.status) {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      copyFormInputs3[index].filename = file[0].name;
      copyFormInputs3[index].value = uploadAffadavitResponse.data;

      setFormInputs(copyFormInputs3);
    } else {
      let copyFormInputs3 = [...copyFormInputs];
      copyFormInputs3[index].loading = false;
      setFormInputs(copyFormInputs3);
      alert("Invalid File Format");
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog/pc/candidate?pcId=${pcId}&electionId=${electionId}&pcName=${pcName}&stateGovtId=${stateGovtId}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let candidatePayload = { pc_id: pcId };
    for (let i = 0; i < values.length; i++) {
      candidatePayload[values[i].name] = values[i].value;
    }
    candidatePayload["pc_id"] = pcId;

    // INFO : call create api
    const candidateResponse = await createEntry(
      currentElection.id,
      candidatePayload
    );

    // INFO : check api response and show relevant alert message
    if (candidateResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/catalog/pc/candidate?pcId=${pcId}&electionId=${electionId}&pcName=${pcName}&stateGovtId=${stateGovtId}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: candidateResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const politicalPartyResponse = await getPoliticalPartyByState(stateGovtId);
    console.log({ politicalPartyResponse });
    if (politicalPartyResponse.status && politicalPartyResponse.status) {
      const formatedPoliticalPartyList = politicalPartyResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      const symbolsResponse = await getAllSymbols();
      console.log({ symbolsResponse });
      if (symbolsResponse.status) {
        const formatedSymbolsList = symbolsResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const formInputData = getCandidateFormInputs({
          formatedPoliticalPartyList,
          formatedSymbolsList,
          handleCandidateFileUpload,
          handleAffidavitFileUpload,
        });
        setFormInputs(formInputData);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
    pcId,
    electionId,
    pcName,
    stateGovtId,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <CandidateCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default CandidateCreateComponent;
