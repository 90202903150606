import { store } from "@src/store";
import {
  constructFilterData,
  filtersToQuery,
  getMenuFilters,
} from "@utils/common";
import {
  addFilterItem,
  setFilterList,
  updateFilterParams,
} from "@reducers/filter.reducer";

export const getFilterQueryString = (menuUrl, params) => {
  const filterList = store.getState().filter.filterList;
  let queryStr = params?.filters || null;
  if (!queryStr) {
    const menuFilters = getMenuFilters(filterList, menuUrl);
    queryStr =
      menuFilters && menuFilters.filters && menuFilters.filters.length > 0
        ? filtersToQuery(menuFilters.filters)
        : "";
  }

  const currentFilterIndex = filterList.findIndex(
    (filter) => filter.menuUrl === menuUrl
  );

  if (currentFilterIndex > -1) {
    store.dispatch(updateFilterParams({ index: currentFilterIndex, params }));
  } else {
    store.dispatch(addFilterItem({ menuUrl, filterList: [], params }));
  }

  return queryStr;
};

export const constructFilterDataAndGetQueryString = (filters, menuUrl) => {
  const filterList = store.getState().filter.filterList;
  const allFilterList = constructFilterData(filterList, menuUrl, filters);
  store.dispatch(setFilterList(allFilterList));
  const menuFilters = allFilterList.find(
    (filter) => filter.menuUrl === menuUrl
  );
  const queryStr =
    menuFilters.filters && menuFilters.filters.length > 0
      ? filtersToQuery(menuFilters.filters)
      : "";
  return queryStr;
};

export const getParamsData = (filterList, menuUrl, rawParams = null) => {
  const data = filterList.find((filter) => filter.menuUrl === menuUrl);
  if (data) return { ...data.params, menuUrl };
  return rawParams ? { ...rawParams, menuUrl } : { menuUrl };
};

export const getCurrentFilter = (pathname) => {
  const filterList = store.getState().filter.filterList;
  const url = pathname.substring(1);
  const currentFilter = filterList.find((filter) => filter.menuUrl === url);
  const currentFilterIndex = filterList.findIndex(
    (filter) => filter.menuUrl === url
  );
  return { filter: currentFilter, currentFilterIndex };
};
