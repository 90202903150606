import React from "react";
import PageNotFoundTemplateComponent from "./page-not-found-template.component";

function PageNotFoundComponent() {
  const goBack = () => {
    window.history.back();
  };
  return <PageNotFoundTemplateComponent goBack={goBack} />;
}

export default PageNotFoundComponent;
