import React from "react";
import CandidateChartComponent from "./chart.component";
import CandidateDashboardComponent from "./dashboard/dashboard.component";

const CandidateSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <CandidateChartComponent />}
      {type === "DASHBOARD" && <CandidateDashboardComponent />}
    </>
  );
};

export default CandidateSwapComponent;
