import LayoutWrapperComponent from "@src/components/organisms/layout-wrapper/layout-wrapper.component";
import React from "react";
import { Route } from "react-router-dom";

const LayoutComponent = ({ children, ...reset }) => {
  return <LayoutWrapperComponent>{children}</LayoutWrapperComponent>;
};

const AppLayoutComponent = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LayoutComponent>
          <Component {...matchProps} />
        </LayoutComponent>
      )}
    />
  );
};

export default AppLayoutComponent;
