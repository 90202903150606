import FormBuilderRenderInputs from "@molecules/form-builder/components/render-inputs.component";
import React from "react";
import { isMobile } from "react-device-detect";

const RenderInputsComponent = ({
  column,
  columnIndex,
  rowIndex,
  formInputs,
  handleChange,
  handleKeyDown,
  staticHeader,
  handleFileUpload,
  handleDeleteFile,
}) => {
  if (column.type != "DATA")
    return (
      <div
        className={`col-md-${column.isFullLine ? "12 mb-1" : column.size}`}
        style={{ padding: "0px 5px" }}
      >
        <FormBuilderRenderInputs
          index={columnIndex}
          formInputs={formInputs}
          formInput={{
            ...column,
            label: !staticHeader ? column.label : null,
            isVertical: true,
            id: column.id + rowIndex,
            staticHeader,
            handleInputIconClick: (formInputs) => {
              if (column.handleInputIconClick)
                column.handleInputIconClick(formInputs, { rowIndex });
            },
          }}
          handleChange={(column, index, value) =>
            handleChange({
              column,
              columnIndex: index,
              rowIndex,
              value,
            })
          }
          handleKeyDown={handleKeyDown}
          handleDeleteFile={(column, index, value) =>
            handleDeleteFile({
              column,
              columnIndex: index,
              rowIndex,
              file: value,
            })
          }
          handleFileUpload={(column, index, value) => {
            console.log({ column, index, value });
            handleFileUpload({
              column,
              columnIndex: index,
              rowIndex,
              file: value,
            });
          }}
        />
      </div>
    );
};

export default RenderInputsComponent;
