import { APP_NAME } from "@constants/app.constants";
import ACBoothCandidatesComponent from "@screens/catalog/ac-booth/ac-booth-candidates/ac-booth-candidates.component";
import ACBoothCreateComponent from "@screens/catalog/ac-booth/ac-booth-create/ac-booth-create.component";
import ACBoothEditComponent from "@screens/catalog/ac-booth/ac-booth-edit/ac-booth-edit.component";
import ACBoothListComponent from "@screens/catalog/ac-booth/ac-booth-list/ac-booth-list.component";
import UsersCreateComponent from "@screens/catalog/ac-booth/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog/ac-booth/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog/ac-booth/user/users-list/users-list.component";

const catalogAcBoothRoutes = [
  {
    name: `AC Booth - ${APP_NAME}`,
    path: "/catalog/ac-booth",
    icon: "briefcase",
    component: ACBoothListComponent,
  },
  {
    name: `Create AC Booth - ${APP_NAME}`,
    path: "/catalog/ac-booth/create",
    component: ACBoothCreateComponent,
  },
  {
    name: `Edit AC Booth- ${APP_NAME}`,
    path: "/catalog/ac-booth/edit",
    component: ACBoothEditComponent,
  },

  {
    name: `AC Booth User - ${APP_NAME}`,
    path: "/catalog/ac-booth/user",
    component: UsersListComponent,
  },
  {
    name: `Create AC Booth User - ${APP_NAME}`,
    path: "/catalog/ac-booth/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit AC Booth User - ${APP_NAME}`,
    path: "/catalog/ac-booth/user/edit",
    component: UsersEditComponent,
  },

  {
    name: `Select Booth Candidate- ${APP_NAME}`,
    path: "/catalog/ac-booth/select-candidate",
    component: ACBoothCandidatesComponent,
  },
];

export default catalogAcBoothRoutes;
