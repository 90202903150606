import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  tableControls as rawControls,
} from "@screens/master-urban/ac-urban/ac-urban-list/model/ac-urban-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import { deleteDataApi, getDataApi } from "./ac-urban-list.component.service";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";
import ACUrbanListTemplateComponent from "./ac-urban-list-template.component";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";
import { getAcByPcId } from "@screens/master/ac/ac-list/ac-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_URBAN_ALERTS: { DELETE_ALERT, REFRESH_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const ACUrbanListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const [tableActions, setTableActions] = useState([]);
  const tableParams = getParamsData(filterList, menuUrl);
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const [basicData, setBasicData] = useState([]);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { MASTER_AC_URBAN_EXPORT } = END_POINT;
  const [filterState, setFilterState] = useState(filters);

  console.log({ tableParams });

  const getData = async (params = null) => {
    const queryStr = getFilterQueryString(menuUrl, params);
    const acUrbanData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (acUrbanData.status) {
      console.log(acUrbanData);
      setTableData(acUrbanData.data);
      setTotalCount(acUrbanData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({ url: `${MASTER_AC_URBAN_EXPORT}?token=${token}${queryStr}` });
    window.open(`${MASTER_AC_URBAN_EXPORT}?token=${token}${queryStr}`);
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "master-ac-urban",
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const handleDashbordClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "DASHBOARD" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      switch (control.name) {
        case "Chart":
          return {
            ...control,
            onClick: handleChartClick,
          };
        case "Refresh":
          return {
            ...control,
            onClick: handleRefreshClick,
          };
        case "Dashboard":
          return {
            ...control,
            onClick: handleDashbordClick,
          };
      }
    });
    setTableControls(updatedTableControls);

    const stateResponse = await getAllStates();
    console.log({ stateResponse });

    let copyFilter = [...rawFilters];

    if (stateResponse.status) {
      const formattedStates = stateResponse.data.map((state) => {
        return {
          name: state.name,
          value: state.id,
        };
      });
      // INFO: 0 is for state index
      copyFilter[0].options = formattedStates;
      copyFilter[0].onChange = handleStateChange;
    }

    setFilterState(copyFilter);
  };

  const handleStateChange = async (name, value, filters) => {
    // API call to get district data based on state id
    // value contains state id
    let copyFilter = [...filters];
    //INFO: as filter values are getting refreshed we need to update the default value again for state.
    // 0 index for state
    copyFilter[0] = { ...copyFilter[0], value };

    console.log({ name, value, filters });
    const pcResponse = await getPcByStateId(value);
    console.log({ pcResponse });
    if (pcResponse.status) {
      const formattedPc = pcResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      copyFilter[1] = { ...copyFilter[1], options: formattedPc };
      copyFilter[1].onChange = handlePCChange;
    }

    setFilterState(copyFilter);
  };

  const handlePCChange = async (name, value, filters) => {
    // API call to get district data based on state id
    // value contains state id
    let copyFilter = [...filters];
    //INFO: as filter values are getting refreshed we need to update the default value again for state.
    // 0 index for state
    copyFilter[1] = { ...copyFilter[1], value };

    console.log({ name, value, filters });
    const acResponse = await getAcByPcId(value);
    console.log({ acResponse });
    if (acResponse.status) {
      const formattedAc = acResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      copyFilter[2] = { ...copyFilter[2], options: formattedAc };
      //copyFilter[1].onChange = handleDistrictChange;
    }

    setFilterState(copyFilter);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ACUrbanListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filterState,
        tableActions,
        openDetailPanel,
        basicData,
        tableControls,
        swapContentType,
        tableParams,
      }}
      templateEvents={{
        getData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default ACUrbanListComponent;
