import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { breadCrumbs } from "../sms-template-list/model/sms-template-list.constants";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";

const ActionContent = ({ handleSaveClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleSaveClick}
    >
      <FeatherIcon icon="save" size={17} />
    </button>
  );
};

const SmsTemplateEditTemplateComponent = ({
  templateModal: { formTriggers, formInputs },
  templateEvents: { handleSmsTemplateSubmit, handleTriggerClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Edit SMS Template"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Edit ", menu_url: "/system/sms-template/edit" },
        ]}
        actionContent={
          <ActionContent handleSaveClick={() => handleTriggerClick("SUBMIT")} />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSmsTemplateSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default SmsTemplateEditTemplateComponent;
