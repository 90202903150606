import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { breadCrumbs } from "../users-list/model/users-list.constants";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { Col, Row } from "reactstrap";
import FormInputCheckboxComponent from "@atoms/form-input/form-input-checkbox/form-input-checkbox.component";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const UsersCreateTemplateComponent = ({
  templateModal: {
    formTriggers,
    formInputs,
    states,
    districts,
    pcs,
    acs,
    acBooths,
  },
  templateEvents: {
    handleUsersSubmit,
    handleTriggerClick,
    handleStateChange,
    handleDistrictChange,
    handlePCChange,
    handleACChange,
    handleACBoothChange,
    handleBackClick,
  },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Create User"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Create ", menu_url: "/user-administration/users/create" },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <Row>
          <Col md={6}>
            <FormBuilderComponent
              inputFields={formInputs}
              submitTrigger={formTriggers.submitTrigger}
              onSubmit={handleUsersSubmit}
              isVertical={true}
            />
          </Col>
          <Col md={6} className="mt-1">
            {states.length > 0 && <p>State List</p>}
            {states.length > 0 &&
              states.map((state) => (
                <FormInputCheckboxComponent
                  key={state.id}
                  label={state.name}
                  name={state.name}
                  checked={state?.checked}
                  onChange={handleStateChange}
                />
              ))}
            <div style={{ height: 300, overflow: "auto", marginTop: 14 }}>
              {districts.length > 0 && <p>District List</p>}
              {districts.length > 0 &&
                districts.map((district) => (
                  <FormInputCheckboxComponent
                    key={district.id}
                    label={district.name}
                    name={district.name}
                    checked={district.checked}
                    onChange={handleDistrictChange}
                  />
                ))}
            </div>
            <div style={{ height: 300, overflow: "auto", marginTop: 14 }}>
              {pcs.length > 0 && <p>PC List</p>}
              {pcs.length > 0 &&
                pcs.map((pc) => (
                  <FormInputCheckboxComponent
                    key={pc.id}
                    label={pc.name}
                    name={pc.name}
                    checked={pc.checked}
                    onChange={handlePCChange}
                  />
                ))}
            </div>
            <div style={{ height: 300, overflow: "auto", marginTop: 14 }}>
              {acs.length > 0 && <p>AC List</p>}
              {acs.length > 0 &&
                acs.map((ac) => (
                  <FormInputCheckboxComponent
                    key={ac.id}
                    label={ac.name}
                    name={ac.name}
                    checked={ac.checked}
                    onChange={handleACChange}
                  />
                ))}
            </div>
            <div style={{ height: 300, overflow: "auto", marginTop: 14 }}>
              {acBooths.length > 0 && <p>AC Booth List</p>}
              {acBooths.length > 0 &&
                acBooths.map((acBooth) => (
                  <FormInputCheckboxComponent
                    key={acBooth.id}
                    label={acBooth.name}
                    name={acBooth.name}
                    checked={acBooth.checked}
                    onChange={handleACBoothChange}
                  />
                ))}
            </div>
          </Col>
        </Row>
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default UsersCreateTemplateComponent;
