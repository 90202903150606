import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createEntry } from "./election-rural-create.component.service";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import ElectionRuralCreateTemplateComponent from "./election-rural-create-template.component";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import { getElectionFormInputs } from "../model/election.modal";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import { menuUrl } from "../election-rural-list/model/election-rural-list.constants";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ElectionRuralCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let electionPayload = {};
    for (let i = 0; i < values.length; i++) {
      electionPayload[values[i].name] = values[i].value;
    }
    console.log({ electionPayload });
    electionPayload["election_category"] = "RURAL";
    electionPayload["type_of_election"] = "RURAL";
    // INFO : call create api
    const electionResponse = await createEntry(electionPayload);

    // INFO : check api response and show relevant alert message
    if (electionResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: electionResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      inputs[4].options = formattedDistrictList;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getElectionFormInputs({
        states: formatedStateList,
        districts: [],
        onStateChange,
        initialValues: {},
      });
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ElectionRuralCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ElectionRuralCreateComponent;
