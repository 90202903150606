import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuUrl } from "../version-list/model/version-list.constants";
import { createEntry } from "./version-create.component.service";
import VersionTemplateCreateComponent from "./version-create-template.component";
import { getVersionFormInputs } from "../model/version.modal";
import { getBugDataByStatusApi } from "@screens/bug-tracker/bug-tracker-list/bug-tracker-list.component.service";
import { tableColumn } from "@screens/bug-tracker/bug-tracker-list/model/bug-tracker-list.constants";
const {
  ALERT_TYPES: { SUCCESS, ERROR },
  VERSION_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const VersionCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let versionPayload = {};
    for (let i = 0; i < values.length; i++) {
      versionPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const versionResponse = await createEntry(versionPayload);

    // INFO : check api response and show relevant alert message
    if (versionResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: versionResponse.message })
      );
    }
  };
  const bugData = async () => {
    const bugStatusData = await getBugDataByStatusApi("Completed");
    console.log({ bugStatusData });
    setTableData(bugStatusData.data.list);
  };

  const templateModal = {
    formTriggers,
    formInputs: getVersionFormInputs({}),
    tableData,
    tableColumn,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    bugData,
  };

  return menuAccess ? (
    <VersionTemplateCreateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default VersionCreateComponent;
