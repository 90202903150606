import { APP_NAME } from "@constants/app.constants";
import HomePageComponent from "@screens/home/home-screen-component";

const homePageRoutes = [
  {
    name: `Home - ${APP_NAME}`,
    icon: "map-pin",
    path: "/home",
    component: HomePageComponent,
  },
];

export default homePageRoutes;
