import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import CandidateSwapComponent from "./components/candidate-swap.component";

function CandidateTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableActions,
    tableControls,
    swapContentType,
    tableParams,
  },
  templateEvents: {
    getCandidateData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;
  console.log({ settingFormEdit });

  const ActionContent = ({ handleCreateClick }) => {
    return <></>;
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Candidate"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getCandidateData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        handleDownload={handleDownload}
        handleDashboard={handleDashboard}
        tableActions={tableActions}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <CandidateSwapComponent type={swapContentType} />
          ) : null
        }
        tableParams={tableParams}
      />
    </PageWrapperComponent>
  );
}

export default CandidateTemplateComponent;
