import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../voter-list/model/voter-list.constants";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const RuralVoterEditTemplateComponent = ({
  templateModal: {
    formTriggers,
    formInputs,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
  },
  templateEvents: { handleSubmit, handleTriggerClick, handleBackClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Edit Village Panchayat Ward Voter"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: villagePanchayatWardName + " - Voter(s)",
            menu_url: `/catalog-rural/village-panchayat-ward/voter?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}`,
          },
          { name: "Edit", menu_url: null },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default RuralVoterEditTemplateComponent;
