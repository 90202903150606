import { APP_NAME } from "@constants/app.constants";
import DistrictPanchayatUnionWardCreateComponent from "@screens/master-rural/district-union-ward/district-union-ward-create/district-union-ward-create.component";
import DistrictPanchayatUnionWardEditComponent from "@screens/master-rural/district-union-ward/district-union-ward-edit/district-union-ward-edit.component";
import DistrictPanchayatUnionWardFileUploadComponent from "@screens/master-rural/district-union-ward/district-union-ward-file/district-union-ward-file.component";
import DistrictPanchayatUnionWardListComponent from "@screens/master-rural/district-union-ward/district-union-ward-list/district-union-ward-list.component";

const masterDistrictUnionWardRoutes = [
  {
    name: "District Union List",
    icon: "framer",
    path: "/master-rural/district-union-ward",
    component: DistrictPanchayatUnionWardListComponent,
  },

  {
    name: `Create District Union Ward - ${APP_NAME}`,
    path: "/master-rural/district-union-ward/create",
    component: DistrictPanchayatUnionWardCreateComponent,
  },

  {
    name: `Edit District Union Ward- ${APP_NAME}`,
    path: "/master-rural/district-union-ward/edit",
    component: DistrictPanchayatUnionWardEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-rural/district-union-ward/upload",
    component: DistrictPanchayatUnionWardFileUploadComponent,
  },
];

export default masterDistrictUnionWardRoutes;
