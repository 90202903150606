import { getDashboardDataApi } from "@screens/dashboard/dashboard.component.service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import CandidateDashboardTemplateComponent from "./dashboard-template.component";
import { candidateDashboardData } from "./dashboard.component.service";

const CandidateDashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const acId = new URLSearchParams(location.search).get("acId");
  const electionId = new URLSearchParams(location.search).get("electionId");

  const onLoad = async () => {
    let candidatePayload = {};
    candidatePayload["ac_id"] = acId;
    candidatePayload["election_id"] = electionId;
    const dashboardResponse = await candidateDashboardData(candidatePayload);
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formateData = {
        totalPoliticalPartyCandidate:
          dashboardResponse.data.total_political_party_candidate,
        totalIndependentPartyCandidate:
          dashboardResponse.data.total_independent_candidate,
        totalPoliticalPartyCandidateContesting:
          dashboardResponse.data.total_political_contesting_count,
        totalIndependentCandidateContesting:
          dashboardResponse.data.total_independent_contesting_count,
        totalCandidate: dashboardResponse.data.total_candidate,
        totalAcceptedCandidate: dashboardResponse.data.total_accepted_candidate,
        totalRejectedCandidate: dashboardResponse.data.total_rejected_candidate,
        totalWithdrawnCandidate:
          dashboardResponse.data.total_withdrawn_candidate,
      };
      setDashboardData(formateData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <CandidateDashboardTemplateComponent
        templateModal={{
          dashboardData,
        }}
      />
    </>
  );
};

export default CandidateDashboardComponent;
