import { APP_NAME } from "@constants/app.constants";
import EmailTemplateCreateComponent from "@screens/system/email-template/email-template-create/email-template-create.component";
import EmailTemplateEditComponent from "@screens/system/email-template/email-template-edit/email-template-edit.component";
import EmailTemplateListComponent from "@screens/system/email-template/email-template-list/email-template-list.component";

const emailTemplateRoutes = [
  {
    name: "Email Template",
    icon: "at-sign",
    path: "/system/email-template",
    component: EmailTemplateListComponent,
  },
  {
    name: `Create Email Template - ${APP_NAME}`,
    path: "/system/email-template/create",
    component: EmailTemplateCreateComponent,
  },
  {
    name: `Edit Email Template - ${APP_NAME}`,
    path: "/system/email-template/edit",
    component: EmailTemplateEditComponent,
  },
];

export default emailTemplateRoutes;
