import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../users-list/model/users-list.constants";
import UsersEditTemplateComponent from "./users-edit-template.component";
import { getUserEditFormInputs } from "@screens/catalog/users/model/users-edit.modal";
import {
  editUsersApi,
  getAllRoles,
} from "@screens/catalog/users/users-form.component.service";
import { uploadUserImage } from "@screens/user-administration/users/users-list/users-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { getAllClients } from "@screens/user-administration/client/client-list/client-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  USER_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const UsersEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const stateId = new URLSearchParams(location.search).get("stateId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const stateName = new URLSearchParams(location.search).get("stateName");

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog/states/user?stateId=${stateId}&electionId=${electionId}&stateName=${stateName}`
    );
  };

  const handleUsersSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct users payload
    let usersPayload = {};
    for (let i = 0; i < values.length; i++) {
      usersPayload[values[i].name] = values[i].value;
    }
    usersPayload["election_id"] = electionId;
    usersPayload["state_id"] = stateId;

    // INFO : call edit api
    const usersResponse = await editUsersApi(
      location?.state?.row?.id,
      usersPayload
    );

    // INFO : check api response and show relevant alert message
    if (usersResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(
        `/catalog/states/user?stateId=${stateId}&electionId=${electionId}&stateName=${stateName}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: usersResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const rolesListResponse = await getAllRoles();
    console.log({ rolesListResponse });
    if (rolesListResponse.status) {
      const formatedRoleList = rolesListResponse.data.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });

      const clientDataResponse = await getAllClients();
      console.log({ clientDataResponse });
      if (clientDataResponse.status && clientDataResponse.status) {
        const formatedClientList = clientDataResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const formInputData = getUserEditFormInputs(
          formatedRoleList,
          formatedClientList,
          {
            name: location?.state?.row?.name || "",
            email: location?.state?.row?.email || "",
            mobile: location?.state?.row?.mobile || "",
            password: location?.state?.row?.password || "",
            roleId: location?.state?.row?.role_id || "",
            userImage: location?.state?.row?.user_image || "",
            whatsappNumber: location?.state?.row?.whatsapp_number || "",
            expiryDate: location?.state?.row?.expiry_date || "",
            clientId: location?.state?.row?.client_id || "",
            deleteProtected: location?.state?.row?.delete_protected,
            type: location?.state?.row?.type || "",
          },
          handleUserImageFileUpload
        );
        console.log({ formatedRoleList, formInputData });
        setFormInputs(formInputData);
      }
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleUserImageFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadUserResponse = await uploadUserImage(file[0]);
    console.log({ uploadUserResponse });
    if (uploadUserResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value =
        BASE_URL + "app-source/user-image/" + uploadUserResponse.data;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
    stateId,
    electionId,
    stateName,
  };

  const templateEvents = {
    handleUsersSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UsersEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UsersEditComponent;
