const tableTitle = "Settings";
const keyColumn = "setting_id";
const menuUrl = "system/settings";
const tableColumn = [
  {
    id: "setting_name",
    numeric: false,
    disablePadding: true,
    label: "Setting Name",
  },
  {
    id: "setting_value",
    numeric: false,
    disablePadding: false,
    label: "Setting Value",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Settings", menu_url: "/system/settings" },
];

const filters = [
  {
    name: "setting_name",
    id: "setting_name",
    label: "Setting Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "setting_value",
    id: "setting_value",
    label: "Setting Value",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
