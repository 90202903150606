import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

function UpcomingElectionListTemplateComponent({
  templateModal: { breadCrumbs },
}) {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Upcoming Election"}
        breadCrumbs={breadCrumbs}
      />
    </PageWrapperComponent>
  );
}

export default UpcomingElectionListTemplateComponent;
