import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import FeatherIcon from "feather-icons-react";
import AvatarImage from "../../../assets/images/portrait/small/avatar-s-11.jpg";
import ConfirmDialogComponent from "./components/confirm-dialog.component";
// ** Reactstrap Imports
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Navbar,
  Badge,
} from "reactstrap";
import ResetPasswordComponent from "@organisms/reset-password/reset-password.component";
import HelmetComponent from "@atoms/helmet/helmet.component";
import FavoriteMenuComponent from "../favorite-menu/favorite-menu.component";
import { getCurrentElectionName } from "@utils/sstring.util";

const HeaderTemplateComponent = ({
  templateModal: {
    openProfile,
    pageTitle,
    logoutOpen,
    user,
    openResetPassword,
    notifications,
    openNotifications,
    currentElection,
  },
  templateEvents: {
    handleThemeClick,
    handleOpenProfile,
    logoutModal,
    logoutCancel,
    confirmLogout,
    handleProfilePage,
    handleResetPassword,
    setMenuVisibility,
    updateNotificationCount,
    handleOpenNotifications,
    handleRedirectToElection,
  },
}) => {
  const { theme } = useSelector((state) => state.app);
  const location = useLocation();
  const menuSlug = location.pathname.split("/")[1];

  const renderUserProfileView = () => {
    console.log({ menuSlug, currentElection });
    return (
      <Dropdown
        isOpen={openProfile}
        toggle={handleOpenProfile}
        tag="li"
        className="dropdown-user nav-item"
      >
        <DropdownToggle
          tag="a"
          className="nav-link dropdown-user-link"
          aria-expanded={openProfile}
          onClick={handleOpenProfile}
        >
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name fw-bold">{user?.name || ""}</span>
            <span className="user-status">{user?.role_name || ""}</span>
          </div>
          <img
            className="round"
            src={AvatarImage}
            alt="avatar"
            height="40"
            width="40"
          />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag="a">
            <FeatherIcon icon="user" size={17} />
            <span className="align-middle" onClick={handleProfilePage}>
              {" "}
              Profile
            </span>
          </DropdownItem>
          <DropdownItem tag="a">
            <FeatherIcon icon="lock" size={17} />
            <span className="align-middle" onClick={handleResetPassword}>
              {" "}
              Reset Password
            </span>
          </DropdownItem>
          <DropdownItem tag="a" onClick={logoutModal}>
            <FeatherIcon icon="log-out" size={17} />{" "}
            <span className="align-middle"> Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderNotificationView = () => {
    return (
      <Dropdown
        isOpen={openNotifications}
        toggle={handleOpenNotifications}
        tag="li"
        className="dropdown-notification nav-item me-25"
      >
        <DropdownToggle
          tag="a"
          className="nav-link cursor-pointer"
          data-toggle="dropdown"
          aria-expanded={openNotifications}
          onClick={() => handleOpenNotifications()}
        >
          <FeatherIcon icon="bell" size={19} />
          {notifications && notifications.count && notifications.count > 0 ? (
            <Badge pill color="danger" className="badge-up">
              {notifications?.count}
            </Badge>
          ) : (
            ""
          )}
        </DropdownToggle>
        <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0">
          <li className="dropdown-menu-header">
            <DropdownItem className="d-flex" tag="div" header>
              <h4 className="notification-title mb-0 me-auto">Notifications</h4>
              <Badge tag="div" color="light-primary" pill>
                {notifications?.count || 0} New
              </Badge>
            </DropdownItem>
          </li>
          <PerfectScrollbar
            component="li"
            className="media-list scrollable-container"
            options={{
              wheelPropagation: false,
            }}
          >
            {notifications &&
            notifications.result &&
            notifications.result.length > 0 ? (
              notifications.result.map((item, index) => {
                return (
                  <Link
                    key={index}
                    className="d-flex"
                    onClick={(e) => updateNotificationCount(e, item)}
                  >
                    <div className="list-item d-flex align-items-center">
                      <div className="me-1">
                        <div className="avatar avatar-lg">
                          <div
                            className="avatar-content font-medium-3"
                            style={{ lineHeight: "10px" }}
                          >
                            {user?.name
                              ? user.name[0].toUpperCase() +
                                user.name[1].toUpperCase()
                              : "G"}
                          </div>
                        </div>
                      </div>
                      <div className="list-item-body flex-grow-1">
                        <p className="media-heading">{item.message}</p>
                        <small className="notification-text">
                          {item.redirect}
                        </small>
                      </div>
                    </div>
                  </Link>
                );
              })
            ) : (
              <div className="list-item d-flex align-items-center">
                <div className="list-item-body flex-grow-1">
                  No Notifications
                </div>
              </div>
            )}
          </PerfectScrollbar>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <Navbar
      expand="lg"
      container={false}
      light={theme}
      dark={!theme}
      className={`header-navbar navbar navbar-expand-lg align-items-center floating-nav  ${
        theme === "dark" ? "navbar-dark" : "navbar-light"
      } navbar-shadow container-xxl`}
    >
      <HelmetComponent title={pageTitle} />
      <div className="navbar-container d-flex content">
        <div className="bookmark-wrapper d-flex align-items-center">
          <ul className="nav navbar-nav d-xl-none">
            <li className="nav-item">
              <a
                className="nav-link menu-toggle"
                onClick={() => setMenuVisibility(true)}
              >
                <FeatherIcon icon={"menu"} />
              </a>
            </li>
          </ul>
          <FavoriteMenuComponent />
          <div>
            <p style={{ fontSize: 15 }}>
              <span
                onClick={() => handleRedirectToElection()}
                style={{
                  marginTop: -3,
                  position: "absolute",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              >
                {(currentElection.type_of_election == "AC" ||
                  currentElection.type_of_election == "PC") &&
                  menuSlug === "catalog" && (
                    <>
                      {getCurrentElectionName(currentElection)}
                      <FeatherIcon
                        icon={"edit"}
                        width={15}
                        style={{ marginLeft: 10, marginTop: -5 }}
                      />
                    </>
                  )}
                {currentElection.election_category === "URBAN" &&
                  menuSlug === "catalog-urban" && (
                    <>
                      {getCurrentElectionName(currentElection)}
                      <FeatherIcon
                        icon={"edit"}
                        width={15}
                        style={{ marginLeft: 10, marginTop: -5 }}
                      />
                    </>
                  )}
                {currentElection.election_category === "RURAL" &&
                  menuSlug === "catalog-rural" && (
                    <>
                      {getCurrentElectionName(currentElection)}
                      <FeatherIcon
                        icon={"edit"}
                        width={15}
                        style={{ marginLeft: 10, marginTop: -5 }}
                      />
                    </>
                  )}
              </span>
            </p>
          </div>
        </div>
        <ul className="nav navbar-nav align-items-center ms-auto">
          <li className="nav-item d-none d-lg-block">
            <a className="nav-link nav-link-style" onClick={handleThemeClick}>
              <FeatherIcon
                icon={`${theme === "dark" ? "sun" : "moon"}`}
                style={{ width: 20 }}
              />
            </a>
          </li>
          {renderNotificationView()}
          {renderUserProfileView()}
        </ul>
        <ResetPasswordComponent
          open={openResetPassword}
          handleResetPassword={handleResetPassword}
        />
        <ConfirmDialogComponent
          templateModal={{ logoutOpen }}
          templateEvents={{ logoutCancel, confirmLogout }}
        />
      </div>
    </Navbar>
  );
};

export default HeaderTemplateComponent;
