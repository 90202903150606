import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import DetailPanelComponent from "@molecules/detail-panel/detail-panel.component";
import AccordionComponent from "@molecules/accordion/accordion.component";
import ListComponent from "@atoms/list/list.component";
import ElectedMemberComponent from "@src/components/organisms/elected-member/elected-member.component";
import VoterListContentComponent from "@src/components/organisms/voter-list-content/voter-list-content.component";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";
import ModalComponent from "@atoms/modal/modal.component";
import CatalogAcSwapComponent from "./components/ac-swap.component";

function ACListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    tableActions,
    totalCount,
    filters,
    tableConfig,
    openDetailPanel,
    basicData,
    electedMember,
    rowData,
    currentElection,
    openTrashModal,
    password,
    tableParams,
    tableControls,
    swapContentType,
  },
  templateEvents: {
    getACData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
    setOpenDetailPanel,
    toggleDetailPanel,
    handleChange,
    confirmTrashData,
    cancelTrashData,
    handleTrashAcVotersClick,
  },
}) {
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  const detailedPanelFormat = ({
    electedMember,
    basicData,
    rowData,
    toggleDetailPanel,
    currentElection,
    handleTrashAcVotersClick,
  }) => {
    {
      if (currentElection?.type_of_election === "PC")
        return [
          {
            id: 1,
            heading: "Basic AC Details",
            content: <ListComponent items={basicData} />,
          },
          {
            id: 2,
            heading: "Voter List",
            content: (
              <VoterListContentComponent
                rowData={rowData}
                toggleDetailPanel={toggleDetailPanel}
                setOpenDetailPanel={setOpenDetailPanel}
              />
            ),
          },
        ];
      else
        return [
          {
            id: 1,
            heading: "Elected Member",
            expanded: true,
            content: electedMember ? (
              <ElectedMemberComponent memberData={electedMember} />
            ) : (
              <> </>
            ),
          },
          {
            id: 2,
            heading: "Basic AC Details",
            content: <ListComponent items={basicData} />,
          },
          {
            id: 3,
            heading: "Voter List",
            content: (
              <VoterListContentComponent
                from="AC"
                rowData={rowData}
                toggleDetailPanel={toggleDetailPanel}
                handleTrashAcVotersClick={handleTrashAcVotersClick}
              />
            ),
          },
        ];
    }
  };

  const ActionContent = ({ handleCreateClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm"
        onClick={handleCreateClick}
        style={{ marginRight: 10 }}
      >
        <FeatherIcon icon="plus" size={17} />
        &nbsp;
      </button>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All AC"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableActions={tableActions}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getACData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        //handleDownload={handleDownload}
        handleDashboard={handleDashboard}
        tableConfig={tableConfig}
        tableParams={tableParams}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <CatalogAcSwapComponent type={swapContentType} />
          ) : null
        }
      />
      <DetailPanelComponent
        open={openDetailPanel}
        toggleDrawer={setOpenDetailPanel}
        drawerWidth={40}
      >
        <AccordionComponent
          items={detailedPanelFormat({
            electedMember,
            basicData,
            rowData,
            toggleDetailPanel,
            currentElection,
            handleTrashAcVotersClick,
          })}
        />
      </DetailPanelComponent>

      <ModalComponent
        open={openTrashModal}
        title={"Delete !"}
        message={
          "Are you sure to delete the voter data from - " + rowData?.name + "?"
        }
        primaryText={"Delete"}
        secondaryText={"Cancel"}
        content={
          <div className="test">
            <FormInputTextComponent
              label={"Password"}
              name={"password"}
              id={"password"}
              variant={"outlined"}
              type={"password"}
              value={password}
              onChange={handleChange}
              size={"small"}
            />
          </div>
        }
        primaryAction={confirmTrashData}
        secondaryAction={cancelTrashData}
      />
    </PageWrapperComponent>
  );
}

export default ACListTemplateComponent;
