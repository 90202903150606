import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../users-list/model/users-list.constants";
import { editUsersApi } from "./users-edit.component.service";
import UsersEditTemplateComponent from "./users-edit-template.component";
import { getAllRoles } from "../users-create/users-create.component.service";
import { getUserEditFormInputs } from "../model/users-edit.modal";
import { getAllElection } from "@screens/election/election-list/election-list.component.service";
import { getAllStates } from "@screens/catalog/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getAllPc } from "@screens/catalog/pc/pc-list/pc-list.component.service";
import { getAllAc } from "@screens/catalog/ac/ac-list/ac-list.component.service";
import { getAcBoothByAcIds } from "@screens/catalog/ac-booth/ac-booth-list/ac-booth-list.component.service";
import { removeLastComma } from "@utils/string.utils";
import { uploadUserImage } from "../users-list/users-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { getAllClients } from "@screens/user-administration/client/client-list/client-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  USER_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const UsersEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);
  const [currentElectionId, setCurrentElectionId] = useState(
    location?.state?.row?.election_id
  );
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [pcs, setPcs] = useState([]);
  const [acs, setAcs] = useState([]);
  const [acBooths, setAcBooths] = useState([]);
  const [electionPermissions, setElectionPermissions] = useState(
    location?.state?.row?.permissions ? location?.state?.row?.permissions : {}
  );

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const onChangeShowByElection = async (value, inputs) => {
    const electionId = inputs[1].value;
    getStateDetails(electionId);
    //getPCDetails(electionId);
    //getACDetails(electionId);
    setCurrentElectionId(location?.state?.row?.election_id);
  };

  const getStateDetails = async (electionId) => {
    const statesResponse = await getAllStates(electionId);
    if (statesResponse.status) {
      const formattedStates = statesResponse.data.map((state) => {
        const selectedStates = location?.state?.row?.permissions?.states || [];
        const checked = selectedStates.find((ss) => ss.value === state.id);
        return {
          label: state.name,
          value: state.id,
          name: state.name,
          checked: checked ? true : false,
        };
      });
      setStates(formattedStates);
    }
  };

  const getDistrictDetails = async (stateId) => {
    const districtResponse = await getDistrictByStateId(stateId);
    if (districtResponse.status) {
      const formattedDistrict = districtResponse.data.map((district) => {
        const selectedDistricts =
          location?.state?.row?.permissions?.districts || [];
        const checked = selectedDistricts.find(
          (sd) => sd.value === district.id
        );
        return {
          label: district.name,
          value: district.id,
          name: district.name,
          checked: checked ? true : false,
        };
      });
      setDistricts(formattedDistrict);
    }
  };

  const getPCDetails = async (electionId, districtId = "") => {
    const pcResponse = await getAllPc(electionId, districtId);
    if (pcResponse.status) {
      const formattedPC = pcResponse.data.map((pc) => {
        const selectedPcs = location?.state?.row?.permissions?.pcs || [];
        const checked = selectedPcs.find((sp) => sp.value === pc.id);
        return {
          label: pc.name,
          value: pc.id,
          name: pc.name,
          checked: checked ? true : false,
        };
      });
      setPcs(formattedPC);
    }
  };

  const getACDetails = async (electionId, pcIds = "", districtIds = "") => {
    const acResponse = await getAllAc(electionId, pcIds, districtIds);
    if (acResponse.status) {
      const formattedAC = acResponse.data.map((ac) => {
        const selectedAcs = location?.state?.row?.permissions?.acs || [];
        const checked = selectedAcs.find((sa) => sa.value === ac.id);
        return {
          label: ac.name,
          value: ac.id,
          name: ac.name,
          checked: checked ? true : false,
        };
      });
      setAcs(formattedAC);
    }
  };

  const getACBoothDetails = async (acId) => {
    const acBoothResponse = await getAcBoothByAcIds(acId);
    if (acBoothResponse.status) {
      const formattedACBooth = acBoothResponse.data.map((acBooth) => {
        const selectedBooths =
          location?.state?.row?.permissions?.acbooths || [];
        const checked = selectedBooths.find((sb) => sb.value === acBooth.id);
        return {
          label: acBooth?.name || acBooth?.regional_name,
          value: acBooth.id,
          name: acBooth?.name || acBooth?.regional_name,
          checked: checked,
        };
      });
      setAcBooths(formattedACBooth);
    }
  };

  const handleStateChange = (name, value) => {
    let copyStates = [...states];
    const index = copyStates.findIndex((state) => state.name === name);
    copyStates[index] = { ...copyStates[index], checked: value };
    setStates(copyStates);

    const selectedStates = copyStates.filter((state) => state.checked);
    handleElectionPermissions("states", selectedStates);

    const selectedStateString = selectedStates
      .map((state) => state.value)
      .join(",");
    // console.log({ selectedStateString });

    getDistrictDetails(selectedStateString);
    getPCDetails(currentElectionId);
  };

  const handleDistrictChange = (name, value) => {
    console.log({ name, value });
    let copyDistrict = [...districts];
    const index = copyDistrict.findIndex((district) => district.name === name);
    copyDistrict[index] = { ...copyDistrict[index], checked: value };
    setDistricts(copyDistrict);

    const selectedDistricts = copyDistrict.filter(
      (district) => district.checked
    );
    handleElectionPermissions("districts", selectedDistricts);

    const selectedDistrictsString = selectedDistricts
      .map((district) => district.value)
      .join(",");
    // console.log({ selectedDistrictsString });

    getACDetails(currentElectionId, "", selectedDistrictsString);
  };

  const handlePCChange = (name, value) => {
    let copyPc = [...pcs];
    const index = copyPc.findIndex((pc) => pc.name === name);
    copyPc[index] = { ...copyPc[index], checked: value };
    setPcs(copyPc);

    const selectedPcs = copyPc.filter((pc) => pc.checked);
    handleElectionPermissions("pcs", selectedPcs);

    const selectedPcString = selectedPcs.map((pc) => pc.value).join(",");
    // console.log({ selectedPcString });
  };

  const handleACChange = (name, value) => {
    let copyAc = [...acs];
    const index = copyAc.findIndex((ac) => ac.name === name);
    copyAc[index] = { ...copyAc[index], checked: value };
    setAcs(copyAc);

    const selectedAcs = copyAc.filter((ac) => ac.checked);
    handleElectionPermissions("acs", selectedAcs);

    const selectedAcString = selectedAcs.map((ac) => ac.value).join(",");
    // console.log({ selectedAcString });
    getACBoothDetails(selectedAcString);
  };

  const handleACBoothChange = (name, value) => {
    let copyAcBooth = [...acBooths];
    const index = copyAcBooth.findIndex((acb) => acb.name === name);
    copyAcBooth[index] = { ...copyAcBooth[index], checked: value };
    setAcBooths(copyAcBooth);

    const selectedAcBooths = copyAcBooth.filter((acb) => acb.checked);
    handleElectionPermissions("acbooths", selectedAcBooths);
  };

  const handleElectionPermissions = (name, values) => {
    const update = { ...electionPermissions, [name]: values };
    // console.log({ update });
    setElectionPermissions(update);
  };

  const getSelectedValues = (permissions) => {
    const acBoothName =
      (permissions &&
        permissions?.acbooths &&
        permissions?.acbooths[0]?.name) ||
      "";
    const acBoothId =
      (permissions &&
        permissions?.acbooths &&
        permissions?.acbooths[0]?.value) ||
      "";

    const stateName =
      (permissions && permissions?.states && permissions?.states[0]?.name) ||
      "";
    const stateId =
      (permissions && permissions?.states && permissions?.states[0]?.value) ||
      "";

    const districtName =
      (permissions &&
        permissions?.districts &&
        permissions?.districts[0]?.name) ||
      "";
    const districtId =
      (permissions &&
        permissions?.districts &&
        permissions?.districts[0]?.value) ||
      "";

    const pcName =
      (permissions && permissions?.pcs && permissions?.pcs[0]?.name) || "";
    const pcId =
      (permissions && permissions?.pcs && permissions?.pcs[0]?.value) || "";

    const acName =
      (permissions && permissions?.acs && permissions?.acs[0]?.name) || "";
    const acId =
      (permissions && permissions?.acs && permissions?.acs[0]?.value) || "";

    return {
      stateName: stateName || "",
      stateId: stateId || "",
      districtName: districtName || "",
      districtId: districtId || "",
      pcName: pcName || "",
      pcId: pcId || "",
      acName: acName || "",
      acId: acId || "",
      acBoothName: acBoothName || "",
      acBoothId: acBoothId || "",
    };
  };

  const districtIds =
    (location?.state?.row &&
      location?.state?.row?.permissions &&
      location?.state?.row?.permissions?.districts) ||
    [];
  let districtIdString = "";
  districtIds.map((d) => {
    districtIdString += d.value + ",";
  });

  const acIds =
    (location?.state?.row &&
      location?.state?.row?.permissions &&
      location?.state?.row?.permissions?.acs) ||
    [];
  let acIdString = "";
  acIds.map((ac) => {
    acIdString += ac.value + ",";
  });

  districtIdString = removeLastComma(districtIdString);
  acIdString = removeLastComma(acIdString);
  // getStateDetails(location?.state?.row?.election_id);
  // getPCDetails(location?.state?.row?.election_id);
  // getACDetails(location?.state?.row?.election_id, "", districtIdString);

  // console.log({
  //   permissions: location?.state?.row?.permissions,
  //   districtIdString,
  //   acIdString,
  // });

  // if (initialValues.stateId) getDistrictDetails(initialValues.stateId);

  // getACBoothDetails(acIdString);

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleUsersSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct users payload
    let usersPayload = {};
    for (let i = 0; i < values.length; i++) {
      usersPayload[values[i].name] = values[i].value;
    }
    usersPayload["permissions"] = electionPermissions;

    // INFO : call edit api
    const usersResponse = await editUsersApi(
      location?.state?.row?.id,
      usersPayload
    );

    // INFO : check api response and show relevant alert message
    if (usersResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: usersResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const rolesListResponse = await getAllRoles();
    const filteredPcRoles = rolesListResponse.data.filter(
      (role) => role.type === "PC"
    );
    const filteredAcRoles = rolesListResponse.data.filter(
      (role) => role.type === "AC"
    );
    const filteredUrbanRoles = rolesListResponse.data.filter(
      (role) => role.type === "URBAN"
    );
    const filteredRuralRoles = rolesListResponse.data.filter(
      (role) => role.type === "RURAL"
    );
    const filteredAdminRoles = rolesListResponse.data.filter(
      (role) => role.type === "ADMIN"
    );
    console.log({ filteredPcRoles });
    if (rolesListResponse.status) {
      const formatedPcRoleList = filteredPcRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedAcRoleList = filteredAcRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedUrbanRoleList = filteredUrbanRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedRuralRoleList = filteredRuralRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedAdminRoleList = filteredAdminRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const electionResponse = await getAllElection();

      if (electionResponse.status) {
        const formatedElectionList = electionResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const clientDataResponse = await getAllClients();
        console.log({ clientDataResponse });
        if (clientDataResponse.status && clientDataResponse.status) {
          const formatedClientList = clientDataResponse.data.map((data) => {
            return {
              name: data.name,
              value: data.id,
            };
          });

          const formInputData = getUserEditFormInputs(
            formatedPcRoleList,
            formatedAcRoleList,
            formatedUrbanRoleList,
            formatedRuralRoleList,
            formatedAdminRoleList,
            formatedElectionList,
            formatedClientList,
            {
              name: location?.state?.row?.name || "",
              electionId: location?.state?.row?.election_id || "",
              email: location?.state?.row?.email || "",
              mobile: location?.state?.row?.mobile || "",
              password: location?.state?.row?.password || "",
              roleId: location?.state?.row?.role_id,
              type: location?.state?.row?.type,
              permissions: location?.state?.row?.permissions,
              ...getSelectedValues(location?.state?.row?.permissions || null),
              userImage: location?.state?.row?.user_image,
              whatsappNumber: location?.state?.row?.whatsapp_number,
              expiryDate: location?.state?.row?.expiry_date,
              clientId: location?.state?.row?.client_id || "",
              deleteProtected: location?.state?.row?.delete_protected,
              pcRoleId: location?.state?.row?.pc_role_id,
              acRoleId: location?.state?.row?.ac_role_id,
              urbanRoleId: location?.state?.row?.urban_role_id,
              ruralRoleId: location?.state?.row?.rural_role_id,
              adminRoleId: location?.state?.row?.admin_role_id,
            },
            onChangeShowByElection,
            handleUserImageFileUpload
          );
          // console.log({ formatedRoleList, formInputData });
          setFormInputs(formInputData);
        }
      }
    }

    getStateDetails(location?.state?.row?.election_id);
    // getStateDetails(location?.state?.row?.election_id);
    getPCDetails(location?.state?.row?.election_id);
    getACDetails(location?.state?.row?.election_id, "", districtIdString);

    const permissions = location?.state?.row?.permissions || null;

    const stateId =
      permissions && permissions.states && permissions.states[0].value;
    console.log({ stateId });

    if (stateId) getDistrictDetails(stateId);

    getACBoothDetails(acIdString);
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleUserImageFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadUserResponse = await uploadUserImage(file[0]);
    console.log({ uploadUserResponse });
    if (uploadUserResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value =
        BASE_URL + "app-source/user-image/" + uploadUserResponse.data;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
    states,
    districts,
    pcs,
    acs,
    acBooths,
  };

  const templateEvents = {
    handleUsersSubmit,
    handleTriggerClick,
    handleStateChange,
    handleDistrictChange,
    handlePCChange,
    handleACChange,
    handleACBoothChange,
    handleBackClick,
  };

  return menuAccess ? (
    <UsersEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UsersEditComponent;
