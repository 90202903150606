import { APP_NAME } from "@constants/app.constants";
import DistrictPanchayatUnionCreateComponent from "@screens/catalog-rural/district-union/district-union-create/district-union-create.component";
import DistrictPanchayatUnionEditComponent from "@screens/catalog-rural/district-union/district-union-edit/district-union-edit.component";
import DistrictPanchayatUnionListComponent from "@screens/catalog-rural/district-union/district-union-list/district-union-list.component";

const catalogRuralDistrictUnionRoutes = [
  {
    name: `District Unions - ${APP_NAME}`,
    path: "/catalog-rural/district-union",
    icon: "framer",
    component: DistrictPanchayatUnionListComponent,
  },
  {
    name: `Create District - ${APP_NAME}`,
    path: "/catalog-rural/district-union/create",
    component: DistrictPanchayatUnionCreateComponent,
  },
  {
    name: `Edit District - ${APP_NAME}`,
    path: "/catalog-rural/district-union/edit",
    component: DistrictPanchayatUnionEditComponent,
  },
  //   {
  //     name: `District User - ${APP_NAME}`,
  //     path: "/catalog-rural/district-union/user",
  //     component: UsersListComponent,
  //   },
  //   {
  //     name: `Create District User - ${APP_NAME}`,
  //     path: "/catalog-rural/district-union/user/create",
  //     component: UsersCreateComponent,
  //   },
  //   {
  //     name: `Edit District User - ${APP_NAME}`,
  //     path: "/catalog-rural/district-union/user/edit",
  //     component: UsersEditComponent,
  //   },
];

export default catalogRuralDistrictUnionRoutes;
