import { APP_NAME } from "@constants/app.constants";
import UrbanWardVoterCreateComponent from "@screens/catalog-urban/urban-ward-voter/urban-ward-voter-create/urban-ward-voter-create.component";
import UrbanWardVoterEditComponent from "@screens/catalog-urban/urban-ward-voter/urban-ward-voter-edit/urban-ward-voter-edit.component";
import UrbanWardVoterFileUploadComponent from "@screens/catalog-urban/urban-ward-voter/urban-ward-voter-file/urban-ward-voter-file.component";
import UrbanWardVoterListComponent from "@screens/catalog-urban/urban-ward-voter/urban-ward-voter-list/urban-ward-voter-list.component";
import CandidateCreateComponent from "@screens/catalog-urban/urban-ward/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog-urban/urban-ward/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog-urban/urban-ward/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog-urban/urban-ward/candidate/candidate-list/candidate-list.component";
import UrbanWardCreateComponent from "@screens/catalog-urban/urban-ward/urban-ward-create/urban-ward-create.component";
import UrbanWardEditComponent from "@screens/catalog-urban/urban-ward/urban-ward-edit/urban-ward-edit.component";
import UrbanWardListComponent from "@screens/catalog-urban/urban-ward/urban-ward-list/urban-ward-list.component";

const catalogUrbanListRoutes = [
  {
    name: "Urban Ward List",
    icon: "life-buoy",
    path: "/catalog-urban/urban-ward",
    component: UrbanWardListComponent,
  },

  {
    name: `Create Urban Ward - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/create",
    component: UrbanWardCreateComponent,
  },

  {
    name: `Edit Urban Ward - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/edit",
    component: UrbanWardEditComponent,
  },

  {
    name: `Urban Ward Candidate - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/candidate",
    component: CandidateListComponent,
  },
  {
    name: `Create Urban Ward Candidate - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit Urban Ward Candidate - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/candidate/upload",
    component: CandidateFileUploadComponent,
  },
  {
    name: `Urban Ward Voter - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/voter",
    component: UrbanWardVoterListComponent,
  },
  {
    name: `Create Urban Ward Voter - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/voter/create",
    component: UrbanWardVoterCreateComponent,
  },
  {
    name: `Edit Urban Ward Voter - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/voter/edit",
    component: UrbanWardVoterEditComponent,
  },
  {
    name: `Upload Voter File - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward/voter/upload",
    component: UrbanWardVoterFileUploadComponent,
  },
];

export default catalogUrbanListRoutes;
