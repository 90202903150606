export const selectCandidateFormInputs = ({ candidates }) => [
  {
    type: "SELECT",
    label: "Candidate Name",
    id: "candidateId",
    name: "candidate_id",
    options: candidates,
    value: "",
    placeholder: "Select Candidate",
    validations: [
      {
        type: "REQUIRED",
        message: "Candidate Name is required !",
      },
    ],
  },
];
