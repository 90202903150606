import { END_POINT } from "@constants/api.constants";

const { NOTIFICATION_STATUS_TOGGLE } = END_POINT;

const tableTitle = "Notification List";
const keyColumn = "id";
const menuUrl = "user-administration/notification";
const tableColumn = [
  {
    id: "admin",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: true,
    label: " Message",
  },
  {
    id: "redirect",
    numeric: false,
    disablePadding: true,
    label: " Redirect",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: NOTIFICATION_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Pending",
      2: "Viewed",
    },
    badgeColor: {
      1: "warning",
      2: "success",
    },
  },
];
const breadCrumbs = [
  { name: "User Administration", menu_url: null },
  { name: "Notification", menu_url: "/user-administration/notification" },
];

const filters = [
  {
    name: "user",
    id: "user",
    label: "User Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "message",
    id: "message",
    label: "Message",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "redirect",
    id: "redirect",
    label: "Redirect",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
