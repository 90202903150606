import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import DistrictPanchayatUnionWardEditTemplateComponent from "./district-union-ward-edit-template.component";
import { getDistrictUnionByDistrictId } from "@screens/master-rural/district-union/district-union-list/district-union-list.component.service";
import { menuUrl } from "../district-union-ward-list/model/district-union-ward-list.constants";
import { editEntry } from "../district-union-ward-list/district-union-ward-list.component.service";
import { getDistrictUnionWardFormInputs } from "../model/district-union-ward.modal";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  DISTRICT_UNION_WARD_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const DistrictPanchayatUnionWardEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const response = await editEntry(location?.state?.row?.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: response.message,
        })
      );
    }
  };
  const onDistrictChange = async (value, inputs) => {
    const districtUnionResponse = await getDistrictUnionByDistrictId(value);
    if (districtUnionResponse.status) {
      const formattedDistrictUnionList = districtUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[4].options = formattedDistrictUnionList;
      setFormInputs(inputs);
    }
  };
  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[3].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    const districtResponse = await getDistrictByStateId(
      location?.state?.row?.state_id || null
    );
    const districtUnionResponse = await getDistrictUnionByDistrictId(
      location?.state?.row?.district_id || null
    );
    let formatedStateList;
    let formattedDistrictList;
    let formattedDistrictUnionList;
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    if (districtResponse.status) {
      formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    if (districtUnionResponse.status) {
      formattedDistrictUnionList = districtUnionResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    const formInputData = getDistrictUnionWardFormInputs({
      states: formatedStateList,
      districts: formattedDistrictList,
      districtUnions: formattedDistrictUnionList,
      initialValues: {
        govtId: location?.state?.row?.govt_id || "",
        stateId: location?.state?.row?.state_id || "",
        districtId: location?.state?.row?.district_id || "",
        districtUnionId: location?.state?.row?.district_union_id || "",
        name: location?.state?.row?.name || "",
        type: location?.state?.row?.type,
        regionalName: location?.state?.row?.regional_name || "",
        reservation: location?.state?.row?.reservation || "",
        totalNoOfWards: location?.state?.row?.total_no_of_wards || "",
        remarks: location?.state?.row?.remarks || "",
        manager: location?.state?.row?.manager || "",
      },
      onStateChange,
      onDistrictChange,
    });
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <DistrictPanchayatUnionWardEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default DistrictPanchayatUnionWardEditComponent;
