import { APP_NAME } from "@constants/app.constants";
import TownPanchayatCreateComponent from "@screens/master-urban/town-panchayat/town-panchayat-create/town-panchayat-create";
import TownPanchayatEditComponent from "@screens/master-urban/town-panchayat/town-panchayat-edit/town-panchayat-edit";
import TownPanchayatFileUploadComponent from "@screens/master-urban/town-panchayat/town-panchayat-file/town-panchayat-file";
import TownPanchayatListComponent from "@screens/master-urban/town-panchayat/town-panchayat-list/town-panchayat-list";

const masterTownPanchayatRoutes = [
  {
    name: "Town Panchayat List",
    icon: "crosshair",
    path: "/master-urban/town-panchayat",
    component: TownPanchayatListComponent,
  },

  {
    name: `Create Town Panchayat - ${APP_NAME}`,
    path: "/master-urban/town-panchayat/create",
    component: TownPanchayatCreateComponent,
  },

  {
    name: `Edit Town Panchayat - ${APP_NAME}`,
    path: "/master-urban/town-panchayat/edit",
    component: TownPanchayatEditComponent,
  },

  {
    name: `Upload File Town Panchayat- ${APP_NAME}`,
    path: "/master-urban/town-panchayat/upload",
    component: TownPanchayatFileUploadComponent,
  },
];

export default masterTownPanchayatRoutes;
