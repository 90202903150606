export const getVersionFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Title",
    id: "title",
    name: "title",
    value: initialValues?.title || "",
    placeholder: "Enter the Title",
    validations: [
      {
        type: "REQUIRED",
        message: "Title is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Version No",
    id: "versionNo",
    name: "version_no",
    value: initialValues?.versionNo || "",
    placeholder: "Enter the Version No",
    validations: [
      {
        type: "REQUIRED",
        message: "Version No is required !",
      },
    ],
  },
  {
    type: "TEXT_AREA",
    label: "Description",
    id: "description",
    name: "description",
    value: initialValues?.description || "",
    placeholder: "Enter the Description",
    validations: [],
  },

  {
    type: "CALENDER",
    label: "Date",
    id: "date",
    name: "date",
    value: initialValues?.date || new Date(),
    placeholder: "Select Date",
    validations: [],
  },
];
