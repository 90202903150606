import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

function DownloadRequestListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableParams,
  },
  templateEvents: { getData, deleteRecords, submitFilter, handleDownload },
}) {
  const settingFormEdit = `${breadCrumbs[0].menu_url}/edit`;

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Data Download Requests"}
        breadCrumbs={breadCrumbs}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        handleDownload={handleDownload}
        tableParams={tableParams}
        disableDelete
      />
    </PageWrapperComponent>
  );
}

export default DownloadRequestListTemplateComponent;
