import SentEmailListComponent from "@screens/reports/sent-email/sent-email-list/sent-email-list.component";

const sentEmailRoutes = [
  {
    name: "Sent Email",
    icon: "mail",
    path: "/reports/sent-email",
    component: SentEmailListComponent,
  },
];

export default sentEmailRoutes;
