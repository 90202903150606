import { APP_NAME } from "@constants/app.constants";

import ElectionUrbanCreateComponent from "@screens/election-urban/election-urban-create/election-urban-create.component";
import ElectionUrbanEditComponent from "@screens/election-urban/election-urban-edit/election-urban-edit.component";
import ElectionUrbanListComponent from "@screens/election-urban/election-urban-list/election-urban-list.component";

const electionUrbanRoutes = [
  {
    name: `Election Urban- ${APP_NAME}`,
    path: "/election-urban",
    icon: "bar-chart-2",
    component: ElectionUrbanListComponent,
  },
  {
    name: `Create Election Urban - ${APP_NAME}`,
    path: "/election-urban/create",
    component: ElectionUrbanCreateComponent,
  },
  {
    name: `Edit Election Urban - ${APP_NAME}`,
    path: "/election-urban/edit",
    component: ElectionUrbanEditComponent,
  },
];

export default electionUrbanRoutes;
