import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "@constants/api.constants";
import DetailPanelComponent from "@molecules/detail-panel/detail-panel.component";
import AccordionComponent from "@molecules/accordion/accordion.component";
import ListComponent from "@atoms/list/list.component";
import CandidateSwapComponent from "./components/candidate-swap.component";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";
import { store } from "@src/store";

function CandidateTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    villagePanchayatWardId,
    electionId,
    villagePanchayatWardName,
    tableActions,
    basicData,
    openDetailPanel,
    tableControls,
    swapContentType,
    tableParams,
    stateGovtId,
  },
  templateEvents: {
    getCandidateData,
    handleCreateClick,
    deleteRecords,
    submitFilter,
    handleDownload,
    handleDashboard,
    setOpenDetailPanel,
    toggleDetailPanel,
    handleBackClick,
  },
}) {
  const history = useHistory();
  const settingFormEdit = `${breadCrumbs[1].menu_url}/candidate/edit?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}&stateGovtId=${stateGovtId}`;
  console.log({ settingFormEdit });
  const settingsFormUploadExcel = `${breadCrumbs[1].menu_url}/candidate/upload?villagePanchayatWardId=${villagePanchayatWardId}&electionId=${electionId}&villagePanchayatWardName=${villagePanchayatWardName}&stateGovtId=${stateGovtId}`;
  const handleFormUploadClick = () => {
    history.push(settingsFormUploadExcel);
  };
  const downloadFile = () => {
    window.location.href =
      BASE_URL + "app-source/sample-file/rural-candidate-template.xlsx";
  };

  const viewBallotPaper = () => {
    window.open(
      BASE_URL +
        "api/catalog/village-panchayat-ward/ballot-paper/" +
        villagePanchayatWardId +
        "?token=" +
        store.getState().app?.token
    );
  };

  const detailedPanelFormat = ({ basicData }) => {
    const candidateImage = basicData.find(
      (data) => data.key === "Candidate Image"
    );
    const affidavit = basicData.find((data) => data.key === "Affidavit");
    console.log({ candidateImage, affidavit });
    {
      return [
        {
          id: 1,
          heading: "Basic Candidate Details",
          content: (
            <>
              {" "}
              {candidateImage && (
                <div style={{ textAlign: "center" }}>
                  <img src={candidateImage.value} style={{ width: 150 }} />
                </div>
              )}
              {affidavit && (
                <div style={{ textAlign: "center" }}>
                  <a href={affidavit.value} target="_blank" rel="noreferrer">
                    {" "}
                    Open Affidavit{" "}
                  </a>
                </div>
              )}
              <ListComponent items={basicData} />,
            </>
          ),
        },
      ];
    }
  };

  const ActionContent = ({ handleCreateClick, handleBackClick }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleFormUploadClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="upload" size={17} />
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={handleCreateClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="plus" size={17} />
          &nbsp;
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={downloadFile}
        >
          <FeatherIcon icon="download" size={17} />
        </button>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm ml-2"
          onClick={handleBackClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="arrow-left" size={17} />
        </button>
        {/* <ToolTipComponent content={"Ballot Paper"}>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={viewBallotPaper}
          >
            <FeatherIcon icon="menu" size={17} />
            &nbsp;
          </button>
        </ToolTipComponent> */}
      </>
    );
  };

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Candidate"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: villagePanchayatWardName + " - Candidate(s)",
            menu_url: null,
          },
        ]}
        actionContent={
          <ActionContent
            handleCreateClick={handleCreateClick}
            handleBackClick={handleBackClick}
          />
        }
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getCandidateData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        handleDownload={handleDownload}
        // handleDashboard={handleDashboard}
        tableActions={tableActions}
        tableControls={tableControls}
        swapContent={
          swapContentType ? (
            <CandidateSwapComponent type={swapContentType} />
          ) : null
        }
        tableParams={tableParams}
      />
      <DetailPanelComponent
        open={openDetailPanel}
        toggleDrawer={setOpenDetailPanel}
        drawerWidth={40}
      >
        <AccordionComponent
          items={detailedPanelFormat({
            basicData,
          })}
        />
      </DetailPanelComponent>
    </PageWrapperComponent>
  );
}

export default CandidateTemplateComponent;
