import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Log List";
const keyColumn = "id";
const menuUrl = "user-administration/users";
const tableColumn = [
  {
    id: "days_ago",
    numeric: false,
    disablePadding: true,
    label: "Days Ago",
  },
  {
    id: "admin_name",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "log_msg",
    numeric: false,
    disablePadding: false,
    label: "Log",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
  },
];
const breadCrumbs = [
  { name: "User Administration", menu_url: null },
  { name: "Users", menu_url: "/user-administration/users" },
];

const additionalActions = ({}) => [];

const filters = [];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
