import { APP } from "@constants/app.constants";

const { GENDER } = APP;
export const getFormInputs = ({ booths, initialValues }) => [
  {
    type: "SELECT",
    label: "Booth Name",
    id: "boothId",
    name: "booth_id",
    options: booths,
    value: initialValues?.boothId || "",
    placeholder: "Select Booth Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Booth Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Section ID",
    id: "sectionId",
    name: "section_id",
    value: initialValues?.sectionId || "",
    placeholder: "Enter the Section ID",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Section Name",
    id: "sectionName",
    name: "section_name",
    value: initialValues?.sectionName || "",
    placeholder: "Enter the Section Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Section Regional Name",
    id: "sectionRegionalName",
    name: "section_regional_name",
    value: initialValues?.sectionRegionalName || "",
    placeholder: "Enter the Section Regional Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "First name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the First Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Voter First Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "First name - Regional",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional First Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Last Name",
    id: "lastName",
    name: "last_name",
    value: initialValues?.lastName || "",
    placeholder: "Enter the Last Name",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Last name - Regional",
    id: "regionalLastName",
    name: "regional_last_name",
    value: initialValues?.regionalLastName || "",
    placeholder: "Enter the Regional last Name",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Indian Election Serial No",
    id: "slnoinpart",
    name: "slnoinpart",
    value: initialValues?.slnoinpart || "",
    placeholder: "Enter the Indian Election Serial No",
    validations: [
      {
        type: "REQUIRED",
        message: "Indian Election Serial No is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Epic ID",
    id: "epicId",
    name: "epic_id",
    value: initialValues?.epicId || "",
    placeholder: "Enter the Epic ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Epic ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Mobile number",
    id: "mobileNo",
    name: "mobile",
    value: initialValues?.mobileNo || "",
    placeholder: "Enter the Mobile No",
    validations: [],
  },

  {
    type: "TEXT",
    label: "WhatsApp number",
    id: "whatsappNumber",
    name: "whatsapp_number",
    value: initialValues?.whatsappNumber || "",
    placeholder: "Enter the WhatsApp Number",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Email ID",
    id: "emailId",
    name: "email_id",
    value: initialValues?.emailId || "",
    placeholder: "Enter the Email ID",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Age",
    id: "age",
    name: "age",
    value: initialValues?.age || "",
    placeholder: "Enter the Age",
    validations: [
      {
        type: "REQUIRED",
        message: "Age is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "Gender",
    id: "gender",
    name: "gender",
    options: GENDER,
    value: initialValues?.gender || "MALE",
    placeholder: "Select Gender",
    validations: [
      {
        type: "REQUIRED",
        message: "Gender is required !",
      },
    ],
  },
  {
    type: "CALENDER",
    label: "Date of Birth",
    id: "dob",
    name: "dob",
    value: initialValues?.dob || "",
    placeholder: "Select Date of Birth",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Address",
    id: "address",
    name: "address",
    value: initialValues?.address || "",
    placeholder: "Enter the Address",
    validations: [],
  },

  {
    type: "TEXT",
    label: "Address - Regional",
    id: "addressRegionalName",
    name: "address_tamil",
    value: initialValues?.addressRegionalName || "",
    placeholder: "Enter the Address in Regional Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "RLN Type",
    id: "rlnType",
    name: "rln_type",
    value: initialValues?.rlnType || "",
    placeholder: "Enter the Relation Type",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Relation First Name",
    id: "relationFirstName",
    name: "relation_first_name",
    value: initialValues?.relationFirstName || "",
    placeholder: "Enter the Relation First Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Relation Last Name",
    id: "relationLastName",
    name: "relation_last_name",
    value: initialValues?.relationLastName || "",
    placeholder: "Enter the Relation Last Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Relation First Name - Regional",
    id: "relationFirstRegionalName",
    name: "relation_first_regional_name",
    value: initialValues?.relationFirstRegionalName || "",
    placeholder: "Enter the Relation First Name in Regional Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Relation Last Name - Regional",
    id: "relationLastRegionalName",
    name: "relation_last_regional_name",
    value: initialValues?.relationLastRegionalName || "",
    placeholder: "Enter the Relation Last Name in Regional Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Relation",
    id: "relation",
    name: "relation",
    value: initialValues?.relation || "",
    placeholder: "Enter the Relation",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Religion",
    id: "religion",
    name: "religion",
    value: initialValues?.religion || "",
    placeholder: "Enter the Religion",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Caste",
    id: "caste",
    name: "caste",
    value: initialValues?.caste || "",
    placeholder: "Enter the Caste",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Language",
    id: "language",
    name: "language",
    value: initialValues?.language || "",
    placeholder: "Enter the Language",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Color",
    id: "color",
    name: "color",
    value: initialValues?.color || "",
    placeholder: "Enter the Color",
    validations: [],
  },
  {
    type: "TEXT",
    label: "City",
    id: "city",
    name: "city",
    value: initialValues?.city || "",
    placeholder: "Enter the City",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Pincode",
    id: "pincode",
    name: "pincode",
    value: initialValues?.pincode || "",
    placeholder: "Enter the Pincode",
    validations: [],
  },
  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
