import React from "react";
import MunicipalityDashboardComponent from "../dashboard/dashboard.component";
import MunicipalityChartComponent from "./chart.component";

const MunicipalitySwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <MunicipalityChartComponent />}
      {type === "DASHBOARD" && <MunicipalityDashboardComponent />}
    </>
  );
};

export default MunicipalitySwapComponent;
