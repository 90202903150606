const tableTitle = "Clients List";
const keyColumn = "id";
const menuUrl = "user-administration/client";
const tableColumn = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },

  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile No",
  },
  {
    id: "political_party",
    numeric: false,
    disablePadding: false,
    label: "Political Party",
  },
  {
    id: "access_level",
    numeric: false,
    disablePadding: false,
    label: "Access Level",
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Current Users",
  },
  {
    id: "total_users",
    numeric: false,
    disablePadding: false,
    label: "Maximum Users",
  },
];
const breadCrumbs = [
  { name: "User Administration", menu_url: null },
  { name: "Client", menu_url: "/user-administration/client" },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
];

const additionalActions = ({ handleViewDetail }) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  tableControls,
  additionalActions,
};
