import { formatRemarks } from "@utils/string.utils";
import { APP } from "@constants/app.constants";

const { INDIA_STATES } = APP;
const tableTitle = "Vendors List";
const keyColumn = "id";
const menuUrl = "crm/vendors";
const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: 150,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    width: 150,
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
    formatData: formatRemarks,
  },

  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },

  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    formatData: formatRemarks,
  },
];
const breadCrumbs = [
  { name: "CRM", menu_url: null },
  { name: "Vendors", menu_url: "/crm/vendors" },
];

const filters = [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: INDIA_STATES,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "type",
    id: "type",
    label: "Type",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
