import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../vendors-list/model/vendors-list.constants";

const ActionContent = ({ handleSaveClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleSaveClick}
    >
      <FeatherIcon icon="save" size={17} />
    </button>
  );
};

const VendorsFileUploadTemplateComponent = ({
  templateModal: { formInputs, formTriggers },
  templateEvents: { handleFormSubmit, handleTriggerClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Upload Vendors"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "File Upload ", menu_url: "/crm/vendors/upload-vendors" },
        ]}
        actionContent={
          <ActionContent handleSaveClick={() => handleTriggerClick("SUBMIT")} />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleFormSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default VendorsFileUploadTemplateComponent;
