import FormInputCheckboxComponent from "@atoms/form-input/form-input-checkbox/form-input-checkbox.component";
import ModalComponent from "@atoms/modal/modal.component";
import { updateColumns } from "@reducers/filter.reducer";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const RenderColumns = ({ columns, handleChange }) => {
  return (
    <>
      {columns.map((column) => (
        <div>
          <FormInputCheckboxComponent
            name={column.label}
            label={column.label}
            id={column.id}
            checked={column.selected}
            onChange={(value) => handleChange(column.id, value)}
          />
        </div>
      ))}
    </>
  );
};

const DataGridManageColumnsComponent = ({ open, tableColumn, toggleClick }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.substring(1);
  const { filterList } = useSelector((state) => state.filter);
  const currentFilter = filterList.find((filter) => filter.menuUrl === url);
  const currentFilterIndex = filterList.findIndex(
    (filter) => filter.menuUrl === url
  );

  const currentColumns = tableColumn.map((column) => {
    const stateFilters = currentFilter?.columns || [];
    const selected = stateFilters.find((col) => col.id === column.id);
    return {
      id: column.id,
      label: column.label,
      selected: selected ? selected.selected : true,
    };
  });

  const updatedTableColumns = JSON.parse(JSON.stringify(currentColumns));
  const [columns, setColumns] = useState([...updatedTableColumns]);

  const handleChange = (id, value) => {
    let copyColumns = JSON.parse(JSON.stringify(columns));
    const index = copyColumns.findIndex((col) => col.id === id);

    copyColumns[index].selected = !copyColumns[index].selected;
    setColumns(copyColumns);
  };

  const handleSubmit = () => {
    dispatch(updateColumns({ index: currentFilterIndex, columns: columns }));
    toggleClick();
  };

  return (
    <>
      <ModalComponent
        open={open}
        secondaryAction={toggleClick}
        title={"Manage Columns"}
        primaryText="Submit"
        primaryAction={handleSubmit}
        secondaryText="Cancel"
      >
        <RenderColumns columns={columns} handleChange={handleChange} />
      </ModalComponent>
    </>
  );
};

export default DataGridManageColumnsComponent;
