import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "States List";
const keyColumn = "id";
const menuUrl = "catalog/states";
const tableColumn = [
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "State Id",
    sorting: true,
  },
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    sorting: true,
    width: 100,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: 150,
    sorting: true,
  },
  {
    id: "official_language",
    numeric: false,
    disablePadding: false,
    label: "Official Language",
    sorting: true,
  },
  {
    id: "election_year",
    numeric: false,
    disablePadding: false,
    label: "Election Year",
  },
  {
    id: "ruling_party",
    numeric: false,
    disablePadding: false,
    label: "Ruling Party",
    sorting: true,
  },
  {
    id: "chief_minister",
    numeric: false,
    disablePadding: false,
    label: "Chief Minister",
    sorting: true,
  },
  {
    id: "client_count",
    numeric: false,
    disablePadding: false,
    label: "Total Clients",
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Total users",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog", menu_url: null },
  { name: "States", menu_url: "/catalog/states" },
];

const additionalActions = ({ handleUserLoginClick }) => [
  {
    name: "Login Users",
    icon: "log-in",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleUserLoginClick,
  },
];

const tableControls = [
  {
    name: "Chart",
    icon: "pie-chart",
    status: 0,
    onClick: null,
  },
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "State name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
