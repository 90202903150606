import React from "react";
import FeatherIcon from "feather-icons-react";
import CardComponent from "@atoms/card/card.component";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import FormInputTextComponent from "@atoms/form-input/form-input-text/form-input-text.component";
import AlertComponent from "@atoms/alert/alert.component";
import FormInputDateComponent from "@atoms/form-input/form-input-date/form-input-date.component";

const ActionContent = ({ handleSaveClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleSaveClick}
    >
      <FeatherIcon icon="save" size={17} />
    </button>
  );
};

const ProfileTemplateComponent = ({
  templateModal: { user },
  templateEvents: { handleChange, handleProfileSubmit },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        breadCrumbs={[{ name: "Profile", menu_url: "/profile" }]}
        actionContent={
          <ActionContent
            handleSaveClick={(event) => handleProfileSubmit(event)}
          />
        }
      />
      <CardComponent>
        <form className="form form-horizontal">
          <div className="row">
            <div className="col-12">
              <div className="mb-1 row">
                <div className="col-sm-2">
                  <label className="col-form-label"> Name </label>
                </div>
                <div className="col-sm-9">
                  <FormInputTextComponent
                    required
                    className={"form-control"}
                    type={"text"}
                    name={"name"}
                    id={"name"}
                    onChange={(value) => handleChange(value, "name")}
                    value={user?.name || ""}
                    placeholder={"Enter Your Name"}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <div className="col-sm-2">
                  <label className="col-form-label"> E-mail </label>
                </div>
                <div className="col-sm-9">
                  <FormInputTextComponent
                    className={"form-control"}
                    type={"text"}
                    name={"email"}
                    id={"email"}
                    value={user?.email || ""}
                    onChange={(value) => handleChange(value, "email")}
                    placeholder={"Enter Your E-mail"}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <div className="col-sm-2">
                  <label className="col-form-label"> Mobile </label>
                </div>
                <div className="col-sm-9">
                  <FormInputTextComponent
                    className={"form-control"}
                    type={"text"}
                    name={"mobile"}
                    id={"mobile"}
                    value={user?.mobile || ""}
                    onChange={(value) => handleChange(value, "mobile")}
                    placeholder={"Enter Your Mobile Number"}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <div className="col-sm-2">
                  <label className="col-form-label"> Date Of Birth </label>
                </div>
                <div className="col-sm-9">
                  <FormInputDateComponent
                    name={"mobile"}
                    id={"mobile"}
                    value={user?.dob || new Date()}
                    onChange={(value) => handleChange(value, "dob")}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default ProfileTemplateComponent;
