import { APP_NAME } from "@constants/app.constants";
import ACCreateComponent from "@screens/catalog-urban/ac/ac-create/ac-create.component";
import ACEditComponent from "@screens/catalog-urban/ac/ac-edit/ac-edit.component";
import ACListComponent from "@screens/catalog-urban/ac/ac-list/ac-list.component";
import UsersCreateComponent from "@screens/catalog-urban/ac/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog-urban/ac/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog-urban/ac/user/users-list/users-list.component";
import ACVoterCreateComponent from "@screens/catalog/ac-voter/ac-voter-create/ac-voter-create.component";
import ACVoterEditComponent from "@screens/catalog/ac-voter/ac-voter-edit/ac-voter-edit.component";
import ACVoterFileUploadComponent from "@screens/catalog/ac-voter/ac-voter-file/ac-voter-file.component";
import ACVoterListComponent from "@screens/catalog/ac-voter/ac-voter-list/ac-voter-list.component";

const catalogUrbanAcRoutes = [
  {
    name: `AC - ${APP_NAME}`,
    path: "/catalog-urban/ac",
    icon: "package",
    component: ACListComponent,
  },
  {
    name: `Create AC - ${APP_NAME}`,
    path: "/catalog-urban/ac/create",
    component: ACCreateComponent,
  },
  {
    name: `Edit AC - ${APP_NAME}`,
    path: "/catalog-urban/ac/edit",
    component: ACEditComponent,
  },

  {
    name: `AC User - ${APP_NAME}`,
    path: "/catalog-urban/ac/user",
    component: UsersListComponent,
  },
  {
    name: `Create AC User - ${APP_NAME}`,
    path: "/catalog-urban/ac/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit AC User - ${APP_NAME}`,
    path: "/catalog-urban/ac/user/edit",
    component: UsersEditComponent,
  },
  {
    name: `AC Voter - ${APP_NAME}`,
    path: "/catalog-urban/ac/voter",
    component: ACVoterListComponent,
  },
  {
    name: `Create AC Voter - ${APP_NAME}`,
    path: "/catalog-urban/ac/voter/create",
    component: ACVoterCreateComponent,
  },
  {
    name: `Edit AC Voter - ${APP_NAME}`,
    path: "/catalog-urban/ac/voter/edit",
    component: ACVoterEditComponent,
  },
  {
    name: `Upload Voter File - ${APP_NAME}`,
    path: "/catalog-urban/ac/voter/upload",
    component: ACVoterFileUploadComponent,
  },
];

export default catalogUrbanAcRoutes;
