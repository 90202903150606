import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFileUploadInputs } from "../model/file-upload.modal";
import {
  breadCrumbs,
  menuUrl,
} from "../panchayat-union-list/model/panchayat-union-list.constants";
import PanchayatUnionFileUploadTemplateComponent from "./panchayat-union-file-template.component";
import { readFile, uploadFile } from "./panchayat-union-file.component.service";

const {
  STATES_ALERTS: { FILE_UPLOAD_ALERT },
  ALERT_TYPES: { SUCCESS },
} = MESSAGES;

const PanchayatUnionFileUploadComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setInitialFormInputs(copyFormInputs);

    const uploadResponse = await uploadFile(file[0]);
    console.log({ uploadResponse });
    if (uploadResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value = uploadResponse.data;

      setInitialFormInputs(copyFormInputs2);
    } else {
    }
  };

  const [initialFormInputs, setInitialFormInputs] = useState(
    getFileUploadInputs(handleFileUpload)
  );

  const handleFormSubmit = async ({ values, errors }) => {
    console.log({ values, errors });

    if (errors.length > 0) {
      alert("file is mandatory");
      return;
    }

    let payload = {};

    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    console.log({ payload });
    const response = await readFile(payload);
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: FILE_UPLOAD_ALERT }));
      history.push(breadCrumbs[1].menu_url);
    } else {
      alert(response.message);
    }
  };

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: initialFormInputs,
  };

  const templateEvents = {
    handleTriggerClick,
    handleFormSubmit,
  };

  return menuAccess ? (
    <PanchayatUnionFileUploadTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default PanchayatUnionFileUploadComponent;
