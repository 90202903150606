import { getACByDistrictId } from "@screens/catalog/ac/ac-list/ac-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "AC Booth List";
const keyColumn = "id";
const menuUrl = "catalog/ac-booth";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District Name",
  },
  {
    id: "ac_name",
    numeric: false,
    disablePadding: false,
    label: "AC Name",
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Part No",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Booth name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "VBooth Name",
    sorting: true,
  },
  {
    id: "booth_type",
    numeric: false,
    disablePadding: false,
    label: "Booth Type",
    sorting: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Village name",
    sorting: true,
  },
  {
    id: "location_regional_name",
    numeric: false,
    disablePadding: false,
    label: "V Village name",
    sorting: true,
  },
  {
    id: "client_count",
    numeric: false,
    disablePadding: false,
    label: "Total Clients",
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Total users",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog", menu_url: null },
  { name: "AC Booth", menu_url: "/catalog/ac-booth" },
];

const additionalActions = ({ handleUserLoginClick, handleVotersSlipClick }) => [
  {
    name: "Login Users",
    icon: "log-in",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleUserLoginClick,
  },

  {
    name: "Voters Slip",
    icon: "menu",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleVotersSlipClick,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const acResponse = await getACByDistrictId(value);
      console.log({ acResponse });
      if (acResponse.status) {
        const formattedAc = acResponse.data.map((ac) => {
          return {
            name: ac.name,
            value: ac.id,
          };
        });

        formInputs[2].options = formattedAc;
      }

      return formInputs;
    },
  },
  {
    name: "ac",
    id: "ac",
    label: "AC Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select AC",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "govt_id",
    id: "govt_id",
    label: "Part No",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];
const tableControls = [
  {
    name: "Chart",
    icon: "pie-chart",
    status: 0,
    onClick: null,
  },
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
