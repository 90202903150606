import MESSAGES from "@constants/messages.constants";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../election-list/model/election-list.constants";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { getElectionDashboardDataApi } from "../election-list/election-list.component.service";
import ElectionDashboardTemplateComponent from "./election-dashboard.template";

const {
  ALERT_TYPES: { SUCCESS },
  STATES_ALERTS: { REFRESH_ALERT },
} = MESSAGES;


const ElectionDashboardComponent = () => {
  const history = useHistory();
  const location = useLocation(); 
  const [dashboardData, setDashboardData] = useState({ recentLog: [] });
  const electionId = new URLSearchParams(location.search).get("electionId");
  const electionName = new URLSearchParams(location.search).get("electionName");  
  const { currentElection } = useSelector((state) => state.election);
  const dispatch = useDispatch();
  const [type, setType] = useState("Overview");

  const breadCrumbs = [
    { name: "Election", menu_url: "/election" },
    { name: electionName, menu_url: null },
  ];


  const handleDashboardBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const onLoad = async () => {
    const dashboardResponse = await getElectionDashboardDataApi({
      electionId: electionId,    
    });
    console.log({ dashboardResponse });
   
  };

  useEffect(() => {
    onLoad();
  }, [type]);

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "dashboard-count",
      election_id: currentElection.id,
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const handleTypeToggle = (buttonType) => {
    console.log({ buttonType: buttonType });

    setType(buttonType);
  };

  return (
    <>
      <ElectionDashboardTemplateComponent
        templateModal={{          
          dashboardData,        
          breadCrumbs,
          type,
        }}
        templateEvents={{                
          handleDashboardBackClick,         
          handleRefreshClick,
          handleTypeToggle,
        }}
      />
    </>
  );
};

export default ElectionDashboardComponent;
