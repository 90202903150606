import { END_POINT } from "@constants/api.constants";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";
import { store } from "@src/store";

const { BUG_COMMENTS_CREATE, BUG_COMMENTS_LIST } = END_POINT;

export const createEntry = async (payload) => {
  return await fetch(BUG_COMMENTS_CREATE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getDataApi = async (payload) => {
  return await fetch(BUG_COMMENTS_LIST, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
