import { APP } from "@constants/app.constants";
const { RESERVATION_TYPE_2 } = APP;

export const getDistrictUnionFormInputs = ({
  states,
  districts,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the District Union ID",
    validations: [
      {
        type: "REQUIRED",
        message: "District Union ID is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "District Union Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the District Union Name",
    validations: [
      {
        type: "REQUIRED",
        message: "District Union Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION_TYPE_2,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Total No of Wards",
    id: "totalNoOfWards",
    name: "total_no_of_wards",
    value: initialValues?.totalNoOfWards || "",
    placeholder: "Enter the Total No of Wards",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
