import FeatherIcon from "feather-icons-react";
import ActivityLogComponent from "@screens/dashboard/components/activity-log.component";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

const DashboardTemplateComponent = ({
  templateModal: { dashboardData, tableOne, tableTwo, breadCrumbs },
  templateEvents: {
    getTableTwoData,
    handleBackClick,
    handleDashboardBackClick,
    handleTableTwoRedirect,
    handleTableOneRedirect,
  },
}) => {
  console.log({ dashboardData });
  const ActionContent = ({ handleDashboardBackClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleDashboardBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    );
  };
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        breadCrumbs={breadCrumbs}
        actionContent={
          <ActionContent handleDashboardBackClick={handleDashboardBackClick} />
        }
      />
      <div className="app-user-list">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalDistrictCount}
                    </h3>
                    <p className="card-text">Total District</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="map-pin" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalPCCount}
                    </h3>
                    <p className="card-text">Total PC</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                    <div className="avatar-content">
                      <FeatherIcon icon="users" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalBoothCount}
                    </h3>
                    <p className="card-text">Total Booth</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidateCount}
                    </h3>
                    <p className="card-text">Total Candidates</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalVotersCount}
                    </h3>
                    <p className="card-text">Total Voters</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="mail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalMaleVotersCount}
                    </h3>
                    <p className="card-text">Total Male Voters</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalFemaleVotersCount}
                    </h3>
                    <p className="card-text">Total Female Voters</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalTransgenderVotersCount}
                    </h3>
                    <p className="card-text">Total Transgender</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidateCount}
                    </h3>
                    <p className="card-text">Total Candidates</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidatePhoneCount}
                    </h3>
                    <p className="card-text">Total Phone Number</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidateWhatsAppCount}
                    </h3>
                    <p className="card-text">Total WhatsApp Number</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalCandidateCount}
                    </h3>
                    <p className="card-text">Total Clients</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalUserLoginCount}
                    </h3>
                    <p className="card-text">Total User Login</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalUserLoginBoothCount}
                    </h3>
                    <p className="card-text">Total Booth Logins</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalPoliticalPartyCandidateCount}
                    </h3>
                    <p className="card-text">
                      Total Political Party Candidates
                    </p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                    <div className="avatar-content">
                      <FeatherIcon icon="map-pin" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalIndependentCount}
                    </h3>
                    <p className="card-text">Total Independent Candidates</p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                    <div className="avatar-content">
                      <FeatherIcon icon="users" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalPoliticalContestingCount}
                    </h3>
                    <p className="card-text">
                      Total Political Party Candidate Contesting
                    </p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                    <div className="avatar-content">
                      <FeatherIcon icon="file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="fw-bolder mb-75">
                      {dashboardData?.totalIndependentContestingCount}
                    </h3>
                    <p className="card-text">
                      Total Independent Candidates Contesting
                    </p>
                  </div>
                  <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                    <div className="avatar-content">
                      <FeatherIcon icon="minus-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              {tableOne.length > 0 ? (
                <>
                  <div className="row">
                    <div
                      class="col-md-4"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      PC Name
                    </div>
                    <div
                      class="col-md-2 "
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      AC Count
                    </div>
                    <div
                      class="col-md-2"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      Booth Count
                    </div>
                    <div
                      class="col-md-2"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      Voter Count
                    </div>
                    <div
                      class="col-md-2"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      Candidate Count
                    </div>
                  </div>
                  {tableOne.map((data, index) => (
                    <div container spacing={3} key={index}>
                      <div className="row">
                        <div
                          class="col-md-4"
                          style={{ fontWeight: "bold", padding: "2px" }}
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() => handleTableOneRedirect(data, "name")}
                          >
                            {" "}
                            {data.govt_id}-{data.name}{" "}
                          </p>
                        </div>
                        <div
                          class="col-md-2 "
                          style={{
                            fontWeight: "bold",
                            padding: "2px",
                          }}
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() => getTableTwoData(data.pc_id)}
                          >
                            {" "}
                            {data.ac_count}{" "}
                          </p>
                        </div>
                        <div
                          class="col-md-2 "
                          style={{ fontWeight: "bold", padding: "2px" }}
                        >
                          <p> {data.booth_count} </p>
                        </div>
                        <div
                          class="col-md-2 "
                          style={{ fontWeight: "bold", padding: "2px" }}
                        >
                          <p> {data.voter_count} </p>
                        </div>
                        <div
                          class="col-md-2 "
                          style={{ fontWeight: "bold", padding: "2px" }}
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() =>
                              handleTableOneRedirect(data, "candidate_count")
                            }
                          >
                            {" "}
                            {data.candidate_count}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
              {tableTwo && tableTwo.length ? (
                <>
                  <div className="row">
                    <div style={{ width: 200 }}>
                      <p
                        style={{
                          cursor: "pointer",
                          fontSize: 15,
                        }}
                        onClick={handleBackClick}
                      >
                        <FeatherIcon icon="back" />
                        <b> Back</b>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      class="col-md-4"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      AC Name
                    </div>
                    <div
                      class="col-md-2"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      Booth Count
                    </div>
                    <div
                      class="col-md-2"
                      style={{ fontWeight: "bold", padding: "15px" }}
                    >
                      Voter Count
                    </div>
                  </div>
                  {tableTwo.map((data, index) => (
                    <div container spacing={3} key={index}>
                      <div className="row">
                        <div class="col-md-4" style={{ fontWeight: "bold" }}>
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() =>
                              handleTableTwoRedirect(data, "ac_name")
                            }
                          >
                            {" "}
                            {data.govt_id} - {data.name}{" "}
                          </p>
                        </div>
                        <div class="col-md-2 " style={{ fontWeight: "bold" }}>
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() =>
                              handleTableTwoRedirect(data, "booth_count")
                            }
                          >
                            {" "}
                            {data.booth_count}{" "}
                          </p>
                        </div>
                        <div class="col-md-2 " style={{ fontWeight: "bold" }}>
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: 15,
                            }}
                            onClick={() =>
                              handleTableTwoRedirect(data, "voter_count")
                            }
                          >
                            {" "}
                            {data.voter_count}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default DashboardTemplateComponent;
