import { APP_NAME } from "@constants/app.constants";

import BulkVoiceCallCreateComponent from "@screens/campaign/bulk-voice-call/bulk-voice-call-create/bulk-voice-call-create";
import BulkVoiceCallEditComponent from "@screens/campaign/bulk-voice-call/bulk-voice-call-edit/bulk-voice-call-edit";
import BulkVoiceCallEntriesListComponent from "@screens/campaign/bulk-voice-call/bulk-voice-call-entries-list/bulk-voice-call-entries-list-component";
import BulkVoiceCallListComponent from "@screens/campaign/bulk-voice-call/bulk-voice-call-list/bulk-voice-call-list.component";

const bulkVoiceCallRoutes = [
  {
    name: `Bulk Voice Call - ${APP_NAME}`,
    path: "/campaign/bulk-voice-call",
    icon: "phone-call",
    component: BulkVoiceCallListComponent,
  },
  {
    name: `Create Bulk Voice Call - ${APP_NAME}`,
    path: "/campaign/bulk-voice-call/create",
    component: BulkVoiceCallCreateComponent,
  },
  {
    name: `Edit Bulk Voice Call - ${APP_NAME}`,
    path: "/campaign/bulk-voice-call/edit/",
    component: BulkVoiceCallEditComponent,
  },

  {
    name: `Entries- ${APP_NAME}`,
    path: "/campaign/bulk-voice-call/entries",
    component: BulkVoiceCallEntriesListComponent,
  },
];

export default bulkVoiceCallRoutes;
