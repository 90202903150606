import React from "react";
import VillagePanchayatWardDashboardComponent from "../dashboard/dashboard.component";
import VillagePanchayatWardChartComponent from "./chart.component";

const VillagePanchayatWardSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <VillagePanchayatWardChartComponent />}
      {type === "DASHBOARD" && <VillagePanchayatWardDashboardComponent />}
    </>
  );
};

export default VillagePanchayatWardSwapComponent;
