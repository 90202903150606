const tableTitle = "Data Download Requests List";
const keyColumn = "id";
const menuUrl = "data-download-requests";
const tableColumn = [
  { id: "request_type", numeric: false, disablePadding: false, label: "Type" },

  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeText: {
      1: "Pending",
      2: "Processing",
      3: "Success",
      4: "Failed",
    },
    badgeColor: {
      1: "warning",
      2: "info",
      3: "success",
      4: "danger",
    },
  },
];
const breadCrumbs = [
  { name: "Data Download Requests", menu_url: "/data-download-requests" },
];

const filters = [
  // {
  //   name: "title",
  //   id: "title",
  //   label: "Title",
  //   value: "",
  //   type: "text",
  // },
];
const additionalActions = ({ handleViewDetail }) => [
  {
    name: "View Details",
    icon: "eye",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleViewDetail,
  },
];
export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
