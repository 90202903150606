import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuUrl } from "../political-party-list/model/political-party-list.constants";
import { createEntry } from "./political-party-create.component.service";
import { getPoliticalPartyFormInputs } from "../model/political-party.modal";
import PoliticalPartyTemplateCreateComponent from "./political-party-create-template.component";
import { uploadlogo } from "../political-party-list/political-party-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  POLITICAL_PARTY_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const PoliticalPartyCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleLogoUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadLogoResponse = await uploadlogo(file[0]);
    if (uploadLogoResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file:
          BASE_URL + "app-source/political-party/" + uploadLogoResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      console.log({ copyFormInputs2 });

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let politicalPartyPayload = {};
    for (let i = 0; i < values.length; i++) {
      politicalPartyPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const politicalPartyResponse = await createEntry(politicalPartyPayload);

    // INFO : check api response and show relevant alert message
    if (politicalPartyResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: politicalPartyResponse.message,
        })
      );
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const statesList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          fullRegionalName: "",
          shortRegionalName: "",
        };
      });
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
      const formInputData = getPoliticalPartyFormInputs(
        formatedStateList,
        statesList,
        {},
        handleLogoUpload
      );
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <PoliticalPartyTemplateCreateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default PoliticalPartyCreateComponent;
