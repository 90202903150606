import { setAlertMessage } from "@reducers/alert.reducer";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ResetPasswordFormComponent from "./reset-password-form.component";
import { getResetFormInputs } from "./reset-password.modal";
import { resetPasswordApi } from "./reset-password.service";

const ResetPasswordComponent = ({ open, handleResetPassword }) => {
  const dispatch = useDispatch();
  const initialInputs = getResetFormInputs({});
  const [formInputs, setFormInputs] = useState(initialInputs);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleFormCancel = () => {
    handleResetPassword(false);
    const copyInputs = [...formInputs];
    for (let index = 0; index < copyInputs.length; index++) {
      copyInputs[index].error = "";
      copyInputs[index].value = "";
    }
    setFormInputs(copyInputs);
  };

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleResetFormSubmit = async ({ values, errors }) => {
    let resetPayload = {};
    for (let i = 0; i < values.length; i++) {
      resetPayload[values[i].name] = values[i].value;
    }
    if (resetPayload && resetPayload.new_password !== resetPayload.confirm_password) {
      dispatch(
        setAlertMessage({
          type: "danger",
          message: "New password and confirm password should be same",
        })
      );
      return;
    }
    const resetResponse = await resetPasswordApi({
      current_password: resetPayload.current_password,
      new_password: resetPayload.new_password,
    });

    if (resetResponse.status) {
      handleResetPassword(false);
      dispatch(
        setAlertMessage({
          type: "success",
          message: resetResponse.message ?? "Password updated successfully!",
        })
      );
    }
  };

  const templateModal = {
    open,
    formTriggers,
    formInputs,
  };
  const templateEvents = {
    handleFormCancel,
    handleResetFormSubmit,
    handleTriggerClick,
  };
  return (
    <ResetPasswordFormComponent templateModal={templateModal} templateEvents={templateEvents} />
  );
};

export default ResetPasswordComponent;
