import CardComponent from "@atoms/card/card.component";
import TabComponent from "@atoms/tab/tab.component";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import FormBuilderRenderInputs from "./render-inputs.component";

const FormInputRender = ({
  changeCount,
  group,
  formInputs,
  handleChange,
  handleFileUpload,
  handleDeleteFile,
  handleKeyDown,
}) => {
  return (
    <div className="card-body">
      {formInputs.map((formInput, index) => {
        if (group === formInput.groupName)
          return (
            <FormBuilderRenderInputs
              formInputs={formInputs}
              changeCount={changeCount}
              formInput={formInput}
              index={index}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleKeyDown={handleKeyDown}
            />
          );

        return <></>;
      })}
    </div>
  );
};

const FormBuilderTabFormComponent = ({
  changeCount,
  groups,
  setActiveTab,
  activeTab,
  formInputs,
  handleChange,
  handleFileUpload,
  handleDeleteFile,
  handleKeyDown,
}) => {
  const [tabContent, setTabContent] = useState([]);

  const onLoad = () => {
    const formattedTabs = groups.map((group, index) => {
      return {
        id: index + 1,
        name: group,
        content: (
          <FormInputRender
            changeCount={changeCount}
            group={group}
            formInputs={formInputs}
            handleChange={handleChange}
            handleFileUpload={handleFileUpload}
            handleDeleteFile={handleDeleteFile}
            handleKeyDown={handleKeyDown}
          />
        ),
      };
    });
    setTabContent(formattedTabs);
  };

  useEffect(() => {
    onLoad();
  }, [formInputs]);

  return (
    <>
      <CardComponent>
        <TabComponent
          data={tabContent}
          active={activeTab}
          setActiveTab={setActiveTab}
        />
      </CardComponent>
    </>
  );
};

export default FormBuilderTabFormComponent;
