import { END_POINT } from "@constants/api.constants";
import { formatDate, formatDateUtil } from "@utils/date.util";

const { USER_STATUS_TOGGLE } = END_POINT;

const tableTitle = "Users List";
const keyColumn = "id";
const menuUrl = "catalog-urban/ac";
const tableColumn = [
  {
    id: "client_name",
    numeric: false,
    disablePadding: true,
    label: " Client",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: " Role",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: " Name",
    sorting: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: " User Name",
    sorting: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: true,
    label: " Contact No",
    sorting: true,
  },
  {
    id: "whatsapp_number",
    numeric: false,
    disablePadding: true,
    label: " WhatsApp No",
  },
  {
    id: "expiry_date",
    numeric: false,
    disablePadding: true,
    label: " Expiry Date",
    formatData: formatDate,
    width: 100,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: USER_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Enabled",
      0: "Disabled",
    },
    badgeColor: {
      1: "success",
      0: "danger",
    },
    sorting: true,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 150,
  },
];
const breadCrumbs = [
  { name: "Catalog Urban", menu_url: null },
  { name: "AC", menu_url: "/catalog-urban/ac" },
];
const filters = [
  {
    name: "role",
    id: "roleName",
    label: "Role Name",
    value: "",
    type: "text",
  },
  {
    name: "name",
    id: "userName",
    label: "Username",
    value: "",
    type: "text",
  },
  {
    name: "email",
    id: "email",
    label: "Email",
    value: "",
    type: "text",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
