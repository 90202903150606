import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DataGridComponent from "@molecules/data-grid/data-grid.component";
import FeatherIcon from "feather-icons-react";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";

const ActionContent = ({ handleCreateClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleCreateClick}
    >
      <FeatherIcon icon="plus" size={17} />
    </button>
  );
};

function MenuItemsListTemplateComponent({
  templateModal: {
    tableTitle,
    keyColumn,
    breadCrumbs,
    tableColumn,
    tableData,
    totalCount,
    filters,
    tableParams,
  },
  templateEvents: {
    getMenuItemData,
    handleCreateClick,
    deleteRecords,

    submitFilter,
  },
}) {
  const settingFormCreate = `${breadCrumbs[1].menu_url}/create`;
  const settingFormEdit = `${breadCrumbs[1].menu_url}/edit`;

  console.log({ filters });

  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"All Menu Items"}
        breadCrumbs={breadCrumbs}
        actionContent={<ActionContent handleCreateClick={handleCreateClick} />}
      />
      <DataGridComponent
        title={tableTitle}
        keyColumn={keyColumn}
        tableData={tableData}
        tableColumn={tableColumn}
        totalCount={totalCount}
        dataRefresh={getMenuItemData}
        editLink={settingFormEdit}
        deleteRecords={deleteRecords}
        breadCrumbs={breadCrumbs}
        filters={filters}
        submitFilter={submitFilter}
        tableParams={tableParams}
      />
    </PageWrapperComponent>
  );
}

export default MenuItemsListTemplateComponent;
