import { createAction, createReducer } from "@reduxjs/toolkit";

export const setFilterList = createAction("filter/setFilterList");
export const updateFilterList = createAction("filter/updateFilterList");
export const updateFilterParams = createAction("filter/updateFilterParams");
export const updateColumns = createAction("filter/updateColumns");
export const addFilterItem = createAction("filter/addFilterItem");

export const filterReducer = createReducer(
  {
    filterList: [],
  },
  {
    [setFilterList]: (state, action) =>
      (state = { ...state, filterList: action.payload }),
    [addFilterItem]: (state, action) =>
      (state = { ...state, filterList: [...state.filterList, action.payload] }),
    [updateFilterParams]: (state, action) =>
      (state = {
        ...state,
        filterList: state.filterList.map((filter, index) =>
          action.payload.index === index
            ? { ...filter, params: action.payload.params }
            : filter
        ),
      }),
    [updateColumns]: (state, action) =>
      (state = {
        ...state,
        filterList: state.filterList.map((filter, index) =>
          action.payload.index === index
            ? { ...filter, columns: action.payload.columns }
            : filter
        ),
      }),
    [updateFilterList]: (state, action) =>
      (state = {
        ...state,
        filterList: state.filterList.map((filter, index) =>
          action.payload.index === index
            ? { ...filter, filter: action.payload.filter }
            : filter
        ),
      }),
  }
);
