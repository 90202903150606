import { APP_NAME } from "@constants/app.constants";

import ClientListComponent from "@screens/user-administration/client/client-list/client-list.component";
import ClientCreateComponent from "@screens/user-administration/client/client-create/client-create.component";
import ClientEditComponent from "@screens/user-administration/client/client-edit/client-edit.component";
import ClientUserListComponent from "@screens/user-administration/client/client-user-list/client-user-list-component";

const clientRoutes = [
  {
    name: "Client",
    icon: "twitch",
    path: "/user-administration/client",
    component: ClientListComponent,
  },
  {
    name: `Create Client - ${APP_NAME}`,
    path: "/user-administration/client/create",
    component: ClientCreateComponent,
  },
  {
    name: `Edit Client - ${APP_NAME}`,
    path: "/user-administration/client/edit",
    component: ClientEditComponent,
  },

  {
    name: `Client Users- ${APP_NAME}`,
    path: "/user-administration/client/user-list",
    component: ClientUserListComponent,
  },
];

export default clientRoutes;
