import { END_POINT } from "@constants/api.constants";
import { getSubMenus } from "@screens/system/menu-items/menu-items-create/menu-items-create.component.service";
import { formatDate, formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "Bug List";
const keyColumn = "id";
const menuUrl = "bug-tracker";
const { BUG_TRACKER_STATUS_TOGGLE } = END_POINT;
const tableColumn = [
  {
    id: "issue_no",
    numeric: false,
    disablePadding: false,
    label: "Issue No",
    sorting: true,
    isVisible: true,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: 150,
    isVisible: true,
  },
  {
    id: "main_menu",
    numeric: false,
    disablePadding: false,
    label: "Main Menu",
    width: 100,
    isVisible: true,
  },
  {
    id: "sub_menu",
    numeric: false,
    disablePadding: false,
    label: "Sub Menu",
    width: 100,
    isVisible: true,
  },

  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    formatData: formatRemarks,
    width: 500,
    isVisible: true,
  },
  {
    id: "set_status",
    numeric: false,
    disablePadding: false,
    label: "Set Status as Completed",
    isBadge: true,
    badgeClick: BUG_TRACKER_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      Open: "Open",
      Completed: "Completed",
    },
    badgeColor: {
      Open: "danger",
      Completed: "success",
    },
    isVisible: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isBadge: true,
    primaryKey: keyColumn,
    badgeText: {
      Bug: "Bug",
      Feature: "Feature",
    },
    badgeColor: {
      Bug: "danger",
      Feature: "info",
    },
    sorting: true,
    isVisible: true,
  },
  {
    id: "created_by",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    width: 100,
    sorting: true,
    isVisible: true,
  },
  {
    id: "assigned_to",
    numeric: false,
    disablePadding: false,
    label: "Assigned to",
    width: 100,
    sorting: true,
    isVisible: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isBadge: true,
    primaryKey: keyColumn,
    badgeText: {
      Low: "Low",
      Medium: "Medium",
      High: "High",
      Immediate: "Immediate",
    },
    badgeColor: {
      Low: "primary",
      Medium: "info",
      High: "danger",
      Immediate: "warning",
    },
    sorting: true,
    isVisible: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    primaryKey: keyColumn,
    badgeText: {
      Open: "Open",
      Pending: "Pending",
      "Need Clarity": "Need Clarity",
      "On Hold": "On Hold",
      Absolute: "Absolute",
      Duplicate: "Duplicate",
      Closed: "Closed",
      Completed: "Completed",
      Reopen: "Reopen",
    },
    badgeColor: {
      "Need Clarity": "primary",
      "On Hold": "info",
      Open: "danger",
      Duplicate: "dark",
      Absolute: "dark",
      Pending: "warning",
      Completed: "success",
      Closed: "success",
      Reopen: "warning",
    },
    sorting: true,
    isVisible: true,
  },

  {
    id: "completed_on",
    numeric: false,
    disablePadding: false,
    label: "Completed On",
    formatData: formatDate,
    sorting: true,
    width: 120,
    sorting: true,
    isVisible: true,
  },
  {
    id: "no_of_days_to_complete",
    numeric: false,
    disablePadding: false,
    label: "No.of days to Complete",
    sorting: true,
    width: 120,
    sorting: true,
    isVisible: true,
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: false,
    label: "Comment",
    formatData: formatRemarks,
    width: 250,
    isVisible: true,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
    sorting: true,
    isVisible: true,
  },
];
const breadCrumbs = [{ name: "Bug Tracker", menu_url: "/bug-tracker" }];

const filters = ({ mainMenus, assignedTo }) => [
  {
    name: "title",
    id: "title",
    label: "Title",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "main_menu_id",
    id: "main_menu_id",
    label: "Main Menu",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Main Menu",
    options: mainMenus,
    onChange: async (value, formInputs) => {
      const subMenuResponse = await getSubMenus(value);
      if (subMenuResponse.status) {
        const formattedSubMenus = subMenuResponse.data.map((menu) => {
          return {
            name: menu.menu_name,
            value: menu.admin_menu_id,
          };
        });

        formInputs[2].options = formattedSubMenus;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "sub_menu_id",
    id: "sub_menu_id",
    label: "Sub Menu",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Sub Menu",
    options: [],
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "type",
    id: "type",
    label: "Type",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Type",
    options: [
      { name: "Bug", value: "Bug" },
      { name: "Feature", value: "Feature" },
    ],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    type: "CALENDER",
    name: "start_date",
    id: "start_date",
    label: "From Date",
    value: "",
    placeholder: "Select From Date",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    type: "CALENDER",
    name: "end_date",
    id: "end_date",
    label: "To Date",
    value: "",
    placeholder: "Select To Date",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Priority",
    options: [
      { name: "Low", value: "Low" },
      { name: "Medium", value: "Medium" },
      { name: "High", value: "High" },
      { name: "Immediate", value: "Immediate" },
    ],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Status",
    options: [
      { name: "Open", value: "Open" },
      { name: "Pending", value: "Pending" },
      { name: "Need Clarity", value: "Need Clarity" },
      { name: "On Hold", value: "On Hold" },
      { name: "Absolute", value: "Absolute" },
      { name: "Duplicate", value: "Duplicate" },
      { name: "Closed", value: "Closed" },
      { name: "Completed", value: "Completed" },
      { name: "Reopen", value: "Reopen" },
    ],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "assigned_to",
    id: "assigned_to",
    label: "Assigned to",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Assigned to",
    options: assignedTo,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const additionalActions = ({ handleComment }) => [
  {
    name: "Comment",
    icon: "message-circle",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleComment,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
