import { APP } from "@constants/app.constants";
const { VOTER_FIELD_TYPE, STATUS } = APP;

export const getVoterFieldFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Field Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Field Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Field Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type",
    options: VOTER_FIELD_TYPE,
    value: initialValues?.type || 1,
    placeholder: "Select Type",
    validations: [
      {
        type: "REQUIRED",
        message: "Type is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Meta Data",
    id: "metaData",
    name: "meta_data",
    value: initialValues?.metaData || "",
    placeholder: "Enter the Meta Data",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Type",
    id: "status",
    name: "status",
    options: STATUS,
    value: initialValues?.status || 1,
    placeholder: "Select Status",
    validations: [],
  },
];
