import React from "react";
import { Table } from "reactstrap";

const RolePermissionTableComponent = ({
  templateModal: { menus, checkBoxState, intermediateState },
  templateEvents: { setMenus, setCheckBoxState, setIntermediateState },
}) => {
  const selectAllCheckBoxes = (type) => {
    // console.log({ type, checkBoxState, menus });
    const checkedStatus = checkBoxState[type];
    const menuList = menus.map((menu) => {
      let readPermission = menu.readPermission;
      let createPermission = menu.createPermission;
      let editPermission = menu.editPermission;
      let deletePermission = menu.deletePermission;

      if (type === "readPermission") readPermission = !checkedStatus;
      if (type === "createPermission") createPermission = !checkedStatus;
      if (type === "editPermission") editPermission = !checkedStatus;
      if (type === "deletePermission") deletePermission = !checkedStatus;

      return {
        ...menu,
        readPermission: readPermission,
        createPermission: createPermission,
        editPermission: editPermission,
        deletePermission: deletePermission,
      };
    });
    funSetCheckBoxState(type, !checkedStatus);
    funSetIntermediateState(type, false);
    setMenus(menuList);
  };

  const changeCheckBoxState = (type, index, rowMenu) => {
    console.log({ menus, rowMenu });
    let menuList = [...menus];
    menuList[index][type] = !menuList[index][type];
    setIntermediateStatus(type);
    setMenus(menuList);
  };

  const setIntermediateStatus = (type) => {
    if (menus && menus.length > 0) {
      const selectedCount = menus.filter((menu) => menu[type] === true);
      if (menus.length === selectedCount.length) {
        funSetCheckBoxState(type, true);
        funSetIntermediateState(type, false);
        return;
      }
      funSetCheckBoxState(type, false);
      if (selectedCount.length === 0)
        return funSetIntermediateState(type, false);
      return funSetIntermediateState(type, true);
    }
  };

  const funSetIntermediateState = (type, state) => {
    if (type === "readPermission")
      setIntermediateState({ ...intermediateState, readPermission: state });
    if (type === "createPermission")
      setIntermediateState({ ...intermediateState, createPermission: state });
    if (type === "editPermission")
      setIntermediateState({ ...intermediateState, editPermission: state });
    if (type === "deletePermission")
      setIntermediateState({ ...intermediateState, deletePermission: state });
  };

  const funSetCheckBoxState = (type, state) => {
    if (type === "readPermission")
      setCheckBoxState({ ...checkBoxState, readPermission: state });
    if (type === "createPermission")
      setCheckBoxState({ ...checkBoxState, createPermission: state });
    if (type === "editPermission")
      setCheckBoxState({ ...checkBoxState, editPermission: state });
    if (type === "deletePermission")
      setCheckBoxState({ ...checkBoxState, deletePermission: state });
  };

  const checkAllPermission = (index, menu) => {
    const status = getAllCheckedStatus(menu);
    let menuList = [...menus];
    menuList[index].readPermission = !status;
    menuList[index].createPermission = !status;
    menuList[index].editPermission = !status;
    menuList[index].deletePermission = !status;
    setMenus(menuList);
  };

  const getAllCheckedStatus = (menu) => {
    if (
      menu.readPermission &&
      menu.createPermission &&
      menu.editPermission &&
      menu.deletePermission
    )
      return true;

    return false;
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Menu Name</th>
          <th>All</th>
          <th>
            <input
              type="checkbox"
              className="form-check-input"
              checked={checkBoxState.readPermission}
              onChange={() => selectAllCheckBoxes("readPermission")}
            />{" "}
            Read
          </th>
          <th>
            <input
              type="checkbox"
              className="form-check-input"
              checked={checkBoxState.createPermission}
              onChange={() => selectAllCheckBoxes("createPermission")}
            />{" "}
            Create
          </th>
          <th>
            <input
              type="checkbox"
              className="form-check-input"
              checked={checkBoxState.editPermission}
              onChange={() => selectAllCheckBoxes("editPermission")}
            />{" "}
            Edit
          </th>
          <th>
            <input
              type="checkbox"
              className="form-check-input"
              checked={checkBoxState.deletePermission}
              onChange={() => selectAllCheckBoxes("deletePermission")}
            />{" "}
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {menus &&
          menus.map((menu, index) => (
            <tr key={index}>
              <td>{menu.menu_name}</td>
              <td>
                A{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={getAllCheckedStatus(menu)}
                  onChange={() => checkAllPermission(index, menu)}
                />{" "}
              </td>
              <td>
                R{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={menu.readPermission}
                  onChange={() =>
                    changeCheckBoxState("readPermission", index, menu)
                  }
                />
              </td>
              <td>
                C{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={menu.createPermission}
                  onChange={() =>
                    changeCheckBoxState("createPermission", index, menu)
                  }
                />
              </td>
              <td>
                E{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={menu.editPermission}
                  onChange={() =>
                    changeCheckBoxState("editPermission", index, menu)
                  }
                />
              </td>
              <td>
                D{" "}
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={menu.deletePermission}
                  onChange={() =>
                    changeCheckBoxState("deletePermission", index, menu)
                  }
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default RolePermissionTableComponent;
