import React, { useEffect, useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
} from "@screens/political-party/political-party-list/model/political-party-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";

import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import {
  deleteDataApi,
  getDataApi,
} from "./political-party-list.component.service";
import PoliticalPartyListTemplateComponent from "./political-party-list-template.component";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { END_POINT } from "@constants/api.constants";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  POLITICAL_PARTY_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const PoliticalPartyListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const [filterState, setFilterState] = useState([]);
  const { token } = useSelector((state) => state.app);
  const { POLITICAL_PARTY_EXPORT } = END_POINT;
  const { appRowsPerPage } = useSelector((state) => state.app);

  const getPoliticalPartyData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const potiticalPartyData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (potiticalPartyData.status) {
      console.log(potiticalPartyData);
      setTableData(potiticalPartyData.data);
      setTotalCount(potiticalPartyData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getPoliticalPartyData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    window.open(`${POLITICAL_PARTY_EXPORT}?token=${token}`);
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getPoliticalPartyData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const onLoad = async () => {
    const stateResponse = await getAllStates();
    console.log({ stateResponse });

    if (stateResponse.status) {
      const formattedStates = stateResponse.data.map((state) => {
        return {
          name: state.name,
          value: state.id,
        };
      });
      const copyFilters = rawFilters({
        states: formattedStates,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <PoliticalPartyListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : filterState,
        tableParams,
      }}
      templateEvents={{
        getPoliticalPartyData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
        handleDownload,
      }}
    />
  ) : (
    <> </>
  );
};

export default PoliticalPartyListComponent;
