import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
  tableControls as rawControls,
  tableConfig,
} from "@screens/election-urban/election-urban-list/model/election-urban-list.constants";
import {
  formatMenuData,
  getFilterData,
  sortArrayOfObject,
} from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess, formatElectionMenus } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { setCurrentElection } from "@reducers/election.reducer";
import { setMenuListAction, updateSubMenuAction } from "@reducers/menu.reducer";
import { deleteDataApi, getDataApi } from "./election-urban-list.service";
import {
  getElectionByState,
  setCurrentElectionAPI,
} from "@screens/election/election-list/election-list.component.service";
import ElectionUrbanListTemplateComponent from "./election-urban-list.template";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const ElectionRuralListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const { appRowsPerPage } = useSelector((state) => state.app);
  const { currentElection } = useSelector((state) => state.election);
  const { allMenuList } = useSelector((state) => state.menu);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const tableParams = getParamsData(filterList, menuUrl);
  const [electionDialog, setElectionDialog] = useState(false);
  const [isCounter, setIsCounter] = useState(false);
  const [counter, setCounter] = useState(10);
  const [password, setPassword] = useState("");
  const [electionRowData, setElectionRowData] = useState({});
  const [error, setError] = useState("");
  let timer = null;
  const [filterState, setFilterState] = useState(filters);
  // console.log({ allMenuList });
  const setActionElectionColor = () => {
    if (currentElection) {
      const allElectionButton = document.getElementsByClassName(
        "btn btn-outline-info"
      );
      if (allElectionButton.length > 0) {
        for (let i = 0; i < allElectionButton.length; i++) {
          allElectionButton[i].style.backgroundColor = "#2a3045";
        }
      }
      const setElectionButton = document.querySelectorAll(
        "#Default-" + currentElection.id
      );

      // console.log({ setElectionButton });
      if (setElectionButton.length > 0)
        setElectionButton[0].style.backgroundColor = "#d6f6fc";
    }
  };

  const handleChangePassword = (value) => {
    setPassword(value);
  };

  const handleDashboardClick = (row) => {
    // console.log({ row });
    if (row.type_of_election == "PC")
      history.push(
        `/election/pc-dashboard?electionId=${row.id}&electionName=${row.name}&stateId=${row.state_id}`
      );
    else
      history.push(
        `/election/ac-dashboard?electionId=${row.id}&electionName=${row.name}&stateId=${row.state_id}`
      );
  };

  const handleCurrentElection = async (row) => {
    const setCurrentElectionResponse = await setCurrentElectionAPI(row.id);
    if (setCurrentElectionResponse.status) dispatch(setCurrentElection(row));
  };

  const handleDeleteRecordsOverride = ({ value, selected, setSelected }) => {
    setElectionDialog(value);
    setElectionRowData({ selected, setSelected });
  };

  const submitPasswordDialog = () => {
    if (password !== "") {
      setIsCounter(true);
    } else {
      setError("Password is required!");
      setIsCounter(false);
    }
  };

  const getElectionData = async (params = null) => {
    // console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (data.status) {
      const formattedData = data.data.map((election) => {
        return {
          ...election,
          current_election: currentElection?.id === election.id ? 1 : 0,
        };
      });

      const sortedData = sortArrayOfObject(
        formattedData,
        "current_election",
        "ASC"
      );

      console.log({ formattedData, sortedData });
      setTableData(sortedData);
      setTotalCount(data.totalCount);
      setTimeout(() => {
        setActionElectionColor();
      }, 500);
    }
  };

  const cancelPasswordDialog = () => {
    electionRowData.setSelected([]);
    clearInterval(timer);
    setElectionDialog(false);
    setIsCounter(false);
    setCounter(10);
    setError("");
    setPassword("");
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        electionRowData.setSelected([]);
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getElectionData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({
            type: ERROR,
            message: response.message,
            viewed: 0,
          })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  useEffect(() => {
    setActionElectionColor();
  }, [currentElection]);

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);

    // console.log({
    //   url: `${SETTING_EXPORT}?token=${token}${queryStr}`,
    // });
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    // console.log({ queryStr });
    getElectionData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };
  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      return {
        ...control,
        onClick: handleChartClick,
      };
    });
    setTableControls(updatedTableControls);

    const stateResponse = await getElectionByState();
    console.log({ stateResponse });

    if (stateResponse.status) {
      const formattedStates = stateResponse.data.map((election) => {
        return {
          name: election.state_name,
          value: election.state_id,
        };
      });
      const copyFilters = rawFilters({
        states: formattedStates,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    if (isCounter && counter > 0 && password !== "") {
      timer = setInterval(() => {
        setCounter((count) => count - 1);
      }, 1000);
    }
    if (counter === 0) {
      clearInterval(timer);
      deleteRecords(electionRowData.selected);
      cancelPasswordDialog();
    }
    return () => {
      clearInterval(timer);
    };
  }, [isCounter, counter]);

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ElectionUrbanListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filterState,
        tableActions: additionalActions({
          handleDashboardClick,
          handleCurrentElection,
        }),
        openDetailPanel,
        tableControls,
        swapContentType,
        tableParams,
        electionDialog,
        counter,
        password,
        error,
        tableConfig,
      }}
      templateEvents={{
        getElectionData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        submitFilter,
        setOpenDetailPanel,
        handleDeleteRecordsOverride,
        submitPasswordDialog,
        cancelPasswordDialog,
        handleChangePassword,
      }}
    />
  ) : (
    <> </>
  );
};

export default ElectionRuralListComponent;
