import React from "react";
import FormBuilderRenderInputs from "./render-inputs.component";

const FormBuilderGroupFormComponent = ({
  changeCount,
  groups,
  formInputs,
  handleChange,
  handleFileUpload,
  handleDeleteFile,
  handleImagePreview,
  handleKeyDown,
}) => {
  return (
    <div className="row">
      {groups &&
        groups.map((group) => {
          const currentGroup = formInputs.find(
            (input) => input.groupName === group
          );

          const currentGroupItems = formInputs.filter(
            (input) => !input.isHide && group === input.groupName
          );

          if (currentGroupItems.length === 0) return <></>;
          return (
            <div className={currentGroup?.groupClass || "col-md-12"}>
              <div className="card">
                {!currentGroup?.hideGroupName && (
                  <div className="card-header">
                    <div className="card-title"> {group} </div>
                  </div>
                )}

                <div className="card-body">
                  <div className="row">
                    {formInputs.map((formInput, index) => {
                      if (group === formInput.groupName)
                        if (!formInput.isHide)
                          return (
                            <div
                              className={formInput?.inputClass || "col-md-12"}
                            >
                              <FormBuilderRenderInputs
                                formInputs={formInputs}
                                changeCount={changeCount}
                                formInput={formInput}
                                index={index}
                                handleChange={handleChange}
                                handleFileUpload={handleFileUpload}
                                handleDeleteFile={handleDeleteFile}
                                handleImagePreview={handleImagePreview}
                                handleKeyDown={handleKeyDown}
                              />
                            </div>
                          );

                      return <></>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FormBuilderGroupFormComponent;
