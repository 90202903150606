import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getACUrbanFormInputs } from "../model/ac-urban.modal";
import { useEffect } from "react";
import ACUrbanEditTemplateComponent from "./ac-urban-edit-template.component";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";
import { getAcByPcId } from "@screens/master/ac/ac-list/ac-list.component.service";
import { editEntry } from "./ac-urban-edit.component.service";
import { menuUrl } from "../ac-urban-list/model/ac-urban-list.constants";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_URBAN_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const ACUrbanEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let acUrbanPayload = {};
    for (let i = 0; i < values.length; i++) {
      acUrbanPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const acUrbanResponse = await editEntry(
      location?.state?.row?.id,
      acUrbanPayload
    );

    // INFO : check api response and show relevant alert message
    if (acUrbanResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: acUrbanResponse.message })
      );
    }
  };
  const onStateChange = async (value, inputs) => {
    const pcResponse = await getPcByStateId(value);
    if (pcResponse.status) {
      const formattedPcList = pcResponse.data.map((pc) => {
        return {
          name: pc.name,
          value: pc.id,
        };
      });

      inputs[2].options = formattedPcList;
      setFormInputs(inputs);
    }
  };

  const onPcChange = async (value, inputs) => {
    const acResponse = await getAcByPcId(value);
    if (acResponse.status) {
      const formattedAcList = acResponse.data.map((ac) => {
        return {
          name: ac.name,
          value: ac.id,
        };
      });

      inputs[3].options = formattedAcList;
      setFormInputs(inputs);
    }
  };
  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const pcResponse = await getPcByStateId(
        location?.state?.row?.state_id || null
      );
      if (pcResponse.status) {
        const formattedPcList = pcResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });

        const acResponse = await getAcByPcId(
          location?.state?.row?.pc_id || null
        );
        if (acResponse.status) {
          const formattedAcList = acResponse.data.map((ac) => {
            return {
              name: ac.name,
              value: ac.id,
            };
          });
          const formInputData = getACUrbanFormInputs({
            states: formatedStateList,
            pcs: formattedPcList,
            acs: formattedAcList,
            initialValues: {
              govtId: location?.state?.row?.govt_id || "",
              name: location?.state?.row?.name || "",
              type: location?.state?.row?.type,
              regionalName: location?.state?.row?.regional_name || "",
              reservation: location?.state?.row?.reservation || "",
              stateId: location?.state?.row?.state_id || "",
              pcId: location?.state?.row?.pc_id || "",
              acId: location?.state?.row?.ac_id || "",
              totalWardCount: location?.state?.row?.total_ward_count || "",
              remarks: location?.state?.row?.remarks || "",
            },
            onStateChange,
            onPcChange,
          });
          setFormInputs(formInputData);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <ACUrbanEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ACUrbanEditComponent;
