import { END_POINT } from "@constants/api.constants";

const tableTitle = "Email Template";
const keyColumn = "id";
const menuUrl = "system/email-template";

const { EMAIL_TEMPLATE_STATUS_TOGGLE, EMAIL_TEMPLATE_DEFAULT_TOGGLE } =
  END_POINT;

const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email_content",
    numeric: false,
    disablePadding: true,
    label: "Email Content",
  },
  {
    id: "default",
    numeric: false,
    disablePadding: false,
    label: "Default",
    isBadge: true,
    badgeClick: EMAIL_TEMPLATE_DEFAULT_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Default",
      2: "None",
    },
    badgeColor: {
      1: "success",
      2: "danger",
    },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: EMAIL_TEMPLATE_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      1: "Enabled",
      2: "Disabled",
    },
    badgeColor: {
      1: "success",
      2: "danger",
    },
  },
];
const breadCrumbs = [
  { name: "System", menu_url: null },
  { name: "Email Template", menu_url: "/system/email-template" },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
