import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadVoterFieldsFile } from "../file-upload/map-voter-fields.service";

const { VOTER_FIELDS_DATA, STATUS } = APP;

export const getMapVoterFieldsFormInputs = () => {
  let fieldOptions = [
    {
      name: "",
      value: "",
    },
  ];
  return [
    {
      groupName: " File",
      groupClass: "col-md-12",
      hideGroupName: true,
      type: "FILE_UPLOAD",
      label: "Select File",
      id: "file",
      name: "file_name",
      handleSelfUpload: uploadVoterFieldsFile,
      filePrefix: BASE_URL + "app-source/files/",
      validations: [
        {
          type: "REQUIRED",
          message: "File is required !",
        },
      ],
      onChange: async (value, formInputs, additionalValues) => {
        console.log({ value, formInputs, additionalValues });
        if (additionalValues?.uploadResponse?.fields)
          fieldOptions = additionalValues?.uploadResponse?.fields;

        const fileUploadIndex = formInputs.findIndex(
          (input) => input.name === "file_name"
        );

        formInputs[fileUploadIndex].value =
          additionalValues?.uploadResponse?.data;

        const repeaterIndex = formInputs.findIndex(
          (input) => input.name === "mapping_fields"
        );

        console.log({
          repeater: formInputs[repeaterIndex],
          items: additionalValues?.uploadResponse?.fields,
        });

        formInputs[repeaterIndex].inputs[1].options =
          additionalValues?.uploadResponse?.fields;

        formInputs[repeaterIndex].reloadInputs =
          (formInputs[repeaterIndex].reloadInputs || 0) + 1;

        console.log({ formInputs });
        return formInputs;
      },
    },

    {
      groupName: " Fields",
      groupClass: "col-md-12",
      hideGroupName: false,
      type: "FORM_REPEATER",
      validations: [],
      name: "mapping_fields",
      value: VOTER_FIELDS_DATA,
      inputs: [
        {
          type: "TEXT",
          id: "name",
          name: "name",
          placeholder: " Name",
          size: 4,
          isDisabled: true,
        },

        {
          type: "AUTOCOMPLETE",
          id: "field",
          name: "field",
          size: 6,
          options: fieldOptions,
          validations: [],
        },
      ],
    },
  ];
};
