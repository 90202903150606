import { APP_NAME } from "@constants/app.constants";
import PoliticalPartyCreateComponent from "@screens/political-party/political-party-create/political-party-create.component";
import PoliticalPartyEditComponent from "@screens/political-party/political-party-edit/political-party-edit.component";
import PoliticalPartyFileUploadComponent from "@screens/political-party/political-party-file/political-party-file.component";
import PoliticalPartyListComponent from "@screens/political-party/political-party-list/political-party-list.component";
import VersionCreateComponent from "@screens/version/version-create/version-create.component";
import VersionEditComponent from "@screens/version/version-edit/version-edit.component";

const politicalPartyRoutes = [
  {
    name: `Political Party - ${APP_NAME}`,
    path: "/political-party",
    component: PoliticalPartyListComponent,
    icon: "user-check",
  },
  {
    name: `Create Political Party - ${APP_NAME}`,
    path: "/political-party/create",
    component: PoliticalPartyCreateComponent,
  },
  {
    name: `Edit Political Party- ${APP_NAME}`,
    path: "/political-party/edit",
    component: PoliticalPartyEditComponent,
  },

  {
    name: `Edit Political Party- ${APP_NAME}`,
    path: "/political-party/upload-parties",
    component: PoliticalPartyFileUploadComponent,
  },
];

export default politicalPartyRoutes;
