import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { useSelector } from "react-redux";

const ToolTipComponent = ({ children, content }) => {
  const { theme } = useSelector((state) => state.app);
  // console.log(theme);
  return (
    <Tippy theme={theme === "light" ? null : "light"} content={content}>
      {children}
    </Tippy>
  );
};

export default ToolTipComponent;
