import React, { useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
} from "@screens/bug-tracker/bug-tracker-list/model/bug-tracker-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import {
  deleteDataApi,
  getDataApi,
} from "./bug-tracker-list.component.service";
import BugTrackerListTemplateComponent from "./bug-tracker-list-template.component";
import { END_POINT } from "@constants/api.constants";
import { useEffect } from "react";
import { getMainMenus } from "@screens/system/menu-items/menu-items-create/menu-items-create.component.service";
import { getUsers } from "@screens/user-administration/users/users-list/users-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BUG_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const BugTrackerListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const { token } = useSelector((state) => state.app);
  const { BUG_EXPORT } = END_POINT;
  const [filterState, setFilterState] = useState(filters);
  const { appRowsPerPage } = useSelector((state) => state.app);

  const getBugData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const bugData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (bugData.status) {
      console.log(bugData);
      setTableData(bugData.data);
      setTotalCount(bugData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const handleReleaseClick = async () => {
    console.log("coming here");
    history.push(`/version/create`);
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    window.open(`${BUG_EXPORT}?token=${token}${queryStr}`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getBugData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const onLoad = async () => {
    const menuListResponse = await getMainMenus();
    const userResponse = await getUsers();

    if (menuListResponse.status && userResponse.status) {
      const formatedMenuList = menuListResponse.data.map((menu) => {
        return {
          name: menu.menu_name,
          value: menu.admin_menu_id,
        };
      });

      const formatedUsersList = userResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
      const copyFilters = rawFilters({
        mainMenus: formatedMenuList,
        assignedTo: formatedUsersList,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getBugData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleComment = (row) => {
    console.log({ row });
    history.push(`/bug-comments/create?bugId=${row.id}&title=${row.title}`);
  };

  return menuAccess ? (
    <BugTrackerListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filterState,
        tableParams,
        tableActions: additionalActions({
          handleComment,
        }),
      }}
      templateEvents={{
        getBugData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
        handleReleaseClick,
        handleDownload,
      }}
    />
  ) : (
    <> </>
  );
};

export default BugTrackerListComponent;
