import BreadcrumbComponent from "@atoms/breadcrumbs/breadcrumb.component";
import React from "react";

const PageHeadComponent = ({ title, breadCrumbs, actionContent }) => {
  return (
    <div className="content-header row">
      <div className="content-header-left col-md-12 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-7">
            <div>
              <h2 className="content-header-title content-header-left  float-start mb-0">
                {title}
              </h2>
              <BreadcrumbComponent breadCrumbs={breadCrumbs} />
            </div>
          </div>

          <div className="col-5">
            <div className="content-header-right text-md-end">
              <div className="breadcrumb-right dropdown d-flex justify-content-end">
                {actionContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeadComponent;
