import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Voter File Upload Request List";
const keyColumn = "id";
const menuUrl = "catalog/voter-file-upload-request";
const tableColumn = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    label: "File Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    primaryKey: keyColumn,
    isBadge: true,
    badgeText: {
      1: "Pending",
      2: "Processing",
      3: "Completed",
      4: "Error",
    },
    badgeColor: {
      1: "danger",
      2: "warning",
      3: "success",
      4: "danger",
    },
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog", menu_url: null },
  {
    name: "Voter File Upload Request",
    menu_url: "/catalog/voter-file-upload-request",
  },
];

const filters = [];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
