import { APP } from "@constants/app.constants";

const { CLIENT_ACCESS_LEVEL } = APP;

export const getClientFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Mobile",
    id: "mobile",
    name: "mobile",
    value: initialValues?.mobile || "",
    placeholder: "Enter the Mobile No",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Political Party",
    id: "politicalParty",
    name: "political_party",
    value: initialValues?.politicalParty || "",
    placeholder: "Enter the Political Party",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Access Level",
    id: "accessLevel",
    name: "access_level",
    options: CLIENT_ACCESS_LEVEL,
    value: initialValues?.accessLevel || "STATE",
    placeholder: "Access Level",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Maximum Users",
    id: "totalUsers",
    name: "total_users",
    value: initialValues?.totalUsers || "",
    placeholder: "Enter the Maximum Users",
    validations: [
      {
        type: "REQUIRED",
        message: "Maximum User Count is required !",
      },
    ],
  },
];
