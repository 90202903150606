import React from "react";
import FeatherIcon from "feather-icons-react";

import { PRIMARY_KEY } from "@constants/app.constants";

const EditWrapper = ({
  children,
  index,
  row,
  element,
  handleTDClick,
  handleChangeInlineEdit,
}) => {
  return (
    <>
      {row.inlineEdit && (
        <input
          id={`txtInline${element.id}${index}`}
          style={{ height: "35px" }}
          onChange={(e) =>
            handleChangeInlineEdit({
              id: element.id,
              value: e.target.value,
              index,
            })
          }
          className="form-control form-control-sm"
          value={row[element.id]}
        />
      )}

      {!row.inlineEdit && (
        <div
          onClick={() =>
            element.inlineEdit ? handleTDClick(index, element) : null
          }
        >
          {children}
        </div>
      )}
    </>
  );
};

const RowElements = ({ element, row, handleBadgeClick }) => {
  return (
    <>
      <div
        style={{ width: element.inlineEdit ? "80%" : "100%", float: "left" }}
      >
        <div style={{ width: element?.width || "100%", float: "left" }}>
          {element.isBadge && (
            <a
              style={{ cursor: "pointer" }}
              onClick={() =>
                element.badgeClick
                  ? handleBadgeClick({
                      action: element.badgeClick,
                      primaryKeyValue: row[PRIMARY_KEY],
                      currentValue: row[element.id],
                    })
                  : null
              }
            >
              <span
                className={`badge bg-${element.badgeColor[row[element.id]]}`}
              >
                {element.badgeText[row[element.id]]}
              </span>
            </a>
          )}

          {!element.isBadge && (
            <>
              {element.formatData
                ? element.formatData(row[element.id])
                : row[element.id]}
            </>
          )}

          {element.columnIcon && (
            <button
              onClick={() =>
                element.columnIcon.onClick
                  ? element.columnIcon.onClick(row)
                  : null
              }
              className="btn btn-default btn-sm"
              style={{ marginLeft: 10 }}
            >
              {element.columnIcon.icon}
            </button>
          )}
        </div>
      </div>

      {element.inlineEdit && (
        <div style={{ float: "right", cursor: "pointer", width: 20 }}>
          <FeatherIcon icon="edit-3" size={12} />
        </div>
      )}
    </>
  );
};

const DataGridRowComponent = ({
  index,
  element,
  row,
  handleBadgeClick,
  handleTDClick,
  handleChangeInlineEdit,
}) => {
  const inlineEditStatus = element?.inlineEdit || false;
  if (inlineEditStatus)
    return (
      <EditWrapper
        element={element}
        row={row}
        index={index}
        handleTDClick={handleTDClick}
        handleChangeInlineEdit={handleChangeInlineEdit}
      >
        <RowElements
          element={element}
          row={row}
          handleBadgeClick={handleBadgeClick}
        />
      </EditWrapper>
    );

  if (!inlineEditStatus)
    return (
      <RowElements
        element={element}
        row={row}
        handleBadgeClick={handleBadgeClick}
      />
    );
};

export default DataGridRowComponent;
