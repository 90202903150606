import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";
import { APP } from "@constants/app.constants";
import { getDistrictByStateId } from "@screens/catalog-urban/districts/districts-list/districts-list.component.service";
const { RESERVATION } = APP;

const tableTitle = "AC List";
const keyColumn = "id";
const menuUrl = "catalog-urban/ac";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District Name",
  },
  {
    id: "pc_name",
    numeric: false,
    disablePadding: false,
    label: "PC Name",
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "AC Id",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "AC name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
  },
  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
  },
  {
    id: "phase",
    numeric: false,
    disablePadding: false,
    label: "Phase",
    sorting: true,
  },
  // {
  //   id: "elected_member_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Elected Member",
  //   width: 100,
  //   sorting: true,
  // },
  // {
  //   id: "elected_member_party_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Elected Member Party Name",
  //   width: 100,
  //   sorting: true,
  // },
  {
    id: "voters_count",
    numeric: false,
    disablePadding: false,
    label: "Total Voters",
    sorting: true,
  },
  {
    id: "client_count",
    numeric: false,
    disablePadding: false,
    label: "Total Clients",
    sorting: true,
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Total users",
    sorting: true,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog Urban", menu_url: null },
  { name: "AC", menu_url: "/catalog-urban/ac" },
];

const additionalActions = [];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "reservation",
    id: "reservation",
    label: "Reservation",
    value: "",
    type: "SELECT",
    placeholder: "Select Reservation",
    options: RESERVATION,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "govt_id",
    id: "govt_id",
    label: "AC No",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "AC name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableConfig = {
  //  actionWidth: 170,
};

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  tableConfig,
  additionalActions,
  tableControls,
};
