import { END_POINT } from "@constants/api.constants";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";
import { store } from "@src/store";

const { ROLE_CREATE, ALL_MENUS, ROLE_EDIT } = END_POINT;

export const createRolesApi = async (payload) => {
  return await fetch(ROLE_CREATE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const editRoleApi = async ({
  roleId,
  name,
  menus,
  description,
  type,
}) => {
  return await fetch(`${ROLE_EDIT}/${roleId}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ name, menus, description, type }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, message: data.message };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getAllMenus = async () => {
  return await fetch(ALL_MENUS, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: formatMenu(data.data) };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getIntermediateAndCheckedState = (formatedMenu) => {
  const menuCount = formatedMenu.length;

  const totalReadPermission = formatedMenu.filter(
    (menu) => menu.readPermission === true
  );
  const totalWritePermission = formatedMenu.filter(
    (menu) => menu.createPermission === true
  );
  const totalEditPermission = formatedMenu.filter(
    (menu) => menu.editPermission === true
  );
  const totalDeletePermission = formatedMenu.filter(
    (menu) => menu.deletePermission === true
  );

  let intermediateState = {
    readPermission:
      totalReadPermission.length === menuCount
        ? false
        : totalReadPermission.length === 0
        ? false
        : true,
    createPermission:
      totalWritePermission.length === menuCount
        ? false
        : totalWritePermission.length === 0
        ? false
        : true,
    editPermission:
      totalEditPermission.length === menuCount
        ? false
        : totalEditPermission.length === 0
        ? false
        : true,
    deletePermission:
      totalDeletePermission.length === menuCount
        ? false
        : totalDeletePermission.length === 0
        ? false
        : true,
  };

  const checkedState = {
    readPermission: totalReadPermission.length === menuCount ? true : false,
    createPermission: totalWritePermission.length === menuCount ? true : false,
    editPermission: totalEditPermission.length === menuCount ? true : false,
    deletePermission: totalDeletePermission.length === menuCount ? true : false,
  };

  return { intermediateState, checkedState };
};

export const formatMenuDataForEdit = (menus, role) => {
  console.log(menus, role);
  return menus.map((menu) => {
    const permission = role.permissions.filter(
      (rolePermission) => rolePermission.menu_id === menu.admin_menu_id
    );
    console.log(permission);
    return {
      ...menu,
      readPermission:
        permission &&
        permission.length > 0 &&
        permission[0].permission_read === "on"
          ? true
          : false,
      createPermission:
        permission &&
        permission.length > 0 &&
        permission[0].permission_write === "on"
          ? true
          : false,
      editPermission:
        permission &&
        permission.length > 0 &&
        permission[0].permission_edit === "on"
          ? true
          : false,
      deletePermission:
        permission &&
        permission.length > 0 &&
        permission[0].permission_delete === "on"
          ? true
          : false,
    };
  });
};

const formatMenu = (menus) => {
  return menus.map((menu) => {
    return {
      ...menu,
      readPermission: false,
      createPermission: false,
      editPermission: false,
      deletePermission: false,
    };
  });
};
