import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../ac-booth-list/model/ac-booth-list.constants";
import ACBoothCandidatesTemplateComponent from "./ac-booth-candidates-template.component";
import { getCandidateByAcIdApi } from "@screens/catalog/ac/candidate/candidate-list/candidate-list.component.service";
import { selectCandidateFormInputs } from "../model/select-candidate.modal";
import { BASE_URL, END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { createVoterSlipPdf } from "../ac-booth-list/ac-booth-list.component.service";

const ACBoothCandidatesComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const acId = new URLSearchParams(location.search).get("acId");
  const boothId = new URLSearchParams(location.search).get("boothId");
  const [formInputs, setFormInputs] = useState([]);
  const [handleFunction, setHandleFunction] = useState();
  const { CATALOG_AC_BOOTH_VOTERS } = END_POINT;
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    let candidateId;
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;
    console.log({ values });
    for (let i = 0; i < values.length; i++) {
      if (values[i].name == "candidate_id") candidateId = values[i].value;
    }
    console.log(
      `${CATALOG_AC_BOOTH_VOTERS}${boothId}/${acId}/${candidateId}?token=${
        store.getState().app?.token
      }`
    );
    console.log({ handleFunction });
    if (handleFunction == "print") {
      console.log({ handleFunction });
      window.open(
        `${CATALOG_AC_BOOTH_VOTERS}${boothId}/${acId}/${candidateId}?token=${
          store.getState().app?.token
        }`
      );
    } else {
      const response = createVoterSlipPdf(boothId, acId, candidateId);
    }
  };

  const onLoad = async () => {
    const response = await getCandidateByAcIdApi(acId);
    console.log({ response });
    if (response.status) {
      const formattedCandidateList = response.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = selectCandidateFormInputs({
        candidates: formattedCandidateList,
      });
      console.log({ formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handlePrintClick = () => {
    setHandleFunction("print");
    handleTriggerClick("SUBMIT");
  };

  const handleDownloadClick = async () => {
    setHandleFunction("download");
    handleTriggerClick("SUBMIT");
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
    handleDownloadClick,
    handlePrintClick,
  };

  return menuAccess ? (
    <ACBoothCandidatesTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ACBoothCandidatesComponent;
