import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import DataGridTableComponent from "@molecules/data-grid/components/data-grid-table.component";
import { getEntriesByBulkVoiceCallIdApi } from "../bulk-voice-call-list/bulk-voice-call-list.component.service";
import { breadCrumbs } from "../bulk-voice-call-list/model/bulk-voice-call-list.constants";

const BulkVoiceCallEntriesListComponent = () => {
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const bulkVoiceCallId = new URLSearchParams(location.search).get(
    "bulkVoiceCallId"
  );
  console.log({ bulkVoiceCallId });
  const getData = async () => {
    const data = await getEntriesByBulkVoiceCallIdApi(bulkVoiceCallId);
    console.log({ data });
    setTableData(data.data.list);
  };
  const history = useHistory();
  const handleBackClick = () => {
    history.push(`/campaign/bulk-voice-call`);
  };
  const ActionContent = ({ handleBackClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    );
  };
  const tableColumn = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "number",
      numeric: false,
      disablePadding: true,
      label: "Number",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isBadge: true,
      badgeText: {
        1: "PENDING",
        2: "SUCCESS",
      },
      badgeColor: {
        1: "danger",
        2: "success",
      },
    },
  ];
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Bulk Voice Call Entries"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Entries List(s)", menu_url: null },
        ]}
        actionContent={<ActionContent handleBackClick={handleBackClick} />}
      />

      <DataGridTableComponent
        templateModal={{
          title: "Bulk Voice Call Entries List",
          tableColumn,
          tableData,
          disableActions: [],
        }}
        templateEvents={{
          dataRefresh: getData,
        }}
      />
    </PageWrapperComponent>
  );
};

export default BulkVoiceCallEntriesListComponent;
