import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const {
  CATALOG_URBAN_WARD_VOTER_LIST,
  CATALOG_URBAN_WARD_VOTER_DELETE,
  CATALOG_URBAN_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST,
  CATALOG_URBAN_WARD_VOTER_DELETE_ALL,
} = END_POINT;

export const getDataApi = async ({
  orderBy,
  sortBy,
  skip,
  limit,
  filters,
  electionId,
  urbanWardId,
}) => {
  return await fetch(
    `${CATALOG_URBAN_WARD_VOTER_LIST}${electionId}/${urbanWardId}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteDataApi = async (ids) => {
  return await fetch(CATALOG_URBAN_WARD_VOTER_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const createUrbanWardVoterDataDownloadRequestEntry = async (
  urbanWardId,
  id,
  queryStr,
  payload
) => {
  return await fetch(
    `${CATALOG_URBAN_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST}${urbanWardId}/${id}?dummy=test${queryStr}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: store.getState().app.token,
      },
      body: JSON.stringify(payload),
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteAllAcVoterData = async (id) => {
  return await fetch(`${CATALOG_URBAN_WARD_VOTER_DELETE_ALL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids: [id] }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
