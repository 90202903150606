import { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import PageNotFoundComponent from "@screens/page-not-found/page-not-found.component";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import routes, { appRoutes } from "./routes";
import AppLayoutComponent from "./layouts/app-layout.component";
import LoadingComponent from "@atoms/loading/loading.component";
import { fontAwesomeIcons } from "@utils/font-awesome.utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import LaunchPadComponent from "@screens/launchpad/launchpad.component";

// INFO: Fontawesome import
fontAwesomeIcons.map((icon) => {
  library.add(icon);
});

function App() {
  return (
    <Router>
      <Switch>
        {routes.map((item, index) => (
          <Route exact path={item.path} key={index}>
            {item.component}
          </Route>
        ))}
        {appRoutes.map((route) => (
          <AppLayoutComponent
            exact
            path={route.path}
            component={route.component}
          />
        ))}

        <Route exact path="/launchpad">
          <LaunchPadComponent />
        </Route>

        <Route exact path="/permission-denied">
          <PermissionDeniedComponent />
        </Route>
        <Route path="*">
          <PageNotFoundComponent />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
