import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getStatesFormInputs } from "../model/states.modal";
import { menuUrl } from "../states-list/model/states-list.constants";
import { editEntry } from "./states-edit.component.service";
import StatesEditTemplateComponent from "./states-edit-template.component";
import { sortByKey } from "@utils/string.utils";
import { uploadStateFile } from "../states-list/states-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { useEffect } from "react";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  STATES_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const StatesEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadResponse = await uploadStateFile(file[0]);
    console.log({ uploadResponse });
    if (uploadResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file: BASE_URL + "app-source/master-files/" + uploadResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      dispatch(
        setAlertMessage({ type: ERROR, message: uploadResponse.message })
      );
    }
  };
  console.log({ location });
  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleStateSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let statesPayload = {};
    for (let i = 0; i < values.length; i++) {
      statesPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const stateResponse = await editEntry(
      location?.state?.row?.id,
      statesPayload
    );

    // INFO : check api response and show relevant alert message
    if (stateResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: stateResponse.message })
      );
    }
  };
  const onLoad = async () => {
    const formInputData = getStatesFormInputs(
      {
        govtId: location?.state?.row?.govt_id || "",
        name: location?.state?.row?.name || "",
        type: location?.state?.row?.type || "State",
        regionalName: location?.state?.row?.regional_name || "",
        officialLanguage: location?.state?.row?.official_language || "",
        previousElection: location?.state?.row?.previous_election || "",
        totalPc: location?.state?.row?.state_total_pc || "",
        totalAc: location?.state?.row?.state_total_ac || "",
        relevant_link:
          location?.state?.row?.relevant_link &&
          location?.state?.row?.relevant_link.length === 0
            ? [{}]
            : sortByKey(location?.state?.row?.relevant_link, "sort_order"),
        remarks: location?.state?.row?.remarks || "",
        file: location?.state?.row?.file || "",
        manager: location?.state?.row?.manager || "",
        ballot_paper_data:
          location?.state?.row?.ballot_paper_data &&
          location?.state?.row?.ballot_paper_data.length === 0
            ? [{}]
            : location?.state?.row?.ballot_paper_data,
        state_files:
          location?.state?.row?.state_files &&
          location?.state?.row?.state_files.length === 0
            ? [{}]
            : location?.state?.row?.state_files,
      },
      handleFileUpload
    );
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleStateSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <StatesEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default StatesEditComponent;
