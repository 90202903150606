import { createAction, createReducer } from "@reduxjs/toolkit";

export const setMenuListAction = createAction("menu/setMenuList");
export const updateMenuList = createAction("menu/setUpdateMenuList");
export const setAllMenuList = createAction("menu/setAllMenuList");
export const setDrawerState = createAction("menu/setDrawerState");
export const updateSubMenuAction = createAction("menu/updateSubMenuAction");

export const menuReducer = createReducer(
  {
    menuList: [],
    loading: false,
    error: null,
    allMenuList: [],
    drawerState: true,
  },
  {
    [setMenuListAction]: (state, action) =>
      (state = { ...state, menuList: action.payload }),
    [setAllMenuList]: (state, action) =>
      (state = { ...state, allMenuList: action.payload }),
    [updateSubMenuAction]: (state, action) =>
      (state = {
        ...state,
        menuList: state.menuList.map((menu, index) =>
          action.payload.index === index
            ? {
                ...menu,
                sub_menus: action.payload.subMenus,
                subMenuOpen: false,
              }
            : menu
        ),
      }),
    [updateMenuList]: (state, action) =>
      (state = {
        ...state,
        menuList: state.menuList.map((menu, index) =>
          action.payload.index === index
            ? { ...menu, subMenuOpen: action.payload.status }
            : { ...menu, subMenuOpen: false }
        ),
      }),
  }
);
