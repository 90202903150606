import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getACBoothFormInputs } from "../model/ac-booth.modal";
import { menuUrl } from "../ac-booth-list/model/ac-booth-list.constants";
import { createEntry } from "./ac-booth-create.component.service";
import { getAllStates } from "@screens/catalog/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getACByDistrictId } from "@screens/catalog/ac/ac-list/ac-list.component.service";
import ACBoothCreateTemplateComponent from "./ac-booth-create-template.component";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  MASTER_AC_BOOTH_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ACBoothCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let acPayload = {};
    for (let i = 0; i < values.length; i++) {
      acPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const acBoothResponse = await createEntry(currentElection.id, acPayload);

    // INFO : check api response and show relevant alert message
    if (acBoothResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: acBoothResponse.message })
      );
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((district) => {
        return {
          name: district.name,
          value: district.id,
        };
      });

      inputs[2].options = formattedDistrictList;

      setFormInputs(inputs);
    }
  };

  const onDistrictChange = async (value, inputs) => {
    const acResponse = await getACByDistrictId(value);
    if (acResponse.status) {
      const formattedAcList = acResponse.data.map((ac) => {
        return {
          name: ac.name,
          value: ac.id,
        };
      });

      inputs[3].options = formattedAcList;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getACBoothFormInputs({
        states: formatedStateList,
        districts: [],
        acs: [],
        initialValues: {},
        onStateChange,
        onDistrictChange,
      });
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <ACBoothCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ACBoothCreateComponent;
