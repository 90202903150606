import { APP } from "@constants/app.constants";
const { STATE_TYPE } = APP;
export const getStatesFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the State ID",
    validations: [
      {
        type: "REQUIRED",
        message: "State ID is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "State Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the State Name",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Type",
    id: "type",
    name: "type",
    options: STATE_TYPE,
    value: initialValues?.type || "State",
    placeholder: "Select state type",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Official Language",
    id: "officialLanguage",
    name: "official_language",
    value: initialValues?.officialLanguage || "",
    placeholder: "Enter the Official Language",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Ruling Party",
    id: "rulingParty",
    name: "ruling_party",
    value: initialValues?.rulingParty || "",
    placeholder: "Enter the Ruling Party Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "Chief Minister",
    id: "chiefMinister",
    name: "chief_minister",
    value: initialValues?.chiefMinister || "",
    placeholder: "Enter the Chief Minister Name",
    validations: [],
  },
  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
