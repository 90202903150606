import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import React from "react";

function PermissionDeniedTemplateComponent({ goBack }) {
  return (
    <PageWrapperComponent>
      <div className="misc-wrapper">
        <div className="misc-inner p-2 p-sm-3">
          <div className="w-100 text-center">
            <h2 className="mb-1">Permission Denied !</h2>
            <p className="mb-2">Access to this page is restricted</p>
            <a className="btn btn-primary mb-2 btn-sm-block" onClick={goBack}>
              Back
            </a>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
}

export default PermissionDeniedTemplateComponent;
