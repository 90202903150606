import { lazy } from "react";
import { APP_NAME } from "@constants/app.constants";
import { retry } from "@utils/common";
import RolesListComponent from "@screens/user-administration/roles/roles-list/roles-list.component";
import RolesCreateComponent from "@screens/user-administration/roles/roles-create/roles-create.component";
import RolesEditComponent from "@screens/user-administration/roles/roles-edit/roles-edit.component";
import RolesCloneComponent from "@screens/user-administration/roles/roles-clone/roles-clone.component";

const rolesRoutes = [
  {
    name: "Roles",
    icon: "shield-off",
    path: "/user-administration/roles",
    component: RolesListComponent,
  },
  {
    name: `Create Roles - ${APP_NAME}`,
    path: "/user-administration/roles/create",
    component: RolesCreateComponent,
  },
  {
    name: `Edit Roles - ${APP_NAME}`,
    path: "/user-administration/roles/edit",
    component: RolesEditComponent,
  },
  {
    name: `Clone Roles - ${APP_NAME}`,
    path: "/user-administration/roles/clone",
    component: RolesCloneComponent,
  },
];

export default rolesRoutes;
