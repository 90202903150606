import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import AlertComponent from "@atoms/alert/alert.component";

function ResetPasswordFormComponent({
  templateModal: { open, formTriggers, formInputs },
  templateEvents: { handleFormCancel, handleResetFormSubmit, handleTriggerClick },
}) {
  const { alertMessage } = useSelector((state) => state.alert);
  return (
    <div className="basic-modal">
      <Modal className="modal-primary" isOpen={open} toggle={open}>
        <ModalHeader toggle={handleFormCancel}>Reset Password!</ModalHeader>
        <ModalBody>
          {alertMessage && (
            <AlertComponent type={alertMessage.type} message={alertMessage.message} />
          )}
          <FormBuilderComponent
            inputFields={formInputs}
            submitTrigger={formTriggers.submitTrigger}
            onSubmit={handleResetFormSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="outline-primary" onClick={handleFormCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleTriggerClick("SUBMIT")}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ResetPasswordFormComponent;
