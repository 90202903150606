import React from "react";
import DataGridRowComponent from "./data-grid-row.component";
import FeatherIcon from "feather-icons-react";
import { PRIMARY_KEY } from "@constants/app.constants";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";
import SpinnerComponent from "@atoms/spinner/spinner.component";

const DataGridDataComponent = ({
  loading,
  tableColumn,
  tableData,
  isSelected,
  disableActions,
  tableConfig,
  handleCheckBoxClick,
  handleBadgeClick,
  handleTDClick,
  handleCancelInlineEdit,
  handleEditClick,
  handleInlineEditSave,
  handleChangeInlineEdit,
  tableActions,
  handleModalEditClick,
  editFormData,
}) => {
  return (
    <tbody>
      {loading && tableData && tableData.length === 0 && (
        <tr>
          <td
            colSpan={tableColumn.length + 2}
            style={{ textAlign: "center", height: 180 }}
          ></td>
        </tr>
      )}

      {tableData && tableData.length === 0 && !loading && (
        <tr className="text-center">
          <td colSpan={10}>No Data Found</td>
        </tr>
      )}

      {tableData &&
        tableData.length > 0 &&
        tableData?.map((row, index) => {
          const isItemSelected = isSelected(row[PRIMARY_KEY]);
          return (
            <tr
              key={index}
              className={`${editFormData?.editApi ? "cursor-pointer" : ""}`}
              onClick={() =>
                editFormData.editApi
                  ? handleModalEditClick(row)
                  : console.log("Not clickable")
              }
            >
              <td>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isItemSelected}
                  onClick={(e) => handleCheckBoxClick(e, row[PRIMARY_KEY])}
                />
              </td>
              {tableColumn.map((element) => {
                if (element.isVisible)
                  return (
                    <td style={{ padding: "8px 8px 8px 14px" }}>
                      <div>
                        <DataGridRowComponent
                          index={index}
                          element={element}
                          row={row}
                          handleBadgeClick={handleBadgeClick}
                          handleTDClick={handleTDClick}
                          handleChangeInlineEdit={handleChangeInlineEdit}
                        />
                      </div>
                    </td>
                  );
              })}
              {!disableActions && (
                <td
                  style={{
                    textAlign: "right",
                    padding: " 0px 10px 0px 10px",
                  }}
                  className="sticky-col action-col"
                >
                  <div
                    style={{
                      width: tableConfig?.actionWidth || "100px",
                      float: "right",
                    }}
                  >
                    {row.inlineEdit && (
                      <>
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => handleInlineEditSave({ row, index })}
                        >
                          <FeatherIcon icon="save" size={15} />
                        </button>
                        <button
                          style={{ marginLeft: 6 }}
                          className="btn btn-danger btn-sm ml-1"
                          onClick={() => handleCancelInlineEdit(index)}
                        >
                          <FeatherIcon icon="x" size={15} />
                        </button>
                      </>
                    )}
                    {tableActions &&
                      tableActions.map((action) =>
                        action.isHide ? (
                          <></>
                        ) : (
                          <ToolTipComponent content={action.name}>
                            <button
                              id={`${action.name || action.id}-${
                                row[PRIMARY_KEY]
                              }`}
                              style={{ marginRight: 5, padding: 5 }}
                              className={
                                action.buttonClass
                                  ? action.buttonClass
                                  : "btn btn-primary btn-sm"
                              }
                              onClick={() => action.onClick(row)}
                            >
                              <FeatherIcon icon={action.icon} size={15} />
                            </button>
                          </ToolTipComponent>
                        )
                      )}
                    {!row.inlineEdit && (
                      <button
                        style={{ padding: 5 }}
                        className="btn btn-primary btn-sm"
                        onClick={(event) => handleEditClick(event, row)}
                      >
                        <FeatherIcon icon="edit" size={15} />
                      </button>
                    )}
                  </div>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};

export default DataGridDataComponent;
