import { APP } from "@constants/app.constants";
const { PARTY_TYPE } = APP;

export const getPoliticalPartyFormInputs = (
  states,
  repeaterStates,
  initialValues,
  handleLogoUpload
) => [
  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Full Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Full Name is required !",
      },
    ],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Full Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Full Regional Name",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Short Name",
    id: "shortName",
    name: "short_name",
    value: initialValues?.shortName || "",
    placeholder: "Enter the Short Name",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Short Regional Name",
    id: "shortRegionalName",
    name: "short_regional_name",
    value: initialValues?.shortRegionalName || "",
    placeholder: "Enter the Short Regional Name",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "SELECT",
    label: "Party Status",
    id: "type",
    name: "type",
    onChange: (value, formInputs) => {
      console.log({ value, formInputs });

      const stateNameIndex = formInputs.findIndex(
        (option) => option.label === "State Name"
      );

      if (value === "NATIONAL R" || value === "NATIONAL UR")
        formInputs[stateNameIndex].isHide = true;
      else formInputs[stateNameIndex].isHide = false;

      return formInputs;
    },
    options: PARTY_TYPE,
    value: initialValues?.type || "",
    placeholder: "Select Party Status",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    isHide:
      initialValues?.type === "NATIONAL R" ||
      initialValues?.type === "NATIONAL UR"
        ? true
        : false,
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "CALENDER",
    label: "Date of Establishment",
    id: "establishYear",
    name: "establish_year",
    value: initialValues?.establishYear || "",
    placeholder: "Select Date of Establishment",
    validations: [],
  },
  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Founder",
    id: "founderName",
    name: "founder_name",
    value: initialValues?.founderName || "",
    placeholder: "Enter the Founder",
    validations: [],
  },
  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT",
    label: "Party Leader",
    id: "partyLeaderName",
    name: "party_leader_name",
    value: initialValues?.partyLeaderName || "",
    placeholder: "Enter the Party Leader",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT_AREA",
    label: "About",
    id: "about",
    name: "about",
    value: initialValues?.about || "",
    placeholder: "Enter the About",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FILE_UPLOAD",
    name: "logo",
    id: "logo",
    label: "Flag",
    loading: false,
    multipleUpload: false,
    value: initialValues?.logo || "",
    handleUpload: handleLogoUpload,
    validations: [],
  },

  {
    groupName: "General Details",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FILE_UPLOAD",
    name: "symbol",
    id: "symbol",
    label: "Party Symbol",
    loading: false,
    multipleUpload: false,
    value: initialValues?.symbol || "",
    handleUpload: handleLogoUpload,
    validations: [],
  },

  {
    groupName: "Regional Names",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "regional_names",
    value: initialValues?.regional_names || repeaterStates,
    inputs: [
      {
        type: "TEXT",
        label: "Name",
        id: "name",
        name: "name",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Full Regional Name",
        id: "fullRegionalName",
        name: "fullRegionalName",
        placeholder: "Full Regional Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Short Regional Name",
        id: "shortRegionalName",
        name: "shortRegionalName",
        placeholder: "Short Regional Name",
        size: 3,
      },
    ],
  },
];
