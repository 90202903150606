import React from "react";
import FormBuilderRenderInputs from "./render-inputs.component";

const FormBuilderDefaultFormComponent = ({
  changeCount,
  formInputs,
  handleChange,
  handleFileUpload,
  handleDeleteFile,
  handleKeyDown,
  submitButton,
  handleSubmit,
  handleClear,
}) => {
  return (
    <form className="form form-horizontal">
      <div className="row">
        {formInputs &&
          formInputs.map((formInput, index) => (
            <div className={formInput?.groupClass || "col-md-12"}>
              <FormBuilderRenderInputs
                formInputs={formInputs}
                changeCount={changeCount}
                formInput={formInput}
                index={index}
                handleChange={handleChange}
                handleFileUpload={handleFileUpload}
                handleDeleteFile={handleDeleteFile}
                handleKeyDown={handleKeyDown}
              />
            </div>
          ))}
        {submitButton && (
          <div className={submitButton?.size || "col-md-12"}>
            <button
              className="btn btn-primary btn-sm cursor-pointer"
              style={{ marginTop: 39, marginRight: 10 }}
              type={"button"}
              onClick={handleSubmit}
            >
              {submitButton?.text || "Submit "}
            </button>

            <button
              className="btn btn-outline-dark btn-sm cursor-pointer"
              style={{ marginTop: 39 }}
              type={"button"}
              onClick={handleClear}
            >
              {submitButton?.clearText || "Clear "}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default FormBuilderDefaultFormComponent;
