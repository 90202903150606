import { useEffect, useState } from "react";
import ACDashboardTemplateComponent from "./dashboard-template.component";
import { getMasterAcsDashboardDataApi } from "./dashboard.component.service";

const ACDashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();

  const onLoad = async () => {
    const dashboardResponse = await getMasterAcsDashboardDataApi();
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formatData = {
        totalAC: dashboardResponse.data.total_master_ac,
      };
      setDashboardData(formatData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <ACDashboardTemplateComponent
        templateModal={{
          dashboardData,
        }}
      />
    </>
  );
};

export default ACDashboardComponent;
