import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPanchayatUnionFormInputs } from "../model/panchayat-union.modal";
import { useEffect } from "react";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import { createEntry } from "./panchayat-union-create.component.service";
import PanchayatUnionCreateTemplateComponent from "./panchayat-union-create-template.component";
import { menuUrl } from "../panchayat-union-list/model/panchayat-union-list.constants";
import { getDistrictUnionByDistrictId } from "@screens/master-rural/district-union/district-union-list/district-union-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  PANCHAYAT_UNION_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const PanchayatUnionCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let payload = {};
    for (let i = 0; i < values?.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const response = await createEntry(payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: response.message,
        })
      );
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[3].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };

  const onDistrictChange = async (value, inputs) => {
    const districtUnionResponse = await getDistrictUnionByDistrictId(value);
    if (districtUnionResponse.status) {
      const formattedDistrictUnionList = districtUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[4].options = formattedDistrictUnionList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
      const formInputData = getPanchayatUnionFormInputs({
        states: formatedStateList,
        districts: [],
        districtUnions: [],
        initialValues: {},
        onStateChange,
        onDistrictChange,
      });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <PanchayatUnionCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default PanchayatUnionCreateComponent;
