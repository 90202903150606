import { APP_NAME } from "@constants/app.constants";

import ElectionCreateComponent from "@screens/election/election-create/election-create.component";
import ElectionEditComponent from "@screens/election/election-edit/election-edit.component";
import ElectionListComponent from "@screens/election/election-list/election-list.component";
import PCDashboardComponent from "@screens/election/pc-dashboard/dashboard.component";
import ACDashboardComponent from "@screens/election/ac-dashboard/dashboard.component";
import ElectionDashboardComponent from "@screens/election/election-dashboard/election-dashboard.component";

const electionRoutes = [
  {
    name: `Election - ${APP_NAME}`,
    path: "/election",
    icon: "bar-chart-2",
    component: ElectionListComponent,
  },
  {
    name: `Create Election - ${APP_NAME}`,
    path: "/election/create",
    component: ElectionCreateComponent,
  },
  {
    name: `Edit Election - ${APP_NAME}`,
    path: "/election/edit",
    component: ElectionEditComponent,
  },
  {
    name: `Election PC Dashboard - ${APP_NAME}`,
    path: "/election/pc-dashboard",
    component: PCDashboardComponent,
  },
  {
    name: `Election AC Dashboard - ${APP_NAME}`,
    path: "/election/ac-dashboard",
    component: ACDashboardComponent,
  },

  {
    name: `Election Dashboard - ${APP_NAME}`,
    path: "/election/dashboard",
    component: ElectionDashboardComponent,
  },
];

export default electionRoutes;
