import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import { getDistrictUnionByDistrictId } from "@screens/master-rural/district-union/district-union-list/district-union-list.component.service";

const tableTitle = "Panchayat Union";
const keyColumn = "id";
const menuUrl = "master-rural/panchayat-union";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District name",
    width: 150,
    sorting: true,
  },
  // {
  //   id: "district_union_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "District Union name",
  //   width: 150,
  //   sorting: true,
  // },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union Id",
    sorting: true,
    width: 100,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager name",
    width: 150,
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Panchayat Union name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },

  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "total_ward_count",
    numeric: false,
    disablePadding: false,
    label: "Total Ward",
    sorting: true,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Master", menu_url: null },
  { name: "Panchayat Union", menu_url: "/master-rural/panchayat-union" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[1].options = formattedDistrict;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    onChange: async (value, formInputs) => {
      const districtUnionResponse = await getDistrictUnionByDistrictId(value);
      console.log({ districtUnionResponse });
      if (districtUnionResponse.status) {
        const formattedDistrictUnion = districtUnionResponse.data.map(
          (data) => {
            return {
              name: data.name,
              value: data.id,
            };
          }
        );

        formInputs[2].options = formattedDistrictUnion;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district_union",
    id: "district_union",
    label: "District Union",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District Union",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "Panchayat Union name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
