import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "PC";
const keyColumn = "id";
const menuUrl = "master/pc";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager",
    width: 100,
    sorting: true,
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "PC Id",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "PC name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
  },
  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 150,
  },
];
const breadCrumbs = [
  { name: "Master", menu_url: null },
  { name: "PC", menu_url: "/master/pc" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "name",
    id: "name",
    label: "PC name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
