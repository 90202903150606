import { APP } from "@constants/app.constants";
const { RESERVATION } = APP;
export const getACFormInputs = ({
  states,
  districts,
  pcs,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "AC ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the AC ID",
    validations: [
      {
        type: "REQUIRED",
        message: "AC ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "PC Name",
    id: "pcId",
    name: "pc_id",
    options: pcs,
    value: initialValues?.pcId || "",
    placeholder: "Select PC",
    validations: [
      {
        type: "REQUIRED",
        message: "PC Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "AC Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the AC Name",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
