import { END_POINT } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { getACByDistrictId } from "@screens/catalog/ac/ac-list/ac-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getPcByStateId } from "@screens/catalog/pc/pc-list/pc-list.component.service";
import { getStateByElectionId } from "@screens/catalog/states/states-list/states-list.component.service";
import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const {
  CATALOG_CANDIDATE_STATUS_TOGGLE,
  CATALOG_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE,
} = END_POINT;
const { CANDIDATE_STATUS } = APP;
const tableTitle = "Candidate List";
const keyColumn = "id";
const menuUrl = "crm/candidate";
const tableColumn = [
  {
    id: "election_name",
    numeric: false,
    disablePadding: true,
    label: "Election name",
    sorting: true,
    width: 100,
  },

  {
    id: "ac_govt_id",
    numeric: false,
    disablePadding: true,
    label: "AC Id",
    sorting: true,
  },
  {
    id: "pc_govt_id",
    numeric: false,
    disablePadding: true,
    label: "PC Id",
    sorting: true,
  },
  {
    id: "ac_name",
    numeric: false,
    disablePadding: true,
    label: "AC name",
    sorting: true,
  },
  {
    id: "pc_name",
    numeric: false,
    disablePadding: true,
    label: "PC name",
    sorting: true,
  },
  {
    id: "ac_govt_id_name",
    numeric: false,
    disablePadding: true,
    label: "AC no",
    sorting: true,
    width: 100,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Candidate name",
    sorting: true,
  },
  {
    id: "party_name",
    numeric: false,
    disablePadding: true,
    label: "Political Party",
    sorting: true,
  },
  {
    id: "age",
    numeric: false,
    disablePadding: true,
    label: "Age",
    sorting: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
    sorting: true,
  },
  {
    id: "education",
    numeric: false,
    disablePadding: true,
    label: "Education",
    sorting: true,
  },
  {
    id: "personal_mobile",
    numeric: false,
    disablePadding: false,
    label: "Contact No",
    sorting: true,
  },
  {
    id: "whatsapp_number",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp No 1",
    sorting: true,
  },
  {
    id: "whatsapp_number2",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp No 2",
    sorting: true,
  },
  {
    id: "whatsapp_response",
    numeric: false,
    disablePadding: false,
    label: "WhatsApp Response",
    isBadge: true,
    badgeClick: CATALOG_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      Yes: "Yes",
      No: "No",
    },
    badgeColor: {
      Yes: "success",
      No: "danger",
    },
  },
  {
    id: "official_mobile",
    numeric: false,
    disablePadding: false,
    label: "Official No",
    sorting: true,
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
    sorting: true,
  },
  {
    id: "asset",
    numeric: false,
    disablePadding: true,
    label: "Asset",
    sorting: true,
  },
  {
    id: "epic_id",
    numeric: false,
    disablePadding: true,
    label: "Epic Id",
    sorting: true,
  },
  {
    id: "part_no",
    numeric: false,
    disablePadding: true,
    label: "Part No",
    sorting: true,
  },
  {
    id: "serial_no",
    numeric: false,
    disablePadding: true,
    label: "Serial No",
    sorting: true,
  },
  {
    id: "criminal_case",
    numeric: false,
    disablePadding: true,
    label: "Criminal Case",
    sorting: true,
  },
  {
    id: "facebook",
    numeric: false,
    disablePadding: true,
    label: "Facebook",
    sorting: true,
  },

  {
    id: "instagram",
    numeric: false,
    disablePadding: true,
    label: "Instagram",
    sorting: true,
  },
  {
    id: "linkedin",
    numeric: false,
    disablePadding: true,
    label: "LinkedIn",
    sorting: true,
  },
  {
    id: "twitter",
    numeric: false,
    disablePadding: true,
    label: "Twitter",
    sorting: true,
  },
  {
    id: "elected_member",
    numeric: false,
    disablePadding: false,
    label: "Elected Member",
    isBadge: true,
    badgeClick: CATALOG_CANDIDATE_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      APPLIED: "APPLIED",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED",
      WITHDRAWN: "WITHDRAWN",
      CONTESTING: "CONTESTING",
      RUNNER: "RUNNER",
      WINNER: "WINNER",
    },
    badgeColor: {
      APPLIED: "primary",
      ACCEPTED: "info",
      REJECTED: "danger",
      WITHDRAWN: "dark",
      CONTESTING: "warning",
      RUNNER: "info",
      WINNER: "success",
    },
    sorting: true,
  },
  {
    id: "total_votes",
    numeric: false,
    disablePadding: false,
    label: "Total Votes",
    sorting: true,
  },
  {
    id: "total_affidavit",
    numeric: false,
    disablePadding: false,
    label: "No.of.Affidavit",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: true,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "CRM", menu_url: null },
  { name: "Candidate", menu_url: "/crm/candidate" },
];

const additionalActions = ({ handleViewDetail }) => [];

const filters = ({ elections, parties }) => [
  {
    name: "election",
    id: "election",
    label: "Election",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Election",
    options: elections,
    onChange: async (value, formInputs) => {
      console.log({ formInputs });
      const stateResponse = await getStateByElectionId(value);
      if (stateResponse.status) {
        const formattedState = stateResponse.data.map((state) => {
          console.log({ state });
          return {
            name: state.name,
            value: state.id,
          };
        });
        formInputs[1].options = formattedState;
        formInputs[2].options = [];
        formInputs[3].options = [];
        formInputs[4].options = [];
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: [],
    clearOptions: true,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const PcResponse = await getPcByStateId(value);
      const districtResponse = await getDistrictByStateId(value);

      console.log({ PcResponse });
      if (PcResponse.status) {
        const formattedPc = PcResponse.data.map((pc) => {
          return {
            name: pc.name,
            value: pc.id,
          };
        });

        formInputs[2].options = formattedPc;
        formInputs[3].options = [];
        formInputs[4].options = [];
      }

      if (districtResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        formInputs[3].options = formattedDistrict;
        formInputs[4].options = [];
      }

      return formInputs;
    },
  },

  {
    name: "pc",
    id: "pc",
    label: "PC",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select PC",
    options: [],
    clearOptions: true,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    clearOptions: true,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    onChange: async (value, formInputs) => {
      const acResponse = await getACByDistrictId(value);
      console.log({ acResponse });
      if (acResponse.status) {
        const formattedAc = acResponse.data.map((ac) => {
          return {
            name: ac.name,
            value: ac.id,
          };
        });

        formInputs[4].options = formattedAc;
      }

      return formInputs;
    },
  },
  {
    name: "ac",
    id: "ac",
    label: "AC",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select AC",
    options: [],
    clearOptions: true,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "whatsapp_response",
    id: "whatsapp_response",
    label: "WhatsApp Response",
    value: "",
    type: "SELECT",
    placeholder: "Select WhatsApp Response",
    options: [
      { name: "Yes", value: "Yes" },
      { name: "No", value: "No" },
    ],
    clearOptions: true,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "name",
    id: "name",
    label: "Candidate name",
    placeholder: "Enter Candidate name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "mobile",
    id: "mobile",
    label: "Candidate mobile",
    placeholder: "Enter Candidate mobile number",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "candidate_status",
    id: "candidate_status",
    label: "Candidate Status",
    value: "",
    type: "SELECT",
    placeholder: "Select Candidate Status",
    options: CANDIDATE_STATUS,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "political_party_id",
    id: "political_party_id",
    label: "Political Party",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select Political Party",
    options: parties,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];
const tableControls = [
  {
    name: "Chart",
    icon: "pie-chart",
    status: 0,
    onClick: null,
  },
];
export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
