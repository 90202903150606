import { useEffect, useState } from "react";
import PCDashboardTemplateComponent from "./dashboard-template.component";
import { getMasterPcsDashboardDataApi } from "./dashboard.component.service";

const PCDashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();

  const onLoad = async () => {
    const dashboardResponse = await getMasterPcsDashboardDataApi();
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formatData = {
        totalPC: dashboardResponse.data.total_master_pc,
      };
      setDashboardData(formatData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <PCDashboardTemplateComponent
        templateModal={{
          dashboardData,
        }}
      />
    </>
  );
};

export default PCDashboardComponent;
