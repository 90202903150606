import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getAllStates } from "@screens/master/states/states-list/states-list.component.service";
import { getAcByPcId } from "@screens/master/ac/ac-list/ac-list.component.service";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";
import UrbanWardCreateTemplateComponent from "./urban-ward-create-template";
import { getCorporationByDistrictId } from "@screens/master-urban/corporation/corporation-list/corporation-list.service";
import { getMunicipalityByDistrictId } from "@screens/master-urban/municipality/municipality-list/municipality-list.service";
import { getTownPanchayatByDistrictId } from "@screens/master-urban/town-panchayat/town-panchayat-list/town-panchayat-list.service";
import { createEntry } from "./urban-ward-create.service";
import { menuUrl } from "../urban-ward-list/model/urban-ward-list.constants";
import { getUrbanWardFormInputs } from "../model/urban-ward.modal";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  URBAN_WARD_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const UrbanWardCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    console.log({ values });
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    // INFO : call create api
    const response = await createEntry(payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };

  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[3].options = formattedDistrictList;
      setFormInputs(inputs);
    }
    const pcResponse = await getPcByStateId(value);
    if (pcResponse.status) {
      const formattedPcList = pcResponse.data.map((pc) => {
        return {
          name: pc.name,
          value: pc.id,
        };
      });

      inputs[4].options = formattedPcList;
      setFormInputs(inputs);
    }
  };

  const onPcChange = async (value, inputs) => {
    const acResponse = await getAcByPcId(value);
    if (acResponse.status) {
      const formattedAcList = acResponse.data.map((ac) => {
        return {
          name: ac.name,
          value: ac.id,
        };
      });

      inputs[5].options = formattedAcList;
      setFormInputs(inputs);
    }
  };

  const onDistrictChange = async (value, inputs) => {
    const corporationResponse = await getCorporationByDistrictId(value);
    if (corporationResponse.status) {
      const formattedCorporationList = corporationResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[7].options = formattedCorporationList;
    }
    const municipalityResponse = await getMunicipalityByDistrictId(value);
    if (municipalityResponse.status) {
      const formattedMunicipalityList = municipalityResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[8].options = formattedMunicipalityList;
    }
    const townPanchayatResponse = await getTownPanchayatByDistrictId(value);
    if (townPanchayatResponse.status) {
      const formattedTownPanchayatsList = townPanchayatResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[9].options = formattedTownPanchayatsList;
    }
    setFormInputs(inputs);
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates();
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
      const formInputData = getUrbanWardFormInputs({
        states: formatedStateList,
        districts: [],
        pcs: [],
        acs: [],
        corporations: [],
        municipalities: [],
        townPanchayats: [],
        initialValues: {},
        onStateChange,
        onDistrictChange,
        onPcChange,
      });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UrbanWardCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UrbanWardCreateComponent;
