import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

import { getFileTypeFromUrl } from "@utils/string.utils";
import ModalComponent from "@atoms/modal/modal.component";
import FormInputFileUploadPreview from "./preview.component";

const FormInputFileUploadComponent = ({
  name,
  id,
  loader = false,
  handleUpload,
  handleDeleteFile,
  value,
  label,
  error,
  isMultiple = false,
  isVertical,
  handleImagePreview,
}) => {
  console.log({ error });
  const fileInput = useRef();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const singleFileType = !isMultiple ? getFileTypeFromUrl(value) : null;

  const handleBoxClick = () => {
    fileInput.current.click();
  };

  const onDeleteClick = ({ isMultiple, index, file }) => {
    setCurrentFile({ ...file, index, isMultiple });
    toggleDeleteModal();
  };

  const confirmDelete = () => {
    if (handleDeleteFile) handleDeleteFile(currentFile);
    toggleDeleteModal();
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!showDeleteModal);
  };

  return (
    <div className="row">
      {label && (
        <div
          style={{ marginBottom: 12 }}
          className={isVertical ? "col-sm-12" : "col-sm-2"}
        >
          <label className="col-form-label" style={{ fontSize: 12 }}>
            {" "}
            {label || ""}{" "}
          </label>
        </div>
      )}
      <div
        style={{ marginBottom: 12 }}
        className={isVertical ? "col-sm-12" : "col-sm-9"}
      >
        <input
          className={`form-control ${error ? "is-invalid" : ""}`}
          ref={fileInput}
          type={"file"}
          name={name}
          id={id}
          onChange={handleUpload}
        />
        {error && <div className="invalid-feedback">{error}</div>}
        <div className="row">
          {loader === true && (
            <div style={{ marginTop: 5, position: "relative", marginLeft: 11 }}>
              <div style={{ width: 25, float: "left" }}>
                <Spinner animation="border" role="status"></Spinner>
              </div>
              <p style={{ float: "left", marginLeft: 15, marginTop: 6 }}>
                <span>File is being uploaded, please wait....</span>
              </p>
            </div>
          )}
        </div>

        <div className="row">
          {!isMultiple && value && (
            <p style={{ marginTop: 5, marginLeft: 12 }}>
              <FormInputFileUploadPreview
                index={0}
                file={value}
                name={value}
                fileType={singleFileType}
                onDeleteClick={onDeleteClick}
                handleImagePreview={handleImagePreview}
              />
            </p>
          )}

          {isMultiple &&
            value &&
            value.map((file, index) => {
              const fileType = getFileTypeFromUrl(file.file);

              return (
                <p
                  style={
                    index === 0
                      ? { marginTop: 5, marginLeft: 12, marginBottom: 5 }
                      : { marginTop: 2, marginLeft: 12, marginBottom: 5 }
                  }
                >
                  <FormInputFileUploadPreview
                    index={index}
                    file={file.file}
                    name={file.name}
                    fileType={fileType}
                    onDeleteClick={onDeleteClick}
                    handleImagePreview={handleImagePreview}
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </p>
              );
            })}
        </div>
      </div>

      <ModalComponent
        title={"Delete File !"}
        message="Are you sure to delete the file?"
        open={showDeleteModal}
        primaryAction={confirmDelete}
        primaryText="Delete"
        secondaryAction={toggleDeleteModal}
        secondaryText="Cancel"
      />
    </div>
  );
};

FormInputFileUploadComponent.propTypes = {
  name: PropTypes.string,
  loader: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  handleUpload: PropTypes.func,
};

export default FormInputFileUploadComponent;
