import { APP_NAME } from "@constants/app.constants";
import BulkWhatsAppCreateComponent from "@screens/campaign/bulk-whatsapp/bulk-whatsapp-create/bulk-whatsapp-create";
import BulkWhatsAppEditComponent from "@screens/campaign/bulk-whatsapp/bulk-whatsapp-edit/bulk-whatsapp-edit";
import BulkWhatsAppEntriesListComponent from "@screens/campaign/bulk-whatsapp/bulk-whatsapp-entries-list/bulk-whatsapp-entries-list-component";
import BulkWhatsAppListComponent from "@screens/campaign/bulk-whatsapp/bulk-whatsapp-list/bulk-whatsapp-list.component";

const bulkWhatsAppRoutes = [
  {
    name: `Bulk WhatsApp - ${APP_NAME}`,
    path: "/campaign/bulk-whatsapp",
    icon: "trello",
    component: BulkWhatsAppListComponent,
  },
  {
    name: `Create Bulk WhatsApp - ${APP_NAME}`,
    path: "/campaign/bulk-whatsapp/create",
    component: BulkWhatsAppCreateComponent,
  },
  {
    name: `Edit Bulk WhatsApp - ${APP_NAME}`,
    path: "/campaign/bulk-whatsapp/edit/",
    component: BulkWhatsAppEditComponent,
  },

  {
    name: `Entries- ${APP_NAME}`,
    path: "/campaign/bulk-whatsapp/entries",
    component: BulkWhatsAppEntriesListComponent,
  },
];

export default bulkWhatsAppRoutes;
