import { APP } from "@constants/app.constants";
const { RESERVATION_TYPE_2 } = APP;

export const getCorporationFormInputs = ({
  states,
  districts,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the Corporation ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Corporation ID is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },
  {
    type: "AUTOCOMPLETE",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Corporation Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the Corporation Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Corporation Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION_TYPE_2,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Total Ward",
    id: "totalWardCount",
    name: "total_ward_count",
    value: initialValues?.totalWardCount || "",
    placeholder: "Enter the Total Ward",
    validations: [],
  },

  {
    type: "TEXT",
    label: "No.of.Zones",
    id: "noOfZones",
    name: "no_of_zones",
    value: initialValues?.noOfZones || "",
    placeholder: "Enter the No of Zones",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
