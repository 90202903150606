import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../candidate-list/model/candidate-list.constants";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <ToolTipComponent content="Save">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={handleSaveClick}
        >
          <FeatherIcon icon="save" size={17} />
        </button>
      </ToolTipComponent>
      <ToolTipComponent content="Back">
        <button
          type="button"
          className="btn btn-outline-primary btn-sm ml-2"
          onClick={handleBackClick}
          style={{ marginRight: 10 }}
        >
          <FeatherIcon icon="arrow-left" size={17} />
        </button>
      </ToolTipComponent>
    </>
  );
};

const CandidateFileUploadTemplateComponent = ({
  templateModal: {
    formInputs,
    formTriggers,
    districtUnionWardId,
    electionId,
    districtUnionWardName,
    stateGovtId,
  },
  templateEvents: { handleFormSubmit, handleTriggerClick, handleBackClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Upload District Union Ward Candidate"}
        breadCrumbs={[
          ...breadCrumbs,
          {
            name: districtUnionWardName + " - Candidate(s)",
            menu_url: `/catalog-urban/district-union-ward/candidate?districtUnionWardId=${districtUnionWardId}&electionId=${electionId}&districtUnionWardName=${districtUnionWardName}&stateGovtId=${stateGovtId}`,
          },
          { name: "File Upload", menu_url: null },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />

      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleFormSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default CandidateFileUploadTemplateComponent;
