import React from "react";
import { ButtonGroup, Button } from "reactstrap";

const ButtonGroupComponent = ({
  buttons,
  active,
  handleClick,
  size = "sm",
}) => {
  console.log({ active });
  return (
    <>
      <ButtonGroup size={size}>
        {buttons.map((button) => (
          <Button
            outline={active === button.name ? false : true}
            color={button?.color || "primary"}
            onClick={() => handleClick(button.name)}
          >
            {button.name}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default ButtonGroupComponent;
