import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import DistrictUnionWardCreateTemplateComponent from "./district-union-ward-create-template.component";
import { menuUrl } from "../district-union-ward-list/model/district-union-ward-list.constants";
import { getAllStates } from "@screens/catalog-rural/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog-rural/districts/districts-list/districts-list.component.service";
import { getDistrictUnionByDistrictId } from "@screens/catalog-rural/district-union/district-union-list/district-union-list.component.service";
import { createEntry } from "../district-union-ward-list/district-union-ward-list.component.service";
import { getDistrictUnionWardFormInputs } from "../model/district-union-ward.modal";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  DISTRICT_UNION_WARD_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const DistrictPanchayatUnionWardCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const { currentElection } = useSelector((state) => state.election);

  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }
    payload["election_id"] = currentElection.id;

    // INFO : call create api
    const response = await createEntry(payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: response.message,
        })
      );
    }
  };
  const onDistrictChange = async (value, inputs) => {
    const districtUnionResponse = await getDistrictUnionByDistrictId(value);
    if (districtUnionResponse.status) {
      const formattedDistrictUnionList = districtUnionResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );

      inputs[3].options = formattedDistrictUnionList;
      setFormInputs(inputs);
    }
  };
  const onStateChange = async (value, inputs) => {
    const districtResponse = await getDistrictByStateId(value);
    if (districtResponse.status) {
      const formattedDistrictList = districtResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      inputs[2].options = formattedDistrictList;
      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
      const formInputData = getDistrictUnionWardFormInputs({
        states: formatedStateList,
        districts: [],
        districtUnions: [],
        initialValues: {},
        onStateChange,
        onDistrictChange,
      });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <DistrictUnionWardCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default DistrictPanchayatUnionWardCreateComponent;
