import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";

const tableTitle = "AC List";
const keyColumn = "id";
const menuUrl = "master/ac";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
    width: 100,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District Name",
  },
  {
    id: "pc_name",
    numeric: false,
    disablePadding: false,
    label: "PC Name",
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "AC Id",
    sorting: true,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager",
    width: 100,
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "AC name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
  },
  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 150,
  },
];
const breadCrumbs = [
  { name: "Master", menu_url: null },
  { name: "AC", menu_url: "/master/ac" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      const pcResponse = await getPcByStateId(value);

      if (districtResponse.status && pcResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        const formattedpc = pcResponse.data.map((pc) => {
          return {
            name: pc.name,
            value: pc.id,
          };
        });

        formInputs[1].options = formattedDistrict;
        formInputs[2].options = formattedpc;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District ID - Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "pc",
    id: "pc",
    label: "PC ID - Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select PC",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "AC name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
