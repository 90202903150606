import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getMainMenus,
  getModules,
} from "../menu-items-create/menu-items-create.component.service";
import { getMenuItemFormInputs } from "../model/menu-items.modal";
import { menuUrl } from "../menu-items-list/model/menu-items-list.constants";
import MenuItemEditTemplateComponent from "./menu-items-edit-template.component";
import { editMenuItemApi } from "./menu-items-edit.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  MENU_ITEMS_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const MenuItemEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [formInputs, setFormInputs] = useState([]);

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleMenuItemSubmit = async ({ values, errors }) => {
    console.log({ values, errors });
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct menu item payload
    let menuItemPayload = {};
    for (let i = 0; i < values.length; i++) {
      menuItemPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const menuItemResponse = await editMenuItemApi(
      location?.state?.row?.id,
      menuItemPayload
    );

    // INFO : check api response and show relevant alert message
    if (menuItemResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: menuItemResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleMenuItemSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  const onLoad = async () => {
    const menuListResponse = await getMainMenus();
    const modulesResponse = await getModules();
    console.log({ menuListResponse });
    if (menuListResponse.status && modulesResponse.status) {
      const formatedMenuList = menuListResponse.data.map((menu) => {
        return {
          name: menu.menu_name,
          value: menu.admin_menu_id,
        };
      });
      //setMainMenus(formatedMenuList);

      console.log({ modulesResponse });

      const formatedModules = modulesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getMenuItemFormInputs(
        formatedMenuList,
        formatedModules,
        {
          menuName: location?.state?.row?.menu_name || "",
          menuUrl: location?.state?.row?.menu_url || "",
          menuIcon: location?.state?.row?.menu_icon || "",
          sortOrder: location?.state?.row?.sort_order || "",
          parentId: location?.state?.row?.parent_id || "",
          roleView: location?.state?.row?.role_view || "",
          type: location?.state?.row?.type || "Menu",
          moduleId: location?.state?.row?.module_id || "",
        }
      );
      console.log({ formatedMenuList, formInputData, location });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <MenuItemEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default MenuItemEditComponent;
