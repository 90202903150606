import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardTemplateComponent from "./dashboard-template.component";
import { getDashboardDataApi } from "./dashboard.component.service";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";
import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";

const {
  ALERT_TYPES: { SUCCESS },
  STATES_ALERTS: { REFRESH_ALERT },
} = MESSAGES;

const DashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();
  const dispatch = useDispatch();
  const { currentElection } = useSelector((state) => state.election);
  const formatRecentLog = (logs) => {
    return logs.map((log, index) => {
      return {
        id: index,
        name: log.admin_name,
        username: log.username,
        days_ago: log.times_ago,
        message: log.log_msg,
      };
    });
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "dashboard-count",
      election_id: currentElection.id,
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const onLoad = async () => {
    const dashboardResponse = await getDashboardDataApi();
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formateData = {
        totalUsers: dashboardResponse.data.total_users.toString(),
        totalDistricts: dashboardResponse.data.total_districts.toString(),
        totalStates: dashboardResponse.data.total_states.toString(),
        totalUserLog: dashboardResponse.data.total_user_log.toString(),
        totalAc: dashboardResponse?.data?.total_acs || "",
        totalPc: dashboardResponse?.data?.total_pcs || "",
        totalAcBooth: dashboardResponse?.data?.total_booths || "",
        recentLog: formatRecentLog(dashboardResponse.data.recent_log),
      };
      setDashboardData(formateData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <DashboardTemplateComponent
        templateModal={{
          dashboardData,
          handleRefreshClick,
        }}
      />
    </>
  );
};

export default DashboardComponent;
