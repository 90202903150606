import { formatDateUtil } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";

const tableTitle = "Urban Ward Voter List";
const keyColumn = "id";
const menuUrl = "catalog-urban/urban-ward";
const tableColumn = [
  {
    id: "sno",
    numeric: false,
    disablePadding: false,
    label: "S.No",
    sorting: true,
  },
  {
    id: "epic_id",
    numeric: false,
    disablePadding: false,
    label: "Epic Id",
    sorting: true,
  },
  {
    id: "slnoinpart",
    numeric: false,
    disablePadding: false,
    label: "Indian Election Serial No",
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "First name",
    sorting: true,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "First Regional name",
    sorting: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "Last name",
    sorting: true,
  },
  {
    id: "rln_type",
    numeric: false,
    disablePadding: false,
    label: "Relation Type",
    sorting: true,
  },
  {
    id: "regional_last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Regional name",
    sorting: true,
  },
  {
    id: "relation_first_name",
    numeric: false,
    disablePadding: false,
    label: "Relation First name",
    sorting: true,
  },
  {
    id: "relation_first_regional_name",
    numeric: false,
    disablePadding: false,
    label: "Relation First Regional name",
    sorting: true,
  },
  {
    id: "relation_last_name",
    numeric: false,
    disablePadding: false,
    label: "Relation Last name",
    sorting: true,
  },
  {
    id: "relation_last_regional_name",
    numeric: false,
    disablePadding: false,
    label: "Relation Last Regional name",
    sorting: true,
  },
  {
    id: "age",
    numeric: false,
    disablePadding: false,
    label: "Age",
    sorting: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
    sorting: true,
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: true,
    label: "Mobile No",
    sorting: true,
  },
  {
    id: "email_id",
    numeric: false,
    disablePadding: false,
    label: "Email Id",
    sorting: true,
  },
  { id: "dob", numeric: false, disablePadding: false, label: "Date of Birth" },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    sorting: true,
  },
  {
    id: "religion",
    numeric: false,
    disablePadding: false,
    label: "Religion",
    sorting: true,
  },
  {
    id: "caste",
    numeric: false,
    disablePadding: false,
    label: "Caste",
    sorting: true,
  },
  {
    id: "language",
    numeric: false,
    disablePadding: false,
    label: "Language",
    sorting: true,
  },
  {
    id: "relation",
    numeric: false,
    disablePadding: false,
    label: "Relation",
    sorting: true,
  },
  {
    id: "color",
    numeric: false,
    disablePadding: false,
    label: "Color",
    sorting: true,
  },
  {
    id: "pincode",
    numeric: false,
    disablePadding: false,
    label: "Pincode",
    sorting: true,
  },
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District Name",
  },
  {
    id: "urban_ward_name",
    numeric: false,
    disablePadding: false,
    label: "Urban Ward Name",
  },
  {
    id: "urban_ward_booth_govt_id",
    numeric: false,
    disablePadding: false,
    label: "Part No",
  },
  {
    id: "urban_ward_booth_name",
    numeric: false,
    disablePadding: false,
    label: "Booth Name",
  },
  {
    id: "urban_ward_booth_regional_name",
    numeric: false,
    disablePadding: false,
    label: "V Booth Name",
  },
  {
    id: "booth_type",
    numeric: false,
    disablePadding: false,
    label: "Booth type",
  },
  {
    id: "booth_location",
    numeric: false,
    disablePadding: false,
    label: "Village name",
  },
  {
    id: "booth_location_regional_name",
    numeric: false,
    disablePadding: false,
    label: "V Village name",
  },

  {
    id: "section_id",
    numeric: false,
    disablePadding: false,
    label: "Section Id",
    sorting: true,
  },
  {
    id: "section_name",
    numeric: false,
    disablePadding: false,
    label: "Section name",
    sorting: true,
  },
  {
    id: "section_regional_name",
    numeric: false,
    disablePadding: false,
    label: "Section Regional name",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    sorting: true,
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
    sorting: true,
    width: 120,
  },
];
const breadCrumbs = [
  { name: "Catalog Urban", menu_url: null },
  { name: "Urban Ward", menu_url: "/catalog-urban/urban-ward" },
];

const additionalActions = [
  {
    name: "View",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = ({ booths }) => [
  {
    name: "urban_ward_booth",
    id: "urban_ward_booth",
    label: "Urban Ward Booth Name",
    placeholder: "Select Booth",
    value: "",
    type: "AUTOCOMPLETE",
    options: booths,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "Urban Ward Voter name",
    placeholder: "Enter Voter First name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "relationFirstName",
    id: "relation_first_name",
    label: "Relation name",
    placeholder: "Enter Relation First name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "epicId",
    id: "epic_id",
    label: "Epic Id",
    placeholder: "Enter Epic Id",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "mobile",
    id: "mobile",
    label: "Mobile",
    placeholder: "Enter Mobile Number",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  {
    name: "Chart",
    icon: "pie-chart",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
