import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";
import CardComponent from "@atoms/card/card.component";
import { breadCrumbs } from "../bulk-voice-call-list/model/bulk-voice-call-list.constants";

const ActionContent = ({ handleSaveClick, handleBackClick }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSaveClick}
      >
        <FeatherIcon icon="save" size={17} />
      </button>

      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    </>
  );
};

const BulkVoiceCallCreateTemplate = ({
  templateModal: { formTriggers, formInputs },
  templateEvents: { handleSubmit, handleTriggerClick, handleBackClick },
}) => {
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Create Bulk Voice Call"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: "Create ", menu_url: "/campaign/bulk-voice-call/create" },
        ]}
        actionContent={
          <ActionContent
            handleSaveClick={() => handleTriggerClick("SUBMIT")}
            handleBackClick={handleBackClick}
          />
        }
      />
      <CardComponent>
        <FormBuilderComponent
          inputFields={formInputs}
          submitTrigger={formTriggers.submitTrigger}
          onSubmit={handleSubmit}
        />
      </CardComponent>
    </PageWrapperComponent>
  );
};

export default BulkVoiceCallCreateTemplate;
