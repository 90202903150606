import React, { useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  tableControls as rawControls,
  additionalActions,
} from "@screens/user-administration/client/client-list/model/client-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import ClientListTemplateComponent from "./client-list-template.component";
import { deleteDataApi, getDataApi } from "./client-list.component.service";
import { useEffect } from "react";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  CLIENT_ALERTS: { DELETE_ALERT },
  STATES_ALERTS: { REFRESH_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const ClientListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const [tableControls, setTableControls] = useState([]);

  const getClientData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const clientData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (clientData.status) {
      console.log(clientData);
      setTableData(clientData.data);
      setTotalCount(clientData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getClientData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getClientData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "client",
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const onLoad = () => {
    const updatedTableControls = rawControls.map((control) => {
      switch (control.name) {
        case "Refresh":
          return {
            ...control,
            onClick: handleRefreshClick,
          };
      }
    });
    setTableControls(updatedTableControls);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleViewDetail = (row) => {
    console.log({ row });
    history.push(
      `/user-administration/client/user-list?clientId=${row.id}&clientName=${row.name}`
    );
  };

  return menuAccess ? (
    <ClientListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
        tableParams,
        tableControls,
        tableActions: additionalActions({
          handleViewDetail,
        }),
      }}
      templateEvents={{
        getClientData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
      }}
    />
  ) : (
    <> </>
  );
};

export default ClientListComponent;
