import React from "react";
import StateDashboardComponent from "../dashboard/dashboard.component";
import MasterStateChartComponent from "./chart.component";

const MasterStatesSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <MasterStateChartComponent />}
      {type === "DASHBOARD" && <StateDashboardComponent />}
    </>
  );
};

export default MasterStatesSwapComponent;
