import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import { getCorporationByDistrictId } from "@screens/master-urban/corporation/corporation-list/corporation-list.service";
import {
  getMunicipalityByDistrictId,
  getMunicipalityByStateId,
} from "@screens/master-urban/municipality/municipality-list/municipality-list.service";
import {
  getTownPanchayatByDistrictId,
  getTownPanchayatByStateId,
} from "@screens/master-urban/town-panchayat/town-panchayat-list/town-panchayat-list.service";
import {
  getWardsByCorporationIds,
  getWardsByMunicipalityIds,
  getWardsByTownPanchayatIds,
} from "@screens/master-urban/urban-ward/urban-ward-list/urban-ward-list.service";
import { redirectToLink } from "@utils/common";

const {
  ELECTION_CATEGORY,
  ELECTION_STATUS,
  MONTHS,
  YEAR,
  ELECTION_PHASE,
  POLL_DATA,
  AC_URBAN_TYPE,
} = APP;
export const phaseRepeaterSize = {
  1: {
    eventName: 5,
    date: 5,
  },
  2: {
    eventName: 6,
    date: 2,
  },
  3: {
    eventName: 4,
    date: 2,
  },
  4: {
    eventName: 3,
    date: 2,
  },
  5: {
    eventName: 6,
    date: 1,
  },
  6: {
    eventName: 5,
    date: 1,
  },
  7: {
    eventName: 4,
    date: 1,
  },
  8: {
    eventName: 3,
    date: 1,
  },
  9: {
    eventName: 2,
    date: 1,
  },
  10: {
    eventName: 1,
    date: 1,
  },
};

export const getElectionFormInputs = ({
  states,
  districts,
  initialValues,
  onStateChange,
}) => [
  {
    type: "TEXT",
    label: "Election ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter Election ID",
    validations: [
      {
        type: "REQUIRED",
        message: "Election ID is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Election Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter Election Name",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    onChange: onStateChange,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Category",
    id: "category",
    name: "category",
    options: ELECTION_CATEGORY,
    value: initialValues?.category || "",
    placeholder: "Select category",
    validations: [
      {
        type: "REQUIRED",
        message: "Category is required !",
      },
    ],
    onChange: (value, copyInputs, additionalValues) => {
      console.log(value, copyInputs);
      const districtIndex = copyInputs[4];
      if (value == "By-Election") {
        console.log({ value });
        districtIndex.isHide = false;
      } else {
        districtIndex.isHide = true;
      }
    },
  },
  {
    type: "SELECT",
    label: "District Name",
    id: "districtId",
    name: "district_id",
    options: districts,
    value: initialValues?.districtId || "",
    placeholder: "Select District",
    validations: [
      // {
      //   type: "REQUIRED",
      //   message: "District Name is required !",
      // },
    ],
    isHide: true,
  },
  {
    type: "CHECKBOX-MULTIPLE",
    label: "Urban Type",
    id: "urbanTypes",
    name: "urban_types",
    options: [
      { name: "CORPORATION", value: "CORPORATION", checked: false },
      { name: "MUNICIPALITY", value: "MUNICIPALITY", checked: false },
      { name: "TOWN PANCHAYAT", value: "TOWN PANCHAYAT", checked: false },
    ],
    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const categoryIndex = copyInputs[3];
      const districtIndex = copyInputs[4];
      const urbanTypeIndex = copyInputs[5];
      const corporationIndex = copyInputs[6];
      const corporationWardIndex = copyInputs[7];
      const municipalityIndex = copyInputs[8];
      const municipalityWardIndex = copyInputs[9];
      const townPanchayatIndex = copyInputs[10];
      const townPanchayatWardIndex = copyInputs[11];

      urbanTypeIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = urbanTypeIndex.options.filter(
        (option) => option.checked
      );

      urbanTypeIndex.value = selectedValues;

      if (districtIndex.value && categoryIndex.value == "By-Election") {
        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "CORPORATION" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == true
        ) {
          const corporationResponse = await getCorporationByDistrictId(
            districtIndex.value
          );
          console.log({ corporationResponse });
          if (corporationResponse.status && corporationResponse.status) {
            const formatedCorporation = corporationResponse.data.map((data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            });
            corporationIndex.isHide = false;
            corporationIndex.options = formatedCorporation;
          }
        }
        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "CORPORATION" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == false
        ) {
          corporationIndex.isHide = true;
          corporationIndex.value = [];
          corporationWardIndex.isHide = true;
          corporationWardIndex.value = [];
        }

        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "MUNICIPALITY" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == true
        ) {
          const municipalityResponse = await getMunicipalityByDistrictId(
            districtIndex.value
          );
          console.log({ municipalityResponse });
          if (municipalityResponse.status && municipalityResponse.status) {
            const formatedMunicipality = municipalityResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                  checked: false,
                };
              }
            );
            municipalityIndex.isHide = false;
            municipalityIndex.options = formatedMunicipality;
          }
        }
        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "MUNICIPALITY" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == false
        ) {
          municipalityIndex.isHide = true;
          municipalityIndex.value = [];
          municipalityWardIndex.isHide = true;
          municipalityWardIndex.value = [];
        }

        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "TOWN PANCHAYAT" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == true
        ) {
          const townPanchayatResponse = await getTownPanchayatByDistrictId(
            districtIndex.value
          );
          console.log({ townPanchayatResponse });
          if (townPanchayatResponse.status && townPanchayatResponse.status) {
            const formatedTownPanchayat = townPanchayatResponse.data.map(
              (data) => {
                return {
                  name: data.name,
                  value: data.id,
                  checked: false,
                };
              }
            );
            townPanchayatIndex.isHide = false;
            townPanchayatIndex.options = formatedTownPanchayat;
          }
        }
        if (
          urbanTypeIndex.options[additionalValues.optionIndex].value ==
            "TOWN PANCHAYAT" &&
          urbanTypeIndex.options[additionalValues.optionIndex].checked == false
        ) {
          townPanchayatIndex.isHide = true;
          townPanchayatIndex.value = [];
          townPanchayatWardIndex.isHide = true;
          townPanchayatWardIndex.value = [];
        }
      }

      return copyInputs;
    },
    value: "",
    validations: [],
  },
  {
    type: "SELECT-GROUP",
    label: "Corporations",
    id: "corporations",
    name: "corporations",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const corporationIndex = copyInputs[6];
      const corporationWardIndex = copyInputs[7];

      corporationIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = corporationIndex.options.filter(
        (option) => option.checked
      );

      corporationIndex.value = selectedValues;
      const corporationIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ corporationIds });

      if (corporationIndex.value && categoryIndex.value == "By-Election") {
        console.log({ corporation: corporationIndex });
        const corporationWardResponse = await getWardsByCorporationIds(
          corporationIds
        );
        if (corporationWardResponse.status && corporationWardResponse.status) {
          const formatedCorporationWard = corporationWardResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            }
          );
          corporationWardIndex.isHide = false;
          corporationWardIndex.options = formatedCorporationWard;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },

  {
    type: "SELECT-GROUP",
    label: "Corporation Wards",
    id: "corporationWards",
    name: "corporation_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const corporationWardIndex = copyInputs[7];
      corporationWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = corporationWardIndex.options.filter(
        (option) => option.checked
      );

      corporationWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Municipalities",
    id: "municipalities",
    name: "municipalities",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const municipalityIndex = copyInputs[8];
      const municipalityWardIndex = copyInputs[9];

      municipalityIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = municipalityIndex.options.filter(
        (option) => option.checked
      );

      municipalityIndex.value = selectedValues;

      const municipalityIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ municipalityIds });

      if (municipalityIndex.value && categoryIndex.value == "By-Election") {
        console.log({ municipality: municipalityIndex });
        const municipalityWardResponse = await getWardsByMunicipalityIds(
          municipalityIds
        );
        if (
          municipalityWardResponse.status &&
          municipalityWardResponse.status
        ) {
          const formatedMunicipalityWard = municipalityWardResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            }
          );
          municipalityWardIndex.isHide = false;
          municipalityWardIndex.options = formatedMunicipalityWard;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Municipality Wards",
    id: "municipalityWards",
    name: "municipality_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const municipalityWardIndex = copyInputs[9];
      municipalityWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = municipalityWardIndex.options.filter(
        (option) => option.checked
      );

      municipalityWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Town Panchayats",
    id: "townPanchayats",
    name: "town_panchayats",
    isSearchable: true,

    onChange: async (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });

      const categoryIndex = copyInputs[3];
      const townPanchayatIndex = copyInputs[10];
      const townPanchayatWardIndex = copyInputs[11];
      townPanchayatIndex.options[additionalValues.optionIndex].checked = value;

      const selectedValues = townPanchayatIndex.options.filter(
        (option) => option.checked
      );

      townPanchayatIndex.value = selectedValues;

      const townPanchayatIds = selectedValues.map(
        (selectedValue) => selectedValue.value
      );

      console.log({ townPanchayatIds });

      if (townPanchayatIndex.value && categoryIndex.value == "By-Election") {
        console.log({ municipality: townPanchayatIndex });
        const townPanchayatWardResponse = await getWardsByTownPanchayatIds(
          townPanchayatIds
        );
        if (
          townPanchayatWardResponse.status &&
          townPanchayatWardResponse.status
        ) {
          const formatedTownPanchayatWards = townPanchayatWardResponse.data.map(
            (data) => {
              return {
                name: data.name,
                value: data.id,
                checked: false,
              };
            }
          );
          townPanchayatWardIndex.isHide = false;
          townPanchayatWardIndex.options = formatedTownPanchayatWards;
        }
      }

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT-GROUP",
    label: "Town Panchayat Wards",
    id: "townPanchayatWards",
    name: "town_panchayat_wards",
    isSearchable: true,

    onChange: (value, copyInputs, additionalValues) => {
      console.log({ value, copyInputs, additionalValues });
      const townPanchayatWardIndex = copyInputs[11];
      townPanchayatWardIndex.options[additionalValues.optionIndex].checked =
        value;

      const selectedValues = townPanchayatWardIndex.options.filter(
        (option) => option.checked
      );

      townPanchayatWardIndex.value = selectedValues;

      console.log({ copyInputs });

      return copyInputs;
    },
    value: "",
    validations: [],
    isHide: true,
  },
  {
    type: "SELECT",
    label: "Year",
    id: "yearOfElection",
    name: "year_of_election",
    options: YEAR,
    value: initialValues?.year_of_election || "",
    placeholder: "Select year ",
    validations: [
      {
        type: "REQUIRED",
        message: "Election year is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Month",
    id: "monthOfElection",
    name: "month_of_election",
    options: MONTHS,
    value: initialValues?.month_of_election || "",
    placeholder: "Select month",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Month is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: ELECTION_STATUS,
    value: initialValues?.status || "ONGOING",
    placeholder: "Select status",
  },

  {
    type: "TEXT",
    label: "No.of Polling Station",
    id: "totalPollingStation",
    name: "total_polling_station",
    value: initialValues?.totalPollingStation || "",
    placeholder: "Enter No.of Polling Station",
    validations: [],
  },

  {
    type: "SELECT",
    label: "No of Phase",
    id: "totalPhase",
    name: "total_phase",
    options: ELECTION_PHASE,
    value: initialValues?.totalPhase || 1,
    placeholder: "No of Phase",
    validations: [
      {
        type: "REQUIRED",
        message: "Election Phase is required !",
      },
    ],
    onChange: (value, formInputs) => {
      const pollDataIndex = formInputs.findIndex(
        (input) => input.name === "poll_data"
      );

      const newRepeaterInputs = [];

      newRepeaterInputs.push({
        ...formInputs[pollDataIndex].inputs[0],
        size: phaseRepeaterSize[value].eventName,
      });

      for (let i = 1; i <= value; i++) {
        console.log("increase repeater length");
        newRepeaterInputs.push({
          ...formInputs[pollDataIndex].inputs[1],
          name: "date" + i,
          label: "Date " + i,
          id: "date" + i,
          size: phaseRepeaterSize[value].date,
        });
      }

      formInputs[pollDataIndex].inputs = newRepeaterInputs;
    },
  },
  {
    type: "TEXT",
    label: "No.of Pink Booth",
    id: "totalPinkBooth",
    name: "total_pink_booth",
    value: initialValues?.totalPinkBooth || "",
    placeholder: "Enter No.of Pink Booth",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Voters",
    id: "totalVoters",
    name: "total_voters",
    value: initialValues?.totalVoters || "",
    placeholder: "Enter No.of Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Male Voters",
    id: "totalMaleVoters",
    name: "total_male_voters",
    value: initialValues?.totalMaleVoters || "",
    placeholder: "Enter No.of Male Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Female Voters",
    id: "totalFemaleVoters",
    name: "total_female_voters",
    value: initialValues?.totalFemaleVoters || "",
    placeholder: "Enter No.of Female Voters",
    validations: [],
  },
  {
    type: "TEXT",
    label: "No.of Transgender Voters",
    id: "totalTransgenderVoters",
    name: "total_transgender_voters",
    value: initialValues?.totalTransgenderVoters || "",
    placeholder: "Enter No.of Transgender Voters",
    validations: [],
  },
  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
  {
    groupName: "Poll Data",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "poll_data",
    value: initialValues?.poll_data || POLL_DATA,
    inputs: [
      {
        type: "TEXT",
        label: "Poll Event",
        id: "pollEvent",
        name: "pollEvent",
        placeholder: "Poll Event",
        size: 6,
      },
      {
        type: "CALENDER",
        label: "Date",
        id: "date",
        name: "date",
        placeholder: "Date",
        size: 5,
      },
    ],
  },
  {
    groupName: "Relevant Links",
    groupClass: "col-md-12",
    hideGroupName: true,
    type: "FORM_REPEATER",
    validations: [],
    name: "relevant_link",
    value: initialValues?.relevant_link || [{}],
    inputs: [
      {
        type: "TEXT",
        label: "Relevant Link Name",
        id: "link_name",
        name: "linkName",
        placeholder: "Name",
        size: 4,
      },
      {
        type: "TEXT",
        label: "Sort Order",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "TEXT",
        label: "Link",
        id: "link",
        name: "link",
        placeholder: "Link",
        size: 5,
        inputIcon: "link",
        handleInputIcon: redirectToLink,
      },
    ],
  },
  {
    groupName: "Election Files",
    groupClass: "col-md-12",
    hideGroupName: false,
    type: "FORM_REPEATER",
    validations: [],
    name: "election_files",
    value: initialValues?.election_files || [{}],
    inputs: [
      {
        type: "TEXT",
        id: "filename",
        name: "fileName",
        placeholder: "File Name",
        size: 3,
      },
      {
        type: "TEXT",
        id: "sort_order",
        name: "sortOrder",
        placeholder: "Sort Order",
        size: 2,
      },
      {
        type: "FILE_UPLOAD",
        id: "file",
        name: "file",
        placeholder: "File",
        handleSelfUpload: uploadElectionFiles,
        filePrefix: BASE_URL + "app-source/election-files/",
        size: 3,
      },
      {
        type: "TEXT",
        id: "remarks",
        name: "remarks",
        placeholder: "Remarks",
        size: 3,
      },
    ],
  },
];
