import { useEffect, useState } from "react";
import DistrictDashboardTemplateComponent from "./dashboard-template.component";
import { getMasterDistrictsDashboardDataApi } from "./dashboard.component.service";

const DistrictDashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();

  const onLoad = async () => {
    const dashboardResponse = await getMasterDistrictsDashboardDataApi();
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formatData = {
        totalDistricts: dashboardResponse.data.total_master_districts,
      };
      setDashboardData(formatData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <DistrictDashboardTemplateComponent
        templateModal={{
          dashboardData,
        }}
      />
    </>
  );
};

export default DistrictDashboardComponent;
