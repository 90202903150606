import { APP_NAME } from "@constants/app.constants";
import VoterFileUploadRequestListComponent from "@screens/catalog/voter-file-upload-request/voter-file-upload-request-list/voter-file-upload-request-list.component";

const voterFileUploadRequestRoutes = [
  {
    name: `Voter Upload Request - ${APP_NAME}`,
    path: "/catalog/voter-file-upload-request",
    component: VoterFileUploadRequestListComponent,
  },
];

export default voterFileUploadRequestRoutes;
