import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadAudioFile, uploadFile } from "@utils/upload.util";

const { STATUS } = APP;
export const getFormInputs = (initialValues) => {
  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: initialValues?.name || "",
      placeholder: "Enter the Name",
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required !",
        },
      ],
    },

    {
      type: "FILE_UPLOAD",
      label: "Select Voice File",
      id: "voiceFile",
      name: "voice_file",
      placeholder: "Select Voice File",
      handleSelfUpload: uploadAudioFile,
      filePrefix: BASE_URL + "app-source/files/",
      value: initialValues?.voiceFile,
      validations: [],
    },

    {
      type: "TEXT",
      label: "Description",
      id: "description",
      name: "description",
      value: initialValues?.description || "",
      placeholder: "Enter the Description",
      validations: [],
    },
    {
      type: "FILE_UPLOAD",
      label: "Numbers File",
      id: "file",
      name: "file_name",
      placeholder: "Select File",
      handleSelfUpload: uploadFile,
      filePrefix: BASE_URL + "app-source/files/",
      value: initialValues?.file,
      validations: [
        {
          type: "REQUIRED",
          message: "File is required !",
        },
      ],
    },
    {
      type: "SELECT",
      label: "Status",
      id: "status",
      name: "status",
      options: STATUS,
      value: initialValues?.status || 1,
      placeholder: "Select Status",
      validations: [
        {
          type: "REQUIRED",
          message: "Status is required !",
        },
      ],
    },
  ];
};
