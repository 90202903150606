import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import { getCurrentElectionName } from "@utils/sstring.util";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ElectionEditTemplateComponent from "./election-urban-edit-template.component";
import { editEntry } from "./election-urban-edit.component.service";
import { setCurrentElection } from "@reducers/election.reducer";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "@constants/api.constants";
import { sortByKey } from "@utils/string.utils";
import { uploadElectionFiles } from "@screens/election/election-list/election-list.component.service";
import ElectionUrbanEditTemplateComponent from "./election-urban-edit-template.component";
import { menuUrl } from "../election-urban-list/model/election-urban-list.constants";
import { getElectionEditFormInputs } from "../model/election-edit.modal";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const ElectionUrbanEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const { currentElection } = useSelector((state) => state.election);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let electionPayload = {};
    for (let i = 0; i < values.length; i++) {
      electionPayload[values[i].name] = values[i].value;
    }
    electionPayload["id"] = location?.state?.row?.id;
    electionPayload["election_category"] = "URBAN";
    electionPayload["type_of_election"] = "URBAN";

    // INFO : call create api
    const electionResponse = await editEntry(
      location?.state?.row?.id,
      electionPayload
    );

    // INFO : check api response and show relevant alert message
    if (electionResponse.status) {
      if (currentElection.id == location?.state?.row?.id)
        dispatch(
          setCurrentElection({ ...location.state.row, ...electionPayload })
        );
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: electionResponse.message })
      );
    }
  };
  const handleElectionFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadElectionResponse = await uploadElectionFiles(file[0]);
    if (uploadElectionResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      const uploadedFile = {
        name: file[0].name,
        file:
          BASE_URL + "app-source/election-files/" + uploadElectionResponse.data,
      };
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].value = copyFormInputs2[index].multipleUpload
        ? [...copyFormInputs2[index].value, uploadedFile]
        : uploadedFile.file;

      copyFormInputs2[index].loading = false;

      console.log({ copyFormInputs2 });

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  const onLoad = async () => {
    const formInputData = getElectionEditFormInputs({
      govtId: location?.state?.row?.govt_id || "",
      name: location?.state?.row?.name || "",
      yearOfElection: location?.state?.row?.year_of_election || "",
      monthOfElection: location?.state?.row?.month_of_election || "",
      status: location?.state?.row?.status || "",
      totalPollingStation: location?.state?.row?.total_polling_station || "",
      totalPhase: location?.state?.row?.total_phase || "",
      totalVoters: location?.state?.row?.total_voters || "",
      totalMaleVoters: location?.state?.row?.total_male_voters || "",
      totalFemaleVoters: location?.state?.row?.total_female_voters || "",
      totalTransgenderVoters:
        location?.state?.row?.total_transgender_voters || "",
      poll_data:
        location?.state?.row?.poll_data &&
        location?.state?.row?.poll_data.length === 0
          ? [{}]
          : location?.state?.row?.poll_data,
      election_files:
        location?.state?.row?.election_files &&
        location?.state?.row?.election_files.length === 0
          ? [{}]
          : sortByKey(location?.state?.row?.election_files, "sort_order"),
      relevant_link:
        location?.state?.row?.relevant_link &&
        location?.state?.row?.relevant_link.length === 0
          ? [{}]
          : sortByKey(location?.state?.row?.relevant_link, "sort_order"),
      remarks: location?.state?.row?.remarks || "",
      totalPinkBooth: location?.state?.row?.total_pink_booth || "",
      corporation: location?.state?.row?.corporation || "",
      municipality: location?.state?.row?.municipality || "",
      townPanchayat: location?.state?.row?.town_panchayat || "",
    });
    setFormInputs(formInputData);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <ElectionUrbanEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ElectionUrbanEditComponent;
