import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { menuUrl } from "../bug-tracker-list/model/bug-tracker-list.constants";
import { editEntry } from "./bug-tracker-edit.component.service";
import { getBugFormInputs } from "../model/bug-tracker.modal";
import BugEditTemplateComponent from "./bug-tracker-edit-template.component";
import {
  getMainMenus,
  getSubMenus,
} from "@screens/system/menu-items/menu-items-create/menu-items-create.component.service";
import { useEffect } from "react";
import { getUsers } from "@screens/user-administration/users/users-list/users-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BUG_ALERTS: { EDIT_ALERT },
} = MESSAGES;

const BugEditComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let bugPayload = {};
    for (let i = 0; i < values.length; i++) {
      bugPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const bugResponse = await editEntry(location?.state?.row?.id, bugPayload);

    // INFO : check api response and show relevant alert message
    if (bugResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: EDIT_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: bugResponse.message }));
    }
  };

  const onMainMenuChange = async (value, inputs) => {
    const subMenuResponse = await getSubMenus(value);
    if (subMenuResponse.status) {
      const formattedSubMenus = subMenuResponse.data.map((menu) => {
        return {
          name: menu.menu_name,
          value: menu.admin_menu_id,
        };
      });

      inputs[2].options = formattedSubMenus;

      setFormInputs(inputs);
    }
  };

  const onLoad = async () => {
    const menuListResponse = await getMainMenus();
    console.log({ menuListResponse });
    if (menuListResponse.status) {
      const formatedMenuList = menuListResponse.data.map((menu) => {
        return {
          name: menu.menu_name,
          value: menu.admin_menu_id,
        };
      });

      const subMenuResponse = await getSubMenus(
        location?.state?.row.main_menu_id || null
      );
      if (subMenuResponse.status) {
        const formattedSubMenus = subMenuResponse.data.map((menu) => {
          return {
            name: menu.menu_name,
            value: menu.admin_menu_id,
          };
        });

        const userResponse = await getUsers();
        console.log({ userResponse });
        if (userResponse.status) {
          const formatedUsersList = userResponse.data.map((data) => {
            return {
              name: data.name,
              value: data.id,
            };
          });
          const formInputData = getBugFormInputs({
            mainMenus: formatedMenuList,
            onMainMenuChange,
            subMenus: formattedSubMenus,
            formatedUsersList,
            initialValues: {
              title: location?.state?.row?.title || "",
              description: location?.state?.row?.description || "",
              issueNo: location?.state?.row?.issue_no || "",
              bugfiles: location?.state?.row?.bug_files || "",
              status: location?.state?.row?.status || "",
              createdBy: location?.state?.row?.created_by || "",
              assignedTo: location?.state?.row?.assigned_to || "",
              priority: location?.state?.row?.priority || "",
              type: location?.state?.row.type || "",
              completedOn: location?.state?.row.completed_on || "",
              mainMenuId: location?.state?.row.main_menu_id || "",
              subMenuId: location?.state?.row.sub_menu_id || "",
              mainMenu: location?.state?.row.main_menu || "",
              subMenu: location?.state?.row.sub_menu || "",
              comment: location?.state?.row.comment || "",
              assignedToId: location?.state?.row.assigned_to_id || "",
            },
          });
          console.log({ formatedMenuList, formInputData });
          setFormInputs(formInputData);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <BugEditTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default BugEditComponent;
