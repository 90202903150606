import React, { useState } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
} from "@screens/campaign/bulk-whatsapp/bulk-whatsapp-list/model/bulk-whatsapp-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import BulkWhatsAppListTemplateComponent from "./bulk-whatsapp-list-template.component";
import {
  deleteDataApi,
  getDataApi,
} from "./bulk-whatsapp-list.component.service";
import { END_POINT } from "@constants/api.constants";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  BULK_WHATSAPP_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const BulkWhatsAppListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { BULK_WHATSAPP_EXPORT } = END_POINT;

  const getData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const voterFieldData = await getDataApi({
      ...params,
      filters: queryStr,
    });
    if (voterFieldData.status) {
      console.log(voterFieldData);
      setTableData(voterFieldData.data);
      setTotalCount(voterFieldData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleDownload = async (filters = null, selected) => {
    const type = "download";
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${BULK_WHATSAPP_EXPORT}/${type}?token=${token}${queryStr}}`,
    });
    window.open(`${BULK_WHATSAPP_EXPORT}/${type}?token=${token}${queryStr}`);
  };

  const handleTemplateDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    const type = "template";
    console.log({ type });
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${BULK_WHATSAPP_EXPORT}?token=${token}${queryStr}`,
    });
    window.open(`${BULK_WHATSAPP_EXPORT}/${type}?token=${token}${queryStr}`);
  };

  const handleViewDetail = async (row) => {
    console.log({ row });
    history.push(`/campaign/bulk-whatsapp/entries?bulkWhatsAppId=${row.id}`);
  };

  return menuAccess ? (
    <BulkWhatsAppListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
        tableParams,
        tableActions: additionalActions({
          handleViewDetail,
        }),
      }}
      templateEvents={{
        getData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
        handleDownload,
        handleTemplateDownload,
      }}
    />
  ) : (
    <> </>
  );
};

export default BulkWhatsAppListComponent;
