import { APP } from "@constants/app.constants";
const { STATUS } = APP;

export const getModulesFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the name",
    validations: [
      {
        type: "REQUIRED",
        message: "Name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Slug",
    id: "slug",
    name: "slug",
    value: initialValues?.slug || "",
    placeholder: "Enter the Slug",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Status",
    id: "status",
    name: "status",
    options: STATUS,
    value: initialValues?.status || 1,
    placeholder: "Select Status",
    validations: [
      {
        type: "REQUIRED",
        message: "Status is required !",
      },
    ],
  },
];
