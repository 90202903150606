export const getBlockedIpFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "IP Address",
    id: "ipAddress",
    name: "ip_address",
    value: initialValues?.ipAddress || "",
    placeholder: "Enter the IP Address",
    validations: [
      {
        type: "REQUIRED",
        message: "IP Address is required !",
      },
    ],
  },
];
