import { APP_NAME } from "@constants/app.constants";
import NotificationCreateComponent from "@screens/user-administration/notification/notification-create/notification-create.component";
import NotificationEditComponent from "@screens/user-administration/notification/notification-edit/notification-edit.component";
import NotificationListComponent from "@screens/user-administration/notification/notification-list/notification-list.component";

const notificationRoutes = [
  {
    name: "Notification",
    icon: "bell",
    path: "/user-administration/notification",
    component: NotificationListComponent,
  },
  {
    name: `Create Notification - ${APP_NAME}`,
    path: "/user-administration/notification/create",
    component: NotificationCreateComponent,
  },
  {
    name: `Edit Notification - ${APP_NAME}`,
    path: "/user-administration/notification/edit",
    component: NotificationEditComponent,
  },
];

export default notificationRoutes;
