import { APP_NAME } from "@constants/app.constants";

import LeadsCreateComponent from "@screens/crm/leads/leads-create/leads-create.component";
import LeadsEditComponent from "@screens/crm/leads/leads-edit/leads-edit.component";
import LeadsFileUploadComponent from "@screens/crm/leads/leads-file/leads-file.component";
import LeadsListComponent from "@screens/crm/leads/leads-list/leads-list.component";

const leadsRoutes = [
  {
    name: `Leads - ${APP_NAME}`,
    path: "/crm/leads",
    icon: "target",
    component: LeadsListComponent,
  },
  {
    name: `Create Lead - ${APP_NAME}`,
    path: "/crm/leads/create",
    component: LeadsCreateComponent,
  },
  {
    name: `Edit Lead- ${APP_NAME}`,
    path: "/crm/leads/edit",
    component: LeadsEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/crm/leads/upload-leads",
    component: LeadsFileUploadComponent,
  },
];

export default leadsRoutes;
