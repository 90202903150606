import { APP_NAME } from "@constants/app.constants";

import CandidateCreateComponent from "@screens/catalog/pc/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog/pc/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog/pc/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog/pc/candidate/candidate-list/candidate-list.component";
import PCCreateComponent from "@screens/catalog/pc/pc-create/pc-create.component";
import PCEditComponent from "@screens/catalog/pc/pc-edit/pc-edit.component";
import PCListComponent from "@screens/catalog/pc/pc-list/pc-list.component";
import UsersCreateComponent from "@screens/catalog/pc/user/users-create/users-create.component";
import UsersEditComponent from "@screens/catalog/pc/user/users-edit/users-edit.component";
import UsersListComponent from "@screens/catalog/pc/user/users-list/users-list.component";

const catalogPcRoutes = [
  {
    name: `PC - ${APP_NAME}`,
    path: "/catalog/pc",
    icon: "columns",
    component: PCListComponent,
  },

  {
    name: `Create PC - ${APP_NAME}`,
    path: "/catalog/pc/create",
    component: PCCreateComponent,
  },

  {
    name: `Edit PC - ${APP_NAME}`,
    path: "/catalog/pc/edit",
    component: PCEditComponent,
  },

  {
    name: `PC Candidate - ${APP_NAME}`,
    path: "/catalog/pc/candidate",
    component: CandidateListComponent,
  },
  {
    name: `Create PC Candidate - ${APP_NAME}`,
    path: "/catalog/pc/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit PC Candidate - ${APP_NAME}`,
    path: "/catalog/pc/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog/pc/candidate/upload",
    component: CandidateFileUploadComponent,
  },
  {
    name: `PC User - ${APP_NAME}`,
    path: "/catalog/pc/user",
    component: UsersListComponent,
  },
  {
    name: `Create PC User - ${APP_NAME}`,
    path: "/catalog/pc/user/create",
    component: UsersCreateComponent,
  },
  {
    name: `Edit PC User - ${APP_NAME}`,
    path: "/catalog/pc/user/edit",
    component: UsersEditComponent,
  },
];

export default catalogPcRoutes;
