import { APP } from "@constants/app.constants";
const { RESERVATION } = APP;

export const getPCFormInputs = (states, initialValues) => [
  {
    type: "TEXT",
    label: "PC ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the PC ID",
    validations: [
      {
        type: "REQUIRED",
        message: "PC ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "PC Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the PC Name",
    validations: [
      {
        type: "REQUIRED",
        message: "PC Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
