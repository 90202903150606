import { BASE_URL } from "@constants/api.constants";
import { APP } from "@constants/app.constants";
import { uploadFile } from "@utils/upload.util";

const { STATUS } = APP;
export const getFormInputs = (initialValues) => {
  let isHide = true;
  let preview = "";
  if (initialValues.template) {
    const template = initialValues.templates.find(
      (temp) => temp.name === initialValues.template
    );

    if (template) {
      isHide = false;
      preview = template.body;
    }
  }
  return [
    {
      type: "TEXT",
      label: "Name",
      id: "name",
      name: "name",
      value: initialValues?.name || "",
      placeholder: "Enter the Name",
      validations: [
        {
          type: "REQUIRED",
          message: "Name is required !",
        },
      ],
    },

    {
      type: "AUTOCOMPLETE",
      label: "Template",
      id: "template",
      name: "template",
      options: initialValues.templates,
      value: initialValues?.template || "",
      placeholder: "Enter the Template",
      onChange: (value, copyInputs) => {
        console.log({ value, copyInputs, templates: initialValues.templates });

        const template = initialValues.templates.find(
          (temp) => temp.name === value
        );

        if (template) {
          copyInputs[2].isHide = false;
          copyInputs[2].value = template.body;
        }

        console.log({ template, copyInputs });

        return copyInputs;
      },
      validations: [
        {
          type: "REQUIRED",
          message: "Template is required !",
        },
      ],
    },
    {
      type: "TEXT_AREA",
      label: "Preview",
      id: "preview",
      name: "preview",
      value: preview || "",
      placeholder: "Preview",
      isHide: isHide,
      validations: [],
    },
    {
      type: "TEXT",
      label: "Description",
      id: "description",
      name: "description",
      value: initialValues?.description || "",
      placeholder: "Enter the Description",
      validations: [],
    },
    {
      type: "FILE_UPLOAD",
      label: "Select File",
      id: "file",
      name: "file_name",
      placeholder: "Select File",
      handleSelfUpload: uploadFile,
      filePrefix: BASE_URL + "app-source/files/",
      value: initialValues?.file,
      validations: [
        {
          type: "REQUIRED",
          message: "File is required !",
        },
      ],
    },
    {
      type: "SELECT",
      label: "Status",
      id: "status",
      name: "status",
      options: STATUS,
      value: initialValues?.status || 1,
      placeholder: "Select Status",
      validations: [
        {
          type: "REQUIRED",
          message: "Status is required !",
        },
      ],
    },
  ];
};
