import FormInputTextAreaComponent from "@atoms/form-input/form-input-textarea/form-input-textarea.component";
import React from "react";

const RoleFormComponent = ({
  roleName,
  error,
  handleChange,
  handleDescriptionChange,
  handleTypeChange,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className={"mb-1 row"}>
          <div className={"col-sm-2"}>
            <label className="col-form-label"> Role Name </label>
          </div>
          <div className={"col-sm-10"}>
            <input
              className={`form-control ${error ? "is-invalid" : ""}`}
              type={"text"}
              name={"role_name"}
              id={"role_name"}
              value={roleName.role}
              onChange={handleChange}
              placeholder={"Enter Role Name"}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
          <div className={"col-sm-2"}>
            <label className="col-form-label" style={{ marginTop: 20 }}>
              Type
            </label>
          </div>
          <div className={"col-sm-10"} style={{ marginTop: 20 }}>
            <select
              className={`form-control ${error ? "is-invalid" : ""}`}
              name={"type"}
              id={"type"}
              value={roleName.type}
              onChange={handleTypeChange}
            >
              <option value="">-Select Type-</option>
              <option value="PC">PC</option>
              <option value="AC">AC</option>
              <option value="URBAN">URBAN</option>
              <option value="RURAL">RURAL</option>
              <option value="ADMIN">ADMIN</option>
            </select>
          </div>
          <div className={"col-sm-2"} style={{ marginTop: 20 }}>
            <label className="col-form-label"> Description </label>
          </div>
          <div className={"col-sm-9"} style={{ marginTop: 20 }}>
            <FormInputTextAreaComponent
              className={`form-control `}
              type={"text"}
              id={"description"}
              name={"description"}
              value={roleName.description}
              placeholder={"Enter Description"}
              onChange={handleDescriptionChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleFormComponent;
