import { APP_NAME } from "@constants/app.constants";
import CandidateCreateComponent from "@screens/catalog-rural/district-union-ward/candidate/candidate-create/candidate-create.component";
import CandidateEditComponent from "@screens/catalog-rural/district-union-ward/candidate/candidate-edit/candidate-edit.component";
import CandidateFileUploadComponent from "@screens/catalog-rural/district-union-ward/candidate/candidate-file/candidate-file.component";
import CandidateListComponent from "@screens/catalog-rural/district-union-ward/candidate/candidate-list/candidate-list.component";
import DistrictPanchayatUnionWardCreateComponent from "@screens/catalog-rural/district-union-ward/district-union-ward-create/district-union-ward-create.component";
import DistrictPanchayatUnionWardEditComponent from "@screens/catalog-rural/district-union-ward/district-union-ward-edit/district-union-ward-edit.component";
import DistrictPanchayatUnionWardListComponent from "@screens/catalog-rural/district-union-ward/district-union-ward-list/district-union-ward-list.component";
import RuralVoterCreateComponent from "@screens/catalog-rural/district-union-ward/voter/voter-create/voter-create.component";
import RuralVoterEditComponent from "@screens/catalog-rural/district-union-ward/voter/voter-edit/voter-edit.component";
import RuralVoterFileUploadComponent from "@screens/catalog-rural/district-union-ward/voter/voter-file/voter-file.component";
import RuralVoterListComponent from "@screens/catalog-rural/district-union-ward/voter/voter-list/voter-list.component";

const catalogDistrictUnionWardRoutes = [
  {
    name: "District Union Ward List",
    icon: "framer",
    path: "/catalog-rural/district-union-ward",
    component: DistrictPanchayatUnionWardListComponent,
  },

  {
    name: `Create District Union Ward - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/create",
    component: DistrictPanchayatUnionWardCreateComponent,
  },

  {
    name: `Edit District Union Ward- ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/edit",
    component: DistrictPanchayatUnionWardEditComponent,
  },

  {
    name: `District Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/candidate",
    component: CandidateListComponent,
  },

  {
    name: `Create District Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/candidate/create",
    component: CandidateCreateComponent,
  },
  {
    name: `Edit District Union Ward Candidate - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/candidate/edit",
    component: CandidateEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/candidate/upload",
    component: CandidateFileUploadComponent,
  },
  {
    name: `District Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/voter",
    component: RuralVoterListComponent,
  },
  {
    name: `Create District Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/voter/create",
    component: RuralVoterCreateComponent,
  },
  {
    name: `Edit District Union Ward Voter - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/voter/edit",
    component: RuralVoterEditComponent,
  },
  {
    name: `Upload File - ${APP_NAME}`,
    path: "/catalog-rural/district-union-ward/voter/upload",
    component: RuralVoterFileUploadComponent,
  },
];

export default catalogDistrictUnionWardRoutes;
