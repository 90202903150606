import { Card, Modal, Table } from "reactstrap";

// Local Scss
import "@core/scss/react/libs/tables/react-dataTable-component.scss";

// Local Components
import ModalComponent from "@atoms/modal/modal.component";
import DataGridColumnsComponent from "./components/data-grid-columns.component";
import DataGridDataComponent from "./components/data-grid-data.component";
import DataGridHeaderComponent from "./components/data-grid-header.component";
import DataGridFooterComponent from "./components/data-grid-footer.component";
import DataGridManageColumnsComponent from "./components/data-grid-manage-columns.component";
import FormBuilderComponent from "@molecules/form-builder/form-builder.component";

const DataGridTemplateComponent = ({
  templateModal: {
    title,
    tableColumn,
    tableData,
    totalCount,
    page,
    rowsPerPage,
    selected,
    openDeleteDialog,
    filters,
    openFilterMenu,
    disableActions,
    tableConfig,
    tableControls,
    swapContent,
    orderBy,
    order,
    tableActions,
    showManageColumns,
    tableParams,
    loading,
    editFormData,
  },
  templateEvents: {
    handleRequestSort,
    handleCheckBoxClick,
    setCurrentPage,
    handleDeleteRecords,
    handleSelectAllClick,
    isSelected,
    handleEditClick,
    confirmDeleteData,
    cancelDeleteData,
    handleDownload,
    handleFilterIconClick,
    handleFilterSubmit,
    handleBadgeClick,
    handleChangeRowsPerPage,
    handleTDClick,
    handleChangeInlineEdit,
    handleInlineEditSave,
    handleCancelInlineEdit,
    handleManageColumnsClick,
    handleModalEditClick,
    handleModalEditSubmit,
    handleModalEditCancel,
    handleModalEditSubmitTrigger,
  },
}) => {
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handlePagination = (page) => {
    const currPageNumber = page.selected;
    setCurrentPage(currPageNumber);
  };
  return (
    <Card className="overflow-hidden" id="data-grid-container">
      <DataGridHeaderComponent
        title={title}
        selected={selected}
        filters={filters}
        tableControls={tableControls}
        openFilterMenu={openFilterMenu}
        handleDownload={handleDownload}
        handleFilterSubmit={handleFilterSubmit}
        handleDeleteRecords={handleDeleteRecords}
        handleFilterIconClick={handleFilterIconClick}
        handleManageColumnsClick={handleManageColumnsClick}
      />
      {!swapContent && (
        <>
          <Table
            className={`table-bordered ${loading ? "loading" : ""}`}
            responsive
          >
            <DataGridColumnsComponent
              tableColumn={tableColumn}
              tableData={tableData}
              selected={selected}
              order={order}
              orderBy={orderBy}
              disableActions={disableActions}
              handleSelectAllClick={handleSelectAllClick}
              createSortHandler={createSortHandler}
              tableActions={tableActions}
            />
            <DataGridDataComponent
              loading={loading}
              tableColumn={tableColumn}
              tableData={tableData}
              isSelected={isSelected}
              disableActions={disableActions}
              tableConfig={tableConfig}
              handleCheckBoxClick={handleCheckBoxClick}
              handleBadgeClick={handleBadgeClick}
              handleTDClick={handleTDClick}
              handleCancelInlineEdit={handleCancelInlineEdit}
              handleEditClick={handleEditClick}
              handleInlineEditSave={handleInlineEditSave}
              handleChangeInlineEdit={handleChangeInlineEdit}
              tableActions={tableActions}
              handleModalEditClick={handleModalEditClick}
              editFormData={editFormData}
            />
          </Table>

          <DataGridFooterComponent
            page={page}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            handlePagination={handlePagination}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}

      {swapContent && <>{swapContent}</>}

      <ModalComponent
        open={editFormData.open}
        title={"Edit " + title}
        primaryText={"Submit"}
        secondaryText={"Cancel"}
        primaryAction={handleModalEditSubmitTrigger}
        secondaryAction={handleModalEditCancel}
      >
        <FormBuilderComponent
          formType="default"
          inputFields={editFormData.formInputs}
          submitTrigger={editFormData.submitTrigger}
          onSubmit={handleModalEditSubmit}
        />
      </ModalComponent>

      <ModalComponent
        open={openDeleteDialog}
        title={"Delete !"}
        message={"Are you sure to delete the selected entries"}
        primaryText={"Delete"}
        secondaryText={"Cancel"}
        primaryAction={confirmDeleteData}
        secondaryAction={cancelDeleteData}
      />

      <DataGridManageColumnsComponent
        open={showManageColumns}
        tableColumn={tableColumn}
        tableParams={tableParams}
        toggleClick={handleManageColumnsClick}
      />
    </Card>
  );
};

export default DataGridTemplateComponent;
