import { END_POINT } from "@constants/api.constants";

const { VOTER_FIELDS_STATUS_TOGGLE } = END_POINT;

const tableTitle = "Voter Field List";
const keyColumn = "id";
const menuUrl = "voter-field";
const tableColumn = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  {
    id: "meta_data",
    numeric: false,
    disablePadding: false,
    label: "Meta Data",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isBadge: true,
    badgeClick: VOTER_FIELDS_STATUS_TOGGLE,
    primaryKey: keyColumn,
    badgeText: {
      0: "Disabled",
      1: "Enabled",
    },
    badgeColor: {
      0: "danger",
      1: "success",
    },
  },
];
const breadCrumbs = [{ name: "Voter Field", menu_url: "/voter-field" }];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Voter Field name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
