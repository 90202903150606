import React from "react";
import UrbanWardDashboardComponent from "../dashboard/dashboard.component";
import UrbanWardChartComponent from "./chart.component";

const UrbanWardSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <UrbanWardChartComponent />}
      {type === "DASHBOARD" && <UrbanWardDashboardComponent />}
    </>
  );
};

export default UrbanWardSwapComponent;
