import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Use Log List";
const keyColumn = "log_id";
const menuUrl = "user-administration/user-log";
const tableColumn = [
  {
    id: "days_ago",
    numeric: false,
    disablePadding: true,
    label: "Days Ago",
  },
  {
    id: "admin_name",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "log_msg",
    numeric: false,
    disablePadding: false,
    label: "Log",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
  },
];
const breadCrumbs = [
  { name: "User Administration", menu_url: null },
  { name: "User Log", menu_url: "/user-administration/user-log" },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl };
