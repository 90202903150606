import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
  tableControls as rawControls,
} from "@screens/catalog-urban/urban-ward-voter/urban-ward-voter-list/model/urban-ward-voter-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import {
  createUrbanWardVoterDataDownloadRequestEntry,
  deleteDataApi,
  getDataApi,
} from "./urban-ward-voter-list.component.service";
import UrbanWardVoterListTemplateComponent from "./urban-ward-voter-list-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";
import { getAllUrbanWardBooth } from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-list/urban-ward-booth-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { DELETE_ALERT, DOWNLOAD_REQUEST },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;
const { CATALOG_AC_VOTER_CREATE_DATA_DOWNLOAD_REQUEST } = END_POINT;

const UrbanWardVoterListComponent = () => {
  const location = useLocation();
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const [tableActions, setTableActions] = useState([]);
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [basicData, setBasicData] = useState([]);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { currentElection } = useSelector((state) => state.election);
  const stateId = new URLSearchParams(location.search).get("stateId");
  const districtId = new URLSearchParams(location.search).get("districtId");
  const urbanWardId = new URLSearchParams(location.search).get("urbanWardId");
  const urbanWardName = new URLSearchParams(location.search).get(
    "urbanWardName"
  );
  const electionId = new URLSearchParams(location.search).get("electionId");
  const [filterState, setFilterState] = useState([]);
  const [voterFields, setVoterFields] = useState([]);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const tableParams = getParamsData(filterList, menuUrl);

  const getUrbanWardVoterData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr
        ? queryStr
        : `&state=${stateId || ""}&district=${districtId || ""}&urban_ward=${
            urbanWardId || ""
          }`,
      electionId: currentElection.id,
      urbanWardId: urbanWardId,
    });
    if (data.status) {
      // console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(
      `/${menuUrl}/voter/create?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}`
    );
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getUrbanWardVoterData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({
            type: ERROR,
            message: response.message,
            viewed: 0,
          })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    // console.log('download triggered', filters, queryStr);
    // console.log({
    //   url: `${CATALOG_AC_VOTER_CREATE_DATA_DOWNLOAD_REQUEST}${currentElection.id}?${queryStr}`,
    // });
    const downloadResponse = await createUrbanWardVoterDataDownloadRequestEntry(
      currentElection.id,
      queryStr,
      {
        request_type: "DOWNLOAD_VOTER_DATA",
        status: 1,
      }
    );
    if (downloadResponse.status) {
      dispatch(
        setAlertMessage({
          type: SUCCESS,
          message: DOWNLOAD_REQUEST,
          viewed: 0,
        })
      );
    }
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    // console.log({ queryStr });
    getUrbanWardVoterData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      return {
        ...control,
        onClick: handleChartClick,
      };
    });
    setTableControls(updatedTableControls);

    const urbanWardBoothResponse = await getAllUrbanWardBooth(
      currentElection.id
    );
    // console.log({ urbanWardBoothResponse });

    if (urbanWardBoothResponse.status) {
      console.log({ urbanWardBoothResponse });
      const formattedUrbanWardBooth = urbanWardBoothResponse.data.map(
        (booth) => {
          return {
            name: booth.name,
            value: booth.id,
          };
        }
      );
      const copyFilters = rawFilters({
        booths: formattedUrbanWardBooth,
      });
      console.log({ copyFilters });
      setFilterState(copyFilters);
    }

    // Voter List
    const voterFieldsResponse = await allVoterFields();
    // console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      const allVoterFields = voterFieldsResponse.data.map((field) => {
        return {
          id: field.name,
          numeric: false,
          disablePadding: false,
          label: field.name,
        };
      });
      setVoterFields(allVoterFields);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <UrbanWardVoterListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn: [...tableColumn, ...voterFields],
        tableData,
        totalCount,
        filters: filters ? filters : filterState,
        tableActions,
        openDetailPanel,
        basicData,
        urbanWardId,
        electionId,
        urbanWardName,
        tableControls,
        swapContentType,
        tableParams,
      }}
      templateEvents={{
        getUrbanWardVoterData,
        handleCreateClick,
        deleteRecords,
        submitFilter,
        handleDashboard,
        handleBackClick,
      }}
    />
  ) : (
    <> </>
  );
};

export default UrbanWardVoterListComponent;
