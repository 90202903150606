import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  tableControls as rawControls,
  additionalActions,
  tableConfig,
} from "@screens/catalog-rural/village-panchayat-ward/village-panchayat-ward-list/model/village-panchayat-ward-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import {
  deleteDataApi,
  getDataApi,
} from "./village-panchayat-ward-list.service";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import { createRefreshEntry } from "@screens/catalog/states/states-list/states-list.component.service";
import VillagePanchayatWardListTemplateComponent from "./village-panchayat-ward-list-template";
import { formatDateUtil } from "@utils/date.util";
import { deleteAllRuralWardVoterData } from "@screens/catalog-rural/candidate-voter/voter-service";
import { getElectedMember } from "@screens/catalog-rural/candidate-voter/candidate-service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  VILLAGE_PANCHAYAT_WARD_ALERTS: { DELETE_ALERT, REFRESH_ALERT },
  AC_VOTER_ALERTS: { DELETE_ALL_VOTER_DATA },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const VillagePanchayatWardListComponent = () => {
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const tableParams = getParamsData(filterList, menuUrl);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { CATALOG_VILLAGE_PANCHAYAT_WARD_EXPORT } = END_POINT;
  const { currentElection } = useSelector((state) => state.election);
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [basicData, setBasicData] = useState([]);
  const [electedMember, setElectedMember] = useState({});
  const [rowData, setRowData] = useState(null);
  const [openTrashModal, setTrashModal] = useState(false);
  const [password, setPassword] = useState("");

  console.log({ tableParams });

  const getData = async (params = null) => {
    const queryStr = getFilterQueryString(menuUrl, params);
    const data = await getDataApi({
      ...params,
      filters: queryStr,
      electionId: currentElection.id,
    });
    if (data.status) {
      console.log(data);
      setTableData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(`/${menuUrl}/create`);
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getData({
          orderBy: "created_at",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${CATALOG_VILLAGE_PANCHAYAT_WARD_EXPORT}${currentElection.id}?token=${token}${queryStr}`,
    });
    window.open(
      `${CATALOG_VILLAGE_PANCHAYAT_WARD_EXPORT}${currentElection.id}?token=${token}${queryStr}`
    );
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getData({
      orderBy: "created_at",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };

  const handleChartClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
    setTableControls(copyControls);
  };

  const handleRefreshClick = async ({}) => {
    const response = await createRefreshEntry({
      type: "catalog-rural-village-panchayat-ward",
      election_id: currentElection.id,
    });
    if (response.status)
      dispatch(
        setAlertMessage({ type: SUCCESS, message: REFRESH_ALERT, viewed: 0 })
      );
  };

  const handleDashbordClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "DASHBOARD" : null);
    setTableControls(copyControls);
  };

  const handleCandidateClick = (row) => {
    console.log({ row });
    history.push(
      `/catalog-rural/village-panchayat-ward/candidate?villagePanchayatWardId=${row.id}&electionId=${currentElection.id}&villagePanchayatWardName=${row.name}&stateGovtId=${row.state_govt_id}`
    );
  };

  const handleVoterClick = (row) => {
    console.log({ row });
    history.push(
      `/catalog-rural/village-panchayat-ward/voter?villagePanchayatWardId=${row.id}&electionId=${currentElection.id}&villagePanchayatWardName=${row.name}`
    );
  };

  const onLoad = () => {
    const updatedTableControls = rawControls.map((control) => {
      switch (control.name) {
        case "Chart":
          return {
            ...control,
            onClick: handleChartClick,
          };
        case "Refresh":
          return {
            ...control,
            onClick: handleRefreshClick,
          };
        case "Dashboard":
          return {
            ...control,
            onClick: handleDashbordClick,
          };
      }
    });
    setTableControls(updatedTableControls);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleViewDetail = async (row) => {
    console.log({ row });
    const candidateData = await getElectedMember({
      wardId: row.id,
      electionId: currentElection.id,
      type: "VILLAGE PANCHAYAT WARD",
    });

    setRowData(row);
    setOpenDetailPanel(true);
    const data = [
      { key: "Ward Id", value: row.govt_id },
      { key: "Ward Name", value: row.name },
      { key: "Reservation", value: row.reservation },
      { key: "Regional Name", value: row.regional_name },
      { key: "Election Id", value: row.election_govt_id },
      { key: "Election Name", value: row.election_name },
      { key: "Phase", value: row.phase },
      { key: "State Id", value: row.state_govt_id },
      { key: "State Name", value: row.state_name },
      { key: "District Id", value: row.district_govt_id },
      { key: "District Name", value: row.district_name },
      { key: "Remarks", value: row.remarks },
      {
        key: "CreatedAt",
        value: row?.created_at ? formatDateUtil(row?.created_at) : "",
      },
      {
        key: "UpdatedAt",
        value: row?.updated_at ? formatDateUtil(row?.updated_at) : "",
      },
      { key: "Admin", value: row.admin_name },
    ];
    setBasicData(data);

    if (candidateData.data.result != null) {
      console.log({ candidateData });
      setElectedMember({
        image: "",
        avatarLabel: candidateData.data.result.name,
        details: [
          { key: "Candidate Name", value: candidateData.data.result.name },
          {
            key: "Candidate Regional Name",
            value: candidateData.data.result.regional_name,
          },
          { key: "Designation", value: candidateData.data.result.designation },
          {
            key: "Personal Mobile",
            value: candidateData.data.result.personal_mobile,
          },
          {
            key: "Official Mobile",
            value: candidateData.data.result.official_mobile,
          },
          { key: "Email Id", value: candidateData.data.result.email_id },
          {
            key: "Residential Address",
            value: candidateData.data.result.residential_address,
          },
          {
            key: "Official Address",
            value: candidateData.data.result.official_address,
          },
          { key: "Remarks", value: candidateData.data.result.remarks },
          {
            key: "Candidate Image",
            value: candidateData.data.result.candidate_image,
          },
          { key: "Affidavit", value: candidateData.data.result.affidavit },
          {
            key: "CreatedAt",
            value: candidateData.data.result.created_at
              ? formatDateUtil(candidateData.data.result.created_at)
              : "",
          },
          {
            key: "UpdatedAt",
            value: candidateData.data.result.updated_at
              ? formatDateUtil(candidateData.data.result.updated_at)
              : "",
          },
        ],
      });
    } else setElectedMember("");
  };
  const toggleDetailPanel = (status) => {
    setOpenDetailPanel(status);
  };

  const handleChange = (e) => {
    console.log({ e });
    setPassword(e);
  };

  const handleTrashVotersClick = (status) => {
    setTrashModal(status);
  };

  const confirmTrashData = async () => {
    dispatch(
      setAlertMessage({
        type: SUCCESS,
        message: DELETE_ALL_VOTER_DATA + " " + rowData.name,
        viewed: 0,
      })
    );

    const deleteResponse = await deleteAllRuralWardVoterData(
      rowData.id,
      "VILLAGE PANCHAYAT WARD",
      password
    );

    if (deleteResponse.status) handleTrashVotersClick(false);
    else {
      alert(deleteResponse.message);
    }
  };

  const cancelTrashData = () => {
    setTrashModal(false);
  };

  return menuAccess ? (
    <VillagePanchayatWardListTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableData,
        totalCount,
        filters: filters ? filters : rawFilters,
        tableControls,
        swapContentType,
        tableParams,
        tableActions: additionalActions({
          handleViewDetail,
          handleCandidateClick,
          handleVoterClick,
          typeOfElection: currentElection?.type_of_election,
        }),
        openDetailPanel,
        basicData,
        electedMember,
        rowData,
        openTrashModal,
        password,
        tableConfig,
      }}
      templateEvents={{
        getData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
        setOpenDetailPanel,
        toggleDetailPanel,
        handleChange,
        confirmTrashData,
        cancelTrashData,
        handleTrashVotersClick,
      }}
    />
  ) : (
    <> </>
  );
};

export default VillagePanchayatWardListComponent;
