import { APP_NAME } from "@constants/app.constants";

import UrbanWardBoothCreateComponent from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-create/urban-ward-booth-create.component";
import UrbanWardBoothEditComponent from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-edit/urban-ward-booth-edit.component";
import UrbanWardBoothListComponent from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-list/urban-ward-booth-list.component";

const catalogUrbanWardBoothRoutes = [
  {
    name: `Urban Ward Booth - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward-booth",
    icon: "briefcase",
    component: UrbanWardBoothListComponent,
  },
  {
    name: `Create Urban Ward Booth - ${APP_NAME}`,
    path: "/catalog-urban/urban-ward-booth/create",
    component: UrbanWardBoothCreateComponent,
  },
  {
    name: `Edit Urban Ward Booth- ${APP_NAME}`,
    path: "/catalog-urban/urban-ward-booth/edit",
    component: UrbanWardBoothEditComponent,
  },

  //   {
  //     name: `UrbanWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/urban-ward-booth/user",
  //     component: UsersListComponent,
  //   },
  //   {
  //     name: `Create UrbanWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/urban-ward-booth/user/create",
  //     component: UsersCreateComponent,
  //   },
  //   {
  //     name: `Edit UrbanWard Booth User - ${APP_NAME}`,
  //     path: "/catalog/urban-ward-booth/user/edit",
  //     component: UsersEditComponent,
  //   },
];

export default catalogUrbanWardBoothRoutes;
