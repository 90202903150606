import { APP_NAME } from "@constants/app.constants";

import DistrictPanchayatUnionCreateComponent from "@screens/master-rural/district-union/district-union-create/district-union-create.component";
import DistrictPanchayatUnionEditComponent from "@screens/master-rural/district-union/district-union-edit/district-union-edit.component";
import DistrictPanchayatUnionFileUploadComponent from "@screens/master-rural/district-union/district-union-file/district-union-file.component";
import DistrictPanchayatUnionListComponent from "@screens/master-rural/district-union/district-union-list/district-union-list.component";

const masterDistrictUnionRoutes = [
  {
    name: "District Panchayat Union List",
    icon: "framer",
    path: "/master-rural/district-union",
    component: DistrictPanchayatUnionListComponent,
  },

  {
    name: `Create District Panchayat Union - ${APP_NAME}`,
    path: "/master-rural/district-union/create",
    component: DistrictPanchayatUnionCreateComponent,
  },

  {
    name: `Edit District Panchayat Union - ${APP_NAME}`,
    path: "/master-rural/district-union/edit",
    component: DistrictPanchayatUnionEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-rural/district-union/upload",
    component: DistrictPanchayatUnionFileUploadComponent,
  },
];

export default masterDistrictUnionRoutes;
