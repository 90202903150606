import { APP_NAME } from "@constants/app.constants";

import PCCreateComponent from "@screens/master/pc/pc-create/pc-create.component";
import PCEditComponent from "@screens/master/pc/pc-edit/pc-edit.component";
import PCFileUploadComponent from "@screens/master/pc/pc-file/pc-file.component";
import PCListComponent from "@src/screens/master/pc/pc-list/pc-list.component";

const masterPcRoutes = [
  {
    name: `PC - ${APP_NAME}`,
    icon: "codesandbox",
    path: "/master/pc",
    component: PCListComponent,
  },
  {
    name: `Create PC - ${APP_NAME}`,
    path: "/master/pc/create",
    component: PCCreateComponent,
  },
  {
    name: `Edit PC - ${APP_NAME}`,
    path: "/master/pc/edit",
    component: PCEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master/pc/upload-pc",
    component: PCFileUploadComponent,
  },
];

export default masterPcRoutes;
