import { formatRemarks } from "@utils/string.utils";
import { formatDate, formatDateUtil } from "@utils/date.util";

const tableTitle = "States";
const keyColumn = "id";
const menuUrl = "master/states";
const tableColumn = [
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "State Id",
    sorting: true,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager name",
    width: 150,
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: 150,
    sorting: true,
  },
  {
    id: "official_language",
    numeric: false,
    disablePadding: false,
    label: "Official Language",
    sorting: true,
    width: 150,
  },
  {
    id: "previous_election",
    numeric: false,
    disablePadding: false,
    label: "Previous Election",
    width: 100,
    formatData: formatDate,
  },
  {
    id: "next_election",
    numeric: false,
    disablePadding: false,
    label: "Next Election",
    width: 100,
    formatData: formatDate,
  },
  {
    id: "total_pc",
    numeric: false,
    disablePadding: false,
    label: "Total PC",
    sorting: true,
  },
  {
    id: "total_ac",
    numeric: false,
    disablePadding: false,
    label: "Total AC",
    sorting: true,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Master", menu_url: null },
  { name: "States", menu_url: "/master/states" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "State name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Refresh",
    icon: "refresh-cw",
    status: 0,
    onClick: null,
  },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
