import React from "react";
import FormBuilderDefaultFormComponent from "./components/default-form.component";
import FormBuilderGroupFormComponent from "./components/group-form.component";
import SpinnerComponent from "@atoms/spinner/spinner.component";
import FormBuilderTabFormComponent from "./components/tab-form.component";
import ImagePreviewComponent from "@atoms/image-preview/image-preview.component";

const FormBuilderTemplateComponent = ({
  templateModal: {
    formType,
    groups,
    formInputs,
    loading,
    activeTab,
    imagePreview,
    isImagePreview,
    changeCount,
    submitButton,
  },
  templateEvents: {
    handleChange,
    handleFileUpload,
    handleDeleteFile,
    setActiveTab,
    handleImagePreview,
    cancelImagePreview,
    handleKeyDown,
    handleSubmit,
    handleClear,
  },
}) => {
  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
          <SpinnerComponent />
        </div>
      ) : (
        <>
          {formType === "default" && (
            <FormBuilderDefaultFormComponent
              changeCount={changeCount}
              formInputs={formInputs}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleImagePreview={handleImagePreview}
              handleKeyDown={handleKeyDown}
              submitButton={submitButton}
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          )}

          {formType === "tab" && (
            <FormBuilderTabFormComponent
              changeCount={changeCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              groups={groups}
              formInputs={formInputs}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleImagePreview={handleImagePreview}
              handleKeyDown={handleKeyDown}
            />
          )}

          {formType === "group" && (
            <FormBuilderGroupFormComponent
              changeCount={changeCount}
              groups={groups}
              formInputs={formInputs}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleImagePreview={handleImagePreview}
              handleKeyDown={handleKeyDown}
            />
          )}
          {isImagePreview && (
            <ImagePreviewComponent
              image={imagePreview}
              open={isImagePreview}
              cancelImagePreview={cancelImagePreview}
            />
          )}
        </>
      )}
    </>
  );
};

export default FormBuilderTemplateComponent;
