import { formatRemarks } from "@utils/string.utils";
import { formatDateUtil } from "@utils/date.util";
import { APP } from "@constants/app.constants";
import { getPcByStateId } from "@screens/master/pc/pc-list/pc-list.component.service";
import { getAcByPcId } from "@screens/master/ac/ac-list/ac-list.component.service";
import { getDistrictByStateId } from "@screens/master/districts/districts-list/districts-list.component.service";

const { AC_URBAN_TYPE, RESERVATION_TYPE_2 } = APP;
const tableTitle = "Urban Ward";
const keyColumn = "id";
const menuUrl = "master-urban/urban-ward";
const tableColumn = [
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State name",
    width: 150,
    sorting: true,
  },
  {
    id: "district_name",
    numeric: false,
    disablePadding: false,
    label: "District name",
    width: 150,
    sorting: true,
  },

  {
    id: "pc_name",
    numeric: false,
    disablePadding: false,
    label: "PC name",
    width: 150,
    sorting: true,
  },
  {
    id: "ac_name",
    numeric: false,
    disablePadding: false,
    label: "AC name",
    width: 150,
    sorting: true,
  },
  {
    id: "corporation_name",
    numeric: false,
    disablePadding: false,
    label: "Corporation name",
    width: 150,
    sorting: true,
  },
  {
    id: "municipality_name",
    numeric: false,
    disablePadding: false,
    label: "Municipality name",
    width: 150,
    sorting: true,
  },
  {
    id: "town_panchayat_name",
    numeric: false,
    disablePadding: false,
    label: "Town Panchayat name",
    width: 150,
    sorting: true,
  },
  {
    id: "govt_id",
    numeric: false,
    disablePadding: false,
    label: "Urban ward Id",
    sorting: true,
    width: 150,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: false,
    label: "Manager name",
    width: 150,
    sorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Urban ward name",
    width: 150,
    sorting: true,
  },

  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Regional Name",
    sorting: true,
    width: 120,
  },
  {
    id: "zone_no",
    numeric: false,
    disablePadding: false,
    label: "Zone No",
    sorting: true,
  },
  {
    id: "total_booth_count",
    numeric: false,
    disablePadding: false,
    label: "Booth Count",
    sorting: true,
  },
  {
    id: "total_voters",
    numeric: false,
    disablePadding: false,
    label: "Total Voters",
    sorting: true,
  },
  {
    id: "reservation",
    numeric: false,
    disablePadding: false,
    label: "Reservation",
    sorting: true,
    width: 150,
  },

  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    width: 200,
    formatData: formatRemarks,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    width: 150,
    formatData: formatDateUtil,
    sorting: true,
  },
];
const breadCrumbs = [
  { name: "Master Urban", menu_url: null },
  { name: "Urban Ward", menu_url: "/master-urban/urban-ward" },
];

const additionalActions = [
  {
    name: "view",
    icon: "box",
    buttonColor: "default",
    onClick: null,
  },
];

const filters = ({ states }) => [
  {
    name: "state",
    id: "state",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: async (value, formInputs) => {
      const districtResponse = await getDistrictByStateId(value);
      const pcResponse = await getPcByStateId(value);

      if (districtResponse.status && pcResponse.status) {
        const formattedDistrict = districtResponse.data.map((district) => {
          return {
            name: district.name,
            value: district.id,
          };
        });

        const formattedpc = pcResponse.data.map((pc) => {
          return {
            name: pc.name,
            value: pc.id,
          };
        });

        formInputs[1].options = formattedDistrict;
        formInputs[2].options = formattedpc;
      }
      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "district",
    id: "district",
    label: "District",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select District",
    options: [],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },

  {
    name: "pc",
    id: "pc",
    label: "PC Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select PC",
    options: [],
    onChange: async (value, formInputs) => {
      const acResponse = await getAcByPcId(value);
      console.log({ acResponse });
      if (acResponse.status) {
        const formattedAc = acResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });
        formInputs[3].options = formattedAc;
      }

      return formInputs;
    },
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "ac",
    id: "ac",
    label: "AC Name",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select AC",
    options: [],
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "name",
    id: "name",
    label: "Urban Ward name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "type",
    id: "type",
    label: "Type",
    value: "",
    type: "SELECT",
    placeholder: "Select Type",
    options: [
      { name: "CORPORATION", value: "CORPORATION" },
      { name: "MUNICIPALITY", value: "MUNICIPALITY" },
      { name: "TOWN PANCHAYAT", value: "TOWN PANCHAYAT" },
      { name: "NO TYPE", value: "NO TYPE" },
    ],
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "reservation",
    id: "reservation",
    label: "Reservation",
    value: "",
    type: "SELECT",
    placeholder: "Select Reservation",
    options: RESERVATION_TYPE_2,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

const tableControls = [
  // {
  //   name: "Chart",
  //   icon: "pie-chart",
  //   status: 0,
  //   onClick: null,
  // },
  // {
  //   name: "Refresh",
  //   icon: "refresh-cw",
  //   status: 0,
  //   onClick: null,
  // },
  {
    name: "Dashboard",
    icon: "grid",
    status: 0,
    onClick: null,
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
  tableControls,
};
