import React from "react";
import DashboardComponent from "../dashboard/dashboard.component";
import ChartComponent from "./chart.component";

const PanchayatUnionSwapComponent = ({ type }) => {
  return (
    <>
      {type === "CHART" && <ChartComponent />}
      {type === "DASHBOARD" && <DashboardComponent />}
    </>
  );
};

export default PanchayatUnionSwapComponent;
