import { useLocation, NavLink, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { menuIcons } from "@constants/menu-icon.constants";

import logo from "@assets/images/home-page.png";
import HomeIcon from "@assets/svg-icons/home-icon";

function SideMenuTemplateComponent({
  templateModal: { theme, expandDrawer, menuList, moduleList, menuCollapsed },
  templateEvents: {
    handleShowDrawer,
    handleHideDrawer,
    setMenuCollapsed,
    handleOpenSubMenu,
    currentActiveGroup,
    setMenuVisibility,
  },
}) {
  const { pathname } = useLocation();

  const pathnameArray = pathname.split("/");

  const formattedPathName = "/" + pathnameArray[1] + "/" + pathnameArray[2];

  console.log({ pathname, pathnameArray, formattedPathName, menuList });

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <FeatherIcon
          size={20}
          icon={"disc"}
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      );
    } else {
      return (
        <FeatherIcon
          size={20}
          icon={"circle"}
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      );
    }
  };

  return (
    <div
      onMouseEnter={menuCollapsed ? () => handleShowDrawer() : null}
      onMouseLeave={menuCollapsed ? () => handleHideDrawer() : null}
      className={`main-menu menu-fixed menu-accordion menu-shadow ${
        theme === "dark" ? "menu-dark" : "menu-light"
      }  ${expandDrawer ? "expanded" : ""}`}
      data-scroll-to-active="true"
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item me-auto">
            <NavLink to="/" className="navbar-brand">
              <span className="brand-logo">
                <HomeIcon width={50} height={36} color="#7966ee" />
              </span>
              <h2 className="brand-text mb-0">BLUPAGE</h2>
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div
              className="nav-link modern-nav-toggle cursor-pointer"
              style={{ marginTop: 27 }}
            >
              <Toggler />
              <FeatherIcon
                icon={"x"}
                onClick={() => setMenuVisibility(false)}
                className="toggle-icon icon-x d-block d-xl-none"
                size={20}
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="shadow-bottom"></div>
      <PerfectScrollbar
        className="main-menu-content"
        options={{ wheelPropagation: false }}
      >
        {moduleList &&
          moduleList.length > 0 &&
          moduleList.map((module, moduleIndex) => (
            <ul className="navigation navigation-main" key={moduleIndex}>
              <li className="navigation-header mt-1">
                <span>{module.name}</span>
              </li>

              {module.menus &&
                module.menus.length > 0 &&
                module.menus.map((menu, index) => (
                  <>
                    {menu.sub_menus.length === 0 ? (
                      <li
                        className={`nav-item ${
                          pathname === `/${menu.menu_url}` ? "active" : ""
                        }`}
                        key={menu.menu_id}
                      >
                        <Link
                          className="d-flex align-items-center"
                          to={`/${menu.menu_url}`}
                        >
                          <FeatherIcon
                            icon={menuIcons[menu.menu_name]}
                            style={{ width: "18px", height: "18px" }}
                          />
                          <span className="menu-item text-truncate">
                            {menu.menu_name}
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <li
                        className={`nav-item has-sub ${
                          menu.subMenuOpen ||
                          currentActiveGroup(menu, formattedPathName)
                            ? "open menu-collapsed-open sidebar-group-active"
                            : ""
                        }`}
                      >
                        <a
                          className="d-flex align-items-center"
                          onClick={() =>
                            handleOpenSubMenu(moduleIndex, index, menu)
                          }
                        >
                          <FeatherIcon
                            icon={menuIcons[menu.menu_name]}
                            style={{ width: "18px", height: "18px" }}
                          />
                          <span className="menu-title text-truncate">
                            {menu.menu_name}
                          </span>
                        </a>
                        <ul className="menu-content">
                          <Collapse
                            key={menu.menu_id}
                            isOpen={
                              menu.subMenuOpen ||
                              currentActiveGroup(menu, formattedPathName)
                            }
                          >
                            {menu.sub_menus &&
                              menu.sub_menus.length > 0 &&
                              menu.sub_menus.map((subMenu) => (
                                <li
                                  className={`nav-item ${
                                    formattedPathName === `/${subMenu.menu_url}`
                                      ? "active"
                                      : ""
                                  }`}
                                  key={subMenu.menu_url}
                                >
                                  <Link
                                    className="align-items-center"
                                    to={`/${subMenu.menu_url}`}
                                  >
                                    <FeatherIcon
                                      icon={menuIcons[subMenu.menu_name]}
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                    <span className="menu-item text-truncate">
                                      {subMenu.menu_name}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </Collapse>
                        </ul>
                      </li>
                    )}
                  </>
                ))}
            </ul>
          ))}
      </PerfectScrollbar>
    </div>
  );
}

export default SideMenuTemplateComponent;
