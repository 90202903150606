import React from "react";
import ListComponent from "@atoms/list/list.component";
import PropTypes from "prop-types";
import AvatarComponent from "@atoms/avatar/avatar.component";

const ElectedMemberComponent = ({ memberData }) => {
  const candidateImage = memberData.details.find(
    (data) => data.key === "Candidate Image"
  );

  const memberDataFormat = ({ memberData }) => {
    return memberData.filter(
      (data) => data.key != "Candidate Image" && data.key != "Affidavit"
    );
  };

  const affidavit = memberData.details.find((data) => data.key === "Affidavit");

  return (
    <div>
      {candidateImage && (
        <div style={{ textAlign: "center" }}>
          <img src={candidateImage.value} style={{ width: 150 }} />
        </div>
      )}

      {affidavit && (
        <div style={{ textAlign: "center" }}>
          <a href={affidavit.value} target="_blank" rel="noreferrer">
            {" "}
            Open Affidavit{" "}
          </a>
        </div>
      )}

      {!candidateImage && (
        <div style={{ textAlign: "center", marginLeft: "50%" }}>
          <AvatarComponent name={memberData.avatarLabel} />
        </div>
      )}
      <div>
        <ListComponent
          items={memberDataFormat({ memberData: memberData.details })}
        />
      </div>
    </div>
  );
};

ElectedMemberComponent.propTypes = {
  memberData: PropTypes.object,
};

export default ElectedMemberComponent;
