import { formatDateUtil } from "@utils/date.util";

const tableTitle = "Role List";
const keyColumn = "role_id";
const menuUrl = "user-administration/roles";
const tableColumn = [
  {
    id: "role_name",
    numeric: false,
    disablePadding: true,
    label: "Role Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
  },
];
const breadCrumbs = [
  { name: "User Administration", menu_url: null },
  { name: "Roles", menu_url: "/user-administration/roles" },
];

const additionalActions = ({ handleCloneClick }) => [
  {
    name: "Clone Role",
    icon: "copy",
    buttonClass: "btn btn-outline-info btn-sm",
    onClick: handleCloneClick,
  },
];

const filters = [
  {
    name: "name",
    id: "roleName",
    label: "Role Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export {
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  menuUrl,
  filters,
  additionalActions,
};
