import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import PageHeadComponent from "@src/components/organisms/page-head/page-head.component";
import { tableColumn } from "@screens/user-administration/users/users-list/model/users-list.constants";
import DataGridTableComponent from "@molecules/data-grid/components/data-grid-table.component";
import { breadCrumbs } from "@screens/version/version-list/model/version-list.constants";
import { getUserByClientApi } from "../../users/users-list/users-list.component.service";
import FeatherIcon from "feather-icons-react";

const ClientUserListComponent = () => {
  const location = useLocation();
  const clientId = new URLSearchParams(location.search).get("clientId");
  const clientName = new URLSearchParams(location.search).get("clientName");
  const [tableData, setTableData] = useState([]);

  const userData = async () => {
    const usersData = await getUserByClientApi(clientId);
    console.log({ usersData });
    setTableData(usersData.data.list);
  };
  const history = useHistory();
  const handleBackClick = () => {
    history.push(`/user-administration/client`);
  };
  const ActionContent = ({ handleBackClick }) => {
    return (
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={handleBackClick}
      >
        <FeatherIcon icon="arrow-left" size={17} />
      </button>
    );
  };
  return (
    <PageWrapperComponent>
      <PageHeadComponent
        title={"Users List"}
        breadCrumbs={[
          ...breadCrumbs,
          { name: clientName + " - Users List(s)", menu_url: null },
        ]}
        actionContent={<ActionContent handleBackClick={handleBackClick} />}
      />

      <DataGridTableComponent
        templateModal={{
          title: "Users List",
          tableColumn,
          tableData,
          disableActions: [],
        }}
        templateEvents={{
          dataRefresh: userData,
        }}
      />
    </PageWrapperComponent>
  );
};

export default ClientUserListComponent;
