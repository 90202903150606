import { APP } from "@constants/app.constants";
import { formatDate } from "@utils/date.util";
import { formatRemarks } from "@utils/string.utils";
const { PARTY_TYPE } = APP;
const tableTitle = "Political Party List";
const keyColumn = "id";
const menuUrl = "political-party";
const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Full Name",
    width: 150,
  },
  {
    id: "regional_name",
    numeric: false,
    disablePadding: false,
    label: "Full Regional Name",
    width: 150,
  },
  {
    id: "short_name",
    numeric: false,
    disablePadding: false,
    label: "Short Name",
    width: 80,
  },
  {
    id: "short_regional_name",
    numeric: false,
    disablePadding: false,
    label: "Short Regional Name",
    width: 80,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Party Status",
    width: 120,
  },

  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State Name",
  },
  {
    id: "establish_year",
    numeric: false,
    disablePadding: false,
    label: "Date of Establishment",
    formatData: formatDate,
  },

  {
    id: "age",
    numeric: false,
    disablePadding: false,
    label: "Party Age",
    width: 150,
  },
  {
    id: "founder_name",
    numeric: false,
    disablePadding: false,
    label: "Founder Name",
  },
  {
    id: "party_leader_name",
    numeric: false,
    disablePadding: false,
    label: "Party Leader Name",
    width: 150,
  },
  {
    id: "about",
    numeric: false,
    disablePadding: false,
    label: "About",
    formatData: formatRemarks,
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
    formatData: formatRemarks,
  },
];
const breadCrumbs = [{ name: "Political Party", menu_url: "/political-party" }];

const filters = ({ states }) => [
  {
    name: "name",
    id: "name",
    label: "Party name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "type",
    id: "type",
    label: "Type",
    value: "",
    options: PARTY_TYPE,
    type: "SELECT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
  {
    name: "state_id",
    id: "state_id",
    label: "State",
    value: "",
    type: "AUTOCOMPLETE",
    placeholder: "Select State",
    options: states,
    onChange: null,
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
