import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ForgotPasswordTemplateComponent from "./forgot-password-template.component";
import { forgotPasswordApi } from "./forgot-password.component.service";
import "@core/scss/react/pages/page-authentication.scss";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
} = MESSAGES;

const ForgotPasswordComponent = () => {
  const [username, setUsername] = useState("");
  const { alertMessage } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submitting");
    const forgotPasswordResponse = await forgotPasswordApi({ username });
    console.log({ forgotPasswordResponse });
    if (forgotPasswordResponse.status) {
      dispatch(
        setAlertMessage({
          type: SUCCESS,
          message:
            "Forgot password request sent, Administrator will get in touch with you shortly.",
        })
      );
    } else {
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Request already sent",
        })
      );
    }
  };

  return (
    <ForgotPasswordTemplateComponent
      templateModal={{ username, alertMessage }}
      templateEvents={{ handleSubmit, handleChange }}
    />
  );
};

export default ForgotPasswordComponent;
