import React from "react";

const FormInputTextAreaComponent = ({
  label,
  id,
  name,
  placeholder,
  value,
  error,
  onChange,
  isVertical = false,
}) => {
  console.log({ value });
  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };
  return (
    <div className="col-12">
      <div className={isVertical ? "" : "mb-1 row"}>
        {label && (
          <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
            <label className="col-form-label">{label}</label>
          </div>
        )}
        <div className={isVertical ? "col-sm-12" : "col-sm-9"}>
          <textarea
            style={{ height: 150 }}
            className={`form-control ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={handleChange}
            value={value}
          ></textarea>
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default FormInputTextAreaComponent;
