const FormInputRadioButtonComponent = ({
  id,
  label,
  name,
  onChange,
  error,
  options,
  value,
  isVertical,
}) => {
  console.log({ options });

  const handleChange = (value) => {
    console.log({ value });
    if (onChange) onChange(value);
  };

  return (
    <div className="row">
      <div className={isVertical ? "col-sm-12" : "col-sm-2"}>
        <label className="col-form-label" style={{ marginBottom: 5 }}>
          {" "}
          {label}{" "}
        </label>
      </div>
      <div
        className={isVertical ? "col-sm-12" : "col-sm-9"}
        style={{ marginTop: 6 }}
      >
        {options.map((option) => (
          <>
            <input
              id={id || name}
              type="radio"
              name={name}
              class="form-radio-input"
              checked={value === option.value ? true : false}
              onChange={() => handleChange(option.value)}
              error={error}
              key={option.id}
            />
            &nbsp;
            {option.name}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </>
        ))}
      </div>
    </div>
  );
};

export default FormInputRadioButtonComponent;
