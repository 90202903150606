import { APP_NAME } from "@constants/app.constants";
import CandidateEditComponent from "@screens/crm/candidate/candidate-edit/candidate-edit.component";
import CandidateListComponent from "@screens/crm/candidate/candidate-list/candidate-list.component";
import CRMRuralWardCandidateEditComponent from "@screens/crm/rural-candidates/candidate-edit/candidate-edit.component";
import CRMRuralWardCandidateListComponent from "@screens/crm/rural-candidates/candidate-list/candidate-list.component";
import CRMUrbanWardCandidateEditComponent from "@screens/crm/urban-candidates/candidate-edit/candidate-edit.component";
import UrbanWardCandidateListComponent from "@screens/crm/urban-candidates/candidate-list/candidate-list.component";

const crmCandidateRoutes = [
  {
    name: `Candidate - ${APP_NAME}`,
    icon: "crosshair",
    path: "/crm/candidate",
    component: CandidateListComponent,
  },
  {
    name: `Edit Candidate - ${APP_NAME}`,
    path: "/crm/candidate/edit",
    component: CandidateEditComponent,
  },

  {
    name: `Urban Ward Candidate - ${APP_NAME}`,
    icon: "crosshair",
    path: "/crm/urban-ward-candidates",
    component: UrbanWardCandidateListComponent,
  },
  {
    name: `Urban Ward Candidate Edit - ${APP_NAME}`,
    icon: "crosshair",
    path: "/crm/urban-ward-candidates/edit",
    component:CRMUrbanWardCandidateEditComponent
  },
  {
    name: `Rural Ward Candidate - ${APP_NAME}`,
    icon: "crosshair",
    path: "/crm/rural-candidates",
    component: CRMRuralWardCandidateListComponent
  },
  {
    name: `Urban Ward Candidate Edit - ${APP_NAME}`,
    icon: "crosshair",
    path: "/crm/rural-candidates/edit",
    component:CRMRuralWardCandidateEditComponent
  },
];

export default crmCandidateRoutes;
