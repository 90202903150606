import { APP_NAME } from "@constants/app.constants";
import PanchayatUnionWardCreateComponent from "@screens/master-rural/panchayat-union-ward/panchayat-union-ward-create/panchayat-union-ward-create.component";
import PanchayatUnionWardEditComponent from "@screens/master-rural/panchayat-union-ward/panchayat-union-ward-edit/panchayat-union-ward-edit.component";
import PanchayatUnionWardFileUploadComponent from "@screens/master-rural/panchayat-union-ward/panchayat-union-ward-file/panchayat-union-ward-file.component";
import PanchayatUnionWardListComponent from "@screens/master-rural/panchayat-union-ward/panchayat-union-ward-list/panchayat-union-ward-list.component";

const masterPanchayatUnionWardRoutes = [
  {
    name: "Panchayat Union List",
    icon: "framer",
    path: "/master-rural/panchayat-union-ward",
    component: PanchayatUnionWardListComponent,
  },

  {
    name: `Create Panchayat Union Ward - ${APP_NAME}`,
    path: "/master-rural/panchayat-union-ward/create",
    component: PanchayatUnionWardCreateComponent,
  },

  {
    name: `Edit Panchayat Union Ward- ${APP_NAME}`,
    path: "/master-rural/panchayat-union-ward/edit",
    component: PanchayatUnionWardEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-rural/panchayat-union-ward/upload",
    component: PanchayatUnionWardFileUploadComponent,
  },
];

export default masterPanchayatUnionWardRoutes;
