import { APP_NAME } from "@constants/app.constants";
import VillagePanchayatCreateComponent from "@screens/master-rural/village-panchayat/village-panchayat-create/village-panchayat-create";
import VillagePanchayatEditComponent from "@screens/master-rural/village-panchayat/village-panchayat-edit/village-panchayat-edit";
import VillagePanchayatFileUploadComponent from "@screens/master-rural/village-panchayat/village-panchayat-file/village-panchayat-file";
import VillagePanchayatListComponent from "@screens/master-rural/village-panchayat/village-panchayat-list/village-panchayat-list";

const masterVillagePanchayatRoutes = [
  {
    name: "Village Panchayat List",
    icon: "disc",
    path: "/master-rural/village-panchayat",
    component: VillagePanchayatListComponent,
  },

  {
    name: `Create Village Panchayat - ${APP_NAME}`,
    path: "/master-rural/village-panchayat/create",
    component: VillagePanchayatCreateComponent,
  },

  {
    name: `Edit Village Panchayat - ${APP_NAME}`,
    path: "/master-rural/village-panchayat/edit",
    component: VillagePanchayatEditComponent,
  },

  {
    name: `Upload File Village Panchayat - ${APP_NAME}`,
    path: "/master-rural/village-panchayat/upload",
    component: VillagePanchayatFileUploadComponent,
  },
];

export default masterVillagePanchayatRoutes;
