import { APP_NAME } from "@constants/app.constants";
import MunicipalityCreateComponent from "@screens/catalog-urban/municipality/municipality-create/municipality-create.component";
import MunicipalityEditComponent from "@screens/catalog-urban/municipality/municipality-edit/municipality-edit.component";
import MunicipalityListComponent from "@screens/catalog-urban/municipality/municipality-list/municipality-list.component";

const catalogMunicipalityRoutes = [
  {
    name: "Municipality List",
    icon: "check-square",
    path: "/catalog-urban/municipality",
    component: MunicipalityListComponent,
  },

  {
    name: `Create Corporation - ${APP_NAME}`,
    path: "/catalog-urban/municipality/create",
    component: MunicipalityCreateComponent,
  },

  {
    name: `Edit Corporation - ${APP_NAME}`,
    path: "/catalog-urban/municipality/edit",
    component: MunicipalityEditComponent,
  },
];

export default catalogMunicipalityRoutes;
