export const getSettingFormInputs = (initialValues) => [
  {
    type: "TEXT",
    label: "Setting Name",
    id: "settingName",
    name: "setting_name",
    value: initialValues?.settingName || "",
    placeholder: "Enter the setting name",
    validations: [
      {
        type: "REQUIRED",
        message: "Setting name is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Setting Value",
    id: "settingValue",
    name: "setting_value",
    value: initialValues?.settingValue || "",
    placeholder: "Enter the setting value",
    validations: [
      {
        type: "REQUIRED",
        message: "Setting value is required !",
      },
    ],
  },
];
