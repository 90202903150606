import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getACVoterFormInputs } from "../model/ac-voter.modal";
import { menuUrl } from "../ac-voter-list/model/ac-voter-list.constants";
import { createEntry } from "./ac-voter-create.component.service";
import { getAcBoothByAcId } from "@screens/catalog/ac-booth/ac-booth-list/ac-booth-list.component.service";
import ACVoterCreateTemplateComponent from "./ac-voter-create-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const ACVoterCreateComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formInputs, setFormInputs] = useState([]);
  const acId = new URLSearchParams(location.search).get("acId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const acName = new URLSearchParams(location.search).get("acName");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [voterFields, setVoterFields] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog/ac/voter?acId=${acId}&electionId=${electionId}&acName=${acName}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    console.log({ values });
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let voterField = {};
    let acVoterPayload = {};
    for (let i = 0; i < values.length; i++) {
      acVoterPayload[values[i].name] = values[i].value;
      voterFields.map((field) => {
        if (values[i].name == field.name)
          voterField[field.name] = values[i].value;
      });
    }
    acVoterPayload["ac_id"] = acId;
    acVoterPayload["voter_fields"] = voterField;

    // INFO : call create api
    const acVoterResponse = await createEntry(
      currentElection.id,
      acVoterPayload
    );

    // INFO : check api response and show relevant alert message
    if (acVoterResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/catalog/ac/voter?acId=${acId}&electionId=${electionId}&acName=${acName}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: acVoterResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const catalogAcBoothResponse = await getAcBoothByAcId(acId);
    // console.log({ catalogAcBoothResponse });
    let formatedAcBoothList;
    if (catalogAcBoothResponse.status) {
      formatedAcBoothList = catalogAcBoothResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }

    const formInputData = getACVoterFormInputs({
      acbooths: formatedAcBoothList || [{ name: "-", value: null }],
      initialValues: {},
    });
    // console.log({ formatedAcBoothList, formInputData });
    setFormInputs(formInputData);

    // Voter List
    const voterFieldsResponse = await allVoterFields();
    // console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      const allVoterFields = voterFieldsResponse.data.map((field) => {
        return {
          id: field.name,
          label: field.name,
          type: "TEXT",
          name: field.name,
          placeholder: `Enter the ${field.name}`,
          value: "",
        };
      });
      setVoterFields(allVoterFields);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs: [...formInputs, ...voterFields],
    acId,
    electionId,
    acName,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <ACVoterCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default ACVoterCreateComponent;
