import { APP_NAME } from "@constants/app.constants";

import CorporationCreateComponent from "@screens/master-urban/corporation/corporation-create/corporation-create.component";
import CorporationEditComponent from "@screens/master-urban/corporation/corporation-edit/corporation-edit.component";
import CorporationFileUploadComponent from "@screens/master-urban/corporation/corporation-file/corporation-file.component";
import CorporationListComponent from "@screens/master-urban/corporation/corporation-list/corporation-list";

const masterCorporationRoutes = [
  {
    name: "Corporation List",
    icon: "check-circle",
    path: "/master-urban/corporation",
    component: CorporationListComponent,
  },

  {
    name: `Create Corporation - ${APP_NAME}`,
    path: "/master-urban/corporation/create",
    component: CorporationCreateComponent,
  },

  {
    name: `Edit Corporation - ${APP_NAME}`,
    path: "/master-urban/corporation/edit",
    component: CorporationEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master-urban/corporation/upload",
    component: CorporationFileUploadComponent,
  },
];

export default masterCorporationRoutes;
