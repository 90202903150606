export const menuIcons = {
  Dashboard: "database",
  System: "settings",
  "User Administration": "users",
  Reports: "bar-chart",

  // Sub Menus
  Notification: "bell",
  Roles: "shield-off",
  Users: "users",
  "User Log": "layers",

  "Sent Email": "mail",
  "Sent SMS": "phone",

  Modules: "grid",
  "Menu Items": "list",
  Settings: "tool",
  "Email Template": "at-sign",
  "Blocked IP": "slash",
  "SMS Template": "message-square",
  "Database Backup": "hard-drive",

  "Master AC-PC": "server",
  States: "box",
  Districts: "map-pin",
  PC: "codesandbox",
  AC: "codepen",
  "Upcoming Election": "anchor",

  Catalog: "book-open",
  State: "pie-chart",
  District: "layout",
  "PC.": "columns",
  "AC.": "package",
  "AC Booth": "briefcase",
  "Voter Upload Request": "git-pull-request",

  Election: "award",
  "Election Urban": "thumbs-up",
  "Election Rural": "speaker",

  "Voter Fields": "plus-square",

  Version: "layout",

  CRM: "aperture",
  Candidates: "crosshair",
  Leads: "target",
  Vendors: "layers",
  Symbols: "anchor",

  "Political Party": "user-check",

  "Bug Tracker": "alert-triangle",
  Client: "twitch",

  Campaign: "flag",
  "Bulk WhatsApp": "trello",
  "Bulk Message": "folder",
  "Bulk Voice Call": "phone-call",

  "Master Urban": "hard-drive",
  "AC Urban": "inbox",
  Corporation: "check-circle",
  Municipality: "check-square",
  "Town Panchayat": "crosshair",
  "Urban Ward": "life-buoy",

  "Master Rural": "monitor",
  "District Union": "framer",
  "Panchayat Union": "tablet",
  "Village Panchayat": "disc",
  "VP Ward": "codesandbox",
  "DU Ward": "stop-circle",
  "PU Ward": "file-text",

  Manage: "target",

  "Catalog Urban": "package",
  "Urban States": "box",
  "Urban Districts": "map-pin",
  "Urban PC": "codesandbox",
  "Urban AC": "codepen",
  Corporations: "check-circle",
  Municipalities: "check-square",
  "Town Panchayats": "crosshair",
  "Urban Wards": "life-buoy",
  "Urban Ward Booth": "briefcase",

  "Refresh Catalog Count": "book",
  "Download Requests": "download",

  "Catalog Rural": "book",
  "Rural States": "box",
  "Rural Districts": "map-pin",
  "District Unions": "framer",
  "Panchayat Unions": "tablet",
  "Village Panchayats": "disc",
  "VP Wards": "codesandbox",
  "DU Wards": "stop-circle",
  "PU Wards": "file-text",
  "Rural Ward Booth": "briefcase",

  "Urban Candidates": "cpu",
  "Rural Candidates": "package",
};
