import React, { useState, useEffect } from "react";
import {
  menuUrl,
  tableTitle,
  keyColumn,
  tableColumn,
  breadCrumbs,
  filters as rawFilters,
  additionalActions,
  tableControls as rawControls,
  tableParams as rawParams,
} from "@screens/catalog-rural/district-union-ward/candidate/candidate-list/model/candidate-list.constants";
import { getFilterData } from "@utils/common";
import {
  constructFilterDataAndGetQueryString,
  getFilterQueryString,
  getParamsData,
} from "@utils/filters.util";
import { APP } from "@constants/app.constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkMenuAccess } from "@utils/menu-auth";
import MESSAGES from "@constants/messages.constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "@reducers/alert.reducer";
import { END_POINT } from "@constants/api.constants";
import CandidateTemplateComponent from "./candidate-list-template.component";
import { formatDateUtil } from "@utils/date.util";
import { getPoliticalPartyByState } from "@screens/political-party/political-party-list/political-party-list.component.service";
import {
  getDataApi,
  deleteDataApi,
} from "@screens/catalog-rural/candidate-voter/candidate-service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  ELECTION_CANDIDATE_ALERTS: { DELETE_ALERT },
} = MESSAGES;
const { ROWS_PER_PAGE } = APP;

const CandidateListComponent = () => {
  const location = useLocation();
  const { filterList } = useSelector((state) => state.filter);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = getFilterData(filterList, menuUrl, "filter");
  const menuAccess = checkMenuAccess(menuUrl, "read");
  const { token, appRowsPerPage } = useSelector((state) => state.app);
  const { CATALOG_RURAL_WARD_CANDIDATE_EXPORT } = END_POINT;
  const { currentElection } = useSelector((state) => state.election);
  const districtUnionWardId = new URLSearchParams(location.search).get(
    "districtUnionWardId"
  );
  const electionId = new URLSearchParams(location.search).get("electionId");
  const districtUnionWardName = new URLSearchParams(location.search).get(
    "districtUnionWardName"
  );
  const stateGovtId = new URLSearchParams(location.search).get("stateGovtId");
  const [basicData, setBasicData] = useState([]);
  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [tableControls, setTableControls] = useState([]);
  const [swapContentType, setSwapContentType] = useState(null);
  const tableParams = getParamsData(filterList, menuUrl, rawParams);
  const [filterState, setFilterState] = useState(filters);

  const getCandidateData = async (params = null) => {
    console.log({ params });
    const queryStr = getFilterQueryString(menuUrl, params);
    const candidateData = await getDataApi({
      ...params,
      filters: queryStr,
      id: districtUnionWardId,
      electionId: electionId,
    });
    if (candidateData.status) {
      console.log(candidateData);
      setTableData(candidateData.data);
      setTotalCount(candidateData.totalCount);
    }
  };

  const handleCreateClick = () => {
    history.push(
      `/${menuUrl}/candidate/create?districtUnionWardId=${districtUnionWardId}&electionId=${electionId}&districtUnionWardName=${districtUnionWardName}&stateGovtId=${stateGovtId}`
    );
  };

  const deleteRecords = async (selected) => {
    const deleteAccess = checkMenuAccess(menuUrl, "delete");
    if (deleteAccess) {
      const response = await deleteDataApi(selected);
      if (response.status) {
        dispatch(
          setAlertMessage({ type: SUCCESS, message: DELETE_ALERT, viewed: 0 })
        );
        getCandidateData({
          orderBy: "elected_member",
          sortBy: "desc",
          skip: 0,
          limit: appRowsPerPage || ROWS_PER_PAGE,
        });
      } else {
        dispatch(
          setAlertMessage({ type: ERROR, message: response.message, viewed: 0 })
        );
      }
    } else
      dispatch(
        setAlertMessage({
          type: ERROR,
          message: "Permission denied to delete",
          viewed: 0,
        })
      );
  };

  const handleDownload = async (filters = null) => {
    const queryStr = getFilterQueryString(menuUrl, filters);
    console.log("download triggered", filters, queryStr);
    console.log({
      url: `${CATALOG_RURAL_WARD_CANDIDATE_EXPORT}${currentElection.id}?token=${token}${queryStr}&ward_id=${districtUnionWardId}`,
    });
    window.open(
      `${CATALOG_RURAL_WARD_CANDIDATE_EXPORT}${currentElection.id}?token=${token}${queryStr}&ward_id=${districtUnionWardId}`
    );
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleViewDetail = (row) => {
    console.log({ row });
    setRowData(row);
    setOpenDetailPanel(true);
    const data = [
      { key: "Admin", value: row.admin_name },
      { key: "Regional Name", value: row.regional_name },
      { key: "Election Id", value: row.election_govt_id },
      { key: "Election Name", value: row.election_name },
      { key: "Urban Ward Id", value: row.urban_ward_govt_id },
      { key: "Urban Ward Name", value: row.urban_ward_name },
      { key: "Candidate Id", value: row.govt_id },
      { key: "Candidate Name", value: row.name },
      { key: "Party Name", value: row.party_name },
      { key: "Designation", value: row.designation },
      { key: "Asset", value: row.asset },
      { key: "Age", value: row.age },
      { key: "Personal Mobile", value: row.personal_mobile },
      { key: "WhatsApp Number 1", value: row.whatsapp_number },
      { key: "WhatsApp Number 2", value: row.whatsapp_number2 },
      { key: "Official Mobile", value: row.official_mobile },
      { key: "Residential Address", value: row.residential_address },
      { key: "Email ID", value: row.email_id },
      { key: "Party Type", value: row.party_type },
      { key: "Party Name", value: row.party_name },
      { key: "Pan No", value: row.pan_no },
      { key: "Epic Id", value: row.epic_id },
      { key: "AC No", value: row.ac_govt_id_name },
      { key: "Candidate Status", value: row.elected_member },
      { key: "facebook", value: row.facebook },
      { key: "instagram", value: row.instagram },
      { key: "linkedin", value: row.linkedin },
      { key: "twitter", value: row.twitter },
      { key: "Remarks", value: row.remarks },
      { key: "Candidate Image", value: row.candidate_image },
      { key: "Affidavit", value: row.affidavit },
      {
        key: "CreatedAt",
        value: row?.created_at ? formatDateUtil(row?.created_at) : "",
      },
      {
        key: "UpdatedAt",
        value: row?.updated_at ? formatDateUtil(row?.updated_at) : "",
      },
    ];
    setBasicData(data);
  };

  const handleDashboard = () => {
    console.log("dashboard");
  };

  const submitFilter = (filters) => {
    const queryStr = constructFilterDataAndGetQueryString(filters, menuUrl);
    console.log({ queryStr });
    getCandidateData({
      orderBy: "elected_member",
      sortBy: "desc",
      skip: 0,
      limit: appRowsPerPage || ROWS_PER_PAGE,
      filters: queryStr,
    });
  };
  // const handleChartClick = ({ index, controls }) => {
  //   let copyControls = [...controls];
  //   copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
  //   setSwapContentType(copyControls[index].status === 1 ? "CHART" : null);
  //   setTableControls(copyControls);
  // };

  const handleDashbordClick = ({ index, controls }) => {
    let copyControls = [...controls];
    copyControls[index].status = copyControls[index].status === 1 ? 0 : 1;
    setSwapContentType(copyControls[index].status === 1 ? "DASHBOARD" : null);
    setTableControls(copyControls);
  };

  const onLoad = async () => {
    const updatedTableControls = rawControls.map((control) => {
      return {
        ...control,
        onClick: handleDashbordClick,
      };
    });
    setTableControls(updatedTableControls);

    const politicalPartyResponse = await getPoliticalPartyByState(stateGovtId);
    console.log({ politicalPartyResponse });
    if (politicalPartyResponse.status && politicalPartyResponse.status) {
      const formatedPoliticalPartyList = politicalPartyResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );
      const copyFilters = rawFilters({
        parties: formatedPoliticalPartyList,
      });

      setFilterState(copyFilters);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <CandidateTemplateComponent
      templateModal={{
        tableTitle,
        keyColumn,
        breadCrumbs,
        tableColumn,
        tableActions: additionalActions({
          handleViewDetail,
        }),
        tableData,
        totalCount,
        filters: filterState,
        districtUnionWardId,
        electionId,
        districtUnionWardName,
        basicData,
        openDetailPanel,
        rowData,
        tableControls,
        swapContentType,
        tableParams,
        stateGovtId,
      }}
      templateEvents={{
        getCandidateData,
        handleCreateClick,
        deleteRecords,
        handleDownload,
        handleDashboard,
        submitFilter,
        setOpenDetailPanel,
        handleBackClick,
      }}
    />
  ) : (
    <> </>
  );
};

export default CandidateListComponent;
