import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUrbanWardVoterFormInputs } from "../model/urban-ward-voter.modal";
import { createEntry } from "./urban-ward-voter-create.component.service";
import UrbanWardVoterCreateTemplateComponent from "./urban-ward-voter-create-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";
import { menuUrl } from "../urban-ward-voter-list/model/urban-ward-voter-list.constants";
import { getUrbanWardBoothByUrbanWardId } from "@screens/catalog-urban/urban-ward-booth/urban-ward-booth-list/urban-ward-booth-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const UrbanWardVoterCreateComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formInputs, setFormInputs] = useState([]);
  const urbanWardId = new URLSearchParams(location.search).get("urbanWardId");
  const electionId = new URLSearchParams(location.search).get("electionId");
  const urbanWardName = new URLSearchParams(location.search).get(
    "urbanWardName"
  );
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [voterFields, setVoterFields] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/catalog-urban/urban-ward/voter?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    console.log({ values });
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let voterField = {};
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
      voterFields.map((field) => {
        if (values[i].name == field.name)
          voterField[field.name] = values[i].value;
      });
    }
    payload["urban_ward_id"] = urbanWardId;
    payload["voter_fields"] = voterField;

    // INFO : call create api
    const acVoterResponse = await createEntry(currentElection.id, payload);

    // INFO : check api response and show relevant alert message
    if (acVoterResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/catalog-urban/urban-ward/voter?urbanWardId=${urbanWardId}&electionId=${electionId}&urbanWardName=${urbanWardName}`
      );
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: acVoterResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const catalogUrbanWardBoothResponse = await getUrbanWardBoothByUrbanWardId(
      urbanWardId
    );
    let formatedUrbanWardBoothList;
    if (catalogUrbanWardBoothResponse.status) {
      formatedUrbanWardBoothList = catalogUrbanWardBoothResponse.data.map(
        (data) => {
          return {
            name: data.name,
            value: data.id,
          };
        }
      );
    }

    const formInputData = getUrbanWardVoterFormInputs({
      urbanBooths: formatedUrbanWardBoothList || [{ name: "-", value: null }],
      initialValues: {},
    });
    console.log({ formatedUrbanWardBoothList, formInputData });
    setFormInputs(formInputData);

    // Voter List
    const voterFieldsResponse = await allVoterFields();
    // console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      const allVoterFields = voterFieldsResponse.data.map((field) => {
        return {
          id: field.name,
          label: field.name,
          type: "TEXT",
          name: field.name,
          placeholder: `Enter the ${field.name}`,
          value: "",
        };
      });
      setVoterFields(allVoterFields);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs: [...formInputs, ...voterFields],
    urbanWardId,
    electionId,
    urbanWardName,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <UrbanWardVoterCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UrbanWardVoterCreateComponent;
