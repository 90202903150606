import { APP_NAME } from "@constants/app.constants";
import VoterFieldCreateComponent from "@screens/voter-field/voter-field-create/voter-field-create.component";
import VoterFieldEditComponent from "@screens/voter-field/voter-field-edit/voter-field-edit.component";
import VoterFieldsListComponent from "@screens/voter-field/voter-field-list/voter-field-list.component";

const voterFieldRoutes = [
  {
    name: `Voter Field - ${APP_NAME}`,
    path: "/voter-field",
    icon: "plus-square",
    component: VoterFieldsListComponent,
  },
  {
    name: `Create Voter Field - ${APP_NAME}`,
    path: "/voter-field/create",
    component: VoterFieldCreateComponent,
  },
  {
    name: `Edit Voter Field- ${APP_NAME}`,
    path: "/voter-field/edit",
    component: VoterFieldEditComponent,
  },
];

export default voterFieldRoutes;
