import { APP_NAME } from "@constants/app.constants";
import VillagePanchayatWardCreateComponent from "@screens/master-rural/village-panchayat-ward/village-panchayat-create/village-panchayat-ward-create";
import VillagePanchayatWardEditComponent from "@screens/master-rural/village-panchayat-ward/village-panchayat-edit/village-panchayat-ward-edit";
import VillagePanchayatWardFileUploadComponent from "@screens/master-rural/village-panchayat-ward/village-panchayat-file/village-panchayat-ward-file";
import VillagePanchayatWardListComponent from "@screens/master-rural/village-panchayat-ward/village-panchayat-ward-list/village-panchayat-ward-list";

const masterVillagePanchayatWardRoutes = [
  {
    name: "Village Panchayat Ward List",
    icon: "disc",
    path: "/master-rural/village-panchayat-ward",
    component: VillagePanchayatWardListComponent,
  },

  {
    name: `Create Village Panchayat Ward- ${APP_NAME}`,
    path: "/master-rural/village-panchayat-ward/create",
    component: VillagePanchayatWardCreateComponent,
  },

  {
    name: `Edit Village Panchayat Ward- ${APP_NAME}`,
    path: "/master-rural/village-panchayat-ward/edit",
    component: VillagePanchayatWardEditComponent,
  },

  {
    name: `Upload File Village Panchayat Ward- ${APP_NAME}`,
    path: "/master-rural/village-panchayat-ward/upload",
    component: VillagePanchayatWardFileUploadComponent,
  },
];

export default masterVillagePanchayatWardRoutes;
