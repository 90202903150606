import { APP_NAME } from "@constants/app.constants";
import BugCommentsCreateComponent from "@screens/bug-comments/bug-comments-create/bug-comments-create.component";

import BugCreateComponent from "@screens/bug-tracker/bug-tracker-create/bug-tracker-create.component";
import BugEditComponent from "@screens/bug-tracker/bug-tracker-edit/bug-tracker-edit.component";
import BugTrackerListComponent from "@screens/bug-tracker/bug-tracker-list/bug-tracker-list.component";

const bugTrackerRoutes = [
  {
    name: `Bug Tracker - ${APP_NAME}`,
    path: "/bug-tracker",
    icon: "alert-triangle",
    component: BugTrackerListComponent,
  },
  {
    name: `Create Bug - ${APP_NAME}`,
    path: "/bug-tracker/create",
    component: BugCreateComponent,
  },
  {
    name: `Edit Bug- ${APP_NAME}`,
    path: "/bug-tracker/edit",
    component: BugEditComponent,
  },
  {
    name: `Bug Comment- ${APP_NAME}`,
    path: "/bug-comments/create",
    component: BugCommentsCreateComponent,
  },
];

export default bugTrackerRoutes;
