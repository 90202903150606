import { END_POINT } from "@constants/api.constants";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";
import { store } from "@src/store";

const { MASTER_PC_UPLOAD_FILE, MASTER_PC_READ_FILE } = END_POINT;

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await fetch(MASTER_PC_UPLOAD_FILE, {
    method: "POST",
    headers: {
      token: store.getState().app.token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return { status: true, data: data.data, message: data.message };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const readFile = async (payload) => {
  return await fetch(MASTER_PC_READ_FILE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
