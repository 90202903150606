import { APP_NAME } from "@constants/app.constants";

import VendorsCreateComponent from "@screens/crm/vendors/vendors-create/vendors-create.component";
import VendorsEditComponent from "@screens/crm/vendors/vendors-edit/vendors-edit.component";
import VendorsFileUploadComponent from "@screens/crm/vendors/vendors-file/vendors-file.component";
import VendorsListComponent from "@screens/crm/vendors/vendors-list/vendors-list.component";

const vendorsRoutes = [
  {
    name: `Vendors - ${APP_NAME}`,
    path: "/crm/vendors",
    icon: "layers",
    component: VendorsListComponent,
  },
  {
    name: `Create Lead - ${APP_NAME}`,
    path: "/crm/vendors/create",
    component: VendorsCreateComponent,
  },
  {
    name: `Edit Lead- ${APP_NAME}`,
    path: "/crm/vendors/edit",
    component: VendorsEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/crm/vendors/upload-vendors",
    component: VendorsFileUploadComponent,
  },
];

export default vendorsRoutes;
