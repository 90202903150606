import { createAction, createReducer } from "@reduxjs/toolkit";

export const setThemeAction = createAction("app/setTheme");
export const handleMenuCollapsed = createAction("app/handleMenuCollapsed");
export const setExpandDrawerAction = createAction("app/setExpandDrawer");
export const setDrawerOpenAction = createAction("app/setDrawerOpen");
export const setProfileMenuAction = createAction("app/setProfileMenu");
export const setUserTokenAction = createAction("app/token");
export const resetUserTokenAction = createAction("app/resetUserTokenAction");
export const setUserToken = createAction("app/setUserToken");
export const setRowsPerPageAction = createAction("app/setRowsPerPageAction");
export const setFavoriteMenus = createAction("app/setFavoriteMenus");
export const setNotifications = createAction("app/setNotifications");
export const setModulesAction = createAction("app/setModulesAction");
export const setCurrentRoleId = createAction("app/setCurrentRoleId");

export const appReducer = createReducer(
  {
    theme: "light",
    expandDrawer: true,
    drawerOpen: true,
    profileMenu: false,
    token: null,
    user: null,
    tokenExpired: null,
    menuCollapsed: false,
    appRowsPerPage: 50,
    favoriteMenus: [],
    modules: [],
    notifications: {},
    currentRoleId: null,
  },
  {
    [setCurrentRoleId]: (state, action) =>
      (state = { ...state, currentRoleId: action.payload }),
    [setThemeAction]: (state, action) =>
      (state = { ...state, theme: action.payload }),
    [setModulesAction]: (state, action) =>
      (state = { ...state, modules: action.payload }),
    [setExpandDrawerAction]: (state, action) =>
      (state = { ...state, expandDrawer: action.payload }),
    [handleMenuCollapsed]: (state, action) =>
      (state = { ...state, menuCollapsed: action.payload }),
    [setDrawerOpenAction]: (state, action) =>
      (state = { ...state, drawerOpen: action.payload }),
    [setProfileMenuAction]: (state, action) =>
      (state = { ...state, profileMenu: action.payload }),
    [setRowsPerPageAction]: (state, action) =>
      (state = { ...state, appRowsPerPage: action.payload }),
    [setFavoriteMenus]: (state, action) =>
      (state = { ...state, favoriteMenus: action.payload }),
    [setNotifications]: (state, action) =>
      (state = { ...state, notifications: action.payload }),
    [setUserTokenAction]: (state, action) =>
      (state = {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      }),
    [resetUserTokenAction]: (state, action) =>
      (state = { ...state, token: action.payload, user: action.payload }),
    [setUserToken]: (state, action) =>
      (state = { ...state, user: action.payload }),
  }
);
