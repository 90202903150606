import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserFormInputs } from "../model/users.modal";
import { menuUrl } from "../users-list/model/users-list.constants";
import { createUsersApi, getAllRoles } from "./users-create.component.service";
import UsersCreateTemplateComponent from "./users-create-template.component";
import { getAllElection } from "@screens/election/election-list/election-list.component.service";
import { getAllAc } from "@screens/catalog/ac/ac-list/ac-list.component.service";
import { getAllPc } from "@screens/catalog/pc/pc-list/pc-list.component.service";
import { getAllStates } from "@screens/catalog/states/states-list/states-list.component.service";
import { getDistrictByStateId } from "@screens/catalog/districts/districts-list/districts-list.component.service";
import { getAcBoothByAcIds } from "@screens/catalog/ac-booth/ac-booth-list/ac-booth-list.component.service";
import { uploadUserImage } from "../users-list/users-list.component.service";
import { BASE_URL } from "@constants/api.constants";
import { getAllClients } from "@screens/user-administration/client/client-list/client-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  USER_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const UsersCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };
  const [formInputs, setFormInputs] = useState([]);
  const [currentElectionId, setCurrentElectionId] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [pcs, setPcs] = useState([]);
  const [acs, setAcs] = useState([]);
  const [acBooths, setAcBooths] = useState([]);
  const [electionPermissions, setElectionPermissions] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [electionList, setElectionList] = useState([]);

  const handleElectionPermissions = (name, values) => {
    const update = { ...electionPermissions, [name]: values };
    console.log({ update });
    setElectionPermissions(update);
  };

  const onChangeShowByElection = async (value, inputs) => {
    const electionId = inputs[2].value;
    console.log({ electionId });
    getStateDetails(electionId);
    //getPCDetails(electionId);
    //getACDetails(electionId);
    setCurrentElectionId(electionId);
  };

  const getStateDetails = async (electionId) => {
    const statesResponse = await getAllStates(electionId);
    if (statesResponse.status) {
      const formattedStates = statesResponse.data.map((state) => {
        return {
          label: state.name,
          value: state.id,
          name: state.name,
        };
      });
      setStates(formattedStates);
    }
  };

  const getDistrictDetails = async (stateId) => {
    const districtResponse = await getDistrictByStateId(stateId);
    console.log(districtResponse);
    if (districtResponse.status) {
      const formattedDistrict = districtResponse.data.map((district) => {
        return {
          label: district.name,
          value: district.id,
          name: district.name,
        };
      });
      setDistricts(formattedDistrict);
    }
  };

  const getPCDetails = async (currentElectionId) => {
    const pcResponse = await getAllPc(currentElectionId);
    if (pcResponse.status) {
      const formattedPC = pcResponse.data.map((pc) => {
        return {
          label: pc.name,
          value: pc.id,
          name: pc.name,
        };
      });
      setPcs(formattedPC);
      console.log({ formattedPC });
    }
  };
  const getACDetails = async (
    currentElectionId,
    pcIds = "",
    districtIds = ""
  ) => {
    console.log({ currentElectionId });
    const acResponse = await getAllAc(currentElectionId, pcIds, districtIds);
    if (acResponse.status) {
      const formattedAC = acResponse.data.map((ac) => {
        return {
          label: ac.name,
          value: ac.id,
          name: ac.name,
        };
      });
      setAcs(formattedAC);
      console.log({ formattedAC });
    }
  };

  const getACBoothDetails = async (acId) => {
    console.log({ acId });
    const acBoothResponse = await getAcBoothByAcIds(acId);
    if (acBoothResponse.status) {
      const formattedACBooth = acBoothResponse.data.map((acBooth) => {
        return {
          label: acBooth?.name || acBooth?.regional_name,
          value: acBooth.id,
          name: acBooth?.name || acBooth?.regional_name,
        };
      });
      setAcBooths(formattedACBooth);
    }
  };

  const handleStateChange = (name, value) => {
    console.log({ name, value });
    let copyStates = [...states];
    const index = copyStates.findIndex((state) => state.name === name);
    copyStates[index] = { ...copyStates[index], checked: value };
    setStates(copyStates);
    console.log({ states });
    const selectedStates = copyStates.filter((state) => state.checked);
    handleElectionPermissions("states", selectedStates);
    const selectedStateString = selectedStates
      .map((state) => state.value)
      .join(",");
    console.log({ selectedStateString });
    getDistrictDetails(selectedStateString);
    getPCDetails(currentElectionId);
  };

  const handleDistrictChange = (name, value) => {
    let copyDistrict = [...districts];
    const index = copyDistrict.findIndex((district) => district.name === name);
    copyDistrict[index] = { ...copyDistrict[index], checked: value };
    setDistricts(copyDistrict);

    const selectedDistricts = copyDistrict.filter(
      (district) => district.checked
    );
    handleElectionPermissions("districts", selectedDistricts);
    const selectedDistrictsString = selectedDistricts
      .map((district) => district.value)
      .join(",");
    console.log({ selectedDistrictsString });

    getACDetails(currentElectionId, "", selectedDistrictsString);
  };

  const handlePCChange = (name, value) => {
    let copyPc = [...pcs];
    const index = copyPc.findIndex((pc) => pc.name === name);
    copyPc[index] = { ...copyPc[index], checked: value };
    setPcs(copyPc);

    const selectedPcs = copyPc.filter((pc) => pc.checked);
    handleElectionPermissions("pcs", selectedPcs);

    const selectedPcString = selectedPcs.map((pc) => pc.value).join(",");
    console.log({ selectedPcString });
  };

  const handleACChange = (name, value) => {
    let copyAc = [...acs];
    const index = copyAc.findIndex((ac) => ac.name === name);
    copyAc[index] = { ...copyAc[index], checked: value };
    setAcs(copyAc);

    const selectedAcs = copyAc.filter((ac) => ac.checked);
    handleElectionPermissions("acs", selectedAcs);

    const selectedAcString = selectedAcs.map((ac) => ac.value).join(",");
    console.log({ selectedAcString });
    getACBoothDetails(selectedAcString);
  };

  const handleACBoothChange = (name, value) => {
    let copyAcBooth = [...acBooths];
    const index = copyAcBooth.findIndex((acb) => acb.name === name);
    copyAcBooth[index] = { ...copyAcBooth[index], checked: value };
    setAcBooths(copyAcBooth);

    const selectedAcBooths = copyAcBooth.filter((acb) => acb.checked);
    handleElectionPermissions("acbooths", selectedAcBooths);
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleUsersSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct roles payload
    let usersPayload = {};
    for (let i = 0; i < values.length; i++) {
      usersPayload[values[i].name] = values[i].value;
    }
    usersPayload.username = usersPayload.email;
    console.log({ usersPayload });
    usersPayload["permissions"] = electionPermissions;
    // INFO : call edit api
    const usersResponse = await createUsersApi(usersPayload);

    // INFO : check api response and show relevant alert message
    if (usersResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: usersResponse.message })
      );
    }
  };

  const onLoad = async () => {
    const rolesListResponse = await getAllRoles();
    const electionResponse = await getAllElection();
    console.log({ rolesListResponse });
    const filteredPcRoles = rolesListResponse.data.filter(
      (role) => role.type === "PC"
    );
    const filteredAcRoles = rolesListResponse.data.filter(
      (role) => role.type === "AC"
    );
    const filteredUrbanRoles = rolesListResponse.data.filter(
      (role) => role.type === "URBAN"
    );
    const filteredRuralRoles = rolesListResponse.data.filter(
      (role) => role.type === "RURAL"
    );
    const filteredAdminRoles = rolesListResponse.data.filter(
      (role) => role.type === "ADMIN"
    );
    console.log({ filteredPcRoles });
    if (rolesListResponse.status) {
      const formatedPcRoleList = filteredPcRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedAcRoleList = filteredAcRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedUrbanRoleList = filteredUrbanRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedRuralRoleList = filteredRuralRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });
      const formatedAdminRoleList = filteredAdminRoles.map((data) => {
        return {
          name: data.role_name,
          value: data.role_id,
        };
      });

      if (electionResponse.status) {
        const formatedElectionList = electionResponse.data.map((data) => {
          return {
            name: data.name,
            value: data.id,
          };
        });
        const clientDataResponse = await getAllClients();
        console.log({ clientDataResponse });
        if (clientDataResponse.status && clientDataResponse.status) {
          const formatedClientList = clientDataResponse.data.map((data) => {
            return {
              name: data.name,
              value: data.id,
            };
          });

          setElectionList(formatedElectionList);
          const formInputData = getUserFormInputs(
            formatedPcRoleList,
            formatedAcRoleList,
            formatedUrbanRoleList,
            formatedRuralRoleList,
            formatedAdminRoleList,
            formatedElectionList,
            formatedClientList,
            onChangeShowByElection,
            {},
            handleUserImageFileUpload
          );
          console.log({
            formatedPcRoleList,
            formInputData,
            formatedElectionList,
          });
          setFormInputs(formInputData);
        }
      }
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleUserImageFileUpload = async ({ file, index, formInputs }) => {
    console.log({ file, index, formInputs });
    let copyFormInputs = [...formInputs];
    copyFormInputs[index].loading = true;
    setFormInputs(copyFormInputs);

    const uploadUserResponse = await uploadUserImage(file[0]);
    console.log({ uploadUserResponse });
    if (uploadUserResponse.status) {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      copyFormInputs2[index].filename = file[0].name;
      copyFormInputs2[index].value =
        BASE_URL + "app-source/user-image/" + uploadUserResponse.data;

      setFormInputs(copyFormInputs2);
    } else {
      let copyFormInputs2 = [...copyFormInputs];
      copyFormInputs2[index].loading = false;
      setFormInputs(copyFormInputs2);
      alert("Invalid File Format");
    }
  };

  const templateModal = {
    formTriggers,
    formInputs: formInputs,
    states,
    districts,
    pcs,
    acs,
    acBooths,
  };

  const templateEvents = {
    handleUsersSubmit,
    handleTriggerClick,
    handleStateChange,
    handleDistrictChange,
    handlePCChange,
    handleACChange,
    handleACBoothChange,
    handleBackClick,
  };

  return menuAccess ? (
    <UsersCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default UsersCreateComponent;
