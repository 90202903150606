import { APP_NAME } from "@constants/app.constants";

import ACCreateComponent from "@screens/master/ac/ac-create/ac-create.component";
import ACEditComponent from "@screens/master/ac/ac-edit/ac-edit.component";
import ACFileUploadComponent from "@screens/master/ac/ac-file/ac-file.component";
import ACListComponent from "@src/screens/master/ac/ac-list/ac-list.component";

const masterAcRoutes = [
  {
    name: `AC - ${APP_NAME}`,
    path: "/master/ac",
    component: ACListComponent,
  },
  {
    name: `Create AC - ${APP_NAME}`,
    path: "/master/ac/create",
    component: ACCreateComponent,
  },
  {
    name: `Edit AC - ${APP_NAME}`,
    path: "/master/ac/edit",
    component: ACEditComponent,
  },

  {
    name: `Upload File - ${APP_NAME}`,
    path: "/master/ac/upload-ac",
    component: ACFileUploadComponent,
  },
];

export default masterAcRoutes;
