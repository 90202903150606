import React, { useState, useEffect } from "react";
import { setAlertMessage } from "@reducers/alert.reducer";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "reactstrap";

function AlertComponent({ type, message }) {
  const [isVisible, setIsVisible] = useState(true);
  const {
    alertMessage: { viewed },
  } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleCloseAlert = () => {
    dispatch(setAlertMessage(null));
    setIsVisible(false);
  };

  useEffect(() => {
    if (viewed === 0) dispatch(setAlertMessage({ type, message, viewed: 1 }));
  }, [message, viewed]);

  return (
    isVisible && (
      <Alert color={type} isOpen={isVisible} toggle={handleCloseAlert}>
        <div className="alert-body mt-1">{message}</div>
      </Alert>
    )
  );
}

export default AlertComponent;
