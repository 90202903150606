import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDistrictsFormInputs } from "../model/districts.modal";
import { menuUrl } from "../districts-list/model/districts-list.constants";
import { createEntry } from "./districts-create.component.service";
import DistrictsCreateTemplateComponent from "./districts-create-template.component";
import { getAllStates } from "@screens/catalog/states/states-list/states-list.component.service";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  DISTRICTS_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const DistrictsCreateComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const [formInputs, setFormInputs] = useState([]);
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const { currentElection } = useSelector((state) => state.election);

  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(`/${menuUrl}`);
  };

  const handleSubmit = async ({ values, errors }) => {
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let districtsPayload = {};
    for (let i = 0; i < values.length; i++) {
      districtsPayload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const districtsResponse = await createEntry(
      currentElection.id,
      districtsPayload
    );

    // INFO : check api response and show relevant alert message
    if (districtsResponse.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(`/${menuUrl}`);
    } else {
      dispatch(
        setAlertMessage({ type: ERROR, message: districtsResponse.message })
      );
    }
  };

  const templateModal = {
    formTriggers,
    formInputs,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  const onLoad = async () => {
    const statesResponse = await getAllStates(currentElection.id);
    console.log({ statesResponse });
    if (statesResponse.status && statesResponse.status) {
      const formatedStateList = statesResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });

      const formInputData = getDistrictsFormInputs(formatedStateList, {});
      console.log({ formatedStateList, formInputData });
      setFormInputs(formInputData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return menuAccess ? (
    <DistrictsCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default DistrictsCreateComponent;
