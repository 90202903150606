import React from "react";
import FeatherIcon from "feather-icons-react";
import {
  CardHeader,
  CardTitle,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataGridFilterComponent from "./data-grid-filters.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";

const DataGridHeaderComponent = ({
  title,
  selected,
  filters,
  tableControls,
  openFilterMenu,
  handleDownload,
  handleFilterSubmit,
  handleDeleteRecords,
  handleFilterIconClick,
  handleManageColumnsClick,
}) => {
  const [openMoreOptions, setMoreOptions] = useState(false);
  return (
    <>
      {selected && selected.length > 0 ? (
        <CardHeader
          className="bg-primary text-white selected-items"
          style={{ padding: "17px 5px 17px 30px" }}
        >
          <CardTitle>{selected.length} items selected</CardTitle>
          <CardTitle>
            <button
              className="btn btn-sm btn-default"
              onClick={handleDeleteRecords}
            >
              <FeatherIcon icon="trash" size={18} style={{ color: "white" }} />
            </button>
          </CardTitle>
        </CardHeader>
      ) : (
        <>
          <CardHeader style={{ padding: "17px 5px 17px 30px" }}>
            <CardTitle>{title}</CardTitle>
            <CardTitle>
              {tableControls &&
                tableControls.map((control, controlIndex) => (
                  <ToolTipComponent content={control.name}>
                    <button
                      style={{ float: "left" }}
                      className={`btn btn-sm ${
                        control.status ? "btn-outline-primary" : "btn-default"
                      } mr-2`}
                      onClick={() =>
                        control.onClick({
                          index: controlIndex,
                          controls: tableControls,
                        })
                      }
                    >
                      <FeatherIcon icon={control.icon} size={18} />
                    </button>
                  </ToolTipComponent>
                ))}

              {handleDownload && (
                <>
                  <ToolTipComponent content="Download">
                    <button
                      style={{ float: "left" }}
                      className="btn btn-sm btn-default"
                      onClick={() => handleDownload()}
                    >
                      <FeatherIcon icon="download" size={18} />
                    </button>
                  </ToolTipComponent>
                </>
              )}

              {filters && filters.length > 0 && (
                <>
                  <ToolTipComponent content="Filter">
                    <button
                      style={{ float: "left" }}
                      className="btn btn-sm btn-default"
                      onClick={handleFilterIconClick}
                    >
                      <FeatherIcon icon="filter" size={18} />
                    </button>
                  </ToolTipComponent>
                </>
              )}

              <Dropdown
                isOpen={openMoreOptions}
                toggle={() => {
                  setMoreOptions(!openMoreOptions);
                }}
                // tag="li"
                style={{ float: "left" }}
                className="nav-item"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                  aria-expanded={openMoreOptions}
                  onClick={() => {
                    setMoreOptions(!openMoreOptions);
                  }}
                >
                  <ToolTipComponent content="Manage Columns">
                    <button
                      style={{ padding: 9, width: 49, marginLeft: 6 }}
                      className="btn btn-sm btn-default"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                    </button>
                  </ToolTipComponent>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag="a">
                    <FontAwesomeIcon icon="fa fa-table" size={17} />
                    <span
                      onClick={handleManageColumnsClick}
                      className="align-middle"
                    >
                      {" "}
                      Manage Columns
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </CardTitle>
          </CardHeader>
          {openFilterMenu && (
            <CardBody>
              <DataGridFilterComponent
                filters={filters}
                handleFilterSubmit={handleFilterSubmit}
              />
            </CardBody>
          )}
        </>
      )}
    </>
  );
};

export default DataGridHeaderComponent;
