import FeatherIcon from "feather-icons-react";
import PageWrapperComponent from "@src/components/organisms/page-wrapper/page-wrapper.component";
import DashboardV2Component from "@src/components/organisms/dashboard-v2/dashboard-v2.component";
import ToolTipComponent from "@atoms/tooltip/tooltip.component";
import { Row } from "reactstrap";

import acpc from "@assets/images/ac-pc-image.png";
import ruralUrban from "@assets/images/rural-urban.png";
import { useSelector } from "react-redux";

const DashboardTemplateComponent = ({
  templateModal: { dashboardData, handleRefreshClick },
}) => {
  console.log({ dashboardData });
  const { user, currentRoleId } = useSelector((state) => state.app);

  console.log({ user });
  return (
    <>
      {/* <Row>
        <ToolTipComponent content="Refresh">
          <button
            style={{ width: 40, marginLeft: 1160, marginBottom: 12 }}
            className={`btn btn-sm ${"btn-outline-primary"} mr-2`}
            onClick={handleRefreshClick}
          >
            <FeatherIcon icon="refresh-cw" size={13} />
          </button>
        </ToolTipComponent>
      </Row> */}
      <Row>
        <PageWrapperComponent>
          {user?.pc_role_id === currentRoleId && <img src={acpc} />}

          {user?.ac_role_id === currentRoleId && <img src={acpc} />}

          {user?.urban_role_id === currentRoleId && <img src={ruralUrban} />}

          {user?.rural_role_id === currentRoleId && <img src={ruralUrban} />}

          {/* <div className="app-user-list">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3 className="fw-bolder mb-75">
                          {dashboardData?.totalStates}
                        </h3>
                        <p className="card-text">Total States</p>
                      </div>
                      <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
                        <div className="avatar-content">
                          <FeatherIcon icon="users" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3 className="fw-bolder mb-75">
                          {dashboardData?.totalDistricts}
                        </h3>
                        <p className="card-text">Total Districts</p>
                      </div>
                      <div className="avatar avatar-stats p-50 m-0 bg-light-success">
                        <div className="avatar-content">
                          <FeatherIcon icon="file" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3 className="fw-bolder mb-75">
                          {dashboardData?.totalPc}
                        </h3>
                        <p className="card-text">Total PC</p>
                      </div>
                      <div className="avatar avatar-stats p-50 m-0 bg-light-info">
                        <div className="avatar-content">
                          <FeatherIcon icon="mail" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3 className="fw-bolder mb-75">
                          {dashboardData?.totalAc}
                        </h3>
                        <p className="card-text">Total AC</p>
                      </div>
                      <div className="avatar avatar-stats p-50 m-0 bg-light-danger">
                        <div className="avatar-content">
                          <FeatherIcon icon="minus-circle" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <ActivityLogComponent
        activities={dashboardData?.recentLog}
        title="Activity Log"
      /> */}
          {/* <DashboardV2Component />? */}
        </PageWrapperComponent>
      </Row>
    </>
  );
};

export default DashboardTemplateComponent;
