import { BASE_URL } from "@constants/api.constants";
import { uploadBugFiles } from "../bug-tracker-list/bug-tracker-list.component.service";

export const getBugFormInputs = ({
  mainMenus,
  onMainMenuChange,
  subMenus,
  formatedUsersList,
  initialValues,
}) => [
  {
    type: "TEXT",
    label: "Title",
    id: "title",
    name: "title",
    value: initialValues?.title || "",
    placeholder: "Enter the Title",
    validations: [
      {
        type: "REQUIRED",
        message: "Title is required !",
      },
    ],
  },

  {
    type: "AUTOCOMPLETE",
    label: "Main Menu",
    id: "mainMenuId",
    name: "main_menu_id",
    options: mainMenus,
    value: initialValues?.mainMenuId || "",
    placeholder: "Select Main Menu",
    validations: [],
    onChange: onMainMenuChange,
  },

  {
    type: "AUTOCOMPLETE",
    label: "Sub Menu",
    id: "subMenuId",
    name: "sub_menu_id",
    options: subMenus,
    value: initialValues?.subMenuId || "",
    placeholder: "Select Sub Menu",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Description",
    id: "description",
    name: "description",
    value: initialValues?.description || "",
    placeholder: "Enter the Description",
    validations: [],
  },
  {
    type: "RADIO",
    label: "Type",
    id: "type",
    name: "type",
    options: [
      { name: "Feature", value: "Feature" },
      { name: "Bug", value: "Bug" },
    ],
    value: initialValues?.type || "Feature",
    placeholder: "Select Type",
  },
  {
    type: "AUTOCOMPLETE",
    label: "Assigned to",
    id: "assignedTo",
    name: "assigned_to",
    options: formatedUsersList,
    value: initialValues?.assignedToId || "",
    placeholder: "Select the Assigned to",
    validations: [],
  },

  {
    type: "AUTOCOMPLETE",
    label: "Status",
    id: "status",
    name: "status",
    options: [
      { name: "Open", value: "Open" },
      { name: "Pending", value: "Pending" },
      { name: "Need Clarity", value: "Need Clarity" },
      { name: "On Hold", value: "On Hold" },
      { name: "Absolute", value: "Absolute" },
      { name: "Duplicate", value: "Duplicate" },
      { name: "Closed", value: "Closed" },
      { name: "Completed", value: "Completed" },
      { name: "Reopen", value: "Reopen" },
    ],
    value: initialValues?.status || "Open",
    placeholder: "Select status",
  },
  {
    type: "AUTOCOMPLETE",
    label: "Priority",
    id: "priority",
    name: "priority",
    options: [
      { name: "Low", value: "Low" },
      { name: "Medium", value: "Medium" },
      { name: "High", value: "High" },
      { name: "Immediate", value: "Immediate" },
    ],
    value: initialValues?.priority || "Low",
    placeholder: "Select Priority",
  },
  {
    type: "TEXT_AREA",
    label: "Comment",
    id: "comment",
    name: "comment",
    value: initialValues?.comment || "",
    placeholder: "Enter the Comment",
    validations: [],
  },
  {
    type: "FILE_UPLOAD",
    label: "Files",
    id: "bugfiles",
    name: "bug_files",
    value: initialValues?.bugfiles || [],
    placeholder: "Please select the files",
    validations: [],
    handleSelfUpload: uploadBugFiles,
    filePrefix: BASE_URL + "app-source/bugs/",
    multipleUpload: true,
  },
];
