import { END_POINT } from "@constants/api.constants";
import { store } from "@src/store";
import { checkForExpiredToken } from "@screens/system/settings/setting-list/setting-list.component.service";

const {
  CATALOG_URBAN_WARD_LIST,
  CATALOG_URBAN_WARD_DELETE,
  CATALOG_URBAN_WARD_EXPORT,
  CATALOG_URBAN_WARD_CORPORATION_LIST,
  CATALOG_URBAN_WARD_MUNICIPALITY_LIST,
  CATALOG_URBAN_WARD_TOWN_PANCHAYAT_LIST,
  CATALOG_URBAN_WARD_DISTRICT_LIST,
  CATALOG_URBAN_WARD_VOTER_DELETE_ALL,
  CATALOG_URBAN_WARD_AC_LIST,
  CATALOG_URBAN_WARD_ELECTION_LIST
} = END_POINT;

export const getDataApi = async ({
  orderBy,
  sortBy,
  skip,
  limit,
  filters,
  electionId,
}) => {
  return await fetch(
    `${CATALOG_URBAN_WARD_LIST}${electionId}?sort_by=${sortBy}&order_by=${orderBy}&skip=${skip}&limit=${limit}${filters}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        token: store.getState().app?.token,
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      console.log(response.data);
      if (response.status)
        return {
          status: true,
          data: response.data.list,
          totalCount: response.data.total_count,
        };
      else {
        checkForExpiredToken(response.message);
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteDataApi = async (ids) => {
  return await fetch(CATALOG_URBAN_WARD_DELETE, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const exportData = async () => {
  return await fetch(CATALOG_URBAN_WARD_EXPORT, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status)
        return {
          status: true,
          data: data.data.list,
          totalCount: data.data.total_count,
        };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
export const getWardsByCorporationIds = async (corporationIds) => {
  return await fetch(`${CATALOG_URBAN_WARD_CORPORATION_LIST}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ corporationIds }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getWardsByMunicipalityIds = async (municipalityIds) => {
  return await fetch(`${CATALOG_URBAN_WARD_MUNICIPALITY_LIST}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ municipalityIds }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getWardsByTownPanchayatIds = async (townPanchayatIds) => {
  return await fetch(`${CATALOG_URBAN_WARD_TOWN_PANCHAYAT_LIST}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ townPanchayatIds }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getUrbanWardByDistrictId = async (id) => {
  return await fetch(`${CATALOG_URBAN_WARD_DISTRICT_LIST}${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const deleteAllUrbanWardVoterData = async (id) => {
  return await fetch(`${CATALOG_URBAN_WARD_VOTER_DELETE_ALL}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
    body: JSON.stringify({ ids: [id] }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getUrbanWardByAcId = async (id) => {
  return await fetch(`${CATALOG_URBAN_WARD_AC_LIST}${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};

export const getUrbanWardByElectionId = async (id) => {
  return await fetch(`${CATALOG_URBAN_WARD_ELECTION_LIST}${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      token: store.getState().app.token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) return { status: true, data: data.data };
      else {
        checkForExpiredToken(data.message);
        return { status: false, message: data.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
