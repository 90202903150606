import React from "react";
import LaunchPadTemplate from "./launchpad.template";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import adminImage from "@assets/images/illustration/illustration-admin.png";
import pcElection from "@assets/images/illustration/illustration-parliment.png";
import acElection from "@assets/images/illustration/illustration-ac.png";
import urbanElection from "@assets/images/illustration/illustration-urban.png";
import ruralElection from "@assets/images/illustration/illustration-rural.png";
import {
  resetUserTokenAction,
  setCurrentRoleId,
  setThemeAction,
} from "@reducers/app.reducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setAlertMessage } from "@reducers/alert.reducer";
import { updateRoleIdApi } from "@screens/user-administration/users/users-list/users-list.component.service";

const LaunchPadComponent = () => {
  const { user } = useSelector((state) => state.app);
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoad = () => {
    document.body.style = "background: white";
    dispatch(setThemeAction("light"));
    console.log({ user });
    if (!user) history.push("/");
    const cardsArray = [];

    if (user.pc_role_id)
      cardsArray.push({
        id: user.pc_role_id,
        name: "PC Election",
        image: pcElection,
        admin_id: user.id,
      });

    if (user.ac_role_id)
      cardsArray.push({
        id: user.ac_role_id,
        name: "AC Election",
        image: acElection,
        admin_id: user.id,
      });

    if (user.urban_role_id)
      cardsArray.push({
        id: user.urban_role_id,
        name: "Urban Election",
        image: urbanElection,
        admin_id: user.id,
      });

    if (user.rural_role_id)
      cardsArray.push({
        id: user.rural_role_id,
        name: "Rural Election",
        image: ruralElection,
        admin_id: user.id,
      });
    if (user.admin_role_id)
      cardsArray.push({
        id: user.admin_role_id,
        name: "Admin",
        image: adminImage,
        admin_id: user.id,
      });

    setCards(cardsArray);
  };

  const handleModuleClick = async (card) => {
    console.log({ card });
    dispatch(setCurrentRoleId(card.id));
    let payload = {};
    payload["role_id"] = card.id;
    const response = await updateRoleIdApi(user.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) history.push("/dashboard");
    else {
      alert("role not updated");
      return;
    }
  };

  const handleLogoutClick = () => {
    dispatch(resetUserTokenAction());
    dispatch(setAlertMessage(null));
    history.push("/");
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    cards,
    history,
  };

  const templateEvents = {
    handleModuleClick,
    handleLogoutClick,
  };
  return (
    <LaunchPadTemplate
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  );
};

export default LaunchPadComponent;
