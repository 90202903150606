export const getDistrictsFormInputs = (states, initialValues) => [
  {
    type: "TEXT",
    label: "District ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the District ID",
    validations: [
      {
        type: "REQUIRED",
        message: "District ID is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "District Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the District Name",
    validations: [
      {
        type: "REQUIRED",
        message: "District Name is required !",
      },
    ],
  },

  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    type: "TEXT",
    label: "District Head Quater",
    id: "districtHeadQuater",
    name: "district_head_quater",
    value: initialValues?.districtHeadQuater || "",
    placeholder: "Enter the Head Quater",
    validations: [],
  },

  {
    type: "CALENDER",
    label: "Date of Formation",
    id: "dob",
    name: "dob",
    options: states,
    value: initialValues?.dob || "",
    placeholder: "Select Date",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
