import { useEffect, useState } from "react";
import UrbanWardDashboardTemplateComponent from "./dashboard-template.component";
import { getMasterStatesDashboardDataApi } from "./dashboard.component.service";

const UrbanWardDashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState();

  const onLoad = async () => {
    const dashboardResponse = await getMasterStatesDashboardDataApi();
    console.log({ dashboardResponse });
    if (dashboardResponse.status) {
      const formatData = {
        totalStates: dashboardResponse.data.total_master_states,
        totalTypeState: dashboardResponse.data.total_master_states_type_states,
        totalTypeUnionTerritory:
          dashboardResponse.data.total_master_states_type_union_territory,
        highestDistrictState: dashboardResponse.data.highest_district_state,
        totalHighestStateDistrictCount:
          dashboardResponse.data.total_highest_state_district_count,
        lowestDistrictState: dashboardResponse.data.lowest_district_state,
        totalLowestStateDistrictCount:
          dashboardResponse.data.total_lowest_state_district_count,
      };
      setDashboardData(formatData);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <UrbanWardDashboardTemplateComponent
        templateModal={{
          dashboardData,
        }}
      />
    </>
  );
};

export default UrbanWardDashboardComponent;
