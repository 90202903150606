import { useEffect, useState } from "react";
import { APP } from "@constants/app.constants";
import DataGridTemplateComponent from "./data-grid-template.component";
import { useHistory, useLocation } from "react-router-dom";
import { checkFilterForValue } from "@utils/common";
import { badgeClickApi } from "./data-grid.component.service";
import { useDispatch, useSelector } from "react-redux";
import { setRowsPerPageAction } from "@reducers/app.reducer";
import { PRIMARY_KEY } from "@constants/app.constants";
import { getCurrentFilter } from "@utils/filters.util";
import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
const { ROWS_PER_PAGE } = APP;
const {
  ALERT_TYPES: { SUCCESS, ERROR },
} = MESSAGES;

const DataGridComponent = ({
  title,
  tableColumn: rawColumns,
  tableData,
  totalCount,
  dataRefresh,
  editLink,
  deleteRecords,
  handleDownload,
  filters,
  submitFilter,
  disableActions,
  editRecord,
  tableConfig,
  tableControls,
  hideTable,
  swapContent,
  tableActions,
  tableParams,
  editModal,
}) => {
  const { appRowsPerPage } = useSelector((state) => state.app);
  const { filterList } = useSelector((state) => state.filter);
  const [order, setOrder] = useState(tableParams?.sortBy || "desc");
  const [orderBy, setOrderBy] = useState(tableParams?.orderBy || "created_at");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(tableParams?.page || 0);
  const [appliedFilters, setAppliedFilter] = useState(filters);
  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const [showManageColumns, setShowManageColumns] = useState(false);
  const [tableDataState, setTableDataState] = useState(tableData);
  const [tableColumn, setTableColumns] = useState(rawColumns);
  const [editFormData, setEditFormData] = useState({
    open: false,
    formInputs: [],
    submitTrigger: 0,
    ...editModal,
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    appRowsPerPage || ROWS_PER_PAGE
  );
  const [loading, setLoading] = useState(true);
  const [openFilterMenu, setOpenFilterMenu] = useState(
    checkFilterForValue(filters)
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleRequestSort = (event, property) => {
    // console.log(property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    event.preventDefault();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedIds =
        tableData && tableData.map((item) => item[PRIMARY_KEY]);
      // console.log({ selectedIds });
      setSelected(selectedIds);
      return;
    }
    setSelected([]);
  };

  const handleCheckBoxClick = (e, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const setCurrentPage = (page) => {
    setPage(page);
  };

  const handleEditClick = (event, row) => {
    const editLinkArr = editLink.split("?");
    history.push({
      pathname: editLinkArr[0],
      search: editLinkArr.length === 2 ? "?" + editLinkArr[1] : null,
      state: { row, type: "edit" },
    });
  };

  const confirmDeleteData = async () => {
    setLoading(true);
    setSelected([]);
    setDeleteDialog(false);
    await deleteRecords(selected);
    setLoading(false);
  };

  const handleDeleteRecords = () => {
    setDeleteDialog(true);
  };

  const cancelDeleteData = () => {
    setDeleteDialog(false);
  };

  const handleFilterIconClick = () => {
    setOpenFilterMenu(!openFilterMenu);
  };

  const handleFilterSubmit = (updatedFilter) => {
    setAppliedFilter(updatedFilter);
    submitFilter(updatedFilter);
  };

  const handleBadgeClick = async ({
    action,
    primaryKeyValue,
    currentValue,
  }) => {
    const updatedStatus = currentValue === 1 ? 0 : 1;
    await badgeClickApi({ action, id: primaryKeyValue, status: updatedStatus });
    const payload = {
      sortBy: order,
      orderBy: orderBy,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    };
    if (dataRefresh) dataRefresh(payload);
  };

  const handleChangeRowsPerPage = (noOfRows) => {
    setRowsPerPage(noOfRows);
    dispatch(setRowsPerPageAction(noOfRows));
    setPage(0);
  };

  const handleTDClick = (index, element) => {
    toggleInlineEdit(index);

    setTimeout(() => {
      const inputElement = document.getElementById(
        "txtInline" + element.id + index
      );
      if (inputElement) inputElement.focus();
    }, 100);
  };

  const toggleInlineEdit = (index) => {
    let copyTableData = [...tableDataState];
    copyTableData[index].inlineEdit = !copyTableData[index].inlineEdit;
    setTableDataState(copyTableData);
  };

  const handleChangeInlineEdit = ({ id, value, index }) => {
    let copyTableData = [...tableDataState];
    copyTableData[index][id] = value;
    setTableDataState(copyTableData);
  };

  const handleInlineEditSave = ({ row, index }) => {
    toggleInlineEdit(index);
    if (editRecord) editRecord(row);
  };

  const handleCancelInlineEdit = (index) => {
    toggleInlineEdit(index);
  };

  const handleManageColumnsClick = () => {
    setShowManageColumns(!showManageColumns);
  };

  const getData = async (payload) => {
    setLoading(true);
    await dataRefresh(payload);
    setLoading(false);
  };

  const handleModalEditClick = (row) => {
    console.log({ row });
    if (editModal) {
      const inputFields = editModal.getInputs(row);
      console.log({ inputFields });
      setEditFormData({
        ...editFormData,
        open: true,
        formInputs: inputFields,
        row,
      });
    }
  };

  const handleModalEditSubmit = async ({ values, errors }) => {
    console.log({ values, errors });
    if (errors.length > 0) return;

    // INFO :  construct setting payload
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
    }

    // INFO : call create api
    const editResponse = await editModal.editApi(
      editFormData.row[PRIMARY_KEY],
      payload
    );

    setEditFormData({ ...editFormData, open: false, submitTrigger: 0 });

    // INFO : check api response and show relevant alert message
    if (editResponse.status) {
      getDataTrigger();
      dispatch(
        setAlertMessage({ type: SUCCESS, message: editModal.EDIT_ALERT })
      );
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: editResponse.message }));
    }
  };

  const handleModalEditSubmitTrigger = () => {
    console.log({ editFormData });
    setEditFormData({
      ...editFormData,
      submitTrigger: editFormData.submitTrigger + 1,
    });
  };

  const handleModalEditCancel = () => {
    setEditFormData({ ...editFormData, open: false });
  };

  const getDataTrigger = () => {
    const payload = {
      sortBy: order,
      orderBy: orderBy,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      page,
    };
    getData(payload);
  };

  useEffect(() => {
    getDataTrigger();
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    setTableDataState(tableData);
  }, [tableData]);

  useEffect(() => {
    console.log({ editFormData });
  }, [editFormData]);

  useEffect(() => {
    const updatedTableColumn = getCurrentFilter(location.pathname);
    let copyColumns = rawColumns;
    const updatedColumns = copyColumns.map((col) => {
      const selected =
        updatedTableColumn &&
        updatedTableColumn.filter &&
        updatedTableColumn.filter.columns &&
        updatedTableColumn.filter.columns.find(
          (curCol) => curCol.id === col.id
        );

      return {
        ...col,
        isVisible: selected ? selected.selected : true,
      };
    });

    setTableColumns(updatedColumns);
  }, [filterList]);

  return (
    <DataGridTemplateComponent
      templateModal={{
        title,
        tableData: tableDataState,
        tableColumn,
        totalCount,
        order,
        orderBy,
        page,
        rowsPerPage,
        selected,
        openDeleteDialog,
        openFilterMenu,
        filters,
        appliedFilters,
        disableActions,
        tableConfig,
        tableControls,
        swapContent,
        tableActions,
        showManageColumns,
        loading,
        editFormData,
      }}
      templateEvents={{
        handleRequestSort,
        handleCheckBoxClick,
        setCurrentPage,
        handleSelectAllClick,
        isSelected,
        handleEditClick,
        handleDeleteRecords,
        cancelDeleteData,
        confirmDeleteData,
        handleDownload,
        handleFilterIconClick,
        handleFilterSubmit,
        handleBadgeClick,
        handleChangeRowsPerPage,
        handleTDClick,
        handleChangeInlineEdit,
        handleInlineEditSave,
        handleCancelInlineEdit,
        handleManageColumnsClick,
        handleModalEditClick,
        handleModalEditSubmit,
        handleModalEditSubmitTrigger,
        handleModalEditCancel,
      }}
    />
  );
};

export default DataGridComponent;
