import MESSAGES from "@constants/messages.constants";
import { setAlertMessage } from "@reducers/alert.reducer";
import PermissionDeniedComponent from "@screens/permission-denied/permission-denied.component";
import { checkMenuAccess } from "@utils/menu-auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createEntry } from "@screens/catalog-rural/candidate-voter/voter-service";
import VoterCreateTemplateComponent from "./voter-create-template.component";
import { allVoterFields } from "@screens/voter-field/voter-field-list/voter-field-list.component.service";
import { getRuralWardBoothByRuralWardId } from "@screens/catalog-rural/rural-ward-booth/rural-ward-booth-list/rural-ward-booth-list.component.service";
import { menuUrl } from "../voter-list/model/voter-list.constants";
import { getFormInputs } from "../model/voter.modal";

const {
  ALERT_TYPES: { SUCCESS, ERROR },
  AC_VOTER_ALERTS: { CREATE_ALERT },
} = MESSAGES;

const RuralVoterCreateComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuAccess = checkMenuAccess(menuUrl, "write");
  const { currentElection } = useSelector((state) => state.election);
  const [formInputs, setFormInputs] = useState([]);
  const electionId = new URLSearchParams(location.search).get("electionId");
  const panchayatUnionWardId = new URLSearchParams(location.search).get(
    "panchayatUnionWardId"
  );
  const panchayatUnionWardName = new URLSearchParams(location.search).get(
    "panchayatUnionWardName"
  );
  const [formTriggers, setFormTriggers] = useState({
    submitTrigger: 0,
    validationTrigger: 0,
  });
  const [voterFields, setVoterFields] = useState([]);
  const handleTriggerClick = (type) => {
    if (type === "SUBMIT") {
      setFormTriggers({
        ...formTriggers,
        submitTrigger: formTriggers.submitTrigger + 1,
      });
    }
  };

  const handleBackClick = () => {
    history.push(
      `/${menuUrl}/voter?panchayatUnionWardId=${panchayatUnionWardId}&electionId=${electionId}&panchayatUnionWardName=${panchayatUnionWardName}`
    );
  };

  const handleSubmit = async ({ values, errors }) => {
    console.log({ values });
    // INFO : check for errors and return if errors found
    if (errors.length > 0) return;

    // INFO :  construct payload
    let voterField = {};
    let payload = {};
    for (let i = 0; i < values.length; i++) {
      payload[values[i].name] = values[i].value;
      voterFields.map((field) => {
        if (values[i].name == field.name)
          voterField[field.name] = values[i].value;
      });
    }
    payload["ward_id"] = panchayatUnionWardId;
    payload["rural_type"] = "PANCHAYAT UNION WARD";
    payload["voter_fields"] = voterField;

    // INFO : call create api
    const response = await createEntry(currentElection.id, payload);

    // INFO : check api response and show relevant alert message
    if (response.status) {
      dispatch(setAlertMessage({ type: SUCCESS, message: CREATE_ALERT }));
      history.push(
        `/${menuUrl}/voter?panchayatUnionWardId=${panchayatUnionWardId}&electionId=${electionId}&panchayatUnionWardName=${panchayatUnionWardName}`
      );
    } else {
      dispatch(setAlertMessage({ type: ERROR, message: response.message }));
    }
  };

  const onLoad = async () => {
    const boothResponse = await getRuralWardBoothByRuralWardId(
      panchayatUnionWardId
    );
    let formattedBooths;
    if (boothResponse.status) {
      formattedBooths = boothResponse.data.map((data) => {
        return {
          name: data.name,
          value: data.id,
        };
      });
    }
    const formInputData = getFormInputs({
      booths: formattedBooths || [{ name: "-", value: null }],
      initialValues: {},
    });
    // console.log({ formattedBooths, formInputData });
    setFormInputs(formInputData);

    // Voter List
    const voterFieldsResponse = await allVoterFields();
    // console.log({ voterFieldsResponse });
    if (voterFieldsResponse.status) {
      const allVoterFields = voterFieldsResponse.data.map((field) => {
        return {
          id: field.name,
          label: field.name,
          type: "TEXT",
          name: field.name,
          placeholder: `Enter the ${field.name}`,
          value: "",
        };
      });
      setVoterFields(allVoterFields);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const templateModal = {
    formTriggers,
    formInputs: [...formInputs, ...voterFields],
    panchayatUnionWardId,
    electionId,
    panchayatUnionWardName,
  };

  const templateEvents = {
    handleSubmit,
    handleTriggerClick,
    handleBackClick,
  };

  return menuAccess ? (
    <VoterCreateTemplateComponent
      templateModal={templateModal}
      templateEvents={templateEvents}
    />
  ) : (
    <PermissionDeniedComponent />
  );
};

export default RuralVoterCreateComponent;
