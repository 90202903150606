import { APP } from "@constants/app.constants";
import { formatDateUtil } from "@utils/date.util";

const { LEADS_TYPE, INDIA_STATES } = APP;
const tableTitle = "Symbols List";
const keyColumn = "id";
const menuUrl = "manage/symbols";
const tableColumn = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: 150,
  },

  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    formatData: formatDateUtil,
  },
];
const breadCrumbs = [
  { name: "Manage", menu_url: null },
  { name: "Symbols", menu_url: "/manage/symbols" },
];

const filters = [
  {
    name: "name",
    id: "name",
    label: "Name",
    value: "",
    type: "TEXT",
    validations: [],
    isVertical: true,
    groupClass: "col-md-3",
  },
];

export { tableTitle, keyColumn, tableColumn, breadCrumbs, menuUrl, filters };
