import { applyMiddleware, compose, createStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import rootReducer from "@reducers/index.reducer";

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["app", "menu", "election"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware())
);

const persistor = persistStore(store);

export { store, persistor };
