import { APP } from "@constants/app.constants";
const { RESERVATION, AC_URBAN_TYPE } = APP;

export const getACUrbanFormInputs = ({
  states,
  pcs,
  acs,
  initialValues,
  onStateChange,
  onPcChange,
}) => [
  {
    type: "TEXT",
    label: "Govt ID",
    id: "govtId",
    name: "govt_id",
    value: initialValues?.govtId || "",
    placeholder: "Enter the AC Urban ID",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Urban ID is required !",
      },
    ],
  },
  {
    type: "TEXT",
    label: "Manager Name",
    id: "manager",
    name: "manager",
    value: initialValues?.manager || "",
    placeholder: "Enter the Manager Name",
    validations: [],
  },
  {
    type: "SELECT",
    label: "State Name",
    id: "stateId",
    name: "state_id",
    options: states,
    value: initialValues?.stateId || "",
    placeholder: "Select State",
    onChange: onStateChange,
    validations: [
      {
        type: "REQUIRED",
        message: "State Name is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "PC Name",
    id: "pcId",
    name: "pc_id",
    options: pcs,
    value: initialValues?.pcId || "",
    placeholder: "Select PC",
    onChange: onPcChange,
    validations: [
      {
        type: "REQUIRED",
        message: "PC Name is required !",
      },
    ],
  },
  {
    type: "SELECT",
    label: "AC Name",
    id: "acId",
    name: "ac_id",
    options: acs,
    value: initialValues?.acId || "",
    placeholder: "Select AC",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "AC Urban Name",
    id: "name",
    name: "name",
    value: initialValues?.name || "",
    placeholder: "Enter the AC Urban Name",
    validations: [
      {
        type: "REQUIRED",
        message: "AC Urban Name is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Regional Name",
    id: "regionalName",
    name: "regional_name",
    value: initialValues?.regionalName || "",
    placeholder: "Enter the Regional Name",
    validations: [],
  },
  {
    type: "RADIO",
    label: "Type",
    id: "type",
    name: "type",
    options: AC_URBAN_TYPE,
    value: initialValues?.type || "",
    validations: [],
  },
  {
    type: "SELECT",
    label: "Reservation",
    id: "reservation",
    name: "reservation",
    options: RESERVATION,
    value: initialValues?.reservation || "",
    placeholder: "Select Reservation",
    validations: [
      {
        type: "REQUIRED",
        message: "Reservation is required !",
      },
    ],
  },

  {
    type: "TEXT",
    label: "Total Ward",
    id: "totalWardCount",
    name: "total_ward_count",
    value: initialValues?.totalWardCount || "",
    placeholder: "Enter the Total Ward",
    validations: [],
  },

  {
    type: "TEXT_AREA",
    label: "Remarks",
    id: "remarks",
    name: "remarks",
    value: initialValues?.remarks || "",
    placeholder: "Enter the Remarks",
    validations: [],
  },
];
